import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/Forms";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { limparSalvarTabela } from "../../store/reducers/tabelaCobranca";
import TabelaCobranca from "../../components/TabelaCobranca/Tabela";
import CadastrarTabela from "src/components/TabelaCobranca/ModalCadastrarTabela";
import { useMultiResponseSnackbar, useResponsive, useSnackbar } from "src/ds/CustomHooksDs";
import { ModalOpcoes, SnackbarAlert, TableNavigation } from "src/ds/DesignSystem";
import { converterDataCadastro } from "src/utils";
import { definirTipoDeCobrancaStart, fetchMasterPageStart, limparTipoDeCobranca } from "src/store/reducers/config";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store/reducers";

const TabelaCobrancaPage = () => {
    const tabelasCobrancaDados = useSelector((state: any) => state.tabelaCobranca.listaTabelasCobranca?.data?.dados);
    const tabelasCobranca = tabelasCobrancaDados?.lista;
    const loadingTabelasCobranca = useSelector((state: any) => state.tabelaCobranca.listaTabelasCobranca?.loading);
    const [openCadastro, setOpenCadastro] = useState(false);
    const [openModalOpcoes, setOpenModalOpcoes] = useState(false);
    const [tipoCobranca, setTipoCobranca] = useState<string | number>('');
    const dispatch = useDispatch();
    const [ultimaTabelaCadastrada, setUltimaTabelaCadastrada] = useState(null);
    const [triggerEditar, setTriggerEditar] = useState(false);
    const snack = useSnackbar({ duration: 2000 });
    const response = useSelector((state: any) => state.tabelaCobranca.salvarTabela);
    const response2 = useSelector((state: any) => state.config.tipoDeCobranca);
    const navigate = useNavigate();
    const metodoCobranca = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoCobranca);


    useMultiResponseSnackbar([
        { response: response, clearAction: () => dispatch(limparSalvarTabela()) },
        { response: response2, clearAction: () => dispatch(limparTipoDeCobranca()) }
    ], snack);



    useEffect(() => {
        if (tabelasCobranca?.length > 0) {
            const storedId = localStorage.getItem('lastEditedTabelaId');

            const cincoMinutosAtras = new Date(new Date().getTime() - 0.5 * 60 * 1000);

            const tabelasRecentes = tabelasCobranca?.filter((tabela: any) => {
                const dataCadastro = converterDataCadastro(tabela.dataCadastro);
                return dataCadastro > cincoMinutosAtras;
            });

            const tabelaMaisRecente = tabelasRecentes?.reduce((maisRecente: any, tabelaAtual: any) => {
                const dataMaisRecente = converterDataCadastro(maisRecente?.dataCadastro || '');
                const dataAtual = converterDataCadastro(tabelaAtual?.dataCadastro);
                return dataAtual > dataMaisRecente ? tabelaAtual : maisRecente;
            }, null);

            if (tabelaMaisRecente && tabelaMaisRecente.tabelaCobrancaID !== storedId) {
                setUltimaTabelaCadastrada(tabelaMaisRecente);
                setTriggerEditar(true);
                localStorage.setItem('lastEditedTabelaId', tabelaMaisRecente.tabelaCobrancaID);
                navigate(`/TabelaCobranca/Index/${tabelaMaisRecente.tabelaCobrancaID}`);
            }
        }
    }, [tabelasCobranca]);

    const hookForm = useForm({
        defaultValues: {
            nome: '',
            dataInicial: '',
            dataFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true);
    const cardRef = useRef<CardFormFiltrosRef>(null);
    const tipoUsuario = useSelector((state: any) => state.config?.tipoDeUsuario);
    const { watch } = hookForm;

    useEffect(() => {
        if (primeiraRenderizacao) {
            cardRef.current?.onSubmit();
            setPrimeiraRenderizacao(false);
            return
        }

        const subscription = watch((_value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, primeiraRenderizacao]);

    const handleTipoCobrancaChange = (event: any) => {
        setTipoCobranca(event.target.value);
    };

    useEffect(() => {
        setTipoCobranca(metodoCobranca);
    }, [])

    const handleConfirmar = () => {
        // @ts-ignore
        dispatch(definirTipoDeCobrancaStart({ tipo: tipoCobranca }));

        setOpenModalOpcoes(false);
    };

    const { isMobile } = useResponsive();

    return (
        <Box>
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'listaTabelaCobrancaStart'
                    }}
                    fields={[
                        { name: "nome", value: "nome", isFiltro: true, label: "Buscar por nome" },
                    ]}
                    loading={loadingTabelasCobranca}
                />
            </Box>

            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">TABELAS DE COBRANÇA</Typography>
                    <Stack gap={2} alignContent={"flex-end"} direction={isMobile ? "column" : "row"}>
                        {tipoUsuario === 'SAAS' &&
                            <Button variant="containedFilled" color="secondary" sx={{ flex: 1 }} onClick={() => setOpenModalOpcoes(true)}>
                                Método de cálculo
                            </Button>}
                        <Button variant="containedFilled" sx={{ flex: 1 }} onClick={() => setOpenCadastro(true)}>
                            Inserir
                        </Button>
                    </Stack>
                </Box>

                {tabelasCobrancaDados?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={tabelasCobrancaDados.indiceInicial}
                        indiceFinal={tabelasCobrancaDados.indiceFinal}
                        indiceTotal={tabelasCobrancaDados.total}
                        ultimaPagina={tabelasCobrancaDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <TabelaCobranca
                    fezRequisicao={cardRef.current?.fezRequisicao}
                    tabelasCobranca={tabelasCobranca}
                    loading={loadingTabelasCobranca}
                    atualizar={() => cardRef.current?.onSubmit()}
                    ultimaTabelaCadastrada={ultimaTabelaCadastrada}
                    triggerEditar={triggerEditar}
                    setTriggerEditar={setTriggerEditar}
                />
            </Card>
            <CadastrarTabela open={openCadastro} onClose={() => {
                setOpenCadastro(false);
                setTimeout(() => {
                    cardRef.current?.onSubmit();
                }, 1000)
            }} />
            <ModalOpcoes
                open={openModalOpcoes}
                onClose={() => { setOpenModalOpcoes(false); }}
                acao="Definir método de cálculo"
                opcoes={[
                    { label: 'Cancelar', action() { setOpenModalOpcoes(false); } },
                    { label: 'Confirmar', action: handleConfirmar, disabled: tipoCobranca === '' },
                ]}
                // @ts-ignore
                descricao={
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel shrink htmlFor="estado-label" >Tipos</InputLabel>
                        <Select
                            displayEmpty
                            size="small"
                            id="tipos-label"
                            label="Tipos"
                            sx={{ textAlign: 'left' }}
                            value={tipoCobranca}
                            onChange={handleTipoCobrancaChange}

                        >
                            <MenuItem disabled value=''>
                                Selecione um tipo
                            </MenuItem>
                            <MenuItem key={0} value={0}>
                                Por raio
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                                Por rota
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                Por bairro
                            </MenuItem>
                        </Select>
                    </FormControl>
                }
            />
            <SnackbarAlert {...snack} />
        </Box>
    );
};

export default TabelaCobrancaPage;
