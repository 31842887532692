import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "../../services/api";
import { buscarPorCodigo, buscarPorCodigoFailure, buscarPorCodigoSuccess, buscarPorTelefone, buscarPorTelefoneFailure, buscarPorTelefoneSuccess, calcularValores, calcularValoresFailure, calcularValoresSuccess, cancelarPedidoError, cancelarPedidoRequest, cancelarPedidoSuccess, desatribuirFailure, desatribuirRequest, desatribuirSuccess, despacharFailure, despacharRequest, despacharSuccess, fazerPedido, fazerPedidoFailure, fazerPedidoSuccess, limparMensagens, marcarFinalizadoFailure, marcarFinalizadoRequest, marcarFinalizadoSuccess, registrarOcorrencia, registrarOcorrenciaFailure, registrarOcorrenciaSuccess } from "../reducers/criarPedido";

export function* buscarPorCodigoRequest(action) {
    const codigoPedido = action.payload.codigoPedido;
    const empresaID = action.payload.empresaID;
    const form = new FormData();
    form.append('codigoPedido', codigoPedido)
    form.append('empresaID', empresaID)
    try {
        const response = yield call(api.post, '/Pedido/BuscarPorCodigo', form);
        if (response.data?.modelo) {
            yield put(buscarPorCodigoSuccess(response.data.modelo));
        } else if (response.data.modelo === null) {
            yield put(buscarPorCodigoFailure("Modelo não encontrado."));
        }
    } catch (error) {
        yield put(buscarPorCodigoFailure(error.toString()));
    }
}

export function* buscarPorTelefoneRequest(action) {
    const telefone = (action.payload.telefone).replace(/\D/g, '');
    const empresaID = action.payload.empresaID;
    const form = new FormData();
    form.append('telefone', telefone)
    form.append('empresaID', empresaID)
    try {
        const response = yield call(api.post, '/EmpresaCliente/BuscarPorTelefone', form);
        if (response.data.sucesso === true) {
            yield put(buscarPorTelefoneSuccess({
                telefoneReentrega: true,
                ...response.data.modelo
            }));
        } else if (response.data.sucesso === false) {
            yield put(buscarPorTelefoneFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(buscarPorTelefoneFailure(error.toString()));
    }
}

export function* criarPedidoRequest(action) {
    const form = action.payload;
    try {
        const response = yield call(api.post, '/Pedido/Salvar', form);

        if (response.data.sucesso === true) {
            yield put(fazerPedidoSuccess(response.data));
        } else {
            yield put(fazerPedidoFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(fazerPedidoFailure(error.toString()));
    }
}

export function* calcularValoresRequest(action) {
    const form = action.payload;
    try {
        const response = yield call(api.post, '/Pedido/CalcularValores', form);
        yield put(calcularValoresSuccess(response.data.valores));
    } catch (error) {
        yield put(calcularValoresFailure(error.toString()));
    }
}

export function* cancelarPedido(action) {
    const form = new FormData();
    form.append('pedidoID', action.payload)
    try {
        const response = yield call(api.post, '/Pedido/Cancelar', form);
        yield put(cancelarPedidoSuccess(response.data));
        // TODO
        yield put(limparMensagens());

    } catch (error) {
        yield put(cancelarPedidoError(error.toString()));
    }
}

export function* marcarFinalizado(action) {
    const form = new FormData();
    form.append('pedidoID', action.payload)
    try {
        const response = yield call(api.post, '/Pedido/MarcarFinalizado', form);
        yield put(marcarFinalizadoSuccess(response.data));
        if (response.data.sucesso !== true) {
            yield put(marcarFinalizadoFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(marcarFinalizadoFailure(error.toString()));
    }
}

export function* desatribuir(action) {

    const pedidoID = action.payload.pedidoID

    try {
        const response = yield call(api.post, `/Pedido/DesatribuirEntregador?pedidoID=${pedidoID}`);
        yield put(desatribuirSuccess(response.data.dados));
        yield put(limparMensagens());
    } catch (error) {
        yield put(desatribuirFailure(error.toString()));
    }
}

export function* despacharPedido(action) {
    const form = new FormData();
    const usuarioID = action.payload.usuarioID;
    const pedidoID = action.payload.pedidoID;
    const rota = action.payload?.rota;
    const orcamento = action.payload?.orcamento;
    const cobrarKm = action.payload?.cobrarKm;
    const taxaExtraIDs = action.payload?.taxaExtraIDs;
    if (taxaExtraIDs && Array.isArray(taxaExtraIDs)) {
        taxaExtraIDs.forEach((id, index) => {
            form.append(`taxaExtraIDs[${index}]`, id);
        })
    }

    if (rota && Array.isArray(pedidoID)) {
        pedidoID.forEach((id, index) => {
            form.append(`pedidoIDs[${index}]`, id);
        });
    } else {
        form.append('pedidoIDs[0]', pedidoID);
    }

    form.append('usuarioID', usuarioID);

    try {
        const response = yield call(api.post, `/Pedido/Despachar?gerarRota=${rota}&orcamento=${orcamento}&cobrarPorKmTotal=${cobrarKm}`, form);
        yield put(despacharSuccess(response.data));
        if (response.data.sucesso !== true) {
            yield put(despacharFailure(response.data));
        }
    } catch (error) {
        yield put(despacharFailure(error.toString()));
    }
}

export function* registrarOcorrenciaReq(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/UsuarioOcorrencia/Salvar', form);

        if (response.data.sucesso === true) {
            yield put(registrarOcorrenciaSuccess(response.data.mensagem));
        } else {
            yield put(registrarOcorrenciaFailure(response.data.mensagem));
        }
    } catch (error) {
        yield put(registrarOcorrenciaFailure(error.toString()));
    }
}
function* criarPedidoSaga() {
    yield takeLatest(buscarPorCodigo.type, buscarPorCodigoRequest);
    yield takeLatest(buscarPorTelefone.type, buscarPorTelefoneRequest);
    yield takeLatest(fazerPedido.type, criarPedidoRequest);
    yield takeLatest(cancelarPedidoRequest.type, cancelarPedido);
    yield takeLatest(marcarFinalizadoRequest.type, marcarFinalizado);
    yield takeLatest(desatribuirRequest.type, desatribuir);
    yield takeLatest(despacharRequest.type, despacharPedido);
    yield takeLatest(calcularValores.type, calcularValoresRequest);
    yield takeLatest(registrarOcorrencia.type, registrarOcorrenciaReq);
}

export default criarPedidoSaga;