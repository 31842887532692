import { Box, Typography, Tabs, Tab, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DadosBasicos from './DadosBasicos';
import { useDispatch, useSelector } from 'react-redux';
import { listaEntregadoresStart, usuarioPaginaStart } from 'src/store/reducers/entregador';
import Info from './Info';
import { ArrowBackIos } from '@mui/icons-material';
import { RootState } from 'src/store/reducers';


interface TabConfig {
    label: string;
    content: React.ReactNode;
}

const TabsEntregadores: React.FC = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(usuarioPaginaStart({ id: id }))
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const tabsConfig: TabConfig[] = [
        { label: 'Dados Básicos', content: <DadosBasicos id={id} /> },
        { label: 'Informações do entregador', content: <Info /> },
    ];

    const navigate = useNavigate();


    const entregadorAtual = useSelector((state: RootState) => state.entregadores?.usuarioPagina?.data?.modelo)
    const theme = useTheme();

    return (
        <Box p={2} sx={{ backgroundColor: 'background.paper' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <ArrowBackIos
                    sx={{ cursor: 'pointer', mr: 1 }}
                    onClick={() => navigate("/Usuario/Entregadores")}
                />
                <Typography variant="h6">

                    <>
                        Editar {entregadorAtual?.nome} <span style={{ fontSize: '0.6em', color: theme.palette.grey[400] }}>({id})</span>
                    </>

                </Typography>
            </Box>

            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Tabs de Edição"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ minHeight: '48px' }}
            >
                {tabsConfig.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                        sx={{
                            minWidth: '100px',
                            maxWidth: '200px',
                            color: 'text.primary',
                            fontSize: '12px',
                            textTransform: 'none'
                        }}
                    />
                ))}
            </Tabs>

            <Box sx={{ mt: 3 }}>
                {tabsConfig[activeTab].content}
            </Box>
        </Box>
    );
};

export default TabsEntregadores;
