import { Box, Stack, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'src/ds/CustomHooksDs';
import { BoxLoading, SnackbarAlert } from 'src/ds/DesignSystem';
import { RootState } from 'src/store/reducers';
import { pedidoMapaRastreioStart } from 'src/store/reducers/pedidos';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_API_KEY } from 'src/utils';
import { MapaPoint } from 'src/dtos/PedidosDTO';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import { getSituacaoStyles } from 'src/ds/common';


mapboxgl.accessToken = MAPBOX_API_KEY;

type Coordinate = {
  latitude: number;
  longitude: number;
};

function popUpPoint(point: MapaPoint) {
  function formatSpeed(speed: number) { return `${speed.toFixed(1)} km/h` };

  return (`
    <div class="markerPointPedido">
      <p><strong>Data:</strong> ${point.time}</p>
      <p><strong>Situação:</strong> ${point.situacao}</p>
      <p><strong>Velocidade:</strong> ${formatSpeed(point.velocidade)}</p>
    </div>
  `);
}

const MapaPedido = () => {
  const { id: pedidoID } = useParams();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const theme = useTheme();
  const pedidoMapaRastreio = useSelector((state: RootState) => state.pedidos.pedidoMapaRastreio);
  const template = useSelector((state: RootState) => state.config.template?.template);
  const mapRef = useRef<null | HTMLElement>(null);
  const mapboxMap = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  const [mapCenter, setMapCenter] = useState({ lat: -15.7801, lng: -47.9292 });
  const [zoom, setZoom] = useState(13);
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState<null | number>(null);

  const calculateCenter = (point1: Coordinate, point2: Coordinate) => {
    const latCenter = (point1.latitude + point2.latitude) / 2;
    const lngCenter = (point1.longitude + point2.longitude) / 2;
    return { lat: latCenter, lng: lngCenter };
  };

  const calculateDistance = (point1: Coordinate, point2: Coordinate) => {
    const R = 6371;
    const dLat = ((point2.latitude - point1.latitude) * Math.PI) / 180;
    const dLng = ((point2.longitude - point1.longitude) * Math.PI) / 180;
    const lat1 = (point1.latitude * Math.PI) / 180;
    const lat2 = (point2.latitude * Math.PI) / 180;

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.sin(dLng / 2) ** 2 * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const determineZoomLevel = (distance: number) => {
    if (distance < 1) return 15;
    if (distance < 5) return 13;
    if (distance < 10) return 12;
    if (distance < 20) return 10;
    if (distance < 50) return 8;
    if (distance < 100) return 6;
    return 5;
  };

  useEffect(() => {
    if (!pedidoID) return;
    dispatch(pedidoMapaRastreioStart({ pedidoID }));
  }, [pedidoID, dispatch]);

  useEffect(() => {
    if (template && mapboxMap.current) {
      const mapStyle = theme.palette.mode === 'light' ? template.mapa : 'mapbox://styles/pickngo-demo/clkbehgyk03kl01ns9k0t2o39';
      mapboxMap.current.setStyle(mapStyle);
    }
  }, [template, theme.palette.mode]);

  useEffect(() => {
    if (pedidoMapaRastreio.error) {
      snackbar.showError(pedidoMapaRastreio.error);
    }
  }, [pedidoMapaRastreio.error, snackbar]);

  useEffect(() => {
    if (!mapboxMap.current && mapRef.current) {
      mapboxMap.current = new mapboxgl.Map({
        container: mapRef.current,
      });
      mapboxMap.current.addControl(new mapboxgl.NavigationControl());
    }
    // return () => mapboxMap.current?.remove();
  }, []);

  useEffect(() => {
    if (mapboxMap.current) {
      mapboxMap.current.setCenter(mapCenter);
    }
  }, [mapCenter]);

  useEffect(() => {
    if (zoom && mapboxMap.current) {
      mapboxMap.current.setZoom(zoom);
    }
  }, [zoom]);

  const handleFocusMarker = (index: number) => {
    setCurrentMarkerIndex(index);
    const marker = markersRef.current[index];

    markersRef.current.forEach(marker => {
      const popup = marker?.getPopup();
      if (popup && popup.isOpen()) marker.togglePopup();
    });

    if (marker && mapboxMap.current) {
      mapboxMap.current.flyTo({
        center: marker.getLngLat(),
        zoom: 18,
        speed: 1.3,
        curve: 1.5,
        essential: true
      });
      marker.togglePopup();
    }
  }

  useEffect(() => {
    if (!mapboxMap.current || !pedidoMapaRastreio.data?.points) {
      setCurrentMarkerIndex(null);
      return
    };

    const cordOrigem = pedidoMapaRastreio.data.estabelecimento;
    const cordDestino = pedidoMapaRastreio.data.enderecoEntrega;

    if (cordOrigem && cordDestino) {
      const distance = calculateDistance(cordOrigem, cordDestino);
      const zoomLevel = determineZoomLevel(distance);
      setZoom(zoomLevel);

      const newMapCenter = calculateCenter(cordOrigem, cordDestino);
      setMapCenter(newMapCenter);
    };

    // Remove os markers e linhas antes de cada iteração
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];

    const coordinates: number[][] = []; // [longitude, latitude][]

    pedidoMapaRastreio.data.points.forEach((ponto, index) => {
      const { latitude: lat, longitude: lng } = ponto;
      coordinates.push([lng, lat]);

      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.textContent = (index + 1).toString();

      // @ts-expect-error
      const colors: { backgroundColor: string, color: string } = getSituacaoStyles(ponto.situacao, theme);

      el.style.backgroundColor = colors.backgroundColor;
      el.style.color = colors.color;

      if (!mapboxMap.current) return;
      const marker = new mapboxgl.Marker({ element: el })
        .setLngLat([lng, lat])
        .addTo(mapboxMap.current)
        .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popUpPoint(ponto)));
      marker.getElement().addEventListener('click', () => handleFocusMarker(index + 1));// marker da empresa ocupa o index 0

      markersRef.current.push(marker);
    });

    coordinates.unshift([cordOrigem.longitude, cordOrigem.latitude]);
    coordinates.push([cordDestino.longitude, cordDestino.latitude]);

    const elOrigem = document.createElement('div');
    elOrigem.className = 'custom-marker empresa-marker';
    const markerEmpresa = new mapboxgl.Marker({ element: elOrigem })
      .setLngLat([cordOrigem.longitude, cordOrigem.latitude])
      .addTo(mapboxMap.current)
      .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<h3 style="color: #000000;">Endereço de coleta</h3>`))
      .on('click', () => handleFocusMarker(0));
    markerEmpresa.getElement().addEventListener('click', () => handleFocusMarker(0));// 0 representa o marker da empresa

    const elDestino = document.createElement('div');
    elDestino.className = 'custom-marker pedido-marker';
    const markerPedido = new mapboxgl.Marker({ element: elDestino })
      .setLngLat([cordDestino.longitude, cordDestino.latitude])
      .addTo(mapboxMap.current)
      .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(`<h3 style="color: #000000;">Endereço de entrega</h3>`))
    markerPedido.getElement().addEventListener('click', () => handleFocusMarker(markersRef.current.length - 1));// marker do pedido sempre fica na ultima posição

    // Adiciona os markers ao array
    markersRef.current.unshift(markerEmpresa);
    markersRef.current.push(markerPedido);

    // Função para adicionar a camada de rota
    const addRouteLayer = () => {
      if (mapboxMap.current) {
        if (mapboxMap.current.getSource('route')) {
          mapboxMap.current.removeLayer('route');
          mapboxMap.current.removeSource('route');
        }

        mapboxMap.current?.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: coordinates,
            },
            properties: {},
          },
        });

        mapboxMap.current?.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#888',
            'line-width': 4,
          },
        });
      };
    };

    // Caso mapa esteja carregado, adiciona a camada de rota, se não, aguarda o carregamento e adiciona
    if (mapboxMap.current.isStyleLoaded()) {
      addRouteLayer();
    } else {
      mapboxMap.current.on("load", addRouteLayer);
    }

    return () => {
      // Remove as linhas ao desmontar o componente
      if (mapboxMap.current && mapboxMap.current.isStyleLoaded()) {
        // Verifica e remove a camada de rota, caso ela exista
        if (mapboxMap.current.getLayer('route')) {
          mapboxMap.current.removeLayer('route');
        }
        if (mapboxMap.current.getSource('route')) {
          mapboxMap.current.removeSource('route');
        }
      } else {
        // Adiciona um listener para remover a camada e fonte após o carregamento do estilo
        mapboxMap.current?.on('style.load', () => {
          if (mapboxMap.current?.getLayer('route')) {
            mapboxMap.current.removeLayer('route');
          }
          if (mapboxMap.current?.getSource('route')) {
            mapboxMap.current.removeSource('route');
          }
        });
      }
    };
  }, [pedidoMapaRastreio.data, mapboxMap.current]);

  return (
    <>
      <BoxLoading
        loading={pedidoMapaRastreio.loading || !mapboxMap.current}
        sx={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          sx={{ width: '100%', mb: 2, alignItems: 'flex-end' }}
        >
          <ToggleButtonGroup
            size="small"
            value={currentMarkerIndex}
            disabled={currentMarkerIndex === null}
            exclusive
            color='primary'
            sx={{ width: 'fit-content' }}
          >
            <ToggleButton
              value=""
              disabled={currentMarkerIndex === 0}
              onClick={() => handleFocusMarker(0)}
            >
              <KeyboardDoubleArrowLeftRoundedIcon />
            </ToggleButton>
            <ToggleButton
              value=""
              disabled={currentMarkerIndex === 0}
              onClick={() => handleFocusMarker((currentMarkerIndex ?? 1) - 1)}
            >
              <KeyboardArrowLeftRoundedIcon />
            </ToggleButton>
            <ToggleButton
              sx={{ width: '40px' }}
              value={currentMarkerIndex ?? ''}
              onClick={() => handleFocusMarker(currentMarkerIndex ?? 0)}
            >
              {currentMarkerIndex ?? '-'}
            </ToggleButton>
            <ToggleButton
              value=""
              disabled={currentMarkerIndex === markersRef.current.length - 1}
              onClick={() => handleFocusMarker((currentMarkerIndex ?? -1) + 1)}
            >
              <KeyboardArrowRightRoundedIcon />
            </ToggleButton>
            <ToggleButton
              value=""
              disabled={currentMarkerIndex === markersRef.current.length - 1}
              onClick={() => handleFocusMarker(markersRef.current.length - 1)}
            >
              <KeyboardDoubleArrowRightRoundedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Box ref={mapRef} sx={{ width: '100%', flex: '1' }} />
      </BoxLoading >
      <SnackbarAlert {...snackbar} />
    </>
  );
};

export default MapaPedido;
