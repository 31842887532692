import { useState, useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

/**
 * @deprecated - Use o hook useSnackbar do arquivo `ds/hooks/UseSnackbar.tsx`
 */
export const useSnackbar = (initialOptions = {}) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(initialOptions?.message || '');
    const [type, setType] = useState(initialOptions?.type || 'success');

    const duration = initialOptions?.duration || 0;

    const showSnackbar = (message, type, duration = 0) => {
        if (typeof message === 'object') {
            setMessage(JSON.stringify(message));
        } else {
            message && setMessage(message);
        }

        // message && setMessage(message);
        type && setType(type);
        setOpen(true);
        if (duration > 0) {
            setTimeout(() => {
                setOpen(false);
            }, duration);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            // Evita fechar o Snackbar em determinados casos
            return;
        }
        setOpen(false);
    };

    return {
        duration,
        open,
        setOpen,
        message,
        setMessage,
        type,
        setType,
        showSnackbar,
        handleClose
    };
};

/**
 * @deprecated - Use o hook useResponsive do arquivo `ds/customHooks.jsx` 
 */
export const useResponsive = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return {
        isMobile
    }
}
