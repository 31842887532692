import { Box, Typography, TextField, Button, Stack, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { cleanInput } from "src/ds/common";
import { HeaderModal, ModalBase, SelectToggleAtivo } from "src/ds/DesignSystem";
import { liveMaskMoney } from "src/ds/Utils";
import { listarTipoVeiculoTabelaPagamentoStart, salvarTabelaStart } from "src/store/reducers/tabelaPagamento";

interface FormValues {
    nome: string;
    ativo: boolean;
    valorPontoAdicional: string;
    tipoVeiculoID: string;
}

const CadastrarTabela = ({ open, onClose }: any) => {
    const hookForm = useForm<FormValues>({
        defaultValues: {
            nome: "",
            ativo: true,
            valorPontoAdicional: "0",
            tipoVeiculoID: '',
        },
    });
    const { register, handleSubmit, control, watch, formState: { errors, isSubmitting }, reset } = hookForm;

    const [isSubmitDisabled, setSubmitDisabled] = useState(false);

    const tiposDeVeiculoDados = useSelector((state: any) => state.tabelaPagamento?.tiposDeVeiculo?.data?.dados);
    const tiposDeVeiculo = tiposDeVeiculoDados?.lista;
    const dispatch = useDispatch();

    const onSubmit = (data: FormValues) => {
        setSubmitDisabled(true);
        try {
            const cleanedValues = { ...data, valorPontoAdicional: cleanInput(data?.valorPontoAdicional) };
            dispatch(salvarTabelaStart(cleanedValues));
            reset();
            onClose();
            setSubmitDisabled(false);
        } catch (error) {
            setSubmitDisabled(false);
        }
    };

    useEffect(() => {
        dispatch(listarTipoVeiculoTabelaPagamentoStart({
            pagina: 1,
            paginacao: -1,
        }));
    }, []);

    return (
        <ModalBase open={open} onClose={onClose} size="sm">
            <HeaderModal title="Cadastrar Tabela" onClose={onClose} />
            <Box p={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <TextField
                            label="Nome"
                            fullWidth
                            size="small"
                            {...register("nome", { required: "Nome é obrigatório" })}
                            error={!!errors.nome}
                            helperText={errors.nome ? errors.nome.message : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl fullWidth error={!!errors.tipoVeiculoID}>
                            <InputLabel shrink htmlFor="tipoVeiculoID-label">Tipo de veículo</InputLabel>
                            <Select
                                displayEmpty
                                size="small"
                                id="tipoVeiculoID-label"
                                label="Tipo de ve'iculo"
                                {...register("tipoVeiculoID", { required: "O tipo de veículo é obrigatório" })}
                                value={watch('tipoVeiculoID')}
                                sx={{ textAlign: 'left' }}
                                defaultValue=""
                            >
                                <MenuItem value=''>
                                    Selecione um tipo de veículo
                                </MenuItem>
                                {tiposDeVeiculo?.map((tipoVeiculo: any, index: any) => (
                                    <MenuItem key={index} value={tipoVeiculo.tipoVeiculoID}>
                                        {tipoVeiculo.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.tipoVeiculoID && (
                                <Typography variant="caption" sx={{ px: 2, pt: .3 }} color="error">
                                    {errors.tipoVeiculoID.message}
                                </Typography>
                            )}
                        </FormControl>

                        <Controller
                            name="valorPontoAdicional"
                            control={control}
                            rules={{ required: "O valor por ponto adicional é obrigatório" }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    label="Valor por ponto adicional"
                                    fullWidth
                                    size="small"
                                    value={liveMaskMoney(value || "")}
                                    onChange={(e) => onChange(liveMaskMoney(e.target.value))}
                                    error={!!errors.valorPontoAdicional}
                                    helperText={errors.valorPontoAdicional ? errors.valorPontoAdicional.message : ""}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />

                        <SelectToggleAtivo
                            hookForm={{ methods: hookForm, name: "ativo" }}
                            label="Situação"
                            toggleValues={{ ativo: "true", inativo: "false" }}
                        />

                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" color="secondary" sx={{ width: "fit-content" }} onClick={() => {
                                hookForm.reset();
                                onClose()
                            }} disabled={isSubmitting}>
                                Cancelar
                            </Button>
                            <Button
                                variant="containedFilled"
                                color="primary"
                                sx={{ width: "fit-content" }}
                                type="submit"
                                disabled={isSubmitDisabled || isSubmitting}
                            >
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </ModalBase>
    );
};

export default CadastrarTabela;
