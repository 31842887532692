import { Search, ArrowForwardIos } from "@mui/icons-material";
import { Avatar, Box, Grid2, InputAdornment, TextField, Typography } from "@mui/material";

const TabEntregadores = ({
    handleSearchChange,
    searchTerm,
    entregadores,
    setEntregadorSelecionado,
}) => {
    const filteredEntregadores = searchTerm.length > 0
        ? entregadores.filter(entregador =>
            entregador.nome.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : entregadores;

    return (
        <Box
            sx={{
                height: '100%',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <TextField
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Encontre o entregador pelo nome..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{
                    mt: 1,
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'orange',
                        },
                        '&:hover fieldset': {
                            borderColor: 'orange',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'orange',
                        },
                    },
                }}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }
                }}
            />
            <Grid2
                container
                spacing={0}
                sx={{ flex: '1', overflowY: 'auto', alignContent: 'flex-start' }}
            >
                {filteredEntregadores?.length > 0 ? (
                    filteredEntregadores.map((entregador, index) => (
                        <Grid2
                            size={12}
                            key={index}
                            onClick={() => setEntregadorSelecionado(entregador)}
                            sx={{
                                height: 'fit-content',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    padding: '1rem',
                                    borderRadius: '5px',
                                    borderBottom: '1px solid #e7e7e7',
                                    height: 'fit-content',
                                }}
                            >
                                <Avatar
                                    src={entregador?.perfilUrl}
                                    alt={`Perfil de ${entregador.nome}`}
                                    sx={{ width: 48, height: 48, marginRight: '1rem' }}
                                />
                                <Typography variant="body1" sx={{ fontWeight: '700', flexGrow: 1 }}>
                                    {entregador.nome}
                                </Typography>
                                <ArrowForwardIos />
                            </Box>
                        </Grid2>
                    ))
                ) : (
                    <Grid2 size={12} alignContent={"center"} justifyContent={"center"} sx={{ flex: '1' }}>
                        <Typography variant="notFound" sx={{ textAlign: "center" }} component={'p'}>
                            Nenhum entregador encontrado
                        </Typography>
                    </Grid2>
                )}
            </Grid2>
        </Box>
    );
}

export default TabEntregadores;
