import {
    Box,
    Card,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import circleDollar from "../../assets/icons/circle-dollar.svg";
import handCircleDollar from "../../assets/icons/hand-holding-circle-dollar.svg";
import sackDollar from "../../assets/icons/sack-dollar.svg";
import motorcycle from "../../assets/icons/motorcycle.svg";
import TabelaPedidos from "../../components/Pedidos/TabelaPedidos";
import DashboardPedidos from "../../components/Pedidos/DashboardPedidos";
import { CardFormFiltros, CardFormFiltrosRef, Field } from "../../ds/Forms";
import { RootState } from "../../store/reducers";
import { dinheiro } from "../../ds/common";
import { CardTabelaTotal, TableNavigation } from "src/ds/DesignSystem";

const PedidoDashboard = () => {
    const theme = useTheme();
    const [activeSection, setActiveSection] = useState('pedidos');
    const hookForm = useForm({
        defaultValues: {
            empresa: '',
            paginacao: 20,
            pagina: 1,
        }
    });
    const { handleSubmit, watch, formState: { errors }, setValue, getValues, control } = hookForm;
    const pedidosDados = useSelector((state: RootState) => state.pedidos.listaPedidos?.data?.dados);
    const loadingPedidos = useSelector((state: RootState) => state.pedidos.listaPedidos.loading);
    const cardRef = useRef<CardFormFiltrosRef>(null);

    const handleSectionChange = (section: string) => {
        if (section === null) return
        setActiveSection(section);
    }

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });

        // Cleanup function para a subscrição ao watch
        return () => subscription.unsubscribe();
    }, [watch, handleSubmit]);

    const formFields: Field[] = [
        {
            name: 'Empresa',
            value: 'empresaID',
            label: 'Empresas',
            type: 'autocompleteEmpresa',
            options: [],
        },
        {
            name: 'Código do pedido',
            value: 'codigoExterno'
        },
        {
            name: 'Situação',
            value: 'situacao',
            type: 'select',
            options: [
                { label: 'Finalizado', value: 'FINALIZADO' },
                { label: 'Cancelado', value: 'CANCELADO' },
            ]
        },
        {
            name: 'Tipo',
            value: 'tipo',
            type: 'select',
            options: [
                { label: 'Pedido', value: 'PEDIDO' },
                { label: 'Diária', value: 'DIARIA' },
                { label: 'Bônus', value: 'BONUS' },
                { label: 'Antecipação', value: 'ANTECIPACAO' },
                { label: 'Administrativo', value: 'ADMINISTRATIVO' },
                { label: 'Outros', value: 'OUTROS' },
            ]
        },
        {
            name: 'Nome Entregador',
            value: 'codigoExterno'
        },
        { name: 'Data cadastro inicial', value: 'dataInicioCadastro', type: 'dateTime' },
        { name: 'Data cadastro final', value: 'dataFimCadastro', type: 'dateTime' },
        { name: 'Data finalização inicial', value: 'dataInicialFinalizado', type: 'dateTime' },
        { name: 'Data finalização final', value: 'dataFimFinalizado', type: 'dateTime' },

    ]

    // TODO: INCOMPLETO

    return (
        <Box sx={{ p: 2, backgroundColor: theme.palette.background.paperDark }}>
            <CardFormFiltros
                ref={cardRef}
                fields={formFields}
                formMethods={hookForm}
                dispatchMethods={{ actionName: 'listaPedidosStart' }}
                reentrega
                considerados
            />
            <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap' }} useFlexGap direction="row">
                <CardTabelaTotal
                    srcIcon={circleDollar}
                    altIcon="Ícone Valor Total de Cobrado"
                    bgcolor={theme.palette.green[600]}
                    texts={{
                        title1: 'VALOR TOTAL COBRADO',
                        subtitle1: pedidosDados?.totalCobrado ? dinheiro(pedidosDados?.totalCobrado) : 'R$0,00'
                    }}
                />
                <CardTabelaTotal
                    srcIcon={handCircleDollar}
                    altIcon="Ícone Valor Total de Custo"
                    bgcolor={theme.palette.red[600]}
                    texts={{
                        title1: 'VALOR TOTAL DE CUSTO',
                        subtitle1: pedidosDados?.totalCusto ? dinheiro(pedidosDados?.totalCusto) : 'R$0,00',
                    }}
                />
                <CardTabelaTotal
                    srcIcon={sackDollar}
                    altIcon="Ícone Valor Total de Vendas"
                    bgcolor={theme.palette.yellow[600]}
                    texts={{
                        title1: 'VALOR TOTAL DE VENDAS',
                        subtitle1: pedidosDados?.totalFaturado ? dinheiro(pedidosDados?.totalFaturado) : 'R$0,00',
                    }}
                />
                <CardTabelaTotal
                    srcIcon={motorcycle}
                    altIcon="Ícone Valor Total de Pedidos"
                    bgcolor={theme.palette.blue[600]}
                    texts={{
                        title1: 'PEDIDOS',
                        /* @ts-expect-error */
                        subtitle1: pedidosDados?.pedidosFinalizados ? pedidosDados?.pedidosFinalizados : 0,
                    }}
                />
            </Stack>
            <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }} useFlexGap direction="row">
                <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={activeSection}
                    onChange={(_, value) => handleSectionChange(value)}
                >
                    {/* @ts-ignore */}
                    <ToggleButton variant="plain" value="pedidos">Pedidos</ToggleButton>
                    {/* @ts-ignore */}
                    <ToggleButton variant="plain" value="dashboard">Dashboard</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Card elevation={1} sx={{ mt: 2, borderRadius: 2 }}>
                {(pedidosDados?.ultimaPagina) ? (
                    <TableNavigation
                        indiceInicial={pedidosDados.indiceInicial}
                        indiceFinal={pedidosDados.indiceFinal}
                        indiceTotal={pedidosDados.indiceFinal}
                        ultimaPagina={pedidosDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                {activeSection === 'pedidos'
                    ? <TabelaPedidos pedidos={pedidosDados?.lista} loading={loadingPedidos} />
                    //  @ts-expect-error
                    : <DashboardPedidos />}
            </Card>
        </Box >
    );
};

const stylesForTypography = {
    flex: '2 0 250px'
}

export default PedidoDashboard;
