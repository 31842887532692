//@ts-nocheck
import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Autocomplete,
    TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RowGrid, SnackbarAlert } from '../../../ds';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { listarVeiculosStart } from '../../../store/reducers/escalas';
import { useForm, Controller } from 'react-hook-form';
import { limparResposta, limparSalvarUsuario, salvarUsuarioReq } from '../../../store/reducers/config';
import { telefone } from '../../../ds/common';
import { AutocompleteEmpresas, ModalBase } from 'src/ds/DesignSystem';
import { RootState } from 'src/store/reducers';
import { useMultiResponseSnackbar, useSnackbar } from 'src/ds/CustomHooksDs';
import ModalPesquisarEntregador from 'src/components/common/ModalPesquisarEntregador';

const OcorrenciaPorEntregador = ({ dados, show, setShow }) => {
    const emAndamento = useSelector((state: RootState) => state.config?.emAndamento?.dados?.lista);
    const dispatch = useDispatch();

    const hookForm = useForm({
        defaultValues: {
            empresaID: "",
            pedidoID: "",
            entregadorID: "",
            tipoOcorrencia: "",
            observacoes: "",
        },
    });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = hookForm;

    const handleClose = () => {
        setShow(false);
    };

    const onSubmit = (data) => {
        //dispatch(salvarUsuarioReq(dataToSubmit));
        //handleClose();
    };

    return (
        <>
            <ModalBase open={show} onClose={handleClose} headerModal={{ title: 'Cadastrar ocorrência' }}>
                <Box sx={{ m: '1rem 1rem 0 1rem', display: 'flex', flexDirection: 'column', gap: 2, }}>
                    <AutocompleteEmpresas
                        hookForm={{
                            methods: hookForm,
                            name: 'empresaID',
                        }}
                        sx={{ width: '100%' }}
                        label='Selecionar empresa'
                    />
                    <Controller
                        name="pedidoID"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                size='small'
                                {...field}
                                options={emAndamento || []}
                                getOptionLabel={(option) => option?.codigoQuatro || ''}
                                onChange={(event, newValue) => {
                                    setValue("pedidoID", newValue?.pedidoID || "");
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Selecionar pedido" variant="outlined" fullWidth />
                                )}
                            />
                        )}
                    />
                    <ModalPesquisarEntregador />
                    {/* Seletor de tipo de ocorrencia: Grave, gravíssima, leve e media */}
                    <TextField label="Observações" multiline rows={4} {...register('observacoes')} />
                </Box>
                <RowGrid sx={{ gap: 2, display: 'flex', justifyContent: 'flex-end', p: 2, width: '100%' }}>
                    <Button variant="containedFilled" onClick={handleClose} sx={{ width: 'fit-content' }} color="grey">
                        Cancelar
                    </Button>
                    <Button variant="containedFilled" onClick={handleSubmit(onSubmit)} sx={{ width: 'fit-content' }}>
                        Salvar
                    </Button>
                </RowGrid>
            </ModalBase>
        </>
    )
};

export default OcorrenciaPorEntregador;
