import { Box, TableCell, TableRow, Typography, IconButton, Tooltip, Collapse, Stack } from '@mui/material';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import StepperAndamento from './StepperAndamento';
import { Pedido } from '../../dtos/PedidosDTO';
import { dinheiro } from '../../ds/common';
import accon from '../../assets/integracoes/accon.png';
import alloy from '../../assets/integracoes/alloy.png';
import anotaai from '../../assets/integracoes/anotaai.png';
import deliverydireto from '../../assets/integracoes/deliverydireto.png';
import ifood from '../../assets/integracoes/ifood.png';
import neemo from '../../assets/integracoes/neemo.png';
import { ChipSituacao } from 'src/ds/DesignSystem';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from 'react-router-dom';

interface PedidoRowProps {
    pedido: Pedido;
    onToggleStepper: (pedidoID: string) => void;
    stepperVisible: boolean;
}

const PedidoRow = ({ pedido, onToggleStepper, stepperVisible }: PedidoRowProps) => {
    const navigate = useNavigate();
    const findOrigemImg = (origemNome: string | null) => {
        switch (origemNome) {
            case 'ACCON':
                return accon;
            case 'ALLOY':
                return alloy;
            case 'ANOTAAI':
                return anotaai;
            case 'DELIVERYDIRETO':
                return deliverydireto;
            case 'IFOOD':
                return ifood;
            case 'NEEMO':
                return neemo;
            default:
                return 'https://daouupqyghflu.cloudfront.net/Pick/Logo/28fa0cae-7fe6-11ed-ab9d-12d2147dce0f/icon-app.png';
        }
    };

    return (
        <>
            <TableRow hover>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    <Box
                        component="img"
                        src={findOrigemImg(pedido?.aplicacaoOrigemNome)}
                        alt={pedido.aplicacaoOrigemNome || 'Logo'}
                        sx={{ width: 32, height: 32 }}
                    />
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    <Typography variant="body2" sx={{ maxWidth: '50px', overflowWrap: 'break-word' }}>
                        {pedido.codigoExterno}
                    </Typography>
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    <ChipSituacao situacao={pedido?.situacao} label={pedido.situacao} />
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    {pedido?.empresa?.nome || '-'}
                </TableCell>
                <TableCell align="left" sx={{ borderBottom: 'none' }}>
                    <Typography variant="body2" sx={{ marginBottom: '8px', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                        <Box component="span" fontWeight="bold">Origem: </Box>
                        {pedido.origemEndereco?.enderecoStr}
                    </Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                        <Box component="span" fontWeight="bold">Entrega: </Box>
                        {pedido.entregaEndereco?.enderecoStr}
                    </Typography>
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    {pedido.entregadorUsuario?.nome || '-'}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    {pedido.clienteNome || '-'}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    {pedido.formaPagamento?.nome || '-'}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    {pedido.valor ? dinheiro(pedido.valor) : '-'}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    {pedido.taxaTotalCobrada ? dinheiro(pedido.taxaTotalCobrada) : '-'}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                    {pedido.taxaTotalEntregador ? dinheiro(pedido.taxaTotalEntregador) : '-'}
                </TableCell>
                <TableCell align='center' sx={{ borderBottom: 'none' }}>
                    <Stack direction='row' alignItems='center' justifyContent='center' sx={{ gap: '.5rem' }}>
                        <Tooltip title={stepperVisible ? "Esconder Andamento" : "Ver Andamento"} arrow placement='top'>
                            <IconButton aria-label="Ver Histórico em Andamento" onClick={() => onToggleStepper(pedido.pedidoID)}>
                                {stepperVisible ? <VisibilityOffRoundedIcon color='disabled' /> : <VisibilityRoundedIcon color='primary' />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Editar Pedido"} arrow placement='top'>
                            <IconButton aria-label="Editar Pedido" onClick={() => navigate(`/pedido/Index/${pedido.pedidoID}`)}>
                                <EditNoteIcon color='secondary' />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ borderTop: 'none', paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={stepperVisible} timeout={300} unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <StepperAndamento
                                historicoAndamento={{
                                    recebido: pedido.dataCadastro,
                                    agendamento: pedido.dataAgendamento,
                                    pronto: pedido.dataPronto,
                                    despacho: pedido.dataDespachado,
                                    aceito: pedido.dataAceito,
                                    noLocal: pedido.dataChegouEstabelecimento,
                                    emRota: pedido.dataEmRota,
                                    retorno: pedido.dataRetorno,
                                    finalizado: pedido.dataFinalizado,
                                    cancelado: pedido.dataCancelamento,
                                }}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PedidoRow;
