import { Box, Typography, Tabs, Tab, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBackIos } from '@mui/icons-material';
import { RootState } from 'src/store/reducers';
import DadosBasicos from './DadosBasicos';
import Empresas from './Empresas';
import TiposDeVeiculo from './TiposDeVeiculo';
import { paginaStart } from 'src/store/reducers/despachoAutomatico';

interface TabConfig {
    label: string;
    content: React.ReactNode;
}

const TabsDespachoAutomatico: React.FC = () => {
    const { action, id } = useParams<{ action: string; id?: string }>();
    const [activeTab, setActiveTab] = useState(0);
    const daAtual = useSelector((state: RootState) => state.despachoAutomatico?.pagina?.data?.modelo);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(paginaStart({id: id}))
    }, [])


    const nomeDa = daAtual?.nome;

    const isEditMode = action?.toLowerCase() === 'editar' && id;

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const tabsConfig: TabConfig[] = [
        { label: 'Dados Básicos', content: <DadosBasicos modelo={isEditMode ? daAtual : undefined} /> },
        ...(isEditMode ? [
            { label: 'Empresas', content: <Empresas data={daAtual} /> },
            { label: 'Tipos de veículo', content: <TiposDeVeiculo data={daAtual} /> },
        ] : []),
    ];

    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Box p={2} sx={{ backgroundColor: 'background.paper' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <ArrowBackIos
                    sx={{ cursor: 'pointer', mr: 1 }}
                    onClick={() => navigate("/TabelaDespachoAutomatico/Index")}
                />
                <Typography variant="h6">
                    {isEditMode ? (
                        <>
                            Editar {nomeDa} <span style={{ fontSize: '0.6em', color: theme.palette.grey[400] }}>({id})</span>
                        </>
                    ) : (
                        'Cadastrar'
                    )}
                </Typography>

            </Box>

            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Tabs de Edição"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ minHeight: '48px' }}
            >
                {tabsConfig.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                        sx={{
                            minWidth: '100px',
                            maxWidth: '200px',
                            color: 'text.primary',
                            fontSize: '12px',
                            textTransform: 'none'
                        }}
                    />
                ))}
            </Tabs>

            <Box sx={{ mt: 2 }}>
                {tabsConfig[activeTab]?.content}
            </Box>
        </Box>
    );
};

export default TabsDespachoAutomatico;
