const maskTelefone = (input: string ) => {
    if (!input) return '';
    // Remove todos os caracteres não numéricos
    const cleaned = ('' + input).replace(/\D/g, '');

    // Verifica e formata o número com 9 dígitos (ex: (99) 99999-9999)
    const match9 = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

    // Verifica e formata o número com 8 dígitos (ex: (99) 9999-9999)
    const match8 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

    if (match9) {
        return `(${match9[1]}) ${match9[2]}-${match9[3]}`;
    } else if (match8) {
        return `(${match8[1]}) ${match8[2]}-${match8[3]}`;
    }

    return cleaned;
};

export default maskTelefone;
