import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, IconButton, Theme, useTheme, Avatar, Typography, Collapse, Button, Chip } from "@mui/material";
import { ArrowDownward, ArrowUpward, Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import editar from "../../assets/icons/editar.png";
import deleteIcon from "../../assets/icons/delete.png";
import { TableRowLoading, TableRowNotFound, ButtonTableOrder, ChipSituacao } from "src/ds/DesignSystem"; // Adicione ButtonTableOrder aqui
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import ModalQrCode from "../common/ModalQrCode";
import { dinheiro, formatCnpj } from "src/ds/common";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "src/context/PermissionsContext";
import { ButtonChip } from "src/ds/components/ButtonChip";
import { RootState } from "src/store/reducers";
import dayjs from "dayjs";
import ModalCobrancas from "./Modal";
import { listarMinhasStart, listarMinhasTransacoesStart } from "src/store/reducers/faturas";

interface Empresa {
    nome: string;
    nomeExterno: string;
}

interface ItemFatura {
    descricao: string;
    valor: number;
}

interface Fatura {
    faturaID: string;
    codigo: number;
    dataCadastro: string;
    dataFechamento: string | null;
    descricao: string;
    empresaID: string;
    empresa: Empresa;
    mensagemCliente: string | null;
    valorNota: number;
    totalFaturado: number;
    totalCobrado: number;
    totalPago: number;
    totalAReceber: number;
    temTransacaoVencida: boolean;
    itens: ItemFatura[];
}


interface TabelaProps {
    data: Fatura[];
    loading: boolean;
    fezRequisicao?: boolean;
}

const Tabela = ({ data, loading, fezRequisicao }: TabelaProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        codigoIdentificador: 'asc',
        dataCadastro: 'asc',
        cnpj: 'asc',
    });
    const [row, setRow] = useState<Fatura | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [faturaID, setfaturaID] = useState<string | undefined>(undefined);


    const podeEditarRow = useSelector((state: any) => state.config?.masterPage?.usuario?.['row.editar']);

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        setSortDirection((prevState) => ({
            ...prevState,
            [column]: newSortDirection,
        }));

        const payload = {
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        };

    };

    const permissions = usePermissions();

    const [isAllOpen, setIsAllOpen] = useState(false);

    const [openRow, setOpenRow] = useState<{ [key: string]: boolean }>(() => {
        if (!data || data.length === 0) return {};
        return data.reduce((acc, item, index) => ({
            ...acc,
            [item.faturaID]: index === 0,
        }), {});
    });


    const handleToggle = useCallback((faturaID: string) => {
        setOpenRow(prev => ({
            ...prev,
            [faturaID]: !prev[faturaID],
        }));
    }, []);

    const handleToggleAll = useCallback(() => {
        setOpenRow(prev => {
            const updated = { ...prev };
            data?.forEach(item => {
                updated[item.faturaID] = !isAllOpen;
            });
            return updated;
        });
        setIsAllOpen(prev => !prev);
    }, [data, isAllOpen]);

    const template = useSelector((state: RootState) => state.config?.template?.template);


    return (
        <TableContainer component={Paper} sx={{ width: "100%", overflowY: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Empresa</TableCell>
                        <TableCell align="center">Código</TableCell>
                        <TableCell align="center">Data de cadastro</TableCell>
                        <TableCell align="center">Total faturado</TableCell>
                        <TableCell align="center">Total cobrado</TableCell>
                        <TableCell align="center">Total pago</TableCell>
                        <TableCell align="center">Saldo</TableCell>
                        <TableCell align="center">Itens</TableCell>
                        <TableCell align="center">Situação</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : data?.length > 0 ? (
                        data?.map((row, index) => (
                            <TableRow key={row.faturaID}>
                                <TableCell align='center'>{row.empresa?.nome}</TableCell>
                                <TableCell align='center'><Chip label={row.codigo} color='primary' /></TableCell>
                                <TableCell align='center'>{dayjs(row.dataCadastro, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}</TableCell>
                                <TableCell align="center">{dinheiro(row.totalFaturado)}</TableCell>
                                <TableCell align="center">{dinheiro(row.totalCobrado)}</TableCell>
                                <TableCell align="center">{dinheiro(row.totalPago)}</TableCell>
                                <TableCell align="center">{dinheiro(row.totalAReceber)}</TableCell>
                                <TableCell align="center">{row.itens.map(item => {
                                    return (
                                        <Typography variant="body2">{item.descricao}({dinheiro(item.valor)})</Typography>
                                    )
                                })}</TableCell>
                                <TableCell align="center">
                                    <Chip label={row?.totalAReceber === 0 ? 'Pago' : 'Atrasada'} color={row?.totalAReceber === 0 ? 'success' : 'error'} />
                                </TableCell>
                                <TableCell align="center">
                                    <ButtonChip
                                        label={'Ver cobrança'}
                                        onClick={() => {
                                            dispatch(listarMinhasTransacoesStart({
                                                empresaID: row.empresaID,
                                                paginacao: 20,
                                                pagina: 1,
                                                filtros: `faturaID.toString().Equals("${row.faturaID}")`
                                            }));
                                            setRow(row);
                                            setModalOpen(true)
                                        }}
                                        color={'success'}
                                    />
                                </TableCell>
                            </TableRow>

                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={6} align="center">
                                Nenhuma fatura encontrada.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <ModalCobrancas open={modalOpen} onClose={() => setModalOpen(false)} row={row} />
        </TableContainer >
    );
}

export default Tabela;
