import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/Forms";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import TabelaPagamento from "../../components/TabelaPagamento/TabelaPagamento";
import CadastrarTabela from "src/components/TabelaPagamento/ModalCadastrarTabela";
import { ModalOpcoes, SnackbarAlert, TableNavigation } from "src/ds/DesignSystem";
import { useMultiResponseSnackbar, useSnackbar } from "src/ds/CustomHooksDs";
import { limparEmpresaTabela, limparSalvarTabela, limparTipoVeiculo } from "src/store/reducers/tabelaPagamento";
import { converterDataCadastro } from "src/utils";
import { definirTipoDePagamentoStart, limparTipoDePagamento } from "src/store/reducers/config";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store/reducers";

const TabelaPagamentoPage = () => {
    const tabelasPagamentoDados = useSelector((state: any) => state.tabelaPagamento?.listaTabelasPagamento?.data?.dados);
    const tabelasPagamento = tabelasPagamentoDados?.lista;
    const loadingTabelasPagamento = useSelector((state: any) => state.tabelaPagamento.listaTabelasPagamento?.loading);
    const dispatch = useDispatch();
    const [openCadastro, setOpenCadastro] = useState(false);
    const response = useSelector((state: any) => state.tabelaPagamento.adicionarEmpresaTabela);
    const response3 = useSelector((state: any) => state.tabelaPagamento.salvarTipoVeiculo);
    const response4 = useSelector((state: any) => state.config.tipoDePagamento);
    const snack = useSnackbar({ duration: 2000 });
    const [ultimaTabelaCadastrada, setUltimaTabelaCadastrada] = useState(null);
    const [triggerEditar, setTriggerEditar] = useState(false);
    const [openModalOpcoes, setOpenModalOpcoes] = useState(false);
    const [tipoPagamento, setTipoPagamento] = useState<string | number>('');
    const tipoUsuario = useSelector((state: any) => state.config?.tipoDeUsuario);
    const metodoPagamento = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoPagamento);
    const navigate = useNavigate();

    useMultiResponseSnackbar([
        { response, clearAction: () => { dispatch(limparEmpresaTabela()); } },
        { response: response3, clearAction: () => { dispatch(limparTipoVeiculo()); } },
        { response: response4, clearAction: () => { dispatch(limparTipoDePagamento()); } }
    ], snack);

    useEffect(() => {
        if (tabelasPagamento?.length > 0) {
            const storedId = localStorage.getItem('lastEditedTabelaId');

            const cincoMinutosAtras = new Date(new Date().getTime() - 0.1 * 60 * 1000);

            const tabelasRecentes = tabelasPagamento?.filter((tabela: any) => {
                const dataCadastro = converterDataCadastro(tabela.dataCadastro);
                return dataCadastro > cincoMinutosAtras;
            });

            const tabelaMaisRecente = tabelasRecentes?.reduce((maisRecente: any, tabelaAtual: any) => {
                const dataMaisRecente = converterDataCadastro(maisRecente?.dataCadastro || '');
                const dataAtual = converterDataCadastro(tabelaAtual?.dataCadastro);
                return dataAtual > dataMaisRecente ? tabelaAtual : maisRecente;
            }, null);
            if (tabelaMaisRecente && tabelaMaisRecente.tabelaValorID !== storedId) {
                setUltimaTabelaCadastrada(tabelaMaisRecente);
                setTriggerEditar(true);
                localStorage.setItem('lastEditedTabelaId', tabelaMaisRecente.tabelaValorID);
                navigate(`/TabelaValor/Index/${tabelaMaisRecente.tabelaValorID}`);
            }
        }
    }, [tabelasPagamento]);


    const hookForm = useForm({
        defaultValues: {
            nome: '',
            dataInicial: '',
            dataFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true)
    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao) {
            cardRef.current?.onSubmit();
            setPrimeiraRenderizacao(false);
            return
        }

        const subscription = watch((_value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, primeiraRenderizacao]);

    const handleTipoPagamentoChange = (event: any) => {
        setTipoPagamento(event.target.value);
    };

    useEffect(() => {
        if (metodoPagamento) {
            setTipoPagamento(metodoPagamento);
        }
    }, [])

    const handleConfirmar = () => {
        // @ts-ignore
        dispatch(definirTipoDePagamentoStart({ tipo: tipoPagamento }));
        setOpenModalOpcoes(false);
    };


    return (
        <Box sx={(theme) => ({ backgroundColor: theme.palette.background.paperDark })}>
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'listaTabelaPagamentoStart'
                    }}
                    fields={[
                        { name: "nome", value: "nome", isFiltro: true, label: "Buscar por nome" },
                    ]}
                    loading={loadingTabelasPagamento}
                />
            </Box>

            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">TABELAS DE PAGAMENTO</Typography>
                    <Stack gap={2} alignContent={"flex-end"} direction={"row"}>
                        {tipoUsuario === 'SAAS' &&
                            <Button variant="containedFilled" color="secondary" sx={{ flex: 1 }} onClick={() => setOpenModalOpcoes(true)}>
                                MÉTODO DE CÁLCULO
                            </Button>}
                        <Button variant="containedFilled" sx={{ flex: 1 }} onClick={() => setOpenCadastro(true)}>
                            INSERIR
                        </Button>
                    </Stack>
                </Box>
                {tabelasPagamentoDados?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={tabelasPagamentoDados.indiceInicial}
                        indiceFinal={tabelasPagamentoDados.indiceFinal}
                        indiceTotal={tabelasPagamentoDados.total}
                        ultimaPagina={tabelasPagamentoDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <TabelaPagamento
                    fezRequisicao={cardRef.current?.fezRequisicao}
                    tabelasPagamento={tabelasPagamento}
                    loading={loadingTabelasPagamento}
                    atualizar={cardRef.current?.onSubmit}
                    ultimaTabelaCadastrada={ultimaTabelaCadastrada}
                    triggerEditar={triggerEditar}
                    setTriggerEditar={setTriggerEditar}
                />
            </Card>
            <CadastrarTabela open={openCadastro} onClose={() => {
                setOpenCadastro(false);
                setTimeout(() => {
                    cardRef.current?.onSubmit();
                }, 1000);
            }} />
            <ModalOpcoes
                open={openModalOpcoes}
                onClose={() => setOpenModalOpcoes(false)}
                acao="Definir método de calculo"
                opcoes={[
                    { label: 'Cancelar', action() { setOpenModalOpcoes(false); } },
                    { label: 'Confirmar', action: handleConfirmar, disabled: tipoPagamento === '' }
                ]}
                // @ts-ignore
                descricao={
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel shrink htmlFor="estado-label">Tipos</InputLabel>
                        <Select
                            displayEmpty
                            size="small"
                            id="estado-label"
                            label="Estado"
                            sx={{ textAlign: 'left' }}
                            value={tipoPagamento}
                            onChange={handleTipoPagamentoChange}
                        >
                            <MenuItem disabled value=''>
                                Selecione um tipo
                            </MenuItem>
                            <MenuItem key={0} value={0}>
                                Por raio
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                                Por rota
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                Por bairro
                            </MenuItem>
                        </Select>
                    </FormControl>
                }
            />
            <SnackbarAlert {...snack} />

        </Box>
    );
};

export default TabelaPagamentoPage;
