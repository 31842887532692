// InputDateTime.tsx
import { Box, Button, IconButton, SxProps, Typography } from '@mui/material';
import { DateTimePicker, DateTimePickerToolbar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { Today } from '@mui/icons-material';
dayjs.locale('pt-br');

interface HookForms {
  name: string;
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
}

interface InputDateTimeProps {
  hookForm: HookForms;
  label?: string;
  sx?: SxProps;
  disabled?: boolean
}

const CustomToolbar = (props: any) => {

  const handleTodayClick = () => {
    const today = dayjs();
    props.onChange(today);
  };

  return (
    <Box display="flex"
      sx={{
        position: 'absolute',
        left: `calc(50% - 100px)`,
        zIndex: 1,
        bottom: 55,
      }}
    >
      <DateTimePickerToolbar {...props} />
      <Button
        onClick={handleTodayClick}
        color="primary"
        sx={{ width: '84px' }}
        variant="iconeComTextoFilled"
        startIcon={<Today />}
      >
        <Typography variant="monospaced">Hoje</Typography>
      </Button>
    </Box>
  );
};

const InputDateTime = ({
  hookForm,
  sx,
  label,
  disabled
}: InputDateTimeProps) => {
  const { name, methods, rules } = hookForm;
  const { control, formState: { errors } } = methods;

  return (
    <LocalizationProvider
      // Todo: Retirar LocalizationProvider desnecessários
      dateAdapter={AdapterDayjs}
      adapterLocale="pt-br"
    >
      <Controller
        control={control}
        name={name}
        rules={rules}
        disabled={disabled}
        render={({ field: { onChange, value: watchValue, ...field } }) => (
          <DateTimePicker
            {...field}
            label={label || name}
            slots={{
              toolbar: (props) => <CustomToolbar {...props} />,
            }}
            slotProps={{
              field: { clearable: true },
              textField: {
                size: 'small',
                label: label || name,
                error: !!errors?.[name],
                helperText: errors?.[name]?.message ? String(errors?.[name]?.message) : '',
                // InputLabelProps: {
                //   shrink: watchValue ? true : false
                // },
              },
            }}
            sx={sx}
            ampm={false}
            value={watchValue ? dayjs(watchValue, 'DD/MM/YYYY HH:mm') : null}
            onChange={(newValue) => {
              onChange(newValue ? dayjs(newValue).format('DD/MM/YYYY HH:mm') : null)
            }}
            format="DD/MM/YYYY HH:mm"
            showDaysOutsideCurrentMonth
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default InputDateTime;
