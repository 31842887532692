import { BoltOutlined, KeyboardArrowDown, KeyboardArrowUp, KeyboardDoubleArrowRight, ReportProblemOutlined, Streetview, WhatsApp } from "@mui/icons-material"
import { BotaoSituacao, BotaoTriplo, BotaoTriploContainer, CardPrincipal, ColumnGrid, Line, RowGrid, SnackbarAlert, TimeCounter } from "../../../ds"
import { Box, Button, ClickAwayListener, Collapse, Fade, Link, Popper, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { sanitizeSituacao } from "../../../utils";
import { usePedido } from "../../../context/PedidoContext";
import { useDispatch, useSelector } from "react-redux";
import { cancelarPedidoRequest, marcarFinalizadoRequest } from "../../../store/reducers/criarPedido";
import { alterarSituacaoRequest, emAndamentoStart } from "../../../store/reducers/config";
import { dinheiro, telefone } from "../../../ds/common";
import EditNoteIcon from '@mui/icons-material/EditNote';
import ModalTrocarStatus from "../ModalTrocarStatus";
import { useSnackbar } from "../../../ds/CustomHooksDs";
import { AvatarWithModal, ModalOpcoes, SnackbarAlert as SnackbarAlertDs } from "../../../ds/DesignSystem";
import dayjs from "dayjs";

const CardPedido = ({ pedido, abrirCriarOcorrencia, abrirEditarPedido, abrirEntregaParceira, abrirAtribuirPedido, ...props }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { selecionarPedido, roteando, markersSelecionados, markerSelecionado, selecionarCardPedido, cardPedidoSelecionado } = usePedido();
    const usuario = useSelector(state => state.login?.usuario);
    const resposta = useSelector(state => state.criarPedido.resposta);
    const error = useSelector(state => state.criarPedido.error);
    const podeCancelar = useSelector(state => state.config.masterPage?.empresa?.podeCancelar);
    const tipoDeUsuario = useSelector(state => state.config?.tipoDeUsuario);
    const podeEntregaParceira = useSelector(state => {
        if (tipoDeUsuario === "SAAS") {
            return true;
        } else {
            return state.config.masterPage?.empresa?.podePedirEntregaParceira;
        }
    });
    const devMode = useSelector(state => state.utils?.devMode);
    const alterarSituacao = useSelector(state => state.config?.respostaSituacao);
    const [collapsed, setCollapsed] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [anchorSituacao, setAnchorSituacao] = useState(null);
    const [mostarDashed, setMostarDashed] = useState(false);
    const buttonRef = useRef(null);
    const [cancelarOpen, setCancelarOpen] = useState(false);
    const [snackbarCopyOpen, setSnackbarCopyOpen] = useState(false);

    const alterarSnackbar = useSnackbar({ duration: 3000 })

    useEffect(() => {
        if (alterarSituacao) {
            alterarSnackbar.showSnackbar({ message: alterarSituacao, severity: 'success' })
            dispatch(emAndamentoStart({}))
        }
    }, [alterarSituacao])

    const opcoes = [
        {
            label: 'Cancelar',
            action: () => setCancelarOpen(false)
        },
        {
            label: 'Confirmar',
            action: () => {
                dispatch(cancelarPedidoRequest(pedido.pedidoID))
                setCancelarOpen(false)
                setTimeout(() => {
                    dispatch(emAndamentoStart(usuario?.id))
                }, 1000)
            }
        },
    ];

    const getProximoStatus = (situacao) => {
        switch (situacao) {
            case 'CRIADO':
                return 'Pronto';
            case 'PRONTO':
                return 'Despachar';
            case 'DESPACHADO':
                return 'ACEITO';
            case 'RECEBIDO':
                return 'Pronto';
            case 'ACEITO':
                return 'No local';
            case 'NO_ESTABELECIMENTO':
                return 'Pedido coletado';
            case 'EM_ROTA':
                return 'Finalizado';
            case 'RETORNANDO':
                return 'Finalizado';
            default:
                return 'ERRO';
        }
    };

    const abrirOcorrencia = () => {
        selecionarPedido(pedido);
        abrirCriarOcorrencia();
    }

    const abrirAtribuir = () => {
        selecionarPedido(pedido);
        abrirAtribuirPedido();
    }

    const abrirEditar = () => {
        selecionarPedido(pedido);
        abrirEditarPedido();
    }

    const abrirParceira = () => {
        selecionarPedido(pedido);
        abrirEntregaParceira();
    }

    const marcarFinalizado = () => {
        dispatch(marcarFinalizadoRequest(pedido.pedidoID))
        setTimeout(() => {
            dispatch(emAndamentoStart(usuario?.id))
        }, 1000)
        setOpenSnackbar(true);
    }

    const handleCopyPedido = (e) => {
        selecionarPedido(pedido);
        navigator.clipboard.writeText(`${window.location.origin}/rastreio/${pedido.pedidoID}`);
        setSnackbarCopyOpen(true);
    }

    function closePopper() {
        setAnchorSituacao(null);
    }

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const toggleModalStatus = (e) => {
        if (e?.currentTarget) {
            {
                tipoDeUsuario !== 'Representante' && setAnchorSituacao(anchorSituacao ? null : e.target);
            }
        }
    }
    const openModalStatus = Boolean(anchorSituacao);

    useEffect(() => {
        if (markerSelecionado === pedido.pedidoID) {
            setCollapsed(false);
            setMostarDashed(true);
        } else if (cardPedidoSelecionado?.pedidoID === pedido.pedidoID) {
            setMostarDashed(true);
            setTimeout(() => {
                setMostarDashed(false);
                selecionarCardPedido(null)
            }, 5000)
        } else {
            setMostarDashed(false);
        }
    }, [markerSelecionado, cardPedidoSelecionado])

    const pedidoPronto = (pedidoId) => {
        if (!pedidoId) return
        dispatch(alterarSituacaoRequest({ pedidoID: pedido?.pedidoID, situacao: 'PRONTO' }))
    }

    return (
        <CardPrincipal
            sx={{
                height: 'fit-content',
                outline: ((roteando ? markersSelecionados?.includes(pedido.pedidoID) : pedido.pedidoID === markerSelecionado) || mostarDashed)
                    ? `4px dashed ${theme.palette.primary.main}`
                    : 'initial',
                border: ((roteando ? markersSelecionados?.includes(pedido.pedidoID) : pedido.pedidoID === markerSelecionado) || mostarDashed)
                    ? 'none'
                    : 'initial',
                position: 'relative',
                overflow: 'visible',
                mt: '.5rem',
                backgroundImage: devMode && (roteando ? markersSelecionados.includes(pedido.pedidoID) : pedido.pedidoID === markerSelecionado) ? 'url(https://i.gifer.com/4KY.gif)' : 'none',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
            {...props}
            onClick={() => {
                if (collapsed) {
                    toggleCollapse();
                }
                selecionarCardPedido(pedido)
            }}
        >
            <Line gap={'.2rem'} alignItems={'center'}>
                <TimeCounter
                    initialTimeString={pedido?.tempoDecorridoCadastro}
                    sx={{ flex: '1' }}
                />
                <Line gap={'.2rem'} sx={{ justifyContent: 'space-between', flex: '3' }}>
                    {pedido.situacao !== 'RETORNANDO' && podeEntregaParceira &&
                        <Button variant='textoSimples' onClick={abrirParceira} sx={{ flexGrow: '1' }}>
                            <Tooltip title='Entrega Parceira' arrow placement="top">
                                <BoltOutlined sx={{ fontSize: '1rem' }} />
                            </Tooltip>
                        </Button>}

                    {tipoDeUsuario !== 'SAAS' && <Button variant='textoSimples' onClick={abrirOcorrencia} sx={{ flexGrow: '1' }}>
                        <Tooltip title='Ocorrência' arrow placement="top">
                            <ReportProblemOutlined sx={{ fontSize: '1rem' }} />
                        </Tooltip>
                    </Button>}
                    <Button variant='textoSimples' onClick={() => { abrirEditar() }} sx={{ flexGrow: '1' }}>
                        <Tooltip title='Editar Pedido' arrow placement="top">
                            <EditNoteIcon sx={{ fontSize: '1rem' }} />
                        </Tooltip>
                    </Button>
                </Line>
                <Box sx={{ flex: '1' }}>
                    <BotaoSituacao ref={buttonRef} sx={{ maxWidth: '100%' }} situacao={sanitizeSituacao(pedido)} onClick={toggleModalStatus}>
                        {sanitizeSituacao(pedido)}
                        {tipoDeUsuario !== 'Representante' && <KeyboardDoubleArrowRight fontSize={theme.sizes.fontSize.lg} />}
                    </BotaoSituacao>
                </Box>

                <Popper
                    id={'simple-popper'}
                    open={openModalStatus}
                    anchorEl={anchorSituacao}
                    sx={{ zIndex: 9999 }}
                    placement="bottom-end"
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: true,
                            options: {
                                altBoundary: true,
                                rootBoundary: 'viewport',
                                padding: 8,
                            },
                        },
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                                boundary: 'viewport',
                                altAxis: true,
                            },
                        },
                    ]}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={200}>
                            <Box>
                                <ClickAwayListener onClickAway={() => { setAnchorSituacao(null) }}>
                                    <ModalTrocarStatus
                                        visible={openModalStatus}
                                        onClose={toggleModalStatus}
                                        pedido={pedido}
                                        closePopper={closePopper}
                                        statusAtual={sanitizeSituacao(pedido)}
                                    />
                                </ClickAwayListener>
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </Line>
            <Line sx={{ display: 'flex', gap: '.5rem', mb: '0rem', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="body1">
                    <Typography variant={'span'} fontWeight={'900'}>
                        {`#${pedido?.codigoQuatro} `}
                    </Typography>
                    <Typography variant={'span'} fontWeight={'bold'}>
                        {pedido?.clienteNome}
                    </Typography>
                </Typography>
                {pedido?.dataAgendamentoStr &&
                    <Typography variant='caption' alignSelf={'flex-end'} fontWeight={'700'}>
                        Agendado para {dayjs(pedido.dataAgendamentoStr, 'DD/MM/YYYY HH:mm:ss').format('HH:mm [de] DD/MM ')}
                    </Typography>}
            </Line>

            <Collapse in={!collapsed}>
                <RowGrid sx={{ justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem', padding: '0', margin: '0 0 0.5rem 0' }}>
                        <Typography variant="body2" >Pagamento: {pedido?.formaPagamento?.nome}</Typography>
                        <Typography variant="body2" >Telefone do cliente: {telefone(pedido?.clienteTelefone)}</Typography>
                        {pedido?.taxaTotalCobrada !== 0
                            ? <Typography variant="body2" >Taxa cobrada: {dinheiro(pedido?.taxaTotalCobrada)}</Typography>
                            : null}
                        <Link
                            sx={{
                                p: 0,
                                fontSize: theme.sizes.fontSize.sm,
                                color: theme.palette.text.primary,
                                textDecoration: theme.palette.mode === 'dark' ? 'underline' : 'none',
                            }}
                            onClick={handleCopyPedido}
                        >
                            <Streetview sx={{ fontSize: '12px' }} /> Gerar código de rastreio para o cliente
                        </Link>
                    </div>
                    {pedido?.entregadorUsuario?.perfilUrl && (
                        <ColumnGrid sx={{ width: '30%' }}>
                            <AvatarWithModal
                                src={pedido?.entregadorUsuario?.perfilUrl}
                                alt="Foto do Entregador"
                                size={'60px'}
                                username={pedido?.entregadorUsuario?.nome}
                            />
                            <Line sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{ textAlign: 'center', fontSize: '12px', padding: '0rem' }}>{pedido?.entregadorUsuario?.nome}</Typography>
                            </Line>
                            {pedido?.entregadorUsuario?.telefone &&
                                <Link
                                    sx={{ fontSize: '12px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}
                                >
                                    <WhatsApp sx={{ fontSize: '12px' }} /> {telefone(pedido?.entregadorUsuario?.telefone)}
                                </Link>}
                        </ColumnGrid>
                    )}
                </RowGrid>
                <BotaoTriploContainer >
                    {(pedido?.situacao === 'RECEBIDO' || pedido?.situacao === 'PRONTO') && podeCancelar &&
                        <BotaoTriplo onClick={() => setCancelarOpen(true)}>Cancelar</BotaoTriplo>
                    }
                    {pedido?.situacao === 'NO_ESTABELECIMENTO' &&
                        <BotaoTriplo onClick={() => dispatch(alterarSituacaoRequest({ pedidoID: pedido?.pedidoID, situacao: 'EM_ROTA' }))}>Coletado</BotaoTriplo>
                    }
                    {pedido?.situacao === 'RECEBIDO' &&
                        <BotaoTriplo onClick={() => pedidoPronto(pedido?.pedidoID)}>{getProximoStatus(pedido?.situacao)}</BotaoTriplo>
                    }
                    {((pedido?.situacao === 'RECEBIDO' || pedido?.situacao === 'PRONTO') && tipoDeUsuario !== "REPRESENTANTE") &&
                        <BotaoTriplo onClick={abrirAtribuir}>Atribuir</BotaoTriplo>}
                    {(pedido?.situacao !== 'RECEBIDO' && pedido?.situacao !== 'PRONTO' && pedido?.situacao !== 'EM_ROTA' && pedido?.situacao !== 'RETORNANDO') &&
                        <BotaoTriplo onClick={abrirAtribuir}>Reatribuir</BotaoTriplo>
                    }
                    {pedido?.situacao === 'EM_ROTA' &&
                        <BotaoTriplo onClick={marcarFinalizado}>Entregue</BotaoTriplo>
                    }
                    {pedido?.situacao === 'RETORNANDO' &&
                        <BotaoTriplo onClick={marcarFinalizado}>Pagamento entregue</BotaoTriplo>
                    }
                </BotaoTriploContainer>
                <ModalOpcoes
                    open={cancelarOpen}
                    onClose={() => setCancelarOpen(false)}
                    descricao="Tem certeza que deseja cancelar este pedido?"
                    acao="Cancelar pedido"
                    opcoes={opcoes}
                />
                <SnackbarAlert
                    message={error ? error : resposta?.mensagem}
                    open={openSnackbar}
                    setOpen={setOpenSnackbar}
                    duration={2000}
                    type={error ? 'error' : 'success'}
                />
                <SnackbarAlert
                    message={'Código de rastreio copiado para a área de transferência!'}
                    open={snackbarCopyOpen}
                    setOpen={setSnackbarCopyOpen}
                    duration={2000}
                    type={error ? 'error' : 'success'}
                />
                <SnackbarAlertDs {...alterarSnackbar} />
            </Collapse>
            <Button onClick={toggleCollapse} sx={{
                position: 'absolute',
                width: '20px',
                height: '20px',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                borderRadius: '50%',
                bgcolor: 'primary.main',
                color: 'white',
                border: `1px solid ${theme.palette.primary.main}`,
                aspectRatio: '1',
                '&:hover': {
                    bgcolor: 'primary.dark',
                    borderColor: 'primary.dark',
                },
            }}>
                {collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </Button>
        </CardPrincipal >
    )
}

export default CardPedido