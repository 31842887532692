// ButtonGroupPages.tsx
import { Controller, UseFormReturn } from 'react-hook-form';
import { useResponsive } from '../CustomHooksDs';
import { useEffect, useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

interface ButtonGroupPagesProps {
  hookForms: UseFormReturn<any>;
  name?: string;
  maxPages?: number;
  ultimaPagina: number;
}

/**
 * Componente ButtonGroupPages
 *
 * Este componente exibe um Grupo de Botões para navegação entre páginas. 
 * Utiliza o `ToggleButtonGroup` e o `ToggleButton` do `@mui/material`, 
 * e depende do `react-hook-form` para manipulação do formulário.
 *
 * @component
 * @param {Object} props - As propriedades do componente.
 * @param {UseFormReturn<any>} props.hookForms - Objeto retornado pelo `useForm` do `react-hook-form`. 
 * @param {number} [props.maxPages=5] - Número máximo de botões de página a serem exibidos.
 * @param {string} [props.name='pagina'] - O nome do campo no formulário, usado pelo `setValue` e `watch`.
 * @param {number} props.ultimaPagina - Número da última página disponível para navegação.
 *
 * @returns {JSX.Element} Um grupo de botões para navegação entre as páginas.
 *
 * @example
 * ```tsx
 * const hookForms = useForm()
 * <ButtonGroupPages 
 *   hookForms={hookForms}
 *   name="pagina" 
 *   ultimaPagina={10}
 * />
 * ```
 */
const ButtonGroupPages = ({
  hookForms,
  maxPages = 5,
  name = 'pagina',
  ultimaPagina,
}: ButtonGroupPagesProps) => {
  const { isMobile } = useResponsive()
  const [numPages, setNumPages] = useState<number[]>([]);
  const { control, watch, setValue } = hookForms

  useEffect(() => {
      const totalPages = ultimaPagina || 1;
      setNumPages(Array.from({ length: totalPages }, (_, index) => index + 1));
      if (watch(name) > totalPages) {
          setValue(name, totalPages);
      }
  }, [ultimaPagina, watch, name, setValue]);

  const getVisiblePages = (current: number, total: number) => {
      const half = Math.floor(maxPages / 2);
      let start = Math.max(current - half, 1);
      let end = Math.min(start + maxPages - 1, total);
      start = Math.max(end - maxPages + 1, 1);
      return Array.from({ length: end - start + 1 }, (_, idx) => start + idx);
  };

  const visiblePages = getVisiblePages(watch(name), numPages.length);

  const handlePageChange = (value: string | null, name: string) => {
      if (value === null) return
      setValue(name, parseInt(value));
  };

  const handleNextPage = () => {
      const pagina = watch(name);
      if (pagina < numPages.length) {
          setValue(name, pagina + 1);
      }
  };

  const handlePrevPage = () => {
      const pagina = watch(name);
      if (pagina > 1) {
          setValue(name, pagina - 1);
      }
  };

  return (
      <Box sx={{ display: 'flex', alignItems: isMobile ? 'center' : 'stretch', direction: isMobile ? 'column' : 'row' }}>
          <ToggleButton value='' size="small" onClick={handlePrevPage} disabled={watch('pagina') === 1}>
              <KeyboardArrowLeftRoundedIcon />
          </ToggleButton>
          <Controller
              name={name}
              control={control}
              render={({ field: { value, name } }) => (
                  <ToggleButtonGroup
                      size="small"
                      exclusive
                      value={value}
                      color="primary"
                      onChange={(_, value) => handlePageChange(value, name)}
                  // {...field}
                  >
                      {visiblePages?.map(page => (
                          <ToggleButton key={page} value={page} sx={{ width: '40px' }}>
                              {page}
                          </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
              )}
          />
          <ToggleButton value='' size="small" onClick={handleNextPage} disabled={watch('pagina') === ultimaPagina}>
              <KeyboardArrowRightRoundedIcon />
          </ToggleButton>
      </Box>
  )
}

export default ButtonGroupPages;
