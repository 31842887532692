import {
    Box,
    Card,
    Stack,
    Tab,
    Tabs,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import circleDollar from "../../assets/icons/circle-dollar.svg";
import handCircleDollar from "../../assets/icons/hand-holding-circle-dollar.svg";
import sackDollar from "../../assets/icons/sack-dollar.svg";
import motorcycle from "../../assets/icons/motorcycle.svg";
import TabelaPedidos from "../../components/Pedidos/TabelaPedidos";
import DashboardPedidos from "../../components/Pedidos/DashboardPedidos";
import dayjs from "dayjs";
import { CardFormFiltros, CardFormFiltrosRef, Field } from "../../ds/Forms";
import { RootState } from "../../store/reducers";
import { dinheiro } from "../../ds/common";
import { listarVeiculosStart } from "src/store/reducers/escalas";
import ExportarTabela from "src/ds/components/ExportarTabela";
import { CardTabelaTotal, TableNavigation } from "src/ds/DesignSystem";
import { listarTiposEmpresas } from "src/store/reducers/empresa";
import { listarTipoDeVeiculoStart } from "src/store/reducers/config";
import { listaEntregadoresStart } from "src/store/reducers/entregador";
import { taxaExtraStart } from "src/store/reducers/taxaExtra";
import { useForm } from "react-hook-form";

const RelatorioGeral = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [activeSection, setActiveSection] = useState('pedidos');
    const hookForm = useForm({
        defaultValues: {
            tipoEmpresaID: '',
            paginacao: 20,
            pagina: 1,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
            dtInicial: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
        }
    });
    const { watch } = hookForm;

    const entregadores = useSelector((state: RootState) => state.entregadores?.listaEntregadores?.data?.dados?.lista);
    const tiposVeiculo = useSelector((state: RootState) => state.config?.tiposDeVeiculos?.dados?.lista);
    const tipoEmpresas = useSelector((state: RootState) => state.empresas.tiposEmpresas?.data?.lista);
    const taxasExtra = useSelector((state: RootState) => state.taxaExtra?.lista?.data?.dados?.lista);
    const formasPagamento = useSelector((state: RootState) => state.config?.formaDePagamento);
    const pedidosDados = useSelector((state: RootState) => state.pedidos?.listaPedidos?.data?.dados);
    const loadingPedidos = useSelector((state: RootState) => state.pedidos?.listaPedidos.loading);
    const exportarRelatorioGeral = useSelector((state: RootState) => state.pedidos.exportarRelatorioGeral);

    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true)
    const cardRef = useRef<CardFormFiltrosRef>(null)

    useEffect(() => {
        if (primeiraRenderizacao) { setPrimeiraRenderizacao(false); return }
        dispatch(taxaExtraStart({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }))
        const subscription = watch((_value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, primeiraRenderizacao]);

    const atualizarDashboard = cardRef.current?.atualizarDispatch ?? false;

    useEffect(() => {
        dispatch(listarVeiculosStart());
        dispatch(listarTiposEmpresas({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }))
        dispatch(listarTipoDeVeiculoStart({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }))
        dispatch(listaEntregadoresStart({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        })
        )
    }, []);

    const handleSectionChange = (section: string | null) => {
        if (section === null) return;
        if (section === 'dashboard') {
            cardRef.current && (cardRef.current.atualizarDispatch = true);
        }
        setActiveSection(section);
    }

    const formFields: Field[] = [
        { name: 'Código do pedido', value: 'codigoExterno' },
        { name: 'Entregador', value: 'entregadorUsuarioID', type: 'customSelect', options: entregadores, valueKey: 'usuarioID', labelKey: 'nome' },
        { name: 'Data inicial', value: 'dtInicial', type: 'dateTime' },
        { name: 'Data final', value: 'dtFinal', type: 'dateTime' },
        {
            name: 'Situação',
            value: 'situacao',
            type: 'selectMultiple',
            labelKey: 'label',
            valueKey: 'value',
            options: [
                { label: 'Recebido', value: 'RECEBIDO' },
                { label: 'Pronto', value: 'PRONTO' },
                { label: 'Despachado', value: 'DESPACHADO' },
                { label: 'Aceito', value: 'ACEITO' },
                { label: 'No estabelecimento', value: 'NO_ESTABELECIMENTO' },
                { label: 'Em rota', value: 'EM_ROTA' },
                { label: 'Retornando', value: 'RETORNANDO' },
                { label: 'Finalizado', value: 'FINALIZADO' },
                { label: 'Cancelado', value: 'CANCELADO' },
            ]
        },
        {
            name: 'Tipo de empresa',
            value: 'tipoEmpresaID',
            type: 'customSelect',
            options: tipoEmpresas,
            valueKey: 'tipoEmpresaID',
            labelKey: 'nome',
        },
        { name: 'Data inicial (Finalizado)', value: 'dtInicialFinalizado', type: 'dateTime' },
        { name: 'Data final (Finalizado)', value: 'dtFinalFinalizado', type: 'dateTime' },
        { name: 'Aplicação de origem', value: 'aplicacaoOrigemNome' },
        { name: 'Taxa extra aplicada', value: 'taxaExtraID', type: 'customSelect', options: taxasExtra, valueKey: 'taxaExtraID', labelKey: 'nome' },
        {
            name: 'Tipo de veículo',
            value: 'tipoVeiculoID',
            type: 'customSelect',
            label: 'Tipo de veículo',
            options: tiposVeiculo,
            valueKey: 'tipoVeiculoID',
            labelKey: 'nome',
        },
        { name: 'Forma de pagamento', value: 'formaPagamentoID', type: 'customSelect', options: formasPagamento, valueKey: 'formaPagamentoID', labelKey: 'nome' },
        {
            name: 'Tipo de fatura', value: 'tipoFatura', type: 'select', options: [
                { label: 'Pré-pago', value: 'prePago' },
                { label: 'Pós-pago', value: 'posPago' },
            ]
        },
        { name: 'Empresa', value: 'empresaID', type: 'autocompleteEmpresa' },

        { name: 'Tags no pedido', value: 'tagsFiltro' },
        { name: 'Tags na empresa', value: 'tagsFiltroEmpresas' },
    ]

    return (
        <Box sx={{ p: 2, backgroundColor: theme.palette.background.paperDark }}>
            <CardFormFiltros
                ref={cardRef}
                fields={formFields}
                formMethods={hookForm}
                reentrega
                considerados
                solicitadoParceiro
                alocados
                dispatchMethods={{ actionName: 'listarPedidosGeral' }}
                loading={loadingPedidos}
            />
            <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap' }} useFlexGap direction="row">
                <CardTabelaTotal
                    srcIcon={circleDollar}
                    altIcon="Ícone Valor Total de Cobrado"
                    bgcolor={theme.palette.green[600]}
                    texts={{
                        title1: 'VALOR TOTAL COBRADO',
                        subtitle1: pedidosDados?.totalCobrado ? dinheiro(pedidosDados?.totalCobrado) : 'R$0,00',
                        title2: 'VALOR MÉDIO POR PEDIDO',
                        subtitle2: pedidosDados?.totalCobrado ? dinheiro((pedidosDados?.totalCobrado / pedidosDados?.total).toFixed(2)) : 'R$0,00',
                    }}
                />
                <CardTabelaTotal
                    srcIcon={handCircleDollar}
                    altIcon="Ícone Valor Total de Custo"
                    bgcolor={theme.palette.red[600]}
                    texts={{
                        title1: 'VALOR TOTAL DE CUSTO',
                        subtitle1: pedidosDados?.totalCusto ? dinheiro(pedidosDados?.totalCusto) : 'R$0,00',
                        title2: 'VALOR MÉDIO POR PEDIDO',
                        subtitle2: pedidosDados?.totalCusto ? dinheiro((pedidosDados?.totalCusto / pedidosDados?.total).toFixed(2)) : 'R$0,00',
                    }}
                />
                <CardTabelaTotal
                    srcIcon={sackDollar}
                    altIcon="Ícone Valor Total de Vendas"
                    bgcolor={theme.palette.yellow[600]}
                    texts={{
                        title1: 'VALOR TOTAL DE VENDAS',
                        subtitle1: pedidosDados?.totalFaturado ? dinheiro(pedidosDados?.totalFaturado) : 'R$0,00',
                        title2: 'TICKET MÉDIO',
                        subtitle2: pedidosDados?.totalFaturado ? dinheiro((pedidosDados?.totalFaturado / pedidosDados?.total).toFixed(2)) : 'R$0,00',
                    }}
                />
                <CardTabelaTotal
                    srcIcon={motorcycle}
                    altIcon="Ícone Valor Total de Pedidos"
                    bgcolor={theme.palette.blue[600]}
                    texts={{
                        title1: 'PEDIDOS',
                        subtitle1: pedidosDados?.total ? pedidosDados?.total : 0,
                    }}
                />
            </Stack>
            <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }} direction="row">
                <Tabs
                    value={activeSection}
                    onChange={(_, value) => handleSectionChange(value)}
                    textColor="inherit"
                    variant="fullWidth"
                >
                    <Tab label="Pedidos" value="pedidos" />
                    <Tab label="Dashboard" value="dashboard" />
                </Tabs>
            </Stack>
            <Card elevation={1} sx={{ mt: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">PEDIDOS</Typography>
                    {activeSection === 'pedidos' && <ExportarTabela dispatchMethods={{ actionName: 'exportarRelatorioGeralStart', payload: hookForm.getValues() }} loading={exportarRelatorioGeral.loading} />}
                </Box>
                {!!pedidosDados?.ultimaPagina && pedidosDados?.ultimaPagina > 0 && activeSection === 'pedidos' && (
                    <TableNavigation
                        indiceInicial={pedidosDados?.indiceInicial}
                        indiceFinal={pedidosDados?.indiceFinal}
                        indiceTotal={pedidosDados?.total}
                        ultimaPagina={pedidosDados?.ultimaPagina}
                        hookForm={hookForm}
                        podeTodos={false}
                    />
                )}
                {activeSection === 'pedidos'
                    ? <TabelaPedidos
                        pedidos={pedidosDados?.lista}
                        loading={loadingPedidos}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                    />
                    : <DashboardPedidos
                        formMethods={hookForm}
                        loading={loadingPedidos}
                        atualizar={atualizarDashboard}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                    />}
            </Card>
        </Box>
    );
};

const stylesForTypography = {
    flex: '2 0 250px'
}

export default RelatorioGeral;
