import { Box, Card, CardContent, Grid2, Theme, Typography } from '@mui/material'
import React from 'react'

interface Texts {
    title1: string;
    subtitle1?: string | number;
    title2?: string;
    subtitle2?: string;
}

interface CardTabelaTotalProps {
    srcIcon: string;
    altIcon?: string;
    bgcolor?: string;
    texts: Texts;
}

const CardTabelaTotal = ({
    srcIcon, altIcon, bgcolor, texts
}: CardTabelaTotalProps) => {
    return (
        <Card
            elevation={1}
            sx={{
                borderRadius: '10px',
                flex: '1 0 300px',
                maxHeight: '136px',
                width: '100%',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 2px 0 rgba(0, 0, 0, 0.16)',
                '& .MuiCardContent-root': {
                    height: '100%',
                    padding: 0,
                    '& .MuiGrid2-root': {
                        height: '100%',
                    }
                },
                '&:hover': {
                    transform: 'scale(1.03)',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                }
            }}
        >
            <CardContent>
                <Grid2 container>
                    <Grid2 size={{ xs: 4 }}>
                        <Box
                            sx={(theme: Theme) => ({
                                width: '100%',
                                maxWidth: '100%',
                                height: '100%',
                                padding: '1.5rem',
                                boxSizing: 'border-box',
                                '& img': {
                                    display: 'block',
                                    width: '100%',
                                    height: '100%',
                                },
                                bgcolor: bgcolor ?? theme.palette.grey[600],
                                userSelect: 'none',
                            })}
                        >
                            <img src={srcIcon} alt={altIcon ?? texts.title1} />
                        </Box>
                    </Grid2>
                    <Grid2 size={{ xs: 8 }} sx={{ p: 2 }}>
                        <Typography variant="body1">{texts.title1}</Typography>
                        {texts.subtitle1 && <Typography variant="body1" fontWeight={700}>{texts.subtitle1}</Typography>}
                        {texts.title2 && <Typography variant="body2" sx={{ mt: 1 }}>{texts.title2}</Typography>}
                        {texts.subtitle2 && <Typography variant="body2" fontWeight={700}>{texts.subtitle2}</Typography>}
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

export default CardTabelaTotal
