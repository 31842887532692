import { useCallback, useEffect, useState } from 'react';
import { Button, Paper, Typography, useTheme } from '@mui/material';
import { ColumnGrid, Line } from '../../../ds';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { alterarSituacaoRequest, emAndamentoStart, limparResposta } from '../../../store/reducers/config';
import { unSanitizeSituacao } from '../../../ds/common';
import { ButtonLoading, SnackbarAlert } from '../../../ds/DesignSystem';
import { RootState } from 'src/store/reducers';
import { useSnackbar } from 'src/ds/CustomHooksDs';

interface Props {
    onClose: () => void;
    pedido: any;
    closePopper: () => void;
    statusAtual: any;
}

const ModalTrocarStatus = ({ onClose, pedido, closePopper, statusAtual }: Props) => {
    const theme = useTheme();
    const [selectedStatus, setSelectedStatus] = useState<null | string>(null);
    const usuario = useSelector((state: RootState) => state.login.usuario);
    const respostaSituacao = useSelector((state: RootState) => state.config.respostaSituacao);
    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.config.loadingSituacao);
    const snackbar = useSnackbar({ duration: 5000 });
    
    const handleStatusClick = (status: string) => {
        setSelectedStatus(status);
    };

    useEffect(() => {
        if (statusAtual) {
            setSelectedStatus(statusAtual);
        }
    }, [statusAtual]);

    const handleTrocarStatus = useCallback(() => {
        dispatch(alterarSituacaoRequest({ pedidoID: pedido?.pedidoID, situacao: unSanitizeSituacao(selectedStatus) }));
    }, [dispatch, pedido?.pedidoID, selectedStatus]);

    useEffect(() => {
        if (respostaSituacao) {
            snackbar.showSuccess(respostaSituacao);
            // @ts-expect-error
            dispatch(emAndamentoStart(usuario.id));
            setTimeout(() => {
                dispatch(limparResposta());
                closePopper()
            }, 1000)
        }
    }, [respostaSituacao]);

    return (
        <Paper
            sx={{
                p: theme.sizes.padding.sm,
                width: '200px',
                maxHeight: '100vh',
                maxWidth: '100vw',
                overflow: 'auto',
            }}
        >
            <Line justifyContent="space-between" alignItems="flex-start">
                <CloseOutlined style={{ cursor: 'pointer' }} fontSize="small" sx={{ mr: 1 }} onClick={onClose} />
                <Typography variant='body1'>Alterar situação #{pedido?.codigoQuatro}</Typography>
                <span></span>
            </Line>
            <ColumnGrid mt={2}>
                {['Recebido', 'Pronto', 'Aceito', 'No local', 'Em rota', 'Retornando', 'Finalizado', 'Cancelado'].map((status) => (
                    <Button
                        variant='outlined'
                        key={status}
                        onClick={() => handleStatusClick(status)}
                        sx={{
                            marginBottom: '0.3rem',
                            backgroundColor: selectedStatus === status ? theme.palette.primary.main : theme.palette.background.default,
                            color: selectedStatus === status ? theme.palette.primary.contrastText : theme.palette.grey[500],
                            border: selectedStatus === status ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
                            borderRadius: theme.sizes.borderRadius.sm,
                        }}
                    >
                        {status}
                    </Button>
                ))}
                <ButtonLoading
                    loading={loading}
                    variant="containedFilled"
                    sx={{ mt: 2 }}
                    onClick={handleTrocarStatus}
                >
                    Salvar situação
                </ButtonLoading>
            </ColumnGrid>

            <SnackbarAlert {...snackbar} />
        </Paper>
    );
};

export default ModalTrocarStatus;
