import { QrCode } from '@mui/icons-material';
import { Box, Button, Card, Stack, Theme, Typography, Grid, Divider, TextField, useTheme, FormGroup, FormLabel, Avatar } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TabelaFaturas from 'src/components/MinhasFaturas/TabelaFaturas';
import { dinheiro } from 'src/ds/common';
import { useResponsive } from 'src/ds/CustomHooksDs';
import { TableNavigation } from 'src/ds/DesignSystem';
import { CardFormFiltrosRef } from 'src/ds/Forms';
import { Pick } from 'src/dtos/ConfigDTO';
import { RootState } from 'src/store/reducers';
import { listarMinhasFaturasStart } from 'src/store/reducers/faturas';

const MinhasFaturas = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const cardRef = useRef<CardFormFiltrosRef>(null);
    const dadosFaturas = useSelector((state: RootState) => state.faturas?.listarMinhasFaturas?.data?.dados);
    const listaFaturas = useSelector((state: RootState) => state.faturas?.listarMinhasFaturas?.data?.dados?.lista);
    const pick: Pick = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada);
    const loading = useSelector((state: RootState) => state.faturas?.listarMinhasFaturas?.loading);
    const template = useSelector((state: RootState) => state.config?.template?.template);

    const [selectedItem, setSelectedItem] = useState<any>(null);

    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: 20,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
        }
    })

    useEffect(() => {
        dispatch(listarMinhasFaturasStart(hookForm.getValues()));
        if (listaFaturas?.length > 0) setSelectedItem(listaFaturas[0]);
    }, []);

    const { isMobile } = useResponsive();
    return (
        <Box p={2} >
            <Card elevation={1} sx={{ mt: 2, p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Faturas</Typography>
                </Box>
                {(dadosFaturas?.ultimaPagina) ? (
                    <TableNavigation
                        indiceInicial={dadosFaturas?.indiceInicial}
                        indiceFinal={dadosFaturas?.indiceFinal}
                        indiceTotal={dadosFaturas?.indiceFinal}
                        ultimaPagina={dadosFaturas?.ultimaPagina}
                        hookForm={hookForm}
                    />

                ) : null}
                <TabelaFaturas data={listaFaturas} loading={loading} fezRequisicao={cardRef.current?.fezRequisicao}  />
            </Card>
        </Box>
    );
}

export default MinhasFaturas;
