import { Delete, Edit } from '@mui/icons-material';
import { Autocomplete, Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import InputTime from 'src/ds/components/InputTime';
import TipoDeVeiculo from 'src/ds/components/TipoDeVeiculo';
import { useMultiResponseSnackbar, useSnackbar } from 'src/ds/CustomHooksDs';
import { InputDateTime, ModalOpcoes, SnackbarAlert, TableNavigation, TableRowNotFound } from 'src/ds/DesignSystem';
import { excluirPorDiaDaSemanaStart, excluirTipoDeVeiculoStart, limparExcluirPorDiaDaSemana, limparExcluirTipoDeVeiculo, limparSalvarPorDiaDaSemana, limparSalvarTipoDeVeiculoTaxaExtra, limparTiposDeVeiculoTaxaExtra, listarPorDiaDaSemanaStart, listarTiposDeVeiculoTaxaExtraStart, salvarAgendamentoPorDataStart, salvarPorDiaDaSemanaStart, salvarTipoDeVeiculoTaxaExtraStart } from 'src/store/reducers/taxaExtra';

interface AgendamentoDataProps {
    data: any;
}

const AgendamentoDatas = ({ data }: AgendamentoDataProps) => {
    const [showSelector, setShowSelector] = React.useState(false);
    const [openDeletar, setOpenDeletar] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const snack = useSnackbar({ duration: 2000 });
    const response3 = useSelector((state: any) => state.taxaExtra.salvarAgendamentoPorData);
    const loadingSalvar = response3?.data?.loading;

    useMultiResponseSnackbar(
        [
            { response: response3, clearAction: () => dispatch(limparSalvarPorDiaDaSemana()) }
        ], snack);


    const hookForm = useForm({
        defaultValues: {
            taxaExtraID: data?.taxaExtraID,
            dataInicio: '',
            dataFim: '',
        }
    });
    const dispatch = useDispatch();
    const handleSave = () => {
        dispatch(salvarAgendamentoPorDataStart(hookForm.getValues()))
    }


    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>

            <Stack spacing={2} >
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={hookForm.handleSubmit(handleSave)}>
                    <InputDateTime
                        hookForm={{
                            name: 'dataInicio',
                            methods: hookForm,
                            rules: { required: 'Data inicial obrigatório' },
                        }}
                        label='Data inicial'
                    />
                    <InputDateTime
                        hookForm={{
                            name: 'dataFim',
                            methods: hookForm,
                            rules: { required: 'Data final obrigatório' },
                        }}
                        label='Data final'
                    />
                    <Button
                        variant="containedFilled"
                        sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                        type='submit'
                        disabled={loadingSalvar}
                    >
                        Salvar
                    </Button>
                </Box>
            </Stack>
            <SnackbarAlert {...snack} />
        </Stack>
    );
}

export default AgendamentoDatas