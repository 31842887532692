// @ts-nocheck
import { Box, Card, Typography } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/Forms";
import { useForm } from "react-hook-form";
import TabelaClientes from "../../components/Clientes/TabelaClientes";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { RootState } from "src/store/reducers";
import { TableNavigation } from "src/ds/DesignSystem";

const Clientes = () => {
    const clientesDados = useSelector((state: RootState) => state.clientes.listaClientes?.data);
    const clientes = clientesDados?.lista;
    const loadingClientes = useSelector((state: RootState) => state.clientes.loading);

    const hookForm = useForm({
        defaultValues: {
            filtros: '',
            empresaID: '',
            dtInicial: '',
            dtFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true)
    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao) {
            cardRef.current?.onSubmit();
            setPrimeiraRenderizacao(false);
            return
        }

        const subscription = watch((_value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, primeiraRenderizacao]);

    return (
        <Box >
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'listaClientesStart'
                    }}
                    fields={[
                        { name: "Buscar", value: "filtros" },
                        { name: 'Empresas', value: 'empresaID', type: 'autocompleteEmpresa' },
                    ]}
                    loading={loadingClientes}
                />
            </Box>

            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">CLIENTES</Typography>
                </Box>
                {clientesDados?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={clientesDados.indiceInicial}
                        indiceFinal={clientesDados.indiceFinal}
                        indiceTotal={clientesDados.total}
                        ultimaPagina={clientesDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <TabelaClientes clientes={clientes} loading={loadingClientes} fezRequisicao={cardRef.current?.fezRequisicao} />
            </Card>
        </Box>
    );
};

export default Clientes;
