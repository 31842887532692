import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@mui/material'
import { Pedido } from '../../dtos/PedidosDTO'
import { dinheiro, getSituacaoStyles } from '../../ds/common'
import { TableRowLoading, TableRowNotFound, ChipSituacao } from 'src/ds/DesignSystem'
import accon from '../../assets/integracoes/accon.png';
import alloy from '../../assets/integracoes/alloy.png';
import anotaai from '../../assets/integracoes/anotaai.png';
import deliverydireto from '../../assets/integracoes/deliverydireto.png';
import ifood from '../../assets/integracoes/ifood.png';
import neemo from '../../assets/integracoes/neemo.png';
import { formatTime } from 'src/utils';
import { useNavigate } from 'react-router-dom';
interface TabelaPerformanceProps {
    pedidos: any[] | undefined;
    loading: boolean;
    fezRequisicao?: boolean;
}

const TabelaPerformance = ({ pedidos, loading, fezRequisicao }: TabelaPerformanceProps) => {

    const navigate = useNavigate()

    return (
        <TableContainer
            sx={{
                width: '100%',
                overflowX: 'auto',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ width: '15%' }}>Empresa</TableCell>
                        <TableCell align="center" sx={{ width: '15%' }}>Entregador</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Código do pedido</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Tempo de produção</TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>Tempo de despacho</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Tempo de aceite motoboy</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Tempo de chegada motoboy</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Tempo de rota entregador</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Distância</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Tempo total pedido</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Tempo ETA pedido</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading
                        ? <TableRowLoading />
                        : (!pedidos || pedidos?.length === 0)
                            ? <TableRowNotFound text="Nenhum resultado encontrado" fezRequisicao={fezRequisicao} />
                            : pedidos.map((pedido) => (
                                <TableRow key={pedido.pedidoID} hover>
                                    <TableCell align="center">
                                        <Typography variant="body2" sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                            {pedido.nomeEmpresa}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            {pedido.nomeUsuario}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Chip label={pedido.codigoExterno} color='success' onClick={() => { navigate(`/Pedido/Index/${pedido.pedidoID}`) }} />
                                    </TableCell>
                                    <TableCell align="center">{pedido.tempoProducao}s</TableCell>
                                    <TableCell sx={{ width: '20%' }} align="center">
                                        <Typography variant="body2" sx={{ marginBottom: '8px', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                            {formatTime(pedido.tempoDespacho)}
                                        </Typography>
                                    </TableCell>

                                    <TableCell align="center">{formatTime(pedido.tempoAceiteMotoboy)}</TableCell>
                                    <TableCell align="center">{formatTime(pedido.tempoChegadaMotoboy)}</TableCell>
                                    <TableCell align="center">{formatTime(pedido.tempoRotaEntregador)}</TableCell>
                                    <TableCell align="center">{Number(pedido.distancia.toFixed(2))}</TableCell>
                                    <TableCell align="center">{formatTime(pedido.tempoTotalPedido)}</TableCell>
                                    <TableCell align="center">
                                        <Chip
                                            label={formatTime(pedido.tempoETA)}
                                            color={
                                                pedido.tempoETA > 0
                                                    ? 'error'
                                                    : pedido.tempoETA === 0
                                                        ? 'default'
                                                        : 'success'
                                            }
                                        />
                                    </TableCell>

                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TabelaPerformance;
