// TableNavigation.tsx
import { Stack, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import SelectPaginacao from './SelectPaginacao';
import ButtonGroupPages from './ButtonGroupPages';

interface TableNavigationProps {
  indiceInicial?: string | number;
  indiceFinal?: string | number;
  indiceTotal?: string | number;
  ultimaPagina: number;
  hookForm: UseFormReturn<any>;
  namePagina?: string;
  namePaginacao?: string;
  podeTodos?: boolean;
}

const TableNavigation = ({
  indiceInicial,
  indiceFinal,
  indiceTotal,
  ultimaPagina,
  hookForm,
  namePagina = 'pagina',
  namePaginacao = 'paginacao',
  podeTodos,
}: TableNavigationProps) => {
  return (
    <Stack
      useFlexGap
      sx={{
        gap: 2,
        width: '100%',
        p: 2,
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'flex-end', sm: 'center' },
        justifyContent: { xs: 'center', sm: 'flex-end' },
        boxSizing: 'border-box',
        overflow: 'visible',
        flexWrap: 'wrap'
      }}
    >
      <Typography align='right' sx={{ flex: '1' }}>
        {(!indiceInicial || !indiceFinal)
          ? `Mostrando ${indiceTotal} registros`
          : `Mostrando ${indiceInicial} à ${indiceFinal} de ${indiceTotal} registros`}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        useFlexGap
        alignItems="center"
        justifyContent="flex-end"
        flexWrap={'wrap'}
      >
        <ButtonGroupPages
          hookForms={hookForm}
          ultimaPagina={ultimaPagina}
          name={namePagina}
        />
        <SelectPaginacao
          hookForms={hookForm}
          name={namePaginacao}
          podeTodos={podeTodos}
        />
      </Stack>
    </Stack>
  );
};

export default TableNavigation;
