import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import {
    tornarEntregadorOnline, respostaOnline, registrarErro, listarCidades, listarCidadesSuccess, listarCidadesFailure, listarParceiros, listarParceirosSuccess, listarParceirosFailure, listarPlanosFailure, listarPlanosSuccess, listarPlanos,
    listarQrCodeStart, listarQrCodeSuccess, listarQrCodeFailure,
    gerarQrCodeStart, gerarQrCodeSuccess, gerarQrCodeFailure,
    inativarQrCodeStart, inativarQrCodeSuccess, inativarQrCodeFailure,
    listarestadosSuccess, listarestadosFailure, listarestados,
    atualizarQrCodeStart, atualizarQrCodeSuccess, atualizarQrCodeFailure,
    fetchCnpjStart,
    fetchCnpjSuccess,
    fetchCnpjFailure,
    listarBancosStart,
    listarBancosSuccess,
    listarBancosFailure,
} from '../reducers/utils';

function* tornarOnline(action) {
    const { usuarioID, condition } = action.payload;
    try {
        const response = yield call(api.post, `/Widget/RegistrarSituacaoEntregador?usuarioID=${usuarioID}&online=${condition}`);
        yield put(respostaOnline(response.data));
    } catch (error) {
        yield put(registrarErro(error.toString()));
    }
}

function* listarCidadesRequest(action) {
    const form = new FormData();
    form.append('pagina', 1)
    form.append('paginacao', -1)
    try {
        const response = yield call(api.post, '/api/listarcidades', form);
        if (response.data.sucesso === false) {
            yield put(listarCidadesFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(listarCidadesSuccess(response.data.dados.lista));
    } catch (error) {
        yield put(listarCidadesFailure(error.toString()));
    }
}

function* listarEstadosRequest() {
    const form = new FormData();
    form.append('pagina', 1)
    form.append('paginacao', -1)
    try {
        const response = yield call(api.post, '/api/listarestados', form);
        if (response.data.sucesso === false) {
            yield put(listarestadosFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(listarestadosSuccess(response.data.dados.lista));
    } catch (error) {
        yield put(listarestadosFailure(error.toString()));
    }
}

function* listarParceirosRequest(action) {

    const form = new FormData();
    const cidadeID = action.payload.cidadeID;

    form.append('cidadeID', cidadeID)

    try {
        const response = yield call(api.post, '/Assinatura/GetPicksDaCidade', form);
        if (response.data.sucesso === false) {
            yield put(listarParceirosFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(listarParceirosSuccess(response.data.dados.lista));
    } catch (error) {
        yield put(listarParceirosFailure(error.toString()));
    }
}

function* listarPlanosRequest(action) {
    const form = new FormData();
    form.append('pagina', 1)
    form.append('paginacao', -1)
    try {
        const response = yield call(api.post, '/PlanoAssinatura/Listar');
        if (response.data.sucesso === false) {
            yield put(listarPlanosFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(listarPlanosSuccess(response.data.dados));
    }
    catch (error) {
        yield put(listarPlanosFailure(error.toString()));
    }
}

function* listarQrCode(action) {
    const formData = new FormData();
    formData.append('filtros', `empresaID.ToString() == "${action.payload}"`);
    formData.append('pagina', '1');
    formData.append('paginacao', '-1');
    try {
        const response = yield call(api.post, 'EmpresaQrcode/Listar', formData);
        if (response.data.sucesso === false) {
            yield put(listarQrCodeFailure(response.data));
        } else {
            yield put(listarQrCodeSuccess(response.data));
        }
    }
    catch (error) {
        yield put(listarQrCodeFailure(error.toString()));
    }
}

function* gerarQrCode(action) {
    const formData = new FormData();
    formData.append('conteudo', action.payload);
    formData.append('altura', '250');
    formData.append('largura', '250');
    formData.append('margem', '0');
    try {
        const response = yield call(api.post, '/Utils/GerarQrCode', formData);
        if (response.data.sucesso === false) {
            yield put(gerarQrCodeFailure(response.data.mensagem));
        } else {
            yield put(gerarQrCodeSuccess(response.data));
        }
    }
    catch (error) {
        yield put(gerarQrCodeFailure(error.toString()));
    }
}

function* atualizarQrCode(action) {
    const formData = new FormData();
    formData.append('empresaID', action.payload);
    try {
        const response = yield call(api.post, 'EmpresaQrcode/Salvar', formData);
        if (!response.data.sucesso) {
            yield put(atualizarQrCodeFailure(response.data.mensagem));
        } else {
            yield put(atualizarQrCodeSuccess(response.data));
        }
    }
    catch (error) {
        yield put(atualizarQrCodeFailure(error.toString()));
    }
}

function* inativarQrCode(action) {
    const formData = new FormData();
    formData.append('id', action.payload);
    try {
        const response = yield call(api.post, 'EmpresaQrcode/Inativar', formData);
        if (!response.data.sucesso) {
            yield put(inativarQrCodeFailure(response.data.mensagem));
        } else {
            yield put(inativarQrCodeSuccess(response.data));
        }
    }
    catch (error) {
        yield put(inativarQrCodeFailure(error.toString()));
    }
}

function* fetchCnpjSaga(action) {
    try {
        const form = new FormData();
        form.append('cnpj', action.payload);
        const response = yield call(api.post, '/Utils/ConsultarCNPJ', form);
        if (!response.data.sucesso) {
            yield put(fetchCnpjFailure(response.data.mensagem));
            return;
        }
        yield put(fetchCnpjSuccess(response.data));
    } catch (error) {
        yield put(fetchCnpjFailure(error.toString()));
    }
}

function* listarBancos(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Banco/Listar', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }
        yield put(listarBancosSuccess(response.data));
    } catch (error) {
        yield put(listarBancosFailure(error.toString()));
    }
}



function* utilsSaga() {
    yield takeLatest(tornarEntregadorOnline.type, tornarOnline);
    yield takeLatest(listarCidades.type, listarCidadesRequest);
    yield takeLatest(listarParceiros.type, listarParceirosRequest);
    yield takeLatest(listarPlanos.type, listarPlanosRequest);
    yield takeLatest(listarQrCodeStart.type, listarQrCode);
    yield takeLatest(gerarQrCodeStart.type, gerarQrCode);
    yield takeLatest(inativarQrCodeStart.type, inativarQrCode);
    yield takeLatest(atualizarQrCodeStart.type, atualizarQrCode);
    yield takeLatest(listarestados.type, listarEstadosRequest);
    yield takeLatest(fetchCnpjStart.type, fetchCnpjSaga);
    yield takeLatest(listarBancosStart.type, listarBancos);

}
export default utilsSaga;
