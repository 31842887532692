import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@mui/material'
import { dinheiro, getSituacaoStyles } from '../../ds/common'
import { TableRowLoading, TableRowNotFound, ChipSituacao } from 'src/ds/DesignSystem'
import accon from '../../assets/integracoes/accon.png';
import alloy from '../../assets/integracoes/alloy.png';
import anotaai from '../../assets/integracoes/anotaai.png';
import deliverydireto from '../../assets/integracoes/deliverydireto.png';
import ifood from '../../assets/integracoes/ifood.png';
import neemo from '../../assets/integracoes/neemo.png';
import { formatTime } from 'src/utils';
import { useNavigate } from 'react-router-dom';
interface TabelaPerformanceProps {
    data: any[] | undefined;
    loading: boolean;
    fezRequisicao?: boolean;
}

const TabelaPerformance = ({ data, loading, fezRequisicao }: TabelaPerformanceProps) => {

    const navigate = useNavigate()

    return (
        <TableContainer
            sx={{
                width: '100%',
                overflowX: 'auto',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{ width: '15%' }}>Entregador</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Qtd. pedidos aceitos</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Qtd. recusados manualmente</TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>Qtd. recusados automaticamente </TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Pontuação</TableCell>
                        <TableCell align="center" sx={{ width: '10%' }}>Tempo online</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading
                        ? <TableRowLoading />
                        : (!data || data?.length === 0)
                            ? <TableRowNotFound text="Nenhum resultado encontrado" fezRequisicao={fezRequisicao} />
                            : data.map((row) => (
                                <TableRow key={row.usuarioID} hover>
                                    <TableCell align="center">
                                        <Typography variant="body2" sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                                            {row.nomeUsuario}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            {row.totalPedidosAceitos}
                                        </Typography>
                                    </TableCell>

                                    <TableCell align="center">{row.totalPedidosRecusadosManual}s</TableCell>


                                    <TableCell align="center">{row.totalPedidosRecusadosAutomatico}</TableCell>
                                    <TableCell align="center">{row.pontuacao}</TableCell>
                                    <TableCell align="center">{row.tempoOnline}</TableCell>



                                </TableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TabelaPerformance;
