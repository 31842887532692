import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'src/ds/CustomHooksDs';
import { ButtonTableOrder, SnackbarAlert, TableNavigation, TableRowLoading, TableRowNotFound } from 'src/ds/DesignSystem';
import { RootState } from 'src/store/reducers';
import { pedidoDespachosClear, pedidoDespachosStart } from 'src/store/reducers/pedidos';
import { useForm } from 'react-hook-form';

const Despachos: React.FC = () => {
    const dispatch = useDispatch();
    const { id: pedidoID } = useParams();
    const pedidoDespachos = useSelector((state: RootState) => state.pedidos.pedidoDespachos);
    const snackbar = useSnackbar({ duration: 5000 });
    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: 20
        }
    });

    const { watch } = hookForm;
    const [order, setOrder] = useState<{ ordenacaoCampo: string; ordenacaoOrdem: 'asc' | 'desc' } | null>(null);

    const updateOrder = (field: string) => {
        setOrder(prevOrder => ({
            ordenacaoCampo: field,
            ordenacaoOrdem: prevOrder?.ordenacaoCampo === field && prevOrder.ordenacaoOrdem === 'asc' ? 'desc' : 'asc'
        }));
    };

    useEffect(() => {
        if (!pedidoID) return;
        dispatch(pedidoDespachosStart({
            pedidoID,
            pagina: watch('pagina'),
            paginacao: watch('paginacao'),
            ordenacaoCampo: order?.ordenacaoCampo || '',
            ordenacaoOrdem: order?.ordenacaoOrdem || ''
        }));
    }, [order, watch, pedidoID, dispatch, watch('pagina'), watch('paginacao')]);

    useEffect(() => {
        if (pedidoDespachos.error) {
            snackbar.showError(pedidoDespachos.error);
        }
        return () => { dispatch(pedidoDespachosClear()) }
    }, [pedidoDespachos.error]);

    return (
        <>
            <Box>
                <Stack>
                    {pedidoDespachos.data?.dados &&
                        <TableNavigation
                            hookForm={hookForm}
                            ultimaPagina={pedidoDespachos.data?.dados?.ultimaPagina}
                            indiceFinal={pedidoDespachos.data?.dados?.indiceFinal}
                            indiceInicial={pedidoDespachos.data?.dados?.indiceInicial}
                            indiceTotal={pedidoDespachos.data?.dados?.total}
                        />
                    }
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'dataCadastro' ? { dataCadastro: order.ordenacaoOrdem } : {}}
                                            nome='dataCadastro'
                                            onClick={() => updateOrder('dataCadastro')}
                                        >
                                            Data
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'dataResposta' ? { dataResposta: order.ordenacaoOrdem } : {}}
                                            nome='dataResposta'
                                            onClick={() => updateOrder('dataResposta')}
                                        >
                                            Data de resposta
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'respostaAutomatica' ? { respostaAutomatica: order.ordenacaoOrdem } : {}}
                                            nome='respostaAutomatica'
                                            onClick={() => updateOrder('respostaAutomatica')}
                                        >
                                            Resposta automática
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'aceito' ? { aceito: order.ordenacaoOrdem } : {}}
                                            nome='aceito'
                                            onClick={() => updateOrder('aceito')}
                                        >
                                            Aceito
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder
                                            sortDirection={order?.ordenacaoCampo === 'usuario.nome' ? { 'usuario.nome': order.ordenacaoOrdem } : {}}
                                            nome='usuario.nome'
                                            onClick={() => updateOrder('usuario.nome')}
                                        >
                                            Entregador
                                        </ButtonTableOrder>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <ButtonTableOrder>Log</ButtonTableOrder>
                                    </TableCell>
                                    {/* <TableCell align='center'>
                                        Ações
                                    </TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pedidoDespachos.loading
                                    ? <TableRowLoading />
                                    : (pedidoDespachos.data?.dados && pedidoDespachos.data.dados.lista?.length > 0)
                                        ? pedidoDespachos.data.dados.lista.map((despacho) => (
                                            <TableRow hover key={despacho.pedidoDespachoID}>
                                                <TableCell align='center'>{despacho.dataCadastro || '-'}</TableCell>
                                                <TableCell align='center'>{despacho.dataResposta || '-'}</TableCell>
                                                <TableCell align='center'>{despacho.respostaAutomatica ? 'Sim' : 'Não'}</TableCell>
                                                <TableCell align='center'>{despacho.aceito ? 'Sim' : 'Não'}</TableCell>
                                                <TableCell align='center'>{despacho.usuarioNome || '-'}</TableCell>
                                                <TableCell align='center'>{despacho.log || '-'}</TableCell>
                                                {/* <TableCell align='center'>
                                                    <Tooltip arrow placement='top' title='Excluir'>
                                                        <IconButton onClick={() => { alert('Clicou') }}>
                                                            <DeleteRoundedIcon color='error' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell> */}
                                            </TableRow>
                                        ))
                                        : <TableRowNotFound />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>
            <SnackbarAlert {...snackbar} />
        </>
    );
};

export default Despachos;
