import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import { exportarTabelaEmpresas, exportarTabelaEmpresasFailure, exportarTabelaEmpresasSuccess, listaEmpresasFailure, listaEmpresasStart, listaEmpresasSuccess, listarTiposEmpresas, tiposEmpresasFailure, tiposEmpresasSuccess } from '../reducers/empresa';

export function* listaEmpresas(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, 'Empresa/Listar', form);

        if (response.data.sucesso !== true) {
            yield put(listaEmpresasFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(listaEmpresasSuccess(response.data.dados));
    } catch (error) {
        yield put(listaEmpresasFailure(error.toString()));
    }
}

export function* exportarEmpresa(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.get, 'Empresa/Listar', form);

        if (response.data.sucesso !== true) {
            yield put(listaEmpresasFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(exportarTabelaEmpresasSuccess(response.data.dados));
    } catch (error) {
        yield put(exportarTabelaEmpresasFailure(error.toString()));
    }
}

export function* tiposEmpresa(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, 'TipoEmpresa/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(tiposEmpresasFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(tiposEmpresasSuccess(response.data.dados));
    } catch (error) {
        yield put(tiposEmpresasFailure(error.toString()));
    }
}

function* ListarEmpresas() {
    yield takeLatest(listaEmpresasStart.type, listaEmpresas);
    yield takeLatest(exportarTabelaEmpresas.type, exportarEmpresa);
    yield takeLatest(listarTiposEmpresas.type, tiposEmpresa);

}

export default ListarEmpresas;
