import { cleanInput } from "src/ds/common";

export const setCookies = (nome, valor, diasParaExpirar) => {
    const data = new Date();
    data.setTime(data.getTime() + (diasParaExpirar * 24 * 60 * 60 * 1000));
    const expires = "expires=" + data.toUTCString();
    document.cookie = nome + "=" + valor + ";" + expires + ";path=/";
}

export const converterDataCadastro = (dataCadastroStr) => {
    if (!dataCadastroStr) {
        return 0;
    }

    const [datePart, timePart] = dataCadastroStr.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    return new Date(year, month - 1, day, hours, minutes, seconds);
};

// Função para validar CPF
export const validateCPF = (cpf) => {
    cpf = cleanInput(cpf);
    if (cpf.length !== 11) return false;

    let sum = 0, remainder;
    for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(10, 11))) return false;

    return true;
};

// Função para validar CNPJ
export const validateCNPJ = (cnpj) => {
    cnpj = cleanInput(cnpj);
    if (cnpj.length !== 14) return false;

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0, pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;

    length = length + 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;


    for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1))) return false;

    return true;
};


export const sanitizeSituacao = (pedido) => {
    switch (pedido?.situacao) {
        case 'CRIADO':
            return 'Criado'
        case 'PRONTO':
            return 'Pronto'
        case 'DESPACHADO':
            return 'Despachado'
        case 'RECEBIDO':
            return 'Recebido'
        case 'EM_ROTA':
            return 'Em rota'
        case 'ACEITO':
            return 'Aceito'
        case 'NO_ESTABELECIMENTO':
            return 'No local'
        case 'RETORNANDO':
            return 'Retornando'
    }
    return (pedido?.situacao)
}

export const PLANOFREEMIUM = '8ad3b371-1a50-ef11-acd3-0e6c0ab65689'

export const GOOGLE_API_KEY = 'AIzaSyDi_LKzscOj1wkn9GeYROet6_dfGfz91Kk'
export const MAPBOX_API_KEY = 'pk.eyJ1IjoicGlja25nby1kZW1vIiwiYSI6ImNsOXlxNXpxNzA3Y20zb2x4djBodmh0dHIifQ.F4C8zTSN6X257GKlrB5nWQ'

export function formatTime(seconds) {
    seconds = Math.round(seconds);

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
        return `${hours}h${minutes}m${remainingSeconds}s`;
    } else if (minutes > 0) {
        return `${minutes}m${remainingSeconds}s`;
    } else {
        return `${remainingSeconds}s`;
    }
}


