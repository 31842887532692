import { Dispatch, useEffect, useRef, useState } from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid2, InputAdornment, Slide, Stack, styled, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DateCalendar, LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { criarVagaStart, desassociarEntregadorClear, desassociarEntregadorStart, excluirJornadaClear, excluirJornadaStart, limparCriarVaga } from '../../../store/reducers/escalas';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ModalPesquisarEntregador from 'src/components/common/ModalPesquisarEntregador';
import { AutocompleteTipoVeiculo, BoxLoading, ButtonLoading, ContainerBodyModal, InputDateTime, ModalBase, ModalOpcoes, SnackbarAlert, TextFieldWithButton } from 'src/ds/DesignSystem';
import { useResponsive, useSnackbar } from 'src/ds/CustomHooksDs';
import { RootState } from 'src/store/reducers';
import { Entregador as EntregadorDTO } from 'src/dtos/EntregadoresDTO';
dayjs.locale('pt-br');

interface TipoVeiculo {
    tipoVeiculoID: string;
}

interface Entregador {
    usuarioID: string;
    nome: string;
}

export interface AbrirVagasFormValues {
    tipoVeiculo: TipoVeiculo;
    entregador: Entregador;
    entregadorUsuarioID: string;
    dataAgendamento: string;
    dataFimDiaria: string;
    taxaTotalCobrada: string | number;
    taxaTotalEntregador: string | number;
    minimoGarantidoCobrado: boolean;
    minimoGarantidoEntregador: boolean;
    qtdPedidosMinimos: string;
    minimoGarantidoPorPedido: string;
    detalhesVaga: string;
    datasReplicacaoAux: string;
}

interface InfoPayload {
    pedidoID: string;
    codigoExterno: string;
    empresaID: string;
    tipoVeiculoID: string;
    entregadorUsuarioID: string;
    modalForm_form_entregadorUsuarioID_input: Entregador['nome'];
    dataAgendamento: string;
    dataConcluido: string;
    taxaTotalCobrada: string | number;
    minimoGarantidoCobrado: boolean;
    taxaTotalEntregador: string | number;
    minimoGarantidoEntregador: boolean;
    qtdPedidosMinimoGarantidoCobrado: string;
    detalhes: string;
    minimoGarantidoPorPedido: string;
    qtdVagas: number;
    datasReplicacaoAux: string;
}

interface AbrirVagasProps {
    open: boolean;
    onClose: () => void;
    empresaID: string;
    initialData?: null | Partial<AbrirVagasFormValues>;
    callback?: () => void;
    editar?: boolean;
    pedidoID?: string;
    setPedidoID?: Dispatch<React.SetStateAction<string>>
}

const AbrirVagas = ({
    open,
    onClose,
    empresaID,
    initialData,
    callback,
    editar,
    pedidoID,
    setPedidoID
}: AbrirVagasProps) => {
    const dispatch = useDispatch();
    const { isMobile } = useResponsive()
    const calendarContainer = useRef()
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs | null>(null);
    const [selectedDates, setSelectedDates] = useState<string[]>([]);
    const [modalEntregadoresOpen, setModalEntregadoresOpen] = useState(false);
    const [modalQntVagasOpen, setModalQntVagasOpen] = useState(false);
    const [qntVagas, setQntVagas] = useState(1);
    const [entregadorSelecionado, setEntregadorSelecionado] = useState<Entregador | null>(null);

    const storeVeiculos = useSelector((state: RootState) => state.escalas.listarVeiculos);
    const snackbarVeiculos = useSnackbar({ duration: 5000 });

    const criarVagaStore = useSelector((state: RootState) => state.escalas.criarVaga);
    const snackbarCriarVaga = useSnackbar({ duration: 5000 });

    const desassociarVagasStore = useSelector((state: RootState) => state.escalas.desassociarEntregador);
    const snackbarDesassociarVagas = useSnackbar({ duration: 5000 });

    const excluirVagaStore = useSelector((state: RootState) => state.escalas.excluirJornada);
    const snackbarExcluirVaga = useSnackbar({ duration: 5000 });

    const defaultValues = initialData || {
        tipoVeiculo: { tipoVeiculoID: '' },
        entregador: {
            usuarioID: '',
            nome: ''
        },
        dataAgendamento: dayjs().hour(9).minute(0).format('DD/MM/YYYY HH:mm'),
        dataFimDiaria: dayjs().hour(18).minute(0).format('DD/MM/YYYY HH:mm'),
        taxaTotalCobrada: '0',
        taxaTotalEntregador: '0',
        minimoGarantidoCobrado: false,
        minimoGarantidoEntregador: false,
        qtdPedidosMinimos: '0',
        minimoGarantidoPorPedido: 'false',
        detalhesVaga: '',
        datasReplicacaoAux: ''
    };

    const hookForm = useForm<AbrirVagasFormValues>({
        defaultValues: { ...defaultValues }
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {
            errors,
        },
        control,
        getValues
    } = hookForm;

    const onSubmit: SubmitHandler<AbrirVagasFormValues> = (formData) => {
        const usuarioID = watch('entregador')?.usuarioID
        if (usuarioID === '') {
            setModalQntVagasOpen(true)
        } else {
            criarVagaReq(formData)
        }
    };

    const selecionarEntregador = (entregador: EntregadorDTO) => {
        setEntregadorSelecionado(entregador)
    };

    useEffect(() => {
        if (entregadorSelecionado) {
            setValue('entregador', {
                nome: entregadorSelecionado?.nome,
                usuarioID: entregadorSelecionado?.usuarioID,
            })
            setValue('entregadorUsuarioID', entregadorSelecionado?.usuarioID);
        }
    }, [entregadorSelecionado]);

    const criarVagaReq = (criarVagaPayload?: AbrirVagasFormValues | null) => {
        if (qntVagas >= 1 && criarVagaPayload) {
            const {
                tipoVeiculo,
                entregador,
                dataFimDiaria,
                dataAgendamento,
                taxaTotalCobrada,
                taxaTotalEntregador,
                minimoGarantidoCobrado,
                minimoGarantidoEntregador,
                qtdPedidosMinimos,
                minimoGarantidoPorPedido,
                detalhesVaga
            } = criarVagaPayload

            const infoPayload: InfoPayload = {
                pedidoID: pedidoID || '',
                codigoExterno: 'DIARIA',
                empresaID: empresaID,
                tipoVeiculoID: tipoVeiculo.tipoVeiculoID,
                entregadorUsuarioID: entregador.usuarioID,
                modalForm_form_entregadorUsuarioID_input: entregador.nome,
                dataAgendamento: dataAgendamento,
                dataConcluido: dataFimDiaria,
                taxaTotalCobrada: taxaTotalCobrada,
                minimoGarantidoCobrado: minimoGarantidoCobrado,
                taxaTotalEntregador: taxaTotalEntregador,
                minimoGarantidoEntregador: minimoGarantidoEntregador,
                qtdPedidosMinimoGarantidoCobrado: qtdPedidosMinimos,
                detalhes: detalhesVaga,
                minimoGarantidoPorPedido: minimoGarantidoPorPedido,
                qtdVagas: qntVagas,
                datasReplicacaoAux: selectedDates.length > 0 ? selectedDates.join(',') : '',
                ...selectedDates.reduce((obj, date, index) => ({ ...obj, [`datasReplicacao[${index}]`]: date }), {})
            }
            const formPayload = new FormData();
            Object.keys(infoPayload).forEach(key => {
                const value = infoPayload[key as keyof InfoPayload];
                formPayload.append(key, String(value));
            });
            dispatch(criarVagaStart(formPayload))
        } else {
            console.log('valor inválido');
        }
    };

    const formatToCurrency = (value: number | string) => {
        const stringValue = String(value);
        const cleanValue = stringValue.replace(/\D/g, '');
        if (!cleanValue) return '';
        const numberValue = (parseInt(cleanValue, 10) / 100).toFixed(2);
        return numberValue
            .replace('.', ',')
    };

    const formatQuantity = (value: number | string) => {
        const stringValue = String(value);
        let cleanValue = stringValue.replace(/\D/g, '');
        if (cleanValue.length > 1) { cleanValue = cleanValue.replace(/^0+/, '') }
        return cleanValue;
    }

    // Lidando com máscaras
    const taxaTotalEntregador = watch('taxaTotalEntregador');
    const taxaTotalCobrada = watch('taxaTotalCobrada');
    const qtdPedidosMinimos = watch('qtdPedidosMinimos');

    useEffect(() => {
        setValue('taxaTotalEntregador', formatToCurrency(taxaTotalEntregador))
    }, [taxaTotalEntregador])

    useEffect(() => {
        setValue('taxaTotalCobrada', formatToCurrency(taxaTotalCobrada))
    }, [taxaTotalCobrada])

    useEffect(() => {
        setValue('qtdPedidosMinimos', formatQuantity(qtdPedidosMinimos))
    }, [qtdPedidosMinimos])

    const handleDatasReplicacao = (date: dayjs.Dayjs) => {
        setSelectedDay(date);
        const formattedDate = date.format('DD/MM/YYYY')

        if (!formattedDate) return;

        const isSelected = selectedDates.includes(formattedDate);

        if (isSelected) {
            setSelectedDates((prevDates) =>
                prevDates.filter((d) => d !== formattedDate)
            );
            setSelectedDay(null);
        } else {
            setSelectedDates((prevDates) => [...prevDates, formattedDate]);
            setSelectedDay(date);
        }
    };

    const resetarValores = () => {
        setQntVagas(1)
        setEntregadorSelecionado(null)
        reset()
        dispatch(limparCriarVaga());
        setPedidoID && setPedidoID('')
    }

    useEffect(() => {
        if (criarVagaStore?.error) {
            snackbarCriarVaga.showError(criarVagaStore?.error);
        } else if (criarVagaStore?.data) {
            snackbarCriarVaga.showSuccess(criarVagaStore?.data.mensagem);
            if (callback) callback()

            setTimeout(() => {
                resetarValores()
                setModalQntVagasOpen(false)
                onClose()
            }, 500)
        }
    }, [criarVagaStore]);

    useEffect(() => {
        return () => { dispatch(desassociarEntregadorClear()) }
    }, [])

    useEffect(() => {
        if (storeVeiculos?.error) {
            // @ts-expect-error
            snackbarVeiculos.showError(storeVeiculos?.error.message);
        }
    }, [storeVeiculos]);

    useEffect(() => {
        if (desassociarVagasStore.data) {
            snackbarVeiculos.showSuccess(desassociarVagasStore.data.mensagem);
            setEntregadorSelecionado({
                nome: '',
                usuarioID: ''
            })
            dispatch(desassociarEntregadorClear())
            callback && callback()
        } else if (desassociarVagasStore.error) {
            snackbarVeiculos.showError(desassociarVagasStore.error);
        }
    }, [desassociarVagasStore]);

    useEffect(() => {
        if (excluirVagaStore.data) {
            snackbarExcluirVaga.showSuccess(excluirVagaStore.data.mensagem);
            callback && callback()
            setTimeout(() => {
                dispatch(excluirJornadaClear())
                setPedidoID && setPedidoID('')
                onClose()
            }, 1000)
        } else if (desassociarVagasStore.error) {
            snackbarExcluirVaga.showError(desassociarVagasStore.error);
        }
    }, [excluirVagaStore]);

    const handleDesassociarEntregador = () => {
        if (!pedidoID) return;
        dispatch(desassociarEntregadorStart({ pedidoID: pedidoID }));
    }

    const handleExluirVaga = () => {
        if (!pedidoID) return;
        dispatch(excluirJornadaStart(pedidoID));
    }

    const handleCloseModal = () => {
        setPedidoID && setPedidoID('')
        onClose()
    }

    const validateDateTime = (value: string) => {
        if (!value) return 'Campo obrigatório';

        const dataAgendamento = watch('dataAgendamento');
        const dataFimDiaria = watch('dataFimDiaria');

        const startDate = dayjs(dataAgendamento, 'DD/MM/YYYY HH:mm');
        const endDate = dayjs(dataFimDiaria, 'DD/MM/YYYY HH:mm');

        if (endDate.isBefore(startDate)) {
            return 'A data fim não pode ser menor que a data de início.';
        }

        // Verifica se o intervalo entre as datas é maior que 24 horas
        const diffInMinutes = endDate.diff(startDate, 'minute');
        if (diffInMinutes > 1440) {
            return 'O intervalo entre as datas não pode ser maior que 24 horas.';
        }

        return true;
    }

    return (
        <>
            <ModalBase
                open={open}
                onClose={handleCloseModal}
                size='sm'
                headerModal={{ title: 'Criação de Diárias' }}
            >
                <ContainerBodyModal>
                    <Grid2 container height={'100%'}>
                        <BoxLoading loading={storeVeiculos?.loading}>
                            <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }} id="form">
                                <Grid2 container spacing={'1rem'} mb={2}>
                                    <Grid2 size={{ xs: 12 }}>
                                        <AutocompleteTipoVeiculo
                                            hookForm={{
                                                methods: hookForm,
                                                name: 'tipoVeiculo',
                                                rules: {
                                                    validate: {
                                                        required: (value) => (value ? true : 'Selecione o tipo de veículo'),
                                                        validType: (value) => value && watch('tipoVeiculo')?.tipoVeiculoID !== "" || "Selecione um tipo de veículo válido.",
                                                    }
                                                },
                                            }}
                                            label='Tipo Veículo'
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Controller
                                            control={control}
                                            name="entregador"
                                            render={({ field }) => (
                                                <TextFieldWithButton
                                                    label="Entregador"
                                                    size='small'
                                                    {...field}
                                                    value={field.value?.nome || ''}
                                                    slotProps={{
                                                        htmlInput: {
                                                            readOnly: true,
                                                        },
                                                    }}
                                                    buttonProps={{
                                                        variant: 'containedFilled',
                                                        icon: <SearchRoundedIcon />,
                                                        onClick: () => setModalEntregadoresOpen(true),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }} container>
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                gap: '1rem',
                                                flexDirection: isMobile ? 'column' : 'row',
                                            }}
                                            useFlexGap
                                            divider={!isMobile && <Divider orientation="vertical" variant="middle" flexItem sx={{ height: '100%', mt: 0 }} />}
                                        >
                                            <InputDateTime
                                                hookForm={{
                                                    name: 'dataAgendamento',
                                                    methods: hookForm,
                                                    rules: {
                                                        required: 'Campo obrigatório',
                                                        validate: validateDateTime,
                                                    },
                                                }}
                                                label='Data e Hora de Agendamento'
                                                sx={{ flex: '1' }}
                                            />
                                            <InputDateTime
                                                hookForm={{
                                                    name: 'dataFimDiaria',
                                                    methods: hookForm,
                                                    rules: {
                                                        required: 'Campo obrigatório',
                                                        validate: validateDateTime,
                                                    },
                                                }}
                                                label='Data e Hora de Fim da Diária'
                                                sx={{ flex: '1' }}
                                            />
                                        </Stack>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }} container>
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                gap: '1rem',
                                                flexDirection: isMobile ? 'column' : 'row',
                                            }}
                                            useFlexGap
                                            divider={!isMobile && <Divider orientation="vertical" variant="middle" flexItem sx={{ height: '100%', mt: 0 }} />}
                                        >
                                            <TextField
                                                size='small'
                                                label="Taxa Total Cobrada"
                                                fullWidth
                                                value={watch("taxaTotalCobrada")}
                                                {...register("taxaTotalCobrada", {
                                                    required: "Campo obrigatório"
                                                })}
                                                sx={{ flex: 1 }}
                                                error={!!errors.taxaTotalCobrada}
                                                helperText={errors.taxaTotalCobrada && errors.taxaTotalCobrada?.message}
                                            />
                                            <TextField
                                                size='small'
                                                label="Taxa Total Paga"
                                                fullWidth
                                                value={watch("taxaTotalEntregador")}
                                                {...register("taxaTotalEntregador", {
                                                    required: "Campo obrigatório"
                                                })}
                                                sx={{ flex: 1 }}
                                                error={!!errors.taxaTotalEntregador}
                                                helperText={errors.taxaTotalEntregador && errors.taxaTotalEntregador?.message}
                                            />
                                        </Stack>
                                    </Grid2>
                                    <Grid2 size={{ xs: 6 }}>
                                        <Controller
                                            name='minimoGarantidoCobrado'
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                value={watch("minimoGarantidoCobrado")}
                                                                onChange={field.onChange}
                                                            />
                                                        }
                                                        label="Mínimo Garantido Cobrado"
                                                    />
                                                )
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 6 }}>
                                        <Controller
                                            name='minimoGarantidoEntregador'
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                value={watch("minimoGarantidoEntregador")}
                                                                onChange={field.onChange}
                                                            />
                                                        }
                                                        label="Mínimo Garantido Pago"
                                                    />
                                                )
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 6 }}>
                                        <TextField
                                            size='small'
                                            label="Qtd. Pedidos Mínimo"
                                            fullWidth
                                            value={watch("qtdPedidosMinimos")}
                                            {...register("qtdPedidosMinimos", { required: "Campo obrigatório" })}
                                            error={!!errors.qtdPedidosMinimos}
                                            helperText={!!errors.qtdPedidosMinimos && errors.qtdPedidosMinimos?.message}
                                            slotProps={{
                                                input: {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Os clientes podem definir uma meta de pedidos para os entregadores, garantindo um valor mínimo a ser pago pelo número de pedidos. Após ultrapassar a quantidade mínima de pedidos, o pagamento será o mínimo mais taxa por pedido excedente." arrow placement="top">
                                                                <HelpOutlineRoundedIcon />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                }
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 6 }} alignContent={'center'}>
                                        <Controller
                                            name='minimoGarantidoPorPedido'
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                value={watch("minimoGarantidoPorPedido")}
                                                                onChange={field.onChange}
                                                            />
                                                        }
                                                        label="Qnt. Mínima de Pedidos"
                                                    />
                                                )
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }}>
                                        <TextField
                                            size='small'
                                            label="Detalhes da Vaga"
                                            fullWidth
                                            value={watch("detalhesVaga")}
                                            {...register("detalhesVaga")}
                                            error={!!errors.detalhesVaga}
                                            multiline
                                            placeholder='Digite aqui informações importantes da vaga...'
                                            rows={4}
                                        />
                                    </Grid2>
                                    {!editar && (
                                        <Grid2 size={{ xs: 12 }}>
                                            <Box ref={calendarContainer}>
                                                <Slide in={calendarOpen} container={calendarContainer.current} direction="up">
                                                    <div>
                                                        {calendarOpen &&
                                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                                                <DateCalendar
                                                                    showDaysOutsideCurrentMonth
                                                                    onChange={(date) => handleDatasReplicacao(dayjs(date))}
                                                                    value={selectedDay}
                                                                    slots={{
                                                                        day: (props) => {
                                                                            const highlighted = selectedDates.includes(dayjs(props.day).format('DD/MM/YYYY'))
                                                                            return (<HighlightedPickersDay highlighted={highlighted} {...props} />)
                                                                        }
                                                                    }}
                                                                />
                                                            </LocalizationProvider>}
                                                    </div>
                                                </Slide>
                                            </Box>
                                            <Controller
                                                control={control}
                                                name="datasReplicacaoAux"
                                                render={({ field }) => (
                                                    <TextFieldWithButton
                                                        label="Datas de Replicação"
                                                        size='small'
                                                        {...field}
                                                        value={selectedDates.join(', ')}
                                                        slotProps={{
                                                            htmlInput: {
                                                                readOnly: true,
                                                            }
                                                        }}
                                                        buttonProps={{
                                                            variant: 'containedFilled',
                                                            icon: <CalendarMonthRoundedIcon />,
                                                            onClick: () => setCalendarOpen((prev) => !prev),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid2>
                                    )}
                                </Grid2>
                                <Stack
                                    useFlexGap
                                    sx={{
                                        width: '100%',
                                        gap: 1,
                                        flexWrap: 'wrap',
                                        flexDirection: isMobile ? 'column-reverse' : 'row',
                                        mt: 'auto',
                                        pb: 2,
                                        justifyContent: isMobile ? 'center' : 'flex-end',
                                        alignItems: isMobile ? 'stretch' : 'center',
                                    }}
                                >
                                    <Button
                                        variant='containedFilled'
                                        color='grey'
                                        onClick={handleCloseModal}
                                        sx={{ width: isMobile ? '100%' : 'min-content' }}
                                    >
                                        Cancelar
                                    </Button>
                                    {(pedidoID) &&
                                        <ButtonLoading
                                            loading={excluirVagaStore.loading}
                                            variant='containedFilled'
                                            color='red'
                                            onClick={handleExluirVaga}
                                            sx={{ width: isMobile ? '100%' : 'min-content' }}
                                        >
                                            Excluir
                                        </ButtonLoading>}
                                    {(editar && watch('entregador')?.usuarioID !== '' && pedidoID && initialData?.entregadorUsuarioID) &&
                                        <ButtonLoading
                                            loading={desassociarVagasStore.loading}
                                            variant='containedFilled'
                                            color='secondary'
                                            onClick={handleDesassociarEntregador}
                                            sx={{ width: isMobile ? '100%' : 'min-content' }}
                                        >
                                            Desassociar entregador
                                        </ButtonLoading>}
                                    <ButtonLoading
                                        loading={criarVagaStore.loading}
                                        type='submit'
                                        variant='containedFilled'
                                        sx={{ width: isMobile ? '100%' : 'min-content' }}
                                    >
                                        {editar ? 'Salvar' : 'Abrir'} vaga
                                    </ButtonLoading>
                                </Stack>
                            </form>
                        </BoxLoading>
                    </Grid2>
                </ContainerBodyModal>
            </ModalBase>

            {/* MODAL QNT VAGAS */}
            <ModalOpcoes
                open={modalQntVagasOpen}
                onClose={() => setModalQntVagasOpen(false)}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action() { setModalQntVagasOpen(false) },
                    },
                    {
                        label: 'Criar Vaga',
                        action() { criarVagaReq(getValues()) },
                        disabled: qntVagas < 1,
                    },
                ]}
                acao='Criar vaga'
                descricao={
                    <Grid2 container spacing={2} sx={{ width: '100%', mt: 2 }} >
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant='body1' sx={{ width: '100%' }}>Quantas vagas gostaria de abrir com esses dados?</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <TextField
                                label="Qnt. de Vagas"
                                fullWidth
                                size='small'
                                value={qntVagas}
                                // @ts-expect-error
                                onChange={(e) => setQntVagas(e.target.value)}
                                type={'number'}
                                slotProps={{
                                    htmlInput: {
                                        min: 1
                                    }
                                }}
                            />
                        </Grid2>
                    </Grid2>
                }
            />

            {/* MODAL ENTREGADORES */}
            <ModalPesquisarEntregador
                modalProps={{
                    open: modalEntregadoresOpen,
                    onClose: () => setModalEntregadoresOpen(false),
                }}
                callback={selecionarEntregador}
            />

            <SnackbarAlert {...snackbarVeiculos} />
            <SnackbarAlert {...snackbarCriarVaga} />
            <SnackbarAlert {...snackbarDesassociarVagas} />
            <SnackbarAlert {...snackbarExcluirVaga} />
        </>
    )
}

export default AbrirVagas

type HighlightedPickersDayProps = PickersDayProps<any> & {
    highlighted: boolean;
    theme?: Theme;
}

const HighlightedPickersDay = styled(PickersDay)(({ theme, highlighted }: HighlightedPickersDayProps) => ({
    ...(highlighted && {
        backgroundColor: theme?.palette.primary.main,
        color: theme?.palette.common.white,
        '&:hover': {
            backgroundColor: theme?.palette.primary.dark,
        },
    }),
}));
