import { InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { salvarTabelaStart } from "src/store/reducers/tabelaCobranca";
import { liveMaskMoney } from "src/ds/Utils";
import { cleanInput } from "src/ds/common";
import { SelectToggleAtivo, TextFieldMoney } from "src/ds/DesignSystem";
// import { maskMoney } from "src/ds/Utils";

interface FormValues {
    tabelaCobrancaID: string;
    nome: string;
    ativo: string;
    valorPontoAdicional: string;
}

const DadosBasicos = ({ dados }: any) => {
    const dispatch = useDispatch();
    const hookForms = useForm<FormValues>({
        defaultValues: {
            nome: "",
            ativo: "",
            tabelaCobrancaID: "",
            valorPontoAdicional: "",
        },
    });
    const { register, control, setValue, handleSubmit, watch } = hookForms;

    useEffect(() => {
        if (dados) {
            setValue("tabelaCobrancaID", dados.tabelaCobrancaID || "");
            setValue("nome", dados.nome || "");
            setValue("ativo", dados.ativo ? "true" : "false");
            setValue("valorPontoAdicional", dados.valorPontoAdicional || "");
        }
    }, [dados, setValue]);

    const onSubmit = (data: FormValues) => {
        dispatch(salvarTabelaStart({
            ...data,
            valorPontoAdicional: cleanInput(data.valorPontoAdicional),
        }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
                size="small"
                fullWidth
                label="Nome"
                {...register("nome", { required: "Nome é obrigatório" })}
            />

            <SelectToggleAtivo
                hookForm={{ methods: hookForms, name: "ativo" }}
                label="Situação"
            />
            <TextFieldMoney
                hookForm={{
                    methods: hookForms,
                    name: 'valorPontoAdicional',
                    defaultValue: hookForms.watch('valorPontoAdicional'),
                    rules: {
                        required: 'Valor por ponto adicional é obrigatório',
                    }
                }}
                label='Valor por ponto adicional'
            />
            <Button variant="containedFilled" color="primary" sx={{ alignSelf: 'flex-end', width: 'fit-content' }} type="submit">
                Salvar
            </Button>
        </Box>
    );
};

export default DadosBasicos;
