import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { ApiResponse, DefaultDTO } from "src/dtos/DefaultDTO";
import { api } from "src/services/api";
import { listarMinhasFailure, listarMinhasFaturasFailure, listarMinhasFaturasStart, listarMinhasFaturasSuccess, listarMinhasStart, listarMinhasSuccess, listarMinhasTransacoesFailure, listarMinhasTransacoesStart, listarMinhasTransacoesSuccess, listarTransacoesFailure, listarTransacoesStart, listarTransacoesSuccess, pagarTransacaoCartaoFailure, pagarTransacaoCartaoStart, pagarTransacaoCartaoSuccess, salvarTransacaoFailure, salvarTransacaoStart, salvarTransacaoSuccess } from "../reducers/faturas";

export function* listarMinhasFaturas(action: PayloadAction<any>) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })

    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            '/AssinaturaFatura/ListarMinhasFaturas',
            form
        );
        if (response.data.sucesso === true) {
            yield put(listarMinhasFaturasSuccess(response.data));
        } else {
            yield put(listarMinhasFaturasFailure(response.data.mensagem));
        }
    } catch (error) {
        console.error(error);
    }
}

export function* listarTransacoes(action: PayloadAction<any>) {
    const form = new FormData();
    console.log(action.payload)

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })

    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            '/AssinaturaTransacao/Listar',
            form
        );
        if (response.data.sucesso === true) {
            yield put(listarTransacoesSuccess(response.data));
        } else {
            yield put(listarTransacoesFailure(response.data.mensagem));
        }
    } catch (error) {
        console.error(error);
    }
}

export function* salvarTransacao(action: PayloadAction<any>) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            '/AssinaturaTransacao/Salvar',
            form
        );
        if (response.data.sucesso === true) {
            yield put(salvarTransacaoSuccess(response.data));
        } else {
            yield put(salvarTransacaoFailure(response.data.mensagem));
        }
    }
    catch (error) {
        console.error(error);
    }
}

export function* pagarTransacaoCartao(action: PayloadAction<any>) {
    const form = new FormData();

    if (action.payload && action.payload.assinatura) {
        const assinatura = action.payload.assinatura;
        form.append("assinatura.cartaoNome", assinatura.cartaoNome || '');
        form.append("assinatura.cartaoNumero", assinatura.cartaoNumero || '');
        form.append("assinatura.cartaoMesValidade", assinatura.cartaoMesValidade || '');
        form.append("assinatura.cartaoAnoValidade", assinatura.cartaoAnoValidade || '');
        form.append("assinatura.cartaoCodigo", assinatura.cartaoCodigo || '');
        form.append("assinatura.cartaoDataNascimento", assinatura.cartaoDataNascimento || '');
    } else {
        console.error("Invalid payload");
        return;
    }


    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            '/AssinaturaTransacao/PagarCartao',
            form
        );
        if (response.data.sucesso === true) {
            yield put(pagarTransacaoCartaoSuccess(response.data));
        } else {
            yield put(pagarTransacaoCartaoFailure(response.data.mensagem));
        }
    }
    catch (error) {
        console.error(error);
    }
}

export function* listarMinhas(action: PayloadAction<any>) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);

    });

    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            `/Fatura/ListarMinhas`,
            form
        );
        if (response.data.sucesso === true) {
            yield put(listarMinhasSuccess(response.data));
        } else {
            yield put(listarMinhasFailure(response.data.mensagem));
        }
    } catch (error) {
        console.error(error);
    }
}

export function* listarMinhasTransacoes(action: PayloadAction<any>) {
    const empresaID = action.payload.empresaID;
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        if (key !== 'empresaID') {
            form.append(key, action.payload[key]);
        }
    });

    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            `/FaturaTransacao/ListarMinhas${empresaID ? `?empresaID=${empresaID}` : ''}`,
            form
        );
        if (response.data.sucesso === true) {
            yield put(listarMinhasTransacoesSuccess(response.data));
        } else {
            yield put(listarMinhasTransacoesFailure(response.data.mensagem));
        }
    } catch (error) {
        console.error(error);
    }
}

function* faturasSaga() {
    yield takeLatest(listarMinhasFaturasStart.type, listarMinhasFaturas);
    yield takeLatest(listarTransacoesStart.type, listarTransacoes);
    yield takeLatest(salvarTransacaoStart.type, salvarTransacao);
    yield takeLatest(pagarTransacaoCartaoStart.type, pagarTransacaoCartao);
    yield takeLatest(listarMinhasStart.type, listarMinhas);
    yield takeLatest(listarMinhasTransacoesStart.type, listarMinhasTransacoes);
}

export default faturasSaga;
