// @ts-nocheck
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/Forms";
import { useForm } from "react-hook-form";
import TabelaDespachoAutomatico from "../../components/DespachoAutomatico/TabelaDespachoAutomatico";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { RootState } from "src/store/reducers";
import { TableNavigation } from "src/ds/DesignSystem";
import TabelaDA from "src/components/DespachoAutomatico/TabelaDespachoAutomatico";
import { listarDaStart } from "src/store/reducers/despachoAutomatico";
import { useNavigate } from "react-router-dom";

const DespachoAutomatico = () => {
    const daDados = useSelector((state: RootState) => state.despachoAutomatico.listaDa.data?.dados);
    const daLista = daDados?.lista;
    const loading = useSelector((state: RootState) => state.despachoAutomatico.listaDa.loading);

    const hookForm = useForm({
        defaultValues: {
            filtros: '',
            empresaID: '',
            dtInicial: '',
            dtFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });
    const dispatch = useDispatch();
    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true)
    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao) {
            cardRef.current?.onSubmit();
            setPrimeiraRenderizacao(false);
            return
        }

        const subscription = watch((_value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, primeiraRenderizacao]);

    const navigate = useNavigate();

    return (
        <Box >
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'listarDaStart'
                    }}
                    fields={[
                        { name: "Buscar", value: "nome", isFiltro: true },
                        { name: 'Empresas', value: 'empresaID', type: 'autocompleteEmpresa' },
                    ]}
                    loading={loading}
                />
            </Box>

            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">DESPACHO AUTOMÁTICO</Typography>
                    <Stack gap={2} alignContent={"flex-end"} direction={"row"}>
                        <Button variant="containedFilled" onClick={() => navigate('/TabelaDespachoAutomatico/Inserir')} sx={{ width: 'min-content', height: 'min-content' }}>INSERIR</Button>
                    </Stack>
                </Box>
                {daDados?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={daDados.indiceInicial}
                        indiceFinal={daDados.indiceFinal}
                        indiceTotal={daDados.total}
                        ultimaPagina={daDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <TabelaDA
                    dados={daLista}
                    loading={loading}
                    fezRequisicao={cardRef.current?.fezRequisicao}
                />
            </Card>
        </Box>
    );
};

export default DespachoAutomatico;
