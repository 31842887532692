import { Box, Typography, Tabs, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseModal } from 'src/ds';
import { ContainerBodyModal, HeaderModal, ModalBase, SnackbarAlert } from 'src/ds/DesignSystem';
import { RootState } from 'src/store/reducers';
import DadosBasicos from './EditarDadosBasicos';
import EditarEmpresa from './EditarEmpresa';

import EditarKmCalculado from './EditarKmCalculado';
import EditarValoresPorRaio from './EditarValoresPorRaio';
import EditarValoresPorBairro from './EditarValoresPorBairro';
import { useMultiResponseSnackbar, useResponsive, useSnackbar } from 'src/ds/CustomHooksDs';
import { useNavigate, useParams } from 'react-router-dom';
import { listaTabelaCobrancaStart } from 'src/store/reducers/tabelaCobranca';
import { ArrowBackIos } from '@mui/icons-material';
import { limparTabelaPorBairro, limparTabelaPorKm, limparTabelaPorRaio } from 'src/store/reducers/tabelaPagamento';

interface ListarMensagemProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    dados: any;
    atualizar: () => void;
}

interface TabConfig {
    label: string;
    content: React.ReactNode;
}


const TabsTabelaPagamento: React.FC<ListarMensagemProps> = () => {
    const { id } = useParams();
    const listaPagamento = useSelector((state: RootState) => state.tabelaPagamento?.listaTabelasPagamento?.data);
    const dados = listaPagamento?.dados?.lista.find((item: any) => item.tabelaValorID === id);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const response = useSelector((state: RootState) => state.tabelaPagamento?.salvarTabelaPorRaio);
    const response2 = useSelector((state: RootState) => state.tabelaPagamento?.salvarTabelaPorBairro);
    const response3 = useSelector((state: RootState) => state.tabelaPagamento?.salvarTabelaPorKm);
    const snack = useSnackbar({ duration: 2000 });
    const metodoPagamento = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoPagamento);

    useMultiResponseSnackbar([
        { response, clearAction: () => { dispatch(limparTabelaPorRaio()); } },
        { response: response2, clearAction: () => { dispatch(limparTabelaPorBairro()); } },
        { response: response3, clearAction: () => { dispatch(limparTabelaPorKm()); } }
    ], snack)

    useEffect(() => {
        if (!dados) {
            dispatch(listaTabelaCobrancaStart({ pagina: 1, paginacao: -1, filtros: `` }));
        }
    }, [dados]);

    const tabsConfig: TabConfig[] = [
        { label: 'Dados básicos', content: <DadosBasicos dados={dados} /> },
        { label: 'Empresas', content: <EditarEmpresa dados={dados} /> },
        { label: 'Valores por raio', content: <EditarValoresPorRaio dados={dados} /> },
        { label: 'Valores por bairro', content: <EditarValoresPorBairro dados={dados} /> },
        { label: 'Valores por km calculado', content: <EditarKmCalculado dados={dados} /> }
    ];

    const filteredTabsConfig = tabsConfig.filter(tab => {
        if (tab.label === 'Dados básicos' || tab.label === 'Empresas') {
            return true;
        }

        if (metodoPagamento === 1) {
            return tab.label === 'Valores por km calculado';
        } else if (metodoPagamento === 0) {
            return tab.label === 'Valores por raio';
        } else if (metodoPagamento === 2) {
            return tab.label === 'Valores por bairro';
        }

        return false;
    });


    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };


    const { isMobile } = useResponsive();
    const navigate = useNavigate();

    return (
        <Box p={0} sx={{ backgroundColor: 'background.paper' }}>
            <Box p={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <ArrowBackIos
                        sx={{ cursor: 'pointer', mr: 1 }}
                        onClick={() => navigate("/TabelaValor/Index")}
                    />
                    <Typography variant="h6">
                        <>
                            Editar {dados?.nome} <span style={{ fontSize: '0.6em', color: 'gray' }}>({id})</span>
                        </>
                    </Typography>
                </Box>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Tabs de Edição"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{minHeight: '48px'}}
                >
                    {filteredTabsConfig.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            sx={{
                                minWidth: '100px',
                                maxWidth: '200px',
                                color: 'text.primary',
                                fontSize: '12px',
                                textTransform: 'none'
                            }}
                        />
                    ))}
                </Tabs>

                <Box sx={{ mt: 3 }}>
                    {filteredTabsConfig[activeTab].content}
                </Box>
                <SnackbarAlert {...snack} />
            </Box>
        </Box>
    );
};

export default TabsTabelaPagamento;
