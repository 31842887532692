import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import {
    listaPedidosStart,
    listaPedidosFailure,
    listaPedidosSuccess,
    listarPedidosGeral,
    listarPedidosGeralSuccess,
    listarPedidosGeralFailure,
    listarPedidosPorTipoPorDia,
    listarPedidosPorTipoPorDiaSuccess,
    listarPedidosPorTipoPorDiaFailure,
    listarPedidosPorTipoEmpresaSuccess,
    listarPedidosPorTipoEmpresa,
    listarPedidosPorTipoSuccess,
    listarPedidosPorTipoFailure,
    listarPedidosPorTipo,
    listarPedidosPorTipoEmpresaFailure,
    listarPedidosPorFaixaKmFailure,
    listarPedidosPorFaixaKmSuccess,
    listarPedidosPorFaixaKm,
    listarPedidosPorFaixaKmPorDia,
    listarRankingEmpresasSuccess,
    listarRankingEmpresasFailure,
    listarRankingEmpresas,
    listarRankingEntregadoresFailure,
    listarRankingEntregadoresSuccess,
    listarRankingEntregadores,
    listarRankingBairros,
    listarRankingBairrosFailure,
    listarRankingBairrosSuccess,
    listarPedidosPorFaixaKmPorDiaSuccess,
    listarPedidosPorFaixaKmPorDiaFailure,
    exportarRelatorioGeralStart,
    exportarRelatorioGeralSuccess,
    exportarRelatorioGeralFailure,
    listarPerformancePedidosStart,
    listarPerformancePedidosSuccess,
    listarPerformancePedidosFailure,
    exportarPerformancePedidosStart,
    exportarPerformancePedidosFailure,
    exportarPerformancePedidosSuccess,
    pedidoModeloStart,
    pedidoModeloSuccess,
    pedidoModeloFailure,
    salvarPedidoStart,
    salvarPedidoSuccess,
    salvarPedidoFailure,
    recalcularTabelasPedidoStart,
    recalcularTabelasPedidoSuccess,
    recalcularTabelasPedidoFailure,
    recalcularQuilometragemPedidoStart,
    recalcularQuilometragemPedidoSuccess,
    recalcularQuilometragemPedidoFailure,
    pedidoTaxaExtraStart,
    pedidoTaxaExtraSuccess,
    pedidoTaxaExtraFailure,
    listarLogStart,
    listarLogSuccess,
    listarLogFailure,
    pedidoDespachosStart,
    pedidoDespachosSuccess,
    pedidoDespachosFailure,
    pedidoMapaRastreioStart,
    pedidoMapaRastreioSuccess,
    pedidoMapaRastreioFailure,
} from '../reducers/pedidos';

function* listaPedidos(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, `Pedido/ListarMeusPedidos?empresaID=${action.payload.empresaID}`, form);
        yield put(listaPedidosSuccess(response.data));
    } catch (error) {
        yield put(listaPedidosFailure(error));
    }

}

function* listarGeral(action) {
    const formData = new FormData();

    Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key];
        if (Array.isArray(value)) {
            value.forEach((arrayValue) => {
                formData.append(key, arrayValue);
            });
        } else {
            formData.append(key, value);
        }
    })

    try {
        const response = yield call(api.post, '/Pedido/ListarSql', formData);
        yield put(listarPedidosGeralSuccess(response.data));
    } catch (error) {
        yield put(listarPedidosGeralFailure(error));
    }
}

function* pedidosPorTipoPorDia(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Relatorio/PedidosPorTipoPorDia', form);
        if (response.data.sucesso !== true) {
            yield put(listarPedidosPorTipoPorDiaFailure(response.data));
        }
        yield put(listarPedidosPorTipoPorDiaSuccess(response.data));
    } catch (error) {
        yield put(listarPedidosPorTipoPorDiaFailure(error));
    }
}

function* pedidosPorTipoEmpresa(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Relatorio/PedidosPorTipoEmpresa', form);
        if (response.data.sucesso !== true) {
            yield put(listarPedidosPorTipoEmpresaFailure(response.data));
        }
        yield put(listarPedidosPorTipoEmpresaSuccess(response.data));
    } catch (error) {
        yield put(listarPedidosPorTipoEmpresaFailure(error));
    }
}

function* pedidosPorTipo(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Relatorio/PedidosPorTipo', form);
        if (response.data.sucesso !== true) {
            yield put(listarPedidosPorTipoFailure(response.data));
        }
        yield put(listarPedidosPorTipoSuccess(response.data));
    } catch (error) {
        yield put(listarPedidosPorTipoFailure(error));
    }
}

function* pedidosPorFaixaKm(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Relatorio/PedidosPorFaixaKm', form);
        if (response.data.sucesso !== true) {
            yield put(listarPedidosPorFaixaKmFailure(response.data));
        }
        yield put(listarPedidosPorFaixaKmSuccess(response.data));
    } catch (error) {
        yield put(listarPedidosPorFaixaKmFailure(error));
    }
}

function* pedidosPorFaixaKmPorDia(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Relatorio/PedidosPorFaixaKmPorDia', form);
        if (response.data.sucesso !== true) {
            yield put(listarPedidosPorFaixaKmPorDiaFailure(response.data));
        }
        yield put(listarPedidosPorFaixaKmPorDiaSuccess(response.data));
    } catch (error) {
        yield put(listarPedidosPorFaixaKmPorDiaFailure(error));
    }
}

function* rankingEmpresas(action) {

    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Relatorio/RankingEmpresas', form);
        if (response.data.sucesso !== true) {
            yield put(listarRankingEmpresasFailure(response.data));
        }
        yield put(listarRankingEmpresasSuccess(response.data));
    } catch (error) {
        yield put(listarRankingEmpresasFailure(error));
    }
}

function* rankingEntregadores(action) {

    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Relatorio/RankingEntregadores', form);
        if (response.data.sucesso !== true) {
            yield put(listarRankingEntregadoresFailure(response.data));
        }
        yield put(listarRankingEntregadoresSuccess(response.data));
    } catch (error) {
        yield put(listarRankingEntregadoresFailure(error));
    }
}

function* rankingBairros(action) {

    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });
    try {
        const response = yield call(api.post, '/Relatorio/RankingBairros', form);
        if (response.data.sucesso !== true) {
            yield put(listarRankingBairrosFailure(response.data));
        }
        yield put(listarRankingBairrosSuccess(response.data));
    } catch (error) {
        yield put(listarRankingBairrosFailure(error));
    }
}

function* exportarRelatorio(action) {
    try {
        const queryParams = [
            `paginacao=${-1}`,
            `pagina=${1}`,
            `ordenacaoCampo=${action.payload.ordenacaoCampo || ''}`,
            `ordenacaoOrdem=${action.payload.ordenacaoOrdem || ''}`,
            `filtros=${encodeURIComponent(action.payload.dtInicial ? 'dtInicial=' + action.payload.dtInicial : '')}`,
            `titulos=${encodeURIComponent(action.payload.titulos || 'Origem,Código,Situação,Empresa,CNPJ,ID,Detalhes,Valor do pedido,Forma de pagamento,Nome do cliente,Tem retorno,Distancia por raio (Km),Distancia por rota (Km),Entregador,CPF,Código entregador,Taxa total cobrada,Taxa extra cobrada,Taxa total entregador,Taxa extra entregador,Tipo veiculo,Data de cadastro,Data de agendamento,Data despachado,Data de aceite,Data pronto,Data chegou no estabelecimento,Data em rota,Data retornando,Data finalização,Data de conclusão,Data ETA Entrega,Tipo de fatura,Tipo de despacho,Nome do operador,Endereço origem,Endereço entrega')}`,
            `variaveis=${encodeURIComponent(action.payload.variaveis || 'aplicacaoOrigemNome,codigoExterno,situacao,empresa.nome,empresa.cnpj,pedidoID,detalhes,valor,formaPagamento.nome,clienteNome,temRetorno,distanciaCalculadaKm,distanciaCalculadaKmPorRota,entregadorUsuario.nome,entregadorUsuario.cpf,entregadorUsuario.codigoIdentificador,taxaTotalCobrada,taxaExtraCobrada,taxaTotalEntregador,taxaExtraEntregador,tipoVeiculo,dataCadastro,dataAgendamento,dataDespachado,dataAceito,dataPronto,dataChegouEstabelecimento,dataEmRota,dataRetorno,dataFinalizado,dataConcluido,dataEtaEntrega,tipoFaturaCodigo,tipoDespachoNome,operadorUsuario.nome,origemEndereco.enderecoStr,entregaEndereco.enderecoStr')}`,
            `formato=${action.payload.formato || 'csv'}`,
            `dtInicial=${action.payload.dtInicial || ''}`,
        ].join('&');
        const response = yield call(api.get, `/Pedido/ListarSql?${queryParams}`, {
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorioGeral.csv');
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);

        if (response.data.sucesso !== true) {
            yield put(exportarRelatorioGeralFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(exportarRelatorioGeralSuccess(response.data.dados));
    } catch (error) {
        yield put(exportarRelatorioGeralFailure(error.toString()));
    }
}

function* listarPerformancePedidos(action) {
    const formData = new FormData();

    Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key];
        if (Array.isArray(value)) {
            value.forEach((arrayValue) => {
                formData.append(key, arrayValue);
            });
        } else {
            formData.append(key, value);
        }
    })

    try {
        const response = yield call(api.post, '/Pedido/ListarPerformancePedido', formData);
        yield put(listarPerformancePedidosSuccess(response.data));
    } catch (error) {
        yield put(listarPerformancePedidosFailure(error));
    }
}

export function* exportarPerformancePedidos(action) {
    try {
        const queryParams = [
            `paginacao=${action.payload.paginacao || -1}`,
            `pagina=${action.payload.pagina || 1}`,
            `ordenacaoCampo=${action.payload.ordenacaoCampo || 'tempoProducao'}`,
            `ordenacaoOrdem=${action.payload.ordenacaoOrdem || 'desc'}`,
            `filtros=${encodeURIComponent(action.payload.filtros || '')}`,
            `titulos=${encodeURIComponent(action.payload.titulos || 'Empresa(sec),Entregador(sec),Código do pedido(sec),Tempo de produção(sec),Tempo de despacho(sec),Tempo aceite motoboy(sec),Tempo chegada motoboy(sec),Tempo rota entregador(sec),Distância(sec),Tempo total pedido(sec),Tempo ETA pedido(sec)')}`,
            `variaveis=${encodeURIComponent(action.payload.variaveis || 'nomeEmpresa,nomeUsuario,codigoExterno,tempoProducao,tempoDespacho,tempoAceiteMotoboy,tempoChegadaMotoboy,tempoRotaEntregador,distancia,tempoTotalPedido,tempoETA')}`,
            `formato=${action.payload.formato || 'xls'}`,
            `dtInicial=${encodeURIComponent(action.payload.dtInicial || '')}`,
            `dtFinal=${encodeURIComponent(action.payload.dtFinal || '')}`
        ].join('&');

        const response = yield call(api.get, `/Pedido/ListarPerformancePedido?${queryParams}`, {
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'performance_pedidos.xls');
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);

        if (response.data.sucesso !== true) {
            yield put(exportarPerformancePedidosFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(exportarPerformancePedidosSuccess(response.data));
    } catch (error) {
        yield put(console.error(error.toString()));
    }
}

function* pedidoModelo({ payload }) {
    try {
        const formData = new FormData();
        formData.append('id', payload.id);

        const response = yield call(api.post, `/Pedido/Pagina`, formData);

        if (response.data.sucesso !== true) {
            yield put(pedidoModeloFailure(response.data.erro));
        } else {
            yield put(pedidoModeloSuccess(response.data));
        }
    } catch (error) {
        yield put(pedidoModeloFailure(error));
    }
}

function* salvarPedido({ payload }) {
    try {
        const formData = new FormData();

        Object.keys(payload).forEach(key => {
            const value = payload[key];
            if (Array.isArray(value)) {
                value.forEach(arrayValue => {
                    formData.append(key, arrayValue);
                });
            } else if (value && typeof value === 'object') {
                Object.keys(value).forEach(subKey => {
                    formData.append(`${key}.${subKey}`, value[subKey]);
                });
            } else {
                formData.append(key, value);
            }
        })
        const response = yield call(api.post, `/Pedido/Salvar`, formData);
        if (response.data.sucesso !== true) {
            yield put(salvarPedidoFailure(response.data.mensagem));
        } else {
            yield put(salvarPedidoSuccess(response.data));
        }
    } catch (error) {
        yield put(salvarPedidoFailure(error));
    }
}

function* recalcularTabelasPedido({ payload }) {
    try {
        const formData = new FormData();
        Object.keys(payload).forEach(key => {
            const value = payload[key];
            formData.append(key, value);
        })
        const response = yield call(api.post, '/Home/RecalcularPedidos', formData);
        if (response.data.sucesso !== true) {
            yield put(recalcularTabelasPedidoFailure(response.data.mensagem));
        } else {
            yield put(recalcularTabelasPedidoSuccess(response.data));
        }
    } catch (error) {
        yield put(recalcularTabelasPedidoFailure(error));
    }
}

function* recalcularQuilometragemPedido({ payload }) {
    try {
        const formData = new FormData();
        Object.keys(payload).forEach(key => {
            const value = payload[key];
            formData.append(key, value);
        })
        const response = yield call(api.post, '/Pedido/RecalcularKilometragem', formData);
        if (response.data.sucesso !== true) {
            yield put(recalcularQuilometragemPedidoFailure(response.data.mensagem));
        } else {
            yield put(recalcularQuilometragemPedidoSuccess(response.data));
        }
    } catch (error) {
        yield put(recalcularQuilometragemPedidoFailure(error));
    }
}

function* pedidoTaxaExtra({ payload }) {
    try {
        const formData = new FormData();
        Object.keys(payload).forEach(key => {
            const value = payload[key];
            formData.append(key, value);
        })
        const response = yield call(api.post, '/PedidoTaxaExtra/Listar', formData);
        if (response.data.sucesso !== true) {
            yield put(pedidoTaxaExtraFailure(response.data.mensagem));
        } else {
            yield put(pedidoTaxaExtraSuccess(response.data));
        }
    } catch (error) {
        yield put(pedidoTaxaExtraFailure(error));
    }
}

function* listarLog({ payload }) {
    try {
        const formData = new FormData();
        formData.append('pedidoID', payload.pedidoID);
        const response = yield call(api.post, `/Pedido/VerLog?verbose=${payload.verbose}&storage=${payload.storage}`, formData);
        if (response.data.sucesso !== true) {
            yield put(listarLogFailure(response.data.mensagem));
        } else {
            yield put(listarLogSuccess(response.data));
        }
    } catch (error) {
        yield put(listarLogFailure(error));
    }
}

function* pedidoDespachos({ payload }) {
    try {
        const formData = new FormData();
        Object.keys(payload).forEach((key) => {
            if (key === 'pedidoID') return;
            const value = payload[key];
            formData.append(key, value);
        })

        const response = yield call(api.post, `/PedidoDespacho/ListarSql?pedidoID=${payload.pedidoID}`, formData);
        if (response.data.sucesso !== true) {
            yield put(pedidoDespachosFailure(response.data.mensagem));
        } else {
            yield put(pedidoDespachosSuccess(response.data));
        }
    } catch (error) {
        yield put(pedidoDespachosFailure(error));
    }
}

function* pedidoMapaRastreio({ payload }) {
    try {
        const formData = new FormData();
        Object.keys(payload).forEach((key) => {
            const value = payload[key];
            formData.append(key, value);
        })

        const response = yield call(api.post, '/Rastreio/GetFilteredPointsPedido', formData);
        if (!response.data) {
            yield put(pedidoMapaRastreioFailure(response.data));
        } else {
            yield put(pedidoMapaRastreioSuccess(response.data));
        }
    } catch (error) {
        yield put(pedidoMapaRastreioFailure(error));
    }
}

function* ListarPedidos() {
    yield takeLatest(listaPedidosStart.type, listaPedidos);
    yield takeLatest(listarPedidosGeral.type, listarGeral);
    yield takeLatest(listarPedidosPorTipoPorDia.type, pedidosPorTipoPorDia);
    yield takeLatest(listarPedidosPorTipoEmpresa.type, pedidosPorTipoEmpresa);
    yield takeLatest(listarPedidosPorTipo.type, pedidosPorTipo);
    yield takeLatest(listarPedidosPorFaixaKm.type, pedidosPorFaixaKm);
    yield takeLatest(listarPedidosPorFaixaKmPorDia.type, pedidosPorFaixaKmPorDia);
    yield takeLatest(listarRankingEmpresas.type, rankingEmpresas);
    yield takeLatest(listarRankingEntregadores.type, rankingEntregadores);
    yield takeLatest(listarRankingBairros.type, rankingBairros);
    yield takeLatest(exportarRelatorioGeralStart.type, exportarRelatorio);
    yield takeLatest(listarPerformancePedidosStart.type, listarPerformancePedidos);
    yield takeLatest(exportarPerformancePedidosStart.type, exportarPerformancePedidos);
    yield takeLatest(pedidoModeloStart.type, pedidoModelo);
    yield takeLatest(salvarPedidoStart.type, salvarPedido);
    yield takeLatest(recalcularTabelasPedidoStart.type, recalcularTabelasPedido);
    yield takeLatest(recalcularQuilometragemPedidoStart.type, recalcularQuilometragemPedido);
    yield takeLatest(pedidoTaxaExtraStart.type, pedidoTaxaExtra);
    yield takeLatest(listarLogStart.type, listarLog);
    yield takeLatest(pedidoDespachosStart.type, pedidoDespachos);
    yield takeLatest(pedidoMapaRastreioStart.type, pedidoMapaRastreio);
}

export default ListarPedidos;
