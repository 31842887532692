import { Box, Card, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CardTabelaTotal, ExportarTabela, TableNavigation } from "src/ds/DesignSystem";
import { CardFormFiltros, CardFormFiltrosRef, Field } from "src/ds/Forms";
import { RootState } from "src/store/reducers";
import { formatTime } from "src/utils";

import timer from "../../assets/icons/time.svg";
import motorcycle from "../../assets/icons/motorcycle.svg";
import bag from "../../assets/icons/bag.svg";
import TabelaPerformance from "src/components/PerformanceEntregador/TabelaPerformance";
import { Entregador } from "src/dtos/EntregadoresDTO";
import { listaEntregadoresStart } from "src/store/reducers/entregador";

const PerformanceEntregador = () => {
    const cardRef = useRef<CardFormFiltrosRef>(null);
    const theme = useTheme();
    const data = useSelector((state: RootState) => state.entregadores.listarPerformanceEntregador.data?.dados);
    const exportarPerformanceEntregadores = useSelector((state: RootState) => state.entregadores.exportarPerformanceEntregador.data);
    const entregadores: Entregador[] = useSelector(
        (state: RootState) => state.entregadores.listaEntregadores.data?.dados?.lista || []
    );
    const loading = false
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listaEntregadoresStart({
            pagina: 1,
            paginacao: -1,
            filtros: ``,
        }))
    }, [])




    const options = entregadores?.map((entregador: Entregador) => ({
        nome: entregador?.nome,
        usuarioID: entregador?.usuarioID,
    }));

    const formFields: Field[] = [
        {
            //@ts-ignore
            name: 'Entregador', value: 'entregadorID', type: 'customSelect', options: options, labelKey: 'nome', valueKey: 'usuarioID'
        },
        { name: 'Data inicial', value: 'dtInicial', type: 'dateTime', rules: { required: 'Data inicial é obrigatória' } },
        { name: 'Data final', value: 'dtFinal', type: 'dateTime', rules: { required: 'Data final é obrigatória' } },
    ]

    const hookForm = useForm({
        defaultValues: {
            dtInicial: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
            dtFinal: dayjs().format('DD/MM/YYYY 00:00'),
            entregadorID: ''
        },
    })


    return (
        <Box sx={{ p: 2, backgroundColor: theme.palette.background.paperDark }}>
            <CardFormFiltros
                ref={cardRef}
                fields={formFields}
                formMethods={hookForm}
                dispatchMethods={{ actionName: 'listarPerformanceEntregadorStart' }}
                loading={loading}
            />
            <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap' }} useFlexGap direction="row">
                <CardTabelaTotal
                    srcIcon={bag}
                    altIcon="Ícone quantidade total pedidos"
                    bgcolor={theme.palette.blue[600]}
                    texts={{
                        title1: 'Quantidade total pedidos',
                        subtitle1: data?.totalPedidos ? data?.totalPedidos : '0'
                    }}
                />
                <CardTabelaTotal
                    srcIcon={timer}
                    altIcon="Ícone quantidade total tempo médio"
                    bgcolor={theme.palette.grey[600]}
                    texts={{
                        title1: 'Quantidade total tempo médio',
                        subtitle1: data?.mediaTempoOnline ? formatTime(data?.mediaTempoOnline) : '0',
                    }}
                />
                <CardTabelaTotal
                    srcIcon={motorcycle}
                    altIcon="Quantidade total entregadores"
                    bgcolor={theme.palette.red[600]}
                    texts={{
                        title1: 'Quantidade total entregadores',
                        subtitle1: data?.totalEntregadores ?? '0',
                    }}
                />

            </Stack>

            <Card elevation={1} sx={{ mt: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">Entregadores</Typography>
                    <ExportarTabela dispatchMethods={{ actionName: 'exportarPerformanceEntregadorStart', payload: hookForm.getValues() }} loading={exportarPerformanceEntregadores?.loading} />
                </Box>
                {data?.ultimaPagina > 0 && (
                    <TableNavigation
                        indiceInicial={data?.indiceInicial}
                        indiceFinal={data?.indiceFinal}
                        indiceTotal={data?.total}
                        ultimaPagina={data?.ultimaPagina}
                        hookForm={hookForm}
                        podeTodos={false}
                    />
                )}

                <TabelaPerformance
                    data={data?.lista}
                    loading={loading}
                />

            </Card>
        </Box>
    );
};

const stylesForTypography = {
    flex: '2 0 250px'
}

export default PerformanceEntregador;
