import { ArrowBackIos } from '@mui/icons-material'
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { HeaderModal, ModalBase, ModalOpcoes, SelectToggleAtivo, SnackbarAlert, TextFieldMoney } from 'src/ds/DesignSystem'
import { liveMaskMoney, liveMaskPercentage } from 'src/ds/Utils'
import MapaPoligono from './MapaPoligono'
import { useMultiResponseSnackbar, useResponsive, useSnackbar } from 'src/ds/CustomHooksDs'
import { limparTaxaExtra, salvarTaxaExtraStart, taxaExtraStart } from 'src/store/reducers/taxaExtra'
import { cleanInput, cleanMoneyInput, replaceDecimalSeparator } from 'src/ds/common'
import { TaxaExtra } from 'src/dtos/TaxaExtraDTO'
import { converterDataCadastro } from 'src/utils'

interface FormValues {
  taxaExtraID: string;
  nome: string;
  ativo: boolean;
  aplicarAutomaticamente: string;
  tipoValor: number;
  valorCobrar: string;
  valorPagar: string;
  pushAutomatico: boolean;
  pushTitulo: string;
  pushMensagem: string;
  tagsSelecionadas?: string[];
  tagsExcluidasSelecionadas?: string[];
  aplicarZona?: string;
  pontosGeograficos?: any;
}

interface DadosBasicosProps {
  modelo?: TaxaExtra;
}

const DadosBasicos = ({ modelo }: DadosBasicosProps = {}) => {
  const hookForm = useForm<FormValues>({
    defaultValues: {
      nome: "",
      ativo: true,
      aplicarAutomaticamente: "",
      tipoValor: 0,
      valorCobrar: "",
      valorPagar: "",
      pushAutomatico: false,
      pushTitulo: "",
      pushMensagem: "",
      tagsSelecionadas: [],
      tagsExcluidasSelecionadas: [],
      aplicarZona: "",
      pontosGeograficos: [],
    },
  });
  const { register, setValue, handleSubmit, control, watch, formState: { errors } } = hookForm
  const dispatch = useDispatch();
  const response = useSelector((state: any) => state.taxaExtra.salvarTaxaExtra);
  const snack = useSnackbar({ duration: 2000 });
  const taxasExtra = useSelector((state: any) => state.taxaExtra.lista?.data?.dados?.lista)
  const [ultimaTabelaCadastrada, setUltimaTabelaCadastrada] = useState(null);
  const [triggerEditar, setTriggerEditar] = useState(false);

  useEffect(() => {
    if (modelo) {
      Object.keys(modelo).forEach((key) => {
        const value = (modelo as any)[key];

        if (typeof value === 'object' && value !== null && key !== 'tagsSelecionadas' && key !== 'tagsExcluidasSelecionadas') {
          setValue(key as keyof FormValues, JSON.stringify(value));
        } else {
          setValue(key as keyof FormValues, value);
        }
        if (key === 'aplicarAutomaticamente') setValue("aplicarAutomaticamente", modelo.aplicarAutomaticamente.toString())
      });
    }
  }, [modelo, setValue]);

  useEffect(() => {
    if (taxasExtra?.length > 0) {
      console.log('Taxas extra:');
      const storedId = localStorage.getItem('lastEditedTabelaId');

      const cincoMinutosAtras = new Date(new Date().getTime() - 0.1 * 60 * 1000);

      const tabelasRecentes = taxasExtra?.filter((tabela: any) => {
        const dataCadastro = converterDataCadastro(tabela.dataCadastro);
        return dataCadastro > cincoMinutosAtras;
      });

      const tabelaMaisRecente = tabelasRecentes?.reduce((maisRecente: any, tabelaAtual: any) => {
        const dataMaisRecente = converterDataCadastro(maisRecente?.dataCadastro || '');
        const dataAtual = converterDataCadastro(tabelaAtual?.dataCadastro);
        return dataAtual > dataMaisRecente ? tabelaAtual : maisRecente;
      }, null);
      if (tabelaMaisRecente && tabelaMaisRecente.taxaExtraID !== storedId) {
        console.log('Tabela mais recente:', tabelaMaisRecente);
        setUltimaTabelaCadastrada(tabelaMaisRecente);
        setTriggerEditar(true);
        localStorage.setItem('lastEditedTabelaId', tabelaMaisRecente.taxaExtraID);
        navigate(`/TaxaExtra/Index/Editar/${tabelaMaisRecente.taxaExtraID}`);
      }
    }
  }, [taxasExtra]);


  useMultiResponseSnackbar([{ response, clearAction: () => { dispatch(limparTaxaExtra()) } }], snack);

  const [openModalOpcoes, setOpenModalOpcoes] = useState(false);
  const [modalData, setModalData] = useState({
    descricao: '',
    acao: '',
    opcoes: []
  });
  const handleOpenModal = (descricao: string, acao: string, opcoes: any) => {
    setModalData({ descricao, acao, opcoes });
    setOpenModalOpcoes(true);
  };
  const onSubmit = (data: any) => {
    console.log(data);
    const { tiposVeiculo, ...rest } = data;

    const finalData = {
      ...rest,
      valorCobrar: cleanMoneyInput(replaceDecimalSeparator(data?.valorCobrar)),
      valorPagar: cleanMoneyInput(replaceDecimalSeparator(data?.valorPagar)),
    };
    dispatch(salvarTaxaExtraStart(finalData));
    setTimeout(() => {
      dispatch(taxaExtraStart({
        pagina: 1,
        paginacao: -1
      }), 2000)
    })
  };

  const navigate = useNavigate();

  const tipoValor = watch('tipoValor');
  const aplicarZona = watch('aplicarZona');

  const applyMask = (value: string, tipo: string) => {
    //@ts-ignore
    if (modelo !== null && modelo?.valorPagar === value || modelo?.valorCobrar === value) {
      return value;
    }

    return tipo === '1' ? liveMaskPercentage(value) : liveMaskMoney(value);
  };


  const handlePolygonComplete = (path: any) => {
    const formattedPath = JSON.stringify(path);

    console.log('Pontos geográficos do polígono:', formattedPath);

    setValue('pontosGeograficos', formattedPath);
  };


  const { isMobile } = useResponsive();
  console.log('Modelo:', tipoValor)

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      p={1}
      sx={{ backgroundColor: 'background.paper' }}
    >
      <Stack spacing={2} direction={isMobile ? "column" : "row"} >
        <Box display="flex" sx={{ width: '100%', flexDirection: 'column', gap: 2 }} >
          <TextField
            label="Nome"
            fullWidth
            size="small"
            {...register("nome", { required: "Nome é obrigatório" })}
            error={!!errors.nome}
            helperText={errors.nome?.message}
          />
          <SelectToggleAtivo hookForm={{ methods: hookForm, name: "ativo" }} label="Situação" />
          <FormControl size="small" fullWidth error={!!errors.aplicarAutomaticamente}>
            <InputLabel>Selecione um tipo de aplicação</InputLabel>
            <Controller
              name="aplicarAutomaticamente"
              control={control}
              rules={{ required: "Selecione um tipo de aplicação" }}
              render={({ field: { onChange, value } }) => (
                <Select
                  size="small"
                  fullWidth
                  value={String(value)}
                  label="Selecione um tipo de aplicação"
                  onChange={(e) => onChange(e.target.value)}
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="true">Automática</MenuItem>
                  <MenuItem value="false">Manual</MenuItem>
                </Select>
              )}
            />
            <Typography variant="caption" color="error">
              {errors.aplicarAutomaticamente?.message}
            </Typography>
          </FormControl>

          <FormControl size="small" fullWidth>
            <InputLabel>Selecione um tipo de valor</InputLabel>
            <Controller
              name="tipoValor"
              control={control}
              rules={{ required: "Selecione um tipo de valor" }}

              render={({ field: { onChange, value } }) => (
                <Select
                  size="small"
                  fullWidth
                  value={value}
                  label="Selecione um tipo de valor"
                  {...register("tipoValor", { required: "Selecione um tipo de valor" })}
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value={0}>Valor</MenuItem>
                  <MenuItem value={1}>Percentual</MenuItem>
                </Select>
              )}
            />
            <Typography variant="caption" color="error">
              {errors.tipoValor?.message}
            </Typography>
          </FormControl>
          {
            tipoValor === 1 &&
            <>
              <Controller
                name="valorCobrar"
                control={control}
                rules={{ required: "Valor cobrar é obrigatório" }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Valor cobrar"
                    fullWidth
                    size="small"
                    value={liveMaskPercentage(value)}
                    onChange={(e) => onChange(liveMaskPercentage(e.target.value))}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"><Typography>%</Typography></InputAdornment>, // Adiciona o símbolo de % ao final do campo
                      }
                    }}
                    error={!!errors.valorCobrar}
                    helperText={errors.valorCobrar?.message}
                  />
                )}
              />
              <Controller
                name="valorPagar"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Valor pagar"
                    fullWidth
                    size="small"
                    value={liveMaskPercentage(value)}
                    onChange={(e) => onChange(liveMaskPercentage(e.target.value))}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"><Typography>%</Typography></InputAdornment>,
                      }
                    }}
                    error={!!errors.valorPagar}
                    helperText={errors.valorPagar?.message}
                  />
                )}
              />
            </>

          }
          {tipoValor === 0 &&
            <>
              <TextFieldMoney
                hookForm={{
                  methods: hookForm,
                  name: 'valorCobrar',
                  defaultValue: hookForm.watch('valorCobrar'),
                  rules: {
                    required: 'Valor a cobrar é obrigatório',
                  }
                }}

                label='Valor a cobrar'
              />
              <TextFieldMoney
                hookForm={{
                  methods: hookForm,
                  name: 'valorPagar',
                  defaultValue: hookForm.watch('valorPagar'),
                  rules: {
                    required: 'Valor a pagar é obrigatório',
                  }
                }}
                label='Valor a pagar'
              />

            </>
          }
          <Typography variant="subtitle1">Push ao ativar taxa extra</Typography>
          <SelectToggleAtivo hookForm={{ methods: hookForm, name: "pushAutomatico" }} label="Push" toggleLabels={{ ativo: 'Sim', inativo: 'Não' }} toggleValues={{ ativo: 'true', inativo: 'false' }} />
          <TextField label="Título" fullWidth size="small" {...register("pushTitulo")}
            error={!!errors.pushTitulo}
            helperText={errors.pushTitulo?.message}
          />
          <TextField label="Mensagem" fullWidth size="small" {...register("pushMensagem")}
            error={!!errors.pushMensagem}
            helperText={errors.pushMensagem?.message}
          />
        </Box>
        <Box display="flex" sx={{ width: '100%', flexDirection: 'column', gap: 2 }} >
          <Typography variant="subtitle1">Tags</Typography>
          <TextField label="Tags" fullWidth size="small" {...register("tagsSelecionadas")}
            error={!!errors.tagsSelecionadas}
            helperText={errors.tagsSelecionadas?.message}
          />
          <Typography variant="subtitle1">Tags de não inclusão </Typography>
          <TextField label="Tags" fullWidth size="small" {...register("tagsExcluidasSelecionadas")}
            error={!!errors.tagsExcluidasSelecionadas}
            helperText={errors.tagsExcluidasSelecionadas?.message}
          />
          <Typography variant="subtitle1">Zona de aplicação </Typography>
          <SelectToggleAtivo hookForm={{ methods: hookForm, name: "aplicarZona" }} label="Aplicar em uma zona" toggleLabels={{ ativo: 'Sim', inativo: 'Não' }} toggleValues={{ ativo: 'true', inativo: 'false' }} />
          {aplicarZona === 'true' && (
            <Box mt={2}>
              <MapaPoligono onPolygonComplete={handlePolygonComplete} initialPath={modelo?.pontosGeograficos || []} />
            </Box>
          )}
        </Box>
      </Stack>
      <Box
        sx={(theme) => ({ display: 'flex', gap: 2, flexDirection: 'column', p: 2, backgroundColor: theme.palette.background.paper })}
      >
        <Box display="flex" justifyContent="flex-end" sx={{ width: '100%', mt: 2, gap: 2 }}>
          <Button
            variant="containedFilled"
            color="red"
            sx={{ width: '10%' }}
            onClick={() => handleOpenModal(
              'Deseja excluir?',
              'Excluir',
              [
                {
                  label: 'Cancelar',
                  action: () => setOpenModalOpcoes(false)
                },
                {
                  label: 'Confirmar',
                  action: () => {
                    console.log('Excluir');
                    setOpenModalOpcoes(false);
                  }
                }
              ]
            )}
          >
            Excluir
          </Button>
          <Button
            variant="containedFilled"
            color="primary"
            type="submit"
            sx={{ width: '10%' }}
          >
            Salvar
          </Button>

        </Box>
        <ModalOpcoes
          open={openModalOpcoes}
          onClose={() => setOpenModalOpcoes(false)}
          descricao={modalData.descricao}
          acao={modalData.acao}
          opcoes={modalData.opcoes}
        />
      </Box>
      <SnackbarAlert {...snack} />
    </Box>
  )
}

export default DadosBasicos