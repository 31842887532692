import { Box, TextField, Button, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { cleanMoneyInput, replaceDecimalSeparator } from 'src/ds/common';
import { HeaderModal, ModalBase } from 'src/ds/DesignSystem';
import { liveMaskMoney } from 'src/ds/Utils';
import { salvarTabelaPorBairroStart } from 'src/store/reducers/tabelaCobranca';

interface DadosValores {
    bairro: string;
    valor: string;
    taxaRetorno: string;
    tabelaCobrancaBairroID: string;
}

interface ModalEditarValoresPorBairroProps {
    open: boolean;
    handleClose: () => void;
    dados?: DadosValores;
}

const ModalEditarValoresPorBairro = ({ open, handleClose, dados }: ModalEditarValoresPorBairroProps) => {
    const { register, handleSubmit, reset, setValue, watch } = useForm<DadosValores>({
        defaultValues: {
            tabelaCobrancaBairroID: '',
            bairro: '',
            valor: '',
            taxaRetorno: '',
        },
    });

    useEffect(() => {
        if (dados) {
            setValue("tabelaCobrancaBairroID", dados.tabelaCobrancaBairroID);
            setValue("bairro", dados.bairro);
            setValue("valor", dados.valor);
            setValue("taxaRetorno", dados.taxaRetorno);
        }
    }, [dados, setValue]);

    const dispatch = useDispatch();

    const onSubmit = (data: DadosValores) => {
        const cleanedData = {
            ...data,
            valor: cleanMoneyInput(replaceDecimalSeparator(data.valor)),
            taxaRetorno: cleanMoneyInput(replaceDecimalSeparator(data.taxaRetorno)),
            tabelaCobrancaBairroID: data.tabelaCobrancaBairroID || '',
        };
        dispatch(salvarTabelaPorBairroStart(cleanedData));
        handleClose();
    };

    useEffect(() => {
        if (dados) {
            reset(dados);
        } else {
            reset({
                tabelaCobrancaBairroID: '',
                bairro: '',
                valor: '',
                taxaRetorno: '',
            });
        }
    }, [dados, reset]);

    return (
        <ModalBase open={open} onClose={handleClose} aria-labelledby="ModalBase-title" aria-describedby="modal-description" size='sm'>
            <HeaderModal title="Valores por bairro" onClose={handleClose} />
            <Box p={3}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <TextField
                            label="Bairro"
                            fullWidth
                            size='small'
                            {...register('bairro')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            label="Valor"
                            size='small'
                            fullWidth
                            value={liveMaskMoney(watch('valor'))}
                            {...register('valor')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            label="Taxa de Retorno"
                            size='small'
                            fullWidth
                            value={liveMaskMoney(watch('taxaRetorno'))}
                            {...register('taxaRetorno')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <input type="hidden" {...register('tabelaCobrancaBairroID')} />

                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ width: 'fit-content' }}>
                                Cancelar
                            </Button>
                            <Button variant="containedFilled" color="primary" type="submit" sx={{ width: 'fit-content' }}>
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </ModalBase>
    );
};

export default ModalEditarValoresPorBairro;
