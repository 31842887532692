import React, { useEffect, useState } from 'react';
import { useTheme, useMediaQuery, Grid2 } from "@mui/material";
import { SnackbarAlert } from '../../ds';
import foto from '../../assets/freemium1.png';
import logo1 from '../../assets/images/Logo-Pick-4.webp';
import { useDispatch, useSelector } from 'react-redux';
import { loginFreemium } from '../../store/reducers/login';
import PrimeiroEmail from '../../components/Freemium/PrimeiroEmail';
import LoginFreemium from '../../components/Freemium/LoginFreemium';
import CadastroFreemium from '../../components/Freemium/CadastroFreemium';
import { duration } from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../ds/customHooks';

const PrimeiroAcesso = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [email, setEmail] = useState('');
    const [codigoLogin, setCodigoLogin] = useState('');
    const resposta = useSelector(state => state.login.freemiumResponse);
    const error = useSelector(state => state.login.error);
    const dispatch = useDispatch();
    const respostaSnack = useSnackbar({ duration: 5000 });
    const [requestMade, setRequestMade] = useState(false);
    const [stage, setStage] = useState(1);
    const cadastroFreemiumResponse = useSelector(state => state.login.cadastroFreemiumResponse);
    const navigation = useNavigate();

    useEffect(() => {
        if (cadastroFreemiumResponse?.sucesso === true) {
            setStage(2);

        }
    }, [cadastroFreemiumResponse]);

    useEffect(() => {
        if (email === "") {
            setStage(1);
        }
    }, []);

    useEffect(() => {
        if (email) {
            dispatch(loginFreemium({ email, codigoLogin }));
            setRequestMade(true);
        }
    }, [email]);

    const verifyEmail = () => {
        dispatch(loginFreemium({ email, codigoLogin }));
        setRequestMade(true);
    };


    useEffect(() => {
        if (requestMade) {
            if (resposta?.sucesso === true) {
                respostaSnack.showSnackbar(resposta?.mensagem, 'success', 5000)
                setStage(2);
            }
            if (error) {
                respostaSnack.showSnackbar(error.mensagem, 'error', 5000)
                if (error.mensagem === 'Endereço de e-mail não encontrado') {
                    setStage(3);
                } else if (error.mensagem === 'Assinatura não está no plano freemium') {
                    respostaSnack.showSnackbar('Assinatura não está no plano freemium!', 'warning', 5000)

                    navigation('/');
                }
            }
        }
    }, [resposta, error, requestMade]);

    useEffect(() => {
        if (resposta?.token) {
            navigation('/Redirect');
        }
    }, [resposta]);
    return (

        <Grid2
            container
            sx={{
                display: 'flex',
                height: '100vh',
                backgroundColor: theme.palette.background.default
            }}
        >
            <Grid2
                size={{ xs: 12, sm: 9, md: 6 }}
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    textAlign: 'center',
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {stage === 1 && (
                    <PrimeiroEmail email={email} setEmail={setEmail} isMobile={isMobile} />
                )}
                {stage === 2 && (
                    <LoginFreemium isMobile={isMobile} setCodigoLogin={setCodigoLogin} enviar={verifyEmail} reenviar={verifyEmail} />
                )}
                {stage === 3 && (
                    <CadastroFreemium isMobile={isMobile} email={email} setEmail={setEmail} voltar={() => setStage(1)} />
                )}
            </Grid2>
            <Grid2
                size={{ xs: 0, sm: 3, md: 6 }}
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img src={foto} alt="Freemium" width={'80%'} />
            </Grid2>
            <SnackbarAlert {...respostaSnack} />
        </Grid2>
    );
};

export default PrimeiroAcesso;
