import React from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useFormContext, Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actions } from "src/store/Actions";
import ModalBase from "./ModalBase";
import dayjs from "dayjs";
import InputDate from "./InputDate";
import { formatCardNumber } from "../common";

interface ModalCartaoProps {
    open: boolean;
    onClose: () => void;
    dispatchMethods: { actionName: keyof typeof actions };
}

const ModalCartao: React.FC<ModalCartaoProps> = ({ open, onClose, dispatchMethods }) => {
    const hookForm = useForm({
        defaultValues: {
            assinatura: {
                cartaoNumero: "",
                cartaoNome: "",
                cartaoMesValidade: "",
                cartaoAnoValidade: "",
                cartaoCodigo: "",
                cartaoDataNascimento: "",
            }
        }
    });

    const { control, handleSubmit, setValue } = hookForm;
    const dispatch = useDispatch();

    const onSubmit = (data: any) => {
        console.log(data);
         if (dispatchMethods?.actionName) {
             const actionCreator = actions[dispatchMethods.actionName];
             if (actionCreator) {
                 dispatch(actionCreator(data)); 
             }
         }
    };

    const currentYear = dayjs().get("year");
    const years = Array.from({ length: 20 }, (_, index) => currentYear + index);
    const months = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    return (
        <ModalBase open={open} onClose={onClose} headerModal={{ title: 'Cartão de crédito' }}>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: 2,
                }}
            >
                <Controller
                    name="assinatura.cartaoNome"
                    control={control}
                    defaultValue=""
                    rules={{ required: "O nome no cartão é obrigatório" }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            label="Nome no Cartão"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            fullWidth
                            size="small"
                        />
                    )}
                />

                <Controller
                    name="assinatura.cartaoNumero"
                    control={control}
                    defaultValue=""
                    rules={{ required: "O número do cartão é obrigatório" }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            label="Número do Cartão"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            fullWidth
                            slotProps={{
                                htmlInput: { maxLength: 19 },
                            }}
                            size="small"
                            onChange={e => {
                                field.onChange(formatCardNumber(e.target.value));
                            }}
                        />
                    )}
                />

                <Stack direction="row" spacing={2}>
                    <Controller
                        name="assinatura.cartaoMesValidade"
                        control={control}
                        defaultValue=""
                        rules={{ required: "O mês de validade é obrigatório" }}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel size="small">Mês</InputLabel>
                                <Select {...field} label="Mês"
                                    size="small"
                                >
                                    {months.map((month, index) => (
                                        <MenuItem key={index} value={index + 1}>{month}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="assinatura.cartaoAnoValidade"
                        control={control}
                        defaultValue=""
                        rules={{ required: "O ano de validade é obrigatório" }}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel size="small">Ano</InputLabel>
                                <Select {...field} label="Ano"
                                    size="small"
                                >
                                    {years.map(year => (
                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Stack>

                <Controller
                    name="assinatura.cartaoCodigo"
                    control={control}
                    defaultValue=""
                    rules={{ required: "O CVV é obrigatório" }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            label="CVV"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            fullWidth
                            type="password"
                            size="small"

                        />
                    )}
                />

                <InputDate
                    hookForm={{
                        name: "assinatura.cartaoDataNascimento",
                        methods: hookForm,
                    }}
                    label="Data de nascimento"

                />

                <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
                    <Button variant="containedFilled" color="secondary" sx={{ width: "min-content" }}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="containedFilled" color="primary" sx={{ width: "min-content" }}>
                        Pagar
                    </Button>
                </Stack>
            </Box>
        </ModalBase>
    );
};

export default ModalCartao;
