import { useState } from 'react';
import { TaxaExtra } from '../../dtos/TaxaExtraDTO';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Tooltip, useTheme, IconButton, Typography } from '@mui/material';
import { ModalOpcoes, SnackbarAlert, TableRowLoading, TableRowNotFound, ButtonTableOrder } from 'src/ds/DesignSystem'; // Importando o ButtonTableOrder
import { dinheiro } from '../../ds/common';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { excluirTaxaExtraStart, limparExcluir, taxaExtraStart } from 'src/store/reducers/taxaExtra';
import { useMultiResponseSnackbar, useSnackbar } from 'src/ds/CustomHooksDs';
import { useNavigate } from 'react-router-dom';

interface TabelaTaxaExtraProps {
    data: TaxaExtra[] | undefined;
    loading: boolean;
    fezRequisicao?: boolean;
    atualizar: any;
}

const TabelaTaxaExtra = ({ data, loading, fezRequisicao, atualizar }: TabelaTaxaExtraProps) => {
    const theme = useTheme();
    const [excluir, setExcluir] = useState(false);
    const [row, setRow] = useState<TaxaExtra>();
    const response = useSelector((state: any) => state.taxaExtra?.excluir);
    const snack = useSnackbar({ duration: 2000 });
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        dataCadastro: 'asc',
        valorCobrar: 'asc',
        valorPagar: 'asc',
        tipoVeiculo: 'asc',
        ativo: 'asc',
    });

    useMultiResponseSnackbar([{
        response,
        clearAction: () => { dispatch(limparExcluir()) }
    }], snack);

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        
        const updatedSortDirection = Object.keys(sortDirection).reduce((acc, key) => {
            acc[key] = key === column ? newSortDirection : 'asc';
            return acc;
        }, {} as { [key: string]: 'asc' | 'desc' });

        setSortDirection(updatedSortDirection);

        const payload = {
            paginacao: 20, 
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
        };

        dispatch(taxaExtraStart(payload)); 
    };

    const formatValor = (tipoValor: TaxaExtra['tipoValor'], valor: TaxaExtra['valorCobrar' | 'valorPagar']) => {
        switch (tipoValor) {
            case 0:
                return dinheiro(valor);
            case 1:
                return `${valor}%`;
            default:
                return valor;
        }
    }

    const dispatch = useDispatch();
    const handleConfirmar = () => {
        dispatch(excluirTaxaExtraStart(row?.taxaExtraID));
        setExcluir(false);
        setTimeout(() => {
            atualizar();
        }, 500);
    }
    
    const handleExcluir = (row: any) => {
        setExcluir(true);
        setRow(row);
    }

    const navigate = useNavigate();
    
    return (
        <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <ButtonTableOrder onClick={() => toggleSortDirection('nome')} sortDirection={sortDirection} nome="nome">
                                Taxa extra
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell>
                            <ButtonTableOrder onClick={() => toggleSortDirection('dataCadastro')} sortDirection={sortDirection} nome="dataCadastro">
                                Data de Cadastro
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell>
                            <ButtonTableOrder onClick={() => toggleSortDirection('valorCobrar')} sortDirection={sortDirection} nome="valorCobrar">
                                Valor de Cobrança
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell>
                            <ButtonTableOrder onClick={() => toggleSortDirection('valorPagar')} sortDirection={sortDirection} nome="valorPagar">
                                Valor de Pagamento
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell>
                            <ButtonTableOrder onClick={() => toggleSortDirection('tipoVeiculo')} sortDirection={sortDirection} nome="tipoVeiculo">
                                Tipo de Veículo
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell>
                            <ButtonTableOrder onClick={() => toggleSortDirection('ativo')} sortDirection={sortDirection} nome="ativo">
                                Situação
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : data && data.length > 0 ? (
                        data.map((taxa) => (
                            <TableRow key={taxa.taxaExtraID} hover>
                                <TableCell align='center'>{taxa.nome}</TableCell>
                                <TableCell align='center'>{taxa.dataCadastro}</TableCell>
                                <TableCell align='center'>{formatValor(taxa.tipoValor, taxa.valorCobrar)}</TableCell>
                                <TableCell align='center'>{formatValor(taxa.tipoValor, taxa.valorPagar)}</TableCell>
                                <TableCell align='center'>
                                    {taxa.tiposVeiculo?.length > 0
                                        ? taxa.tiposVeiculo.map((veiculo, index) => (
                                            <span key={index}>
                                                {veiculo.nome}
                                                <br />
                                            </span>
                                        ))
                                        : '-'}
                                </TableCell>
                                <TableCell align='center'>{taxa.ativo ? 'Ativo' : 'Desativado'}</TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1} justifyContent="center">
                                        <Tooltip title="Editar" arrow placement="top">
                                            <IconButton onClick={() => navigate(`/TaxaExtra/Index/Editar/${taxa.taxaExtraID}`)} >
                                                <Edit color='secondary' sx={{ cursor: 'pointer' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Excluir" arrow placement="top">
                                            <IconButton onClick={() => handleExcluir(taxa)}>
                                                <Delete color='error' sx={{ cursor: 'pointer' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRowNotFound fezRequisicao={fezRequisicao} text="Nenhum resultado encontrado" />
                    )}
                </TableBody>
            </Table>
            <ModalOpcoes
                open={excluir}
                onClose={() => setExcluir(false)}
                acao="Excluir"
                descricao={(
                    <Typography>
                        Tem certeza que deseja excluir <span style={{ fontWeight: 'bold' }}>{row?.nome}</span> da tabela?
                    </Typography>
                )}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action() { setExcluir(false) },
                    },
                    {
                        label: 'Excluir',
                        action() { handleConfirmar() },
                    },
                ]}
            />
            <SnackbarAlert {...snack} />
        </TableContainer>
    );
};

export default TabelaTaxaExtra;
