import { Button, Typography, useTheme, Box, TextField } from "@mui/material";
import { ColumnGrid, Input } from "../../ds";
import headerImage from '../../assets/images/Logo-Pick-4.png'; // Altere para o caminho correto da imagem
import { useForm } from "react-hook-form";
import { Watch } from "@mui/icons-material";

const PrimeiroEmail = ({ setEmail, isMobile }) => {
    const theme = useTheme();
    const { register, handleSubmit, formState: { errors }, watch } = useForm();

    const onSubmit = (data) => {
        setEmail(data.email);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '70vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxSizing: 'border-box',
                overflow: 'hidden'
            }}
        >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 6 }}>
                <img src={headerImage} alt="Header" style={{ maxWidth: '50%', height: 'auto' }} />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingX: 2,
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <ColumnGrid
                    width={isMobile ? '100%' : '70%'}
                    maxWidth="100%"
                    justifyContent={'center'}
                    textAlign={'center'}
                    sx={{
                        boxSizing: 'border-box',
                    }}
                >
                    <Typography variant={isMobile ? 'h5' : 'h4'} color="primary" fontWeight="bold" alignSelf={'center'}>
                        Monitore seus entregadores DE GRAÇA!
                    </Typography>
                    <Typography variant={isMobile ? 'p' : 'h6'} alignSelf={'center'} mb={2}>
                    Cadastre seu email e comece a monitorar com a tecnologia Pick n go!
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                        <TextField
                            size='small'
                            label="E-mail"
                            placeholder="Digite seu e-mail para começarmos"
                            fullWidth
                            {...register("email", {
                                required: "E-mail é obrigatório",
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Formato de e-mail inválido"
                                }
                            })}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                        {(Object.keys(errors).length === 0) && (
                            <Box
                                sx={{
                                    pt: isMobile ? '1rem' : '0',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="containedFilled"
                                    sx={{
                                        visibility: watch('email') ? 'visible' : 'hidden',
                                        position: isMobile ? 'relative' : 'absolute',
                                        bottom: isMobile ? 0 : 16,
                                        right: isMobile ? 0 : 16,
                                        backgroundColor: 'orange',
                                        width: 'auto',
                                    }}
                                >
                                    Continuar
                                </Button>
                            </Box>
                        )}
                    </form>
                </ColumnGrid>
            </Box>
        </Box>
    );
};

export default PrimeiroEmail;
