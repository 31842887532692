import { Alert, IconButton, Portal, Slide, Snackbar } from '@mui/material';
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

interface SnackbarProps {
    message?: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    duration?: number;
    severity?: 'success' | 'info' | 'warning' | 'error';
    handleClose?: (event: React.SyntheticEvent | Event, reason: string) => void;
}

/**
 * Snackbar com um alert Configurado
 * @component
 * @param {String} props.message - Texto da mensagem.
 * @param {Boolean} props.open - Estado que define se a mensagem estiver aberta.
 * @param {Function} props.setOpen - Função que define o estado da mensagem.
 * @param {Number} props.duration - Duração da mensagem, caso não seja informado o valor é 0.
 * @param {String} props.severity - Tipo da mensagem (success, info, warning, error).
 * @param {Function} props.handleClose - Função executada ao fechar snacckbar, por padrão apenas fecha o modal.
 *
*/
const SnackbarAlert = ({
    message = '',
    open,
    setOpen,
    duration = 0,
    severity = 'success',
    handleClose = (event: React.SyntheticEvent | Event, reason: string) => { setOpen(false) },
    ...props
}: SnackbarProps) => {

    const onCloseAlert = (event: React.SyntheticEvent | Event) => {
        if (handleClose) {
            handleClose(event, 'close');
        } else {
            setOpen(false);
        }
    };

    return (
        <Portal >
            {/* Garante a rederização do componente fora da árvore doom */}
            <Snackbar
                open={open}
                {...(duration !== 0 && { autoHideDuration: duration })}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionComponent={Slide}
                
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event) => onCloseAlert(event)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert
                    onClose={onCloseAlert}
                    severity={severity}
                    sx={{
                        width: '100%',

                    }}
                >
                    {typeof message === 'object' ? 'Object' : message}
                </Alert>
            </Snackbar>
        </Portal>
    );
}

export default SnackbarAlert
