import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import { listaClientesFailure, listaClientesStart, listaClientesSuccess } from '../reducers/cliente'; 

export function* listaClientes(action) {
    const form = new FormData();
    let empresaID = null;

    Object.keys(action.payload).forEach(key => {
        if (key === 'empresaID') {
            empresaID = action.payload[key]; 
        } else {
            form.append(key, action.payload[key]);  
        }
    });


    const url = empresaID ? `/EmpresaCliente/ListarSql?empresaID=${empresaID}` : '/EmpresaCliente/ListarSql?empresaID=null';

    try {
        const response = yield call(api.post, url, form); 
        if (response.data.sucesso !== true) {
            yield put(listaClientesFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(listaClientesSuccess(response.data.dados));
    } catch (error) {
        yield put(listaClientesFailure(error.toString()));
    }
}


function* ListarClientes() {
    yield takeLatest(listaClientesStart.type, listaClientes);
}

export default ListarClientes;
