/* global fbq */
import React, { useEffect, useState } from "react";
import { Typography, Box, TextField, useTheme, Button } from "@mui/material";
import headerImage from '../../assets/images/logo.png';
import { useDispatch } from "react-redux";
import { limparLoginFreemium } from "../../store/reducers/login";

const LoginFreemium = ({ isMobile, setCodigoLogin, enviar, reenviar }) => {
    const [code, setCode] = useState(Array(6).fill(""));
    const [timer, setTimer] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        setCodigoLogin(code.join(""));
    }, [code]);

    useEffect(() => {
        dispatch(limparLoginFreemium());
    }, []);

    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [timer]);
    useEffect(() => {
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        fbq('init', '525115865853668');
        fbq('track', 'PageView');
    }, []);

    const handleChange = (index, value) => {
        if (/^[0-9]?$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value && index < 5) {
                document.getElementById(`input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && !code[index] && index > 0) {
            document.getElementById(`input-${index - 1}`).focus();
            const newCode = [...code];
            newCode[index - 1] = "";
            setCode(newCode);
        }
    };

    const handlePaste = (event) => {
        const pasteData = event.clipboardData.getData('Text');
        if (/^\d{6}$/.test(pasteData)) {
            const newCode = pasteData.split("").slice(0, 6);
            setCode(newCode);

            setCodigoLogin(newCode.join(""));
        }
    };

    const handleReenviar = () => {
        reenviar();
        setTimer(60); // Define o temporizador para 60 segundos quando o botão é clicado
    };

    const theme = useTheme();
    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxSizing: 'border-box',
                overflow: 'hidden',
            }}
        >
            {isMobile &&
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <img src={headerImage} alt="Header" style={{ maxWidth: '60%', height: 'auto' }} />
                </Box>
            }
            <Box
                sx={{
                    width: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingX: isMobile ? 2 : 20,
                    boxSizing: 'border-box',
                }}
            >
                <Typography variant={isMobile ? 'h5' : 'h4'} component="h1" fontWeight="bold" textAlign={'center'} mb={2}>
                    Você possui um restaurante cadastrado
                </Typography>
                <Typography variant="body1" color={theme.palette.grey[500]} textAlign={'center'} mb={isMobile ? 4 : 0}>
                    Acabamos de enviar um e-mail com um código de 6 dígitos para ser preenchido nesta etapa
                </Typography>
                <Box
                    display="flex"
                    marginTop={isMobile ? 2 : 4}
                    onPaste={handlePaste}
                >
                    {code.map((value, index) => (
                        <React.Fragment key={index}>
                            <TextField
                                id={`input-${index}`}
                                value={value}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                slotProps={{
                                    htmlInput: {
                                        maxLength: 1,
                                        style: { textAlign: 'center' }
                                    }
                                }}
                                sx={{
                                    width: isMobile ? 40 : 56,
                                }}
                            />
                            {index === 2 && (
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginX: isMobile ? 1 : 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: isMobile ? 8 : 8,
                                            height: isMobile ? 8 : 8,
                                            borderRadius: '50%',
                                            backgroundColor: theme.palette.grey[500],
                                        }}
                                    />
                                </Box>
                            )}
                        </React.Fragment>
                    ))}
                </Box>
                <Box
                    sx={{
                        pt: isMobile ? '1rem' : '0',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            position: isMobile ? 'relative' : 'absolute',
                            bottom: isMobile ? 5 : 16,
                            right: isMobile ? 0 : 90,
                            width: 'auto',
                        }}
                        onClick={handleReenviar}
                        disabled={timer > 0}
                    >
                        {timer > 0 ? `Reenviar em ${timer}s` : 'Reenviar código'}
                    </Button>
                    <Button
                        variant="containedFilled"
                        sx={{
                            position: isMobile ? 'relative' : 'absolute',
                            bottom: isMobile ? 0 : 16,
                            right: isMobile ? 0 : 16,
                            backgroundColor: 'orange',
                            width: 'auto',
                            mb: isMobile ? 1 : 0,
                        }}
                        onClick={enviar}
                    >
                        Entrar
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default LoginFreemium;
