import React, { forwardRef, useEffect, useMemo, useState } from "react";
import {
  Alert,
  IconButton,
  Slide,
  Snackbar,
  AppBar,
  Avatar,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Skeleton,
  Toolbar,
  Typography,
  styled,
  Menu,
  ButtonGroup,
  Portal,
  Collapse,
  Grid2
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useTheme } from "@mui/system";
import { CloseOutlined, Logout } from "@mui/icons-material";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { usePagina } from "../context/PaginaContext";
import { useDispatch, useSelector } from "react-redux";
import { PLANOFREEMIUM } from "../utils";
import { useResponsive } from "./customHooks";
import { toggleColorMode } from "../store/reducers/utils";
import * as Icons from 'react-icons/fa';
import ModalContratarPlanos from "../components/Painel/ModalContratarPlanos";
import { useNavigate } from "react-router-dom";
import ModalQrCode from "src/components/common/ModalQrCode";
import logo from '../assets/images/logo.png';
import { logout } from "src/services/api";
import { ModalOpcoes } from "./DesignSystem";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

export const RowGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  alignItems: 'center',
  '&:first-of-type': {
    marginTop: 0,
  },
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const ColumnGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:first-of-type': {
    marginTop: 0,
  },
  '&:last-child': {
    marginBottom: 0,
  },
}));

/**
 * Componente para posicionar elementos em linha.
 * @component
 */
export const Line = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  boxSizing: 'border-box'
}));

/**
 * Grid Usado no Painel Lateral
 * @component
 */
export const GridPainelLateral = styled(Grid)(({ theme }) => ({
  zIndex: 1001,
  backgroundColor: theme.palette.background.default,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflowY: 'auto',
  height: '100%',
  width: '100%',
  top: 0,
  position: 'absolute',
}));

const hexToRgb = (hex) => {
  hex = hex.replace('#', '');

  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
};

export const CardPrincipal = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.sizes.borderRadius.sm,
  padding: `${theme.sizes.padding.xs} ${theme.sizes.padding.sm}`,
  width: '100%',
  boxSizing: 'border-box',
  boxShadow: `0px 0px 4px 0px rgba(${hexToRgb(theme.palette.getContrastText(theme.palette.background.default))}, 0.2)`,
  border: '1px solid #ccc',
  marginBottom: theme.spacing(2),
  minHeight: 'fit-content',
  height: 'auto',
  paddingTop: theme.spacing(1),
  overflow: 'hidden',
  '&:hover': {
    boxShadow: `0px 0px 10px 0px rgba(${hexToRgb(theme.palette.getContrastText(theme.palette.background.default))}, 0.4)`,
  },
  '&:last-child': {
    paddingBottom: theme.sizes.padding.xs,
  }
}));

export const CardPedidoContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100%',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.default,
}))

// export const Dropdown = styled(Select)(({ theme }) => ({
//   width: '100%',
//   marginBottom: '1rem',
//   '& label': {
//     color: theme.palette.primary.main,
//   },
//   '& .MuiOutlinedInput-root': {
//     borderRadius: theme.shape.borderRadius,
//     borderColor: theme.palette.primary.main,
//     '&:hover fieldset': {
//       borderColor: theme.palette.primary.main,
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: theme.palette.primary.main,
//     },
//   },
//   '& .MuiSelect-root': {
//     '&:focus': {
//       backgroundColor: 'transparent',
//     },
//   },
//   '& .MuiSelect-icon': {
//     color: theme.palette.primary.main,
//   },
//   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//     borderColor: theme.palette.primary.main,
//   },
// }));

/**
 * @deprecated - Use o componente BotaoSituacao do arquivo `ds/DesignSystem.tsx`
 * Componente que exibe um botão que altera a cor com base em uma situação.
 * @component
 * @params {string} situacao - Situação do botão (A cor do botão depende da situação).
              */
export const BotaoSituacao = styled(Button)(({ theme, situacao }) => ({
  backgroundColor: theme.palette.status[situacao],
  color: theme.palette.getContrastText(theme.palette.status[situacao]),
  maxHeight: '1rem',
  padding: '0.2rem 0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '20%',
  minWidth: '20%',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
  textAlign: 'center',
  position: 'relative',
  fontSize: theme.sizes.fontSize.xs,
  '&:hover': {
    backgroundColor: theme.palette.status[situacao],
  },
  '& .MuiButton-label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));

/**
 * @deprecated - Use o componente ButtonGroup do módulo `@mui/material`
 */
export const BotaoTriploContainer = styled(ButtonGroup)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: '1px',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.sizes.borderRadius.sm,
  overflow: 'hidden',
  backgroundColor: theme.palette.primary.dark,
  '& .MuiButtonGroup-middleButton': {
    margin: '0',
    borderRightColor: theme.palette.primary.dark,
    ':hover': {
      borderRightColor: theme.palette.primary.dark,
    }
  },
  '& .MuiButtonGroup-firstButton': {
    margin: '0',
    ':hover': {
      borderRightColor: theme.palette.primary.dark,
    }
  },
}));

export const BotaoTriplo = styled((props) => (
  <Button {...props} variant="containedFilled" />
))(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));

/**
 * 
 * @deprecated - Use o componente ButtonFechar do arquivo DesignSystem.tsx
 *
 * Botão com Ícone de fechar
 * @component
 */
export const BotaoFecharDelineado = styled(
  ({ variant = 'contained', ...props }) => (
    <IconButton {...props} variant={variant}>
      <CloseOutlined />
    </IconButton>
  )
)(({ }) => { })

/**
 * Botão com Ícone de Menu
 * @component
 */
export const BotaoMenu = styled(
  ({ variant = 'contained', color = 'primary', ...props }) => (
    <IconButton {...props} variant={variant}>
      <MenuRoundedIcon />
    </IconButton>
  )
)(({ }) => { })

/**
 * @deprecated - Use o componente ModalBase no arquivo `ds/DesignSystem.tsx`
 *
 * Base do Modal
 *
 * @param {boolean} open - Define se o modal est  aberto ou n o.
              * @param {function} onClose - Fun o para fechar o modal.
              * @param {string} size - Tamanho do modal (xs, sm, md, lg, xl).
              * @param {*} children - Conteudo do modal.
              * @component
              */
export const BaseModal = ({
  open,
  onClose,
  children,
  size = 'md',
  ...props
}) => {
  const { isMobile } = useResponsive();
  const sizeMap = {
    xs: { xs: 12, sm: 6, md: 4, lg: 4, xl: 4 },
    sm: { xs: 12, sm: 8, md: 6, lg: 6, xl: 6 },
    md: { xs: 12, sm: 10, md: 8, lg: 8, xl: 8 },
    lg: { xs: 12, sm: 12, md: 10, lg: 10, xl: 10 },
    xl: { xs: 12, sm: 12, md: 11, lg: 11, xl: 11 },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(1px)',
          }
        }
      }}
      {...props}
    >
      <Fade in={open} unmountOnExit>
        <Grid
          container
          item
          {...sizeMap[size]}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '95%' : `100%`,
            maxWidth: '90vw',
            maxHeight: '95vh',
            overflow: 'auto',
            bgcolor: 'background.default',
            border: 'none',
            borderRadius: '10px',
            boxShadow: 24,
          }}
        >
          {children}
        </Grid>
      </Fade>
    </Modal >
  )
}

/**
 * @deprecated - Use o componente HeaderModal no arquivo `ds/DesignSystem.tsx`
 *
 * Header do Modal.
 * @param {Array} controlesHeader - Uma Array de Controles Laterais Header
* @param {string} title - Titulo do Modal
* @param {Boolean} forceMenu - Forçar Mobile
* @param {function} onClose - Função para fechar o modal.
* @returns
*/
export const HeaderModal = ({
  controlesHeader = null,
  forceMenu = false,
  title,
  onClose = null
}) => {
  const { isMobile } = useResponsive();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <>
      <Grid item xs={12} sx={{ p: '1rem' }}>
        <Line sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          {onClose && (
            <div style={{ position: 'absolute', left: 0 }}>
              <BotaoFecharDelineado onClick={onClose} />
            </div>
          )}
          <Typography variant="h2" sx={{ flexGrow: 1, textAlign: 'center', px: '3rem' }}>
            {title}
          </Typography>
          <div style={{ position: 'absolute', right: 0 }}>
            {controlesHeader && (
              (isMobile || forceMenu) ? (
                <>
                  <BotaoMenu onClick={handleClick} />
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiList-root': {
                        padding: '0',
                      }
                    }}
                  >
                    {controlesHeader.map((item, index) => (
                      <MenuItem key={index} /* onClick={handleClose} */>
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Line gap=".5rem">
                  {controlesHeader.map((item, index) => <div key={index}>{item}</div>)}
                </Line>
              )
            )}
          </div>
        </Line>
      </Grid>
      <Divider sx={{ width: '100%', borderColor: '#DFDFDF' }} />
    </>
  )
}

/**
 * @deprecated - Use o componente ContainerBodyModal no arquivo `ds/DesignSystem.tsx`
 */
export const ContainerBodyModal = styled(Container)(({ theme }) => ({
  height: '75vh',
  maxWidth: '100%',
  padding: '1rem',
  width: '100%',
  '@media (min-width: 1280px)': {
    maxWidth: '100%',
  },
  '@media (min-width: 600px)': {
    padding: '1rem',
  },
  overflow: 'auto',
}))

export const GridBodyModal = styled(
  ({ size, ...props }) => (<Grid2 container direction="column" {...props} />)
)(({ theme, size }) => ({
  height: (() => {
    switch (size) {
      case 'xs':
        return '40vh';
      case 'sm':
        return '50vh';
      case 'md':
        return '70vh';
      case 'lg':
        return '80vh';
      case 'xl':
        return '90vh';
      default:
        return '70vh'; // valor padrão caso o size não seja fornecido ou seja diferente dos casos
    }
  })(),
  maxHeight: '100%',
  backgroundColor: theme.palette.background.default,
  padding: '1rem',
  alignContent: 'flex-start',
}))

/**
 * @deprecated - Use o componente SnackbarAlert no arquivo `ds/DesignSystem.tsx`
 * Snackbar com um alert Configurado
 * @component
 * @param {String} message - Texto da mensagem.
 * @param {Boolean} open - Estado que define se a mensagem estiver aberta.
 * @param {Function} setOpen - Função que define o estado da mensagem.
 * @param {Number} duration - Duração da mensagem, caso não seja informado o valor é 0.
 * @param {String} type - Tipo da mensagem (success, info, warning, error).
 * @param {Function} handleClose - Função executada ao fechar snacckbar, por padrão apenas fecha o modal.
 *
 */
export const SnackbarAlert = ({
  message = '',
  open,
  setOpen,
  duration = 0,
  type = 'success',
  handleClose = () => { setOpen(false) },  // Recebe o handleClose do hook
}) => {
  return (
    <Portal>
      {/* Garante a rederização do componente fora da árvore doom */}
      <Snackbar
        open={open}
        {...(duration !== 0 && { autoHideDuration: duration })}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Slide}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert
          onClose={handleClose}
          severity={type}
          sx={{
            width: '100%',
          }}
        >
          {typeof message === 'object' ? 'Object' : message}
        </Alert>
      </Snackbar>
    </Portal>
  );
}

/**
 * Componente que exibe 5 estrelas para avaliação.
 *
 * @param {Number} props.estrelas - Estrelas selecionadas
 * @param {Function} props.setEstrelas - Função para definir Estrelas Selecionadas
 * @param {String} props.size - Tamanho das Estrelas
 * @param {String} props.cor - Cor das Estrelas
 */
export const StarRating = ({ estrelas, setEstrelas, size = '2rem', cor = 'primary.main' }) => {
  const [estrelasTemporaria, setEstrelasTemporaria] = useState(0)

  return (
    <Container
      sx={{ display: 'flex', justifyContent: 'center', width: '100%', fontSize: size, lineHeight: size }}
    >
      {[1, 2, 3, 4, 5].map((x) => (
        <Box
          key={x}
          onClick={() => setEstrelas(x)}
          onMouseEnter={() => {
            setEstrelasTemporaria(x)
          }}
          onMouseLeave={() => {
            setEstrelasTemporaria(estrelas)
          }}
          sx={{
            cursor: 'pointer',
            borderRadius: '50%',
            boxSizing: 'border-box',
            transition: 'all .2s ease-in-out',
            '&:hover': { transform: 'scale(1.2)' }
          }}
        >
          {estrelasTemporaria < x
            ? <StarBorderRoundedIcon sx={{ fontSize: 'inherit' }} />
            : <StarRoundedIcon sx={{ fontSize: 'inherit', color: `${cor}` }} />
          }
        </Box>
      ))}
    </Container>
  )
}

/**
 * Header Geral do Painel
 *
 * @param {String} props.companyName - Nome da Companhia
 * @param {String} props.companyLogo - Src da Logo
 * @param {Array} props.menuOptions - Opções a serem listadas
 * @param {Function} props.menuActions - Funções a Serem executas pelos botões
 * @param {Function} props.abrirCriarPedido - Função para abrir o Modal de Criar Pedido
 */
const buildMenuHierarchy = (menuOptions) => {
  const menuMap = {};
  const menuHierarchy = [];

  // Criar mapa de menuItemID para facilitar a busca dos pais
  menuOptions?.forEach((option) => {
    menuMap[option.menuItemID] = { ...option, children: [] };
  });

  // Associar itens filhos aos seus pais
  menuOptions?.forEach((option) => {
    if (option.menuItemPaiID) {
      menuMap[option.menuItemPaiID]?.children.push(menuMap[option.menuItemID]);
    } else {
      menuHierarchy.push(menuMap[option.menuItemID]);
    }
  });

  return menuHierarchy;
};

const useMenuItemStyles = (paginaAtual, optionItem, theme) => ({
  backgroundColor: paginaAtual === optionItem ? theme.palette.primary.main : 'inherit',
  color: paginaAtual === optionItem ? theme.palette.primary.contrastText : theme.palette.grey[500],
  borderRadius: '5px',
  marginY: '0.3rem',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  width: '100%',
});

const MenuSubItemComponent = ({ option, handleMenuItemClick, paginaAtual, getIconComponent }) => {
  const theme = useTheme();
  const styles = useMenuItemStyles(paginaAtual, option.item, theme);

  return (
    <ListItem onClick={() => handleMenuItemClick(option)} sx={styles}>
      <ListItemIcon sx={{ color: theme.palette.text.primary, minWidth: '35px' }}>
        {getIconComponent(option.icone)}
      </ListItemIcon>
      <ListItemText primary={option.item} sx={{ padding: 0, margin: 0 }} />
    </ListItem>
  );
};

const MenuItemComponent = ({ option, handleMenuItemClick, paginaAtual, getIconComponent }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const hasChildren = option.children && option.children.length > 0;
  const styles = useMenuItemStyles(paginaAtual, option.item, theme);

  const toggleOpen = () => {
    if (hasChildren) setOpen((prevOpen) => !prevOpen);
    else handleMenuItemClick(option);
  };

  return (
    <List>
      <ListItem
        onClick={toggleOpen}
        sx={styles}
        aria-expanded={hasChildren ? open : undefined}
        aria-controls={hasChildren ? `submenu-${option.item}` : undefined}
      >
        <ListItemIcon sx={{ color: paginaAtual === option.item ? theme.palette.text.secondary : theme.palette.text.primary, minWidth: '35px' }}>
          {getIconComponent(option.icone)}
        </ListItemIcon>
        <ListItemText primary={option.item} sx={{ padding: 0, margin: 0 }} />
        {hasChildren && (open ? <Icons.FaChevronUp /> : <Icons.FaChevronDown />)}
      </ListItem>

      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
          <List component="div" disablePadding id={`submenu-${option.item}`} sx={{ pl: '1rem' }}>
            {option.children.map((childOption) => (
              <MenuSubItemComponent
                key={childOption.menuItemID}
                option={childOption}
                handleMenuItemClick={handleMenuItemClick}
                paginaAtual={paginaAtual}
                getIconComponent={getIconComponent}
              />
            ))}
          </List>
        </Collapse>
      )}
    </List>
  );
};

export const Header = forwardRef(
  ({ abrirCriarPedido, ...props }, ref) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMobile } = useResponsive()
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const { paginaAtual, selecionarPagina } = usePagina();
    const usuarioPlano = useSelector(state => state.config.usuarioPlano);
    const [mostrarModalContratar, setMostrarModalContratar] = useState(false);

    const abrirFreemium = () => setMostrarModalContratar(true)
    const [modalQrCodeOpen, setModalQrCodeOpen] = useState(false);
    const [modalSairOpen, setModalSairOpen] = useState(false);

    const empresaID = useSelector((state) => state.config?.empresaID);
    const wallet = useSelector((state) =>
      state.config?.wallet?.data ?? state.config?.masterPage?.pickSelecionada?.saldoWallet ?? 0
    ); const usuario = useSelector(state => state.login.usuario);
    const masterPage = useSelector(state => state.config?.masterPage);
    const template = useSelector(state => state.config?.template?.template);
    const empresa = useSelector(state => state.config?.masterPage?.empresa);
    const menus = useSelector(state => state.config?.masterPage?.menu);
    const configEmpresa = useSelector((state) => state.config?.masterPage?.empresa);
    const companyName = usuario?.nome || masterPage?.pickSelecionada?.nome
    const companyLogo = empresa?.logoUrl ? empresa?.logoUrl : template?.faviconUrl
    const companyImg = useSelector((state) => state.config?.template?.template?.logoUrl);
    const listaPedidos = useSelector((state) => state.config?.emAndamento?.dados?.lista);

    const menuHierarchy = useMemo(() => buildMenuHierarchy(menus), [menus]);

    const generateMenuActions = (menuOptions) => {
      return menuOptions?.map((option) => {
        const sanitizedUrl = option.url.replace(/^\/?#/, '');

        if (option.novaAba) {
          if (option.url === 'https://intercom.help/pickngo/pt-BR/collections/8592351-faq-2-0' || option.url === 'https://linktr.ee/pickngosaas') {
            return () => window.open(`${sanitizedUrl}`, "_blank");
          }
          return () => window.open(`${window.location.origin}${sanitizedUrl}`, "_blank");
        } else {
          const basePath = window.location.pathname;

          const prefix = basePath.endsWith('/') ? '' : '/';
          const fullPath = `${prefix}${sanitizedUrl.startsWith('/') ? sanitizedUrl.substring(1) : sanitizedUrl}`;

          return () => {
            navigate(fullPath)
            setTimeout(() => {
              window.HubSpotConversations?.widget.refresh();
            }, 1000);
          };
        }
      });
    };

    const menuActions = generateMenuActions(menus) || [];

    const additionalActions = [
      ...(configEmpresa?.podeUsarQrcode ? [() => { abrirQrCodeEmpresa() }] : []),
      () => { setModalSairOpen(true) }
    ];

    const completeMenuActions = [...menuActions, ...additionalActions];

    const abrirQrCodeEmpresa = () => {
      if (empresaID) {
        setModalQrCodeOpen(true);
      } else {
        alert("abrirQrCode / empresaID is null: ", empresaID);
      }
    };

    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setDrawerOpen(open);
    };

    const handleLogout = () => {
      logout();
    };

    const handleMenuItemClick = (option) => {
      if (usuarioPlano === PLANOFREEMIUM) {
        abrirFreemium();
        return;
      }
      selecionarPagina(option.item);
      const actionIndex = menus.findIndex((opt) => opt.url === option.url);
      if (actionIndex !== -1) {
        completeMenuActions[actionIndex]();
      }
      setDrawerOpen(false);
    };

    const IconsDic = {
      'fa-home': Icons.FaHome,
      'fa-bars': Icons.FaBars,
      'fa-plus-circle': Icons.FaPlusCircle,
      'fa-sun': Icons.FaSun,
      'fa-moon': Icons.FaMoon,
      'fa-chart-bar': Icons.FaChartBar,
      'fas fa-chart-bar': Icons.FaChartBar,
      'fa-motorcycle': Icons.FaMotorcycle,
      'fa-dollar-sign': Icons.FaDollarSign,
      'fa-user': Icons.FaUser,
      'fas fa-chart-pie': Icons.FaChartPie,
      'fa-address-card': Icons.FaAddressCard,
      'fa fa-headset': Icons.FaHeadset,
      'fa-comment-dots': Icons.FaCommentDots,
      'fa-money-check-alt': Icons.FaMoneyCheckAlt,
      'fas fa-gift': Icons.FaGift,
    };

    const getIconComponent = (iconName) => {
      const IconComponent = IconsDic[iconName];
      return IconComponent ? <IconComponent /> : null;
    };

    return (
      <>
        <div
          style={{ width: '100%', overflow: 'hidden' }}
          id="painelHeader"
          {...props}
          ref={ref}
        >
          <AppBar
            position="sticky"
            sx={{
              backgroundColor: theme.palette.background.default,
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              justifyContent: 'center',
              maxHeight: '52px',
              p: theme.sizes.padding.xs,
              overflowX: 'hidden',
              boxShadow: 'none',
              overflow: 'hidden',
            }}
            elevation={0}
          >
            <Toolbar
              sx={{
                justifyContent: isMobile ? 'space-between' : '',
                p: '0 0 0 .25rem',
                minHeight: '0px',
                height: '100%',
                width: '100%',
                boxSizing: 'border-box',
                '@media (min-width: 600px)': {
                  minHeight: '0px',
                },
                '@media (min-width: 600px)': {
                  minHeight: '0px',
                  px: '0',
                },
                zIndex: 10,
              }}
            >
              <IconButton
                edge="start"
                color="primary"
                size="small"
                aria-label="menu"
                onClick={toggleDrawer(!isDrawerOpen)}
                sx={{
                  mr: 2,
                  ml: 0.2,
                }}
              >
                <Icons.FaBars fontSize="medium" />
              </IconButton>
              {!isMobile && (
                <>
                  <Avatar
                    onClick={() => {
                      navigate('/Painel');
                    }}
                    alt={companyName}
                    src={companyLogo}
                    sx={{ mr: 1, cursor: 'pointer' }}
                  />
                  <Typography variant="body1" color={theme.palette.text.primary}>
                    {companyName}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    // flexItem
                    sx={{ margin: '0 1.5rem', backgroundColor: 'rgba(0, 0, 0, 0.05)', height: '52px' }}
                  />
                </>
              )}
              {(paginaAtual === 'Painel' && listaPedidos !== undefined && listaPedidos?.length > 0) && (
                <>
                  <Button
                    variant="iconeComTextoFilled"
                    onClick={abrirCriarPedido}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Novo pedido
                  </Button>
                  <Divider
                    orientation="vertical"
                    sx={{ margin: '0 1.5rem', backgroundColor: 'rgba(0, 0, 0, 0.05)', height: '52px' }} />
                </>
              )}
              {isMobile && listaPedidos?.length === 0 && (
                <Box
                  component="img"
                  src={logo}
                  sx={{
                    width: 144,
                    height: 24,
                    cursor: 'pointer',
                  }}
                />
              )}
              {(!isMobile && wallet) && (
                <>

                  <Box
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/Pick/AbastecerWallet')}
                  >
                    <Typography variant="body2" align="center" color={theme.palette.text.primary}>
                      {(wallet?.dados?.saldo ?? wallet)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <br />
                      <Typography variant="caption" color={theme.palette.grey[400]}>Na Wallet</Typography>
                    </Typography>
                  </Box>
                </>
              )}
              <div style={{ marginLeft: isMobile ? 0 : 'auto' }}>
                <IconButton
                  sx={{
                    bgcolor: 'transparent',
                    color: theme.palette.text.primary,
                  }}
                  onClick={() => dispatch(toggleColorMode())}
                >
                  {theme.palette.mode === 'dark' ? <Icons.FaSun size={20} /> : <Icons.FaMoon size={20} />}
                </IconButton>
                {!isMobile && (
                  <IconButton
                    size="small"
                    color="inherit"
                    aria-label="company logo"
                    sx={{
                      '&:hover': {
                        background: `transparent`,
                        backgroundSize: 'cover',
                        borderRadius: '5px',
                      },
                    }}
                  >
                    <img
                      src={companyImg || companyLogo}
                      alt="Company Logo"
                      style={{ height: '30px' }}
                    />
                  </IconButton>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            elevation={0}
          >
            <List
              sx={{
                height: '100%',
                maxHeight: '100%',
                overflowY: 'auto',
                alignContent: 'center',
                display: 'flex',
                // flex: '1 0 auto',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                padding: '52px 10px 10px 10px',
                boxSizing: 'border-box',
              }}
            >
              {menuHierarchy.map((option) => (
                <MenuItemComponent
                  key={option.menuItemID}
                  option={option}
                  handleMenuItemClick={handleMenuItemClick}
                  paginaAtual={paginaAtual}
                  getIconComponent={getIconComponent}
                />
              ))}
            </List>
            <Divider sx={{ width: '100%', margin: '1rem 0' }} />
            <Button
              onClick={() => setModalSairOpen(true)}
              variant="contained"
              sx={{
                mx: '1rem',
                position: 'sticky',
                bottom: 10,
                justifyContent: 'space-between',
                marginTop: '.5rem',
                width: '300px',
              }}
              startIcon={<Logout />}
            >
              Sair <span />
            </Button>
          </Drawer>
        </div>
        {(modalQrCodeOpen && empresaID) && (
          <ModalQrCode
            open={modalQrCodeOpen}
            onClose={() => setModalQrCodeOpen(false)}
            empresaID={empresaID}
          />
        )}
        <ModalContratarPlanos open={mostrarModalContratar} onClose={() => setMostrarModalContratar(!mostrarModalContratar)} />
        <ModalOpcoes
          open={modalSairOpen}
          onClose={() => setModalSairOpen(false)}
          acao={'Sair'}
          descricao={'Você deseja sair?'}
          opcoes={[
            {
              label: 'Cancelar',
              action: () => { setModalSairOpen(false) }
            },
            {
              label: 'Confirmar',
              action: () => {
                setModalSairOpen(false);
                handleLogout();
              }
            },
          ]}
        />
      </>
    );
  }
);

/**
 * Função para gerar esqueletos
 *
 * @component
 * @param {*} qnt - Quantidade de esqueletos que se repetirão
 * @param {*} type - Tipo de esqueleto (text, circular, rectangular, rounded)
 * @param {*} height - Altura do Esqueleto
 * @param {*} width - Largura do Esqueleto
 */
export const listOfSkeleton = (qnt, type = 'rounded', height = 'auto', width = '100%', ...props) => {
  return [...Array(qnt)].map((_, index) => (
    <Skeleton key={index} variant={type} width={width} height={height} animation="pulse" sx={{ marginBottom: '1rem' }}  {...props} />
  ))
}

// Função para converter "175h 9m 20s" em segundos
const convertToSeconds = (timeString) => {
  const timeParts = timeString.split(' ');
  let totalSeconds = 0;

  timeParts?.forEach(part => {
    if (part.endsWith('h')) {
      totalSeconds += parseInt(part) * 3600;
    } else if (part.endsWith('m')) {
      totalSeconds += parseInt(part) * 60;
    } else if (part.endsWith('s')) {
      totalSeconds += parseInt(part);
    }
  });

  return totalSeconds;
};

// Função para formatar segundos em "HH:MM:SS"
const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

export const TimeCounter = ({ initialTimeString, ...props }) => {
  const initialTimeInSeconds = convertToSeconds(initialTimeString);
  const [elapsedTime, setElapsedTime] = useState(initialTimeInSeconds);

  useEffect(() => {
    const startTime = Date.now() - initialTimeInSeconds;
    const intervalId = setInterval(() => {
      const secondsElapsed = (Date.now() - startTime) / 1000;
      setElapsedTime(initialTimeInSeconds + secondsElapsed);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [initialTimeInSeconds]);

  return (
    <Typography
      variant="monospaced"
      fontSize={'0.7rem'}
      fontWeight={'700'}
      whiteSpace={'nowrap'}
      width={'35%'}
      {...props}
    >
      {formatTime(elapsedTime.toFixed(0))}
    </Typography>
  );
};

/**
 * Container de carregamento
 * @returns
 */
export const LoadingContainer = styled(
  (props) => (<Container {...props}><CircularProgress /></Container>)
)(({ }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  height: '100%',
  width: '100%',
}))
