import { Box, Button, Typography, Grid } from "@mui/material";
import { BaseModal, HeaderModal } from "../../../ds";
import { dinheiro } from "../../../ds/common";
import { useDispatch, useSelector } from "react-redux";
import { despacharRequest } from "../../../store/reducers/criarPedido";

const ModalCalculos = ({ open, handleClose, payloadzin }) => {
    const pedido = useSelector(state => state.criarPedido.respostaDespacho);
    const dispatch = useDispatch();

    const handleConfirmar = () => {
        let corrigido = payloadzin;
        if (payloadzin?.orcamento === true) {
            corrigido = {
                ...payloadzin,
                orcamento: false,
            };
        }
        if (payloadzin?.cobrarPorKmTotal === false) {
            corrigido = {
                ...corrigido,
                cobrarPorKmTotal: false
            }
        }
        dispatch(despacharRequest(corrigido));
        handleClose();
    };

    return (
        <BaseModal open={open} onClose={handleClose} size="sm">
            <HeaderModal title="Confirme a rota selecionada" onClose={handleClose} />
            <Box p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                <Box mb={2} width="100%" textAlign="center">
                    {pedido?.memoriaCalculo?.map((item, index) => (
                        <Typography key={index} variant="body1">
                            {item}
                        </Typography>
                    ))}
                </Box>
                <Box mb={2} width="100%" textAlign="center">
                    <Typography variant="body1">
                        KM Total: {dinheiro(pedido?.kmTotal)}
                    </Typography>
                    <Typography variant="body1">
                        Total: R$ {dinheiro(pedido?.totalCobrado)}
                    </Typography>
                </Box>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="containedFilled" color="primary" onClick={handleConfirmar}>
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </BaseModal>
    );
};

export default ModalCalculos;
