// InputDate.tsx
import { SxProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');
interface HookForms {
  name: string;
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
}

interface InputDateProps {
  hookForm: HookForms;
  label?: string;
  sx?: SxProps;
  disabled?: boolean;
}

const InputDate = ({
  hookForm,
  sx,
  label,
  disabled
}: InputDateProps) => {
  const { name, methods, rules } = hookForm;
  const { control, formState: { errors } } = methods;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="pt-br"
    >
      <Controller
        control={control}
        name={name}
        rules={rules}
        disabled={disabled }
        render={({ field: { value: watchValue, onChange, ...field } }) => (
          <DatePicker
            {...field}
            label={label || name}
            slotProps={{
              field: { clearable: true },
              textField: {
                size: 'small',
                label: label || name,
                error: !!errors?.[name],
                helperText: errors?.[name]?.message ? String(errors?.[name]?.message) : '',
                InputLabelProps: {
                  shrink: true
                },
              },
            }}
            sx={{ width: '100%', ...sx }}
            value={watchValue ? dayjs(watchValue, 'DD/MM/YYYY') : null}
            onChange={(newValue) => {
              onChange(newValue ? newValue.format('DD/MM/YYYY') : null);
            }}
            format="DD/MM/YYYY"
            showDaysOutsideCurrentMonth
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default InputDate;
