import { Box, Typography, Tabs, Tab, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerBodyModal, ModalBase, SnackbarAlert } from 'src/ds/DesignSystem';
import { RootState } from 'src/store/reducers';
import DadosBasicos from './EditarDadosBasicos';
import EditarEmpresa from './EditarEmpresa';
import EditarTiposDeVeiculo from './EditarTiposDeVeiculo';
import EditarValoresPorRaio from './EditarValoresPorRaio';
import EditarValoresPorBairro from './EditarValoresPorBairro';
import EditarKmCalculado from './EditarKmCalculado';
import { useMultiResponseSnackbar, useResponsive, useSnackbar } from 'src/ds/CustomHooksDs';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { limparEmpresaTabela, limparSalvarTabela, limparSalvarTipoVeiculo, limparTabelaPorBairro, limparTabelaPorKmCalculado, limparTabelaPorRaio } from 'src/store/reducers/tabelaCobranca';

interface ListarMensagemProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    dados: any;
    atualizar: () => void;
}

interface TabConfig {
    label: string;
    content: React.ReactNode;
}

const TabsTabelaCobranca: React.FC<ListarMensagemProps> = () => {
    const { id } = useParams();
    const listaCobranca = useSelector((state: RootState) => state.tabelaCobranca?.listaTabelasCobranca?.data);
    const dados = listaCobranca?.dados?.lista.find((item: any) => item.tabelaCobrancaID === id);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const snack = useSnackbar({ duration: 2000 });
    const metodoCobranca = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoCobranca);
    const tabsConfig: TabConfig[] = [
        { label: 'Dados básicos', content: <DadosBasicos dados={dados} /> },
        { label: 'Empresas', content: <EditarEmpresa dados={dados} /> },
        { label: 'Tipos de veículo', content: <EditarTiposDeVeiculo dados={dados} /> },
        { label: 'Valores por raio', content: <EditarValoresPorRaio dados={dados} /> },
        { label: 'Valores por bairro', content: <EditarValoresPorBairro dados={dados} /> },
        { label: 'Valores por km calculado', content: <EditarKmCalculado dados={dados} /> }
    ];
    const filteredTabsConfig = tabsConfig.filter(tab => {
        if (metodoCobranca !== 1 && tab.label === 'Valores por km calculado') {
            return false;
        } else if (metodoCobranca !== 0 && tab.label === 'Valores por raio') {
            return false;
        } else if (metodoCobranca !== 2 && tab.label === 'Valores por bairro') {
            return false;
        }
        return true;
    });


    const response = useSelector((state: RootState) => state.tabelaCobranca?.salvarTabela);
    const response2 = useSelector((state: RootState) => state.tabelaCobranca?.adicionarEmpresaTabela);
    const response3 = useSelector((state: RootState) => state.tabelaCobranca?.salvarTipoVeiculo);
    const response4 = useSelector((state: RootState) => state.tabelaCobranca?.salvarTabelaPorRaio);
    const response5 = useSelector((state: RootState) => state.tabelaCobranca?.salvarTabelaPorBairro);
    const response6 = useSelector((state: RootState) => state.tabelaCobranca?.salvarTabelaPorKm);

    useMultiResponseSnackbar([
        { response, clearAction: () => { dispatch(limparSalvarTabela()); } },
        { response: response2, clearAction: () => { dispatch(limparEmpresaTabela()); } },
        { response: response3, clearAction: () => { dispatch(limparSalvarTipoVeiculo()); } },
        { response: response4, clearAction: () => { dispatch(limparTabelaPorRaio()); } },
        { response: response5, clearAction: () => { dispatch(limparTabelaPorBairro()); } },
        { response: response6, clearAction: () => { dispatch(limparTabelaPorKmCalculado()); } }
    ], snack)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const { isMobile } = useResponsive();
    const navigate = useNavigate();
    const theme = useTheme()
    return (
        <Box p={0} sx={{ backgroundColor: 'background.paper' }}>
            <Box p={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <ArrowBackIos
                        sx={{ cursor: 'pointer', mr: 1 }}
                        onClick={() => navigate("/TabelaCobranca/Index")}
                    />
                    <Typography variant="h6">
                        <>
                            Editar {dados?.nome} <span style={{ fontSize: '0.6em', color: theme.palette.grey[400] }}>({id})</span>
                        </>
                    </Typography>
                </Box>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Tabs de Edição"
                    variant={isMobile ? 'scrollable' : 'fullWidth'}
                    scrollButtons="auto"
                    sx={{ minHeight: '48px' }}
                >
                    {filteredTabsConfig.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            sx={{
                                minWidth: '100px',
                                maxWidth: '200px',
                                color: 'text.primary',
                                fontSize: '12px',
                                textTransform: 'none'
                            }}
                        />
                    ))}
                </Tabs>
                <Box sx={{ mt: 3 }}>
                    {filteredTabsConfig[activeTab].content}
                </Box>
                <SnackbarAlert {...snack} />
            </Box>
        </Box>
    );
};

export default TabsTabelaCobranca;
