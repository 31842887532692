import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl'
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { MAPBOX_API_KEY } from 'src/utils';

const MapRastreio = ({ positionEmpresa, positionCliente, positionEntregador, etapaAtual }) => {
    const theme = useTheme();
    const markerCliente = useRef(null)
    const markerEmpresa = useRef(null)
    const markerEntregador = useRef(null)
    const mapPedido = useRef(null)
    const defaultPosition = { lat: -15.7801, lng: -47.9292 }
    const { pedido, entregador, empresa } = useSelector(state => ({
        pedido: state.rastreio.pedido.data,
        entregador: state.rastreio.entregadores.data,
        empresa: state.rastreio.empresa.data
    }))
    
    useEffect(() => {
        mapboxgl.accessToken = MAPBOX_API_KEY;
        const map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/pickngo-demo/clabx63al002z14qpcdwwsq40', // style URL
            center: [defaultPosition.lng, defaultPosition.lat],
            zoom: 14,
            pitch: 0,
            maxPitch: 45,
            maxZoom: 16,
            attributionControl: false, 
        });

        mapPedido.current = map;
    }, [])

    const popupOffsets = useMemo(() => ({
        'top': [0, 0],
        'top-left': [0, 0],
        'top-right': [0, 0],
        'bottom': [0, -50],
        'bottom-left': [25, -35],
        'bottom-right': [-25, -35],
        'left': [10, -40],
        'right': [-10, -40]
    }))

    const createPopupContent = useCallback((content) => `
        <div style="text-align: left; min-width: 100px">
            ${content}
        </div>
    `, []);

    const addMarkerWithPopup = useCallback((markerRef, position, color, content, isCustomMarker = false) => {
        if (!position) return
        if (markerRef.current && mapPedido.current._markers.includes(markerRef.current)) {
            if (isCustomMarker) {
                markerRef.current.setLngLat([position.lng, position.lat]);
            } else {
                const markerElement = markerRef.current.getElement();
                const currentColor = markerElement.style.backgroundColor;
                if (currentColor !== color) {
                    markerRef.current.remove();
                    const marker = new mapboxgl.Marker({ color, anchor: 'bottom', offset: [0, -20] })
                        .setLngLat([position.lng, position.lat])
                        .addTo(mapPedido.current);

                    const popup = new mapboxgl.Popup({ offset: popupOffsets, className: 'my-class' })
                        .setHTML(createPopupContent(content))
                        .setMaxWidth("300px")

                    marker.setPopup(popup);
                    marker.getElement().addEventListener('click', () => marker.togglePopup());
                    markerRef.current = marker;
                } else {
                    markerRef.current.setLngLat([position.lng, position.lat]);
                    const popup = markerRef.current.getPopup();
                    popup.setHTML(createPopupContent(content));
                    markerRef.current.setPopup(popup);
                }
            }
        } else {
            let marker;
            if (isCustomMarker) {
                const markerElement = document.createElement('div');
                markerElement.innerHTML = `
                    <img src="https://i.ibb.co/52nBWF0/motoca.png" alt="motoca" border="0" style="width: 40px; height: 40px;">
                `;
                marker = new mapboxgl.Marker({ element: markerElement, anchor: 'bottom', offset: [0, -20] })
                    .setLngLat([position.lng, position.lat])
                    .addTo(mapPedido.current);
            } else {
                marker = new mapboxgl.Marker({ color, anchor: 'bottom', offset: [0, -20] })
                    .setLngLat([position.lng, position.lat])
                    .addTo(mapPedido.current);
            }

            const popup = new mapboxgl.Popup({ offset: popupOffsets, className: 'my-class' })
                .setHTML(createPopupContent(content))
                .setMaxWidth("300px")
            marker.setPopup(popup);
            marker.getElement().addEventListener('click', () => marker.togglePopup());
            markerRef.current = marker
        }
    }, []);

    useEffect(() => {
        if(positionCliente){
            if (!markerCliente.current ) {
                mapPedido.current.setCenter([positionCliente.lng, positionCliente.lat])
            }
            const content = `
            <h3 style="margin: 0;">#${pedido.dados?.lista[0]?.codigoQuatro}</h3>
                <p>${pedido.dados.lista[0]?.entregaEnderecoLogradouro}</p>
                <p style="background-color: ${theme.palette.status[etapaAtual.situacao]}; color: white; text-align: center; margin: 0;">
                    ${etapaAtual.situacao}
                </p>
            `;
            addMarkerWithPopup(markerCliente, positionCliente, theme.palette.status[etapaAtual.situacao], content);
        } 
    }, [positionCliente, etapaAtual])

    useEffect(() => {
        const content = `
            <h3 style="margin: 0;">${entregador?.dados?.lista[0]?.nome}</h3>
        `;
        addMarkerWithPopup(markerEntregador, positionEntregador, 'pink', content, true);
        if(!positionCliente){
            markerEntregador.current?.remove();
        }
    }, [positionEntregador]);

    useEffect(() => {
        const content = `
            <h3 style="margin: 0;">${empresa?.dados?.lista[0]?.nome}</h3>
            <p>${empresa?.dados?.lista[0]?.tipoEmpresa?.nome}</p>
            <p style="margin: 0;">${empresa?.dados?.lista[0]?.enderecoStr}</p>
        `;
        addMarkerWithPopup(markerEmpresa, positionEmpresa, theme.palette.primary.main, content);
        if(!positionCliente){
            markerEmpresa.current?.remove();
        }
    }, [positionEmpresa]);

    return (
        <div id="map" style={{ position: 'absolute', top: 0, bottom: 0, width: '100%', zIndex: 1 }} />
    )
}

export default MapRastreio
