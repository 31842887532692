import { useEffect, useRef } from 'react';
import { UseSnackbarReturn } from './UseSnackbar';

const useMultiResponseSnackbar = (
    responses: Array<{ response: any, clearAction: () => void }>,
    snack: UseSnackbarReturn
) => {
    const processedResponses = useRef<Set<any>>(new Set());

    useEffect(() => {
        responses.forEach(({ response, clearAction }) => {
            if (!response || processedResponses.current.has(response)) return;

            if (response?.data) {
                snack.showSuccess(response.data.mensagem);
            } else if (response?.error) {
                snack.showError(response.error);
            }

            processedResponses.current.add(response);

            setTimeout(() => {
                clearAction();
            }, 1000);
        });
    }, [responses, snack]);
};

export default useMultiResponseSnackbar;
