import { Box, Card, Typography } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/Forms";
import { useForm } from "react-hook-form";
import TabelaEmpresas from "../../components/Empresas/TabelaEmpresas";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { TableNavigation } from "src/ds/DesignSystem";

const Empresas = () => {
    const empresasDados = useSelector((state: any) => state.empresas.listaEmpresas?.data);
    const empresas = empresasDados?.lista;
    const loadingEmpresas = useSelector((state: any) => state.empresas.listaEmpresas.loading);

    const hookForm = useForm({
        defaultValues: {
            nomeEmpresa: '',
            codigoIdentificador: '',
            dataCadastroInicial: '',
            dataCadastroFinal: '',
            cnpj: '',
            ativo: true,
            paginacao: 20,
            pagina: 1,
        },
    });

    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true)
    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao) {
            cardRef.current?.onSubmit()
            setPrimeiraRenderizacao(false);
            return
        }

        const subscription = watch((_value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, primeiraRenderizacao]);

    return (
        <Box>
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    dispatchMethods={{ actionName: 'listaEmpresasStart' }}
                    formMethods={hookForm}
                    fields={[
                        { name: "Buscar", value: "nome", type: "input" },
                        { name: "Tags", value: "tagsFiltro" },
                    ]}
                    loading={loadingEmpresas}
                />
            </Box>

            <Card elevation={1} sx={{ m: 2, borderRadius: 2, }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">Empresas</Typography>
                </Box>
                {empresasDados?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={empresasDados.indiceInicial}
                        indiceFinal={empresasDados.indiceFinal}
                        indiceTotal={empresasDados.total}
                        ultimaPagina={empresasDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <TabelaEmpresas empresas={empresas} loading={loadingEmpresas} fezRequisicao={cardRef.current?.fezRequisicao} />
            </Card>
        </Box>
    );
};

export default Empresas;
