// @ts-nocheck
import {
    Box,
    Card,
    Stack,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import timer from "../../assets/icons/time.svg";
import timerBlack from "../../assets/icons/time-black.svg";
import sackDollar from "../../assets/icons/sack-dollar.svg";
import motorcycle from "../../assets/icons/motorcycle.svg";
import TabelaPedidos from "../../components/Pedidos/TabelaPedidos";
import DashboardPedidos from "../../components/Pedidos/DashboardPedidos";
import dayjs from "dayjs";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/Forms";
import { RootState } from "../../store/reducers";
import { dinheiro } from "../../ds/common";
import { listarVeiculosStart } from "src/store/reducers/escalas";
import ExportarTabela from "src/ds/components/ExportarTabela";
import { CardTabelaTotal, TableNavigation } from "src/ds/DesignSystem";
import { listarTiposEmpresas } from "src/store/reducers/empresa";
import { listarTipoDeVeiculoStart } from "src/store/reducers/config";
import { listaEntregadoresStart } from "src/store/reducers/entregador";
import { taxaExtraStart } from "src/store/reducers/taxaExtra";
import { useForm } from "react-hook-form";
import TabelaPerformance from "src/components/PerformancePedidos/TabelaPerformance";
import DashboardPerformance from "src/components/PerformancePedidos/DashboardPerformance";
import { formatTime } from "src/utils";

const PerformancePedido = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const hookForm = useForm({
        defaultValues: {
            empresaID: '',
            filtroCodigo: '',
            distanciaInicial: '',
            distanciaFinal: '',
            dtInicial: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
            filtroNome: '',
            dtFinal: '',
            faixaKm: '',
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        }
    });

    const { register, handleSubmit, watch, formState: { errors }, setValue, getValues, control } = hookForm;
    const entregadores = useSelector((state: RootState) => state.entregadores.listaEntregadores?.data?.dados?.lista);
    const tiposVeiculo = useSelector((state: RootState) => state.config?.tiposDeVeiculos?.dados?.lista);
    const tipoEmpresas = useSelector((state: RootState) => state.empresas.tiposEmpresas?.data?.lista);
    const taxasExtra = useSelector((state: RootState) => state.taxaExtra?.lista?.data?.dados?.lista);
    const formasPagamento = useSelector((state: RootState) => state.config?.formaDePagamento);
    const pedidosDados = useSelector((state: RootState) => state.pedidos.performancePedidos?.data?.dados);
    const loadingPedidos = useSelector((state: RootState) => state.pedidos.performancePedidos.loading);
    const loadingExportar = useSelector((state: RootState) => state.pedidos.exportarPerformancePedidos.loading);
    const exportarPerformancePedido = useSelector((state: RootState) => state.pedidos.exportarPerformancePedido);

    const [activeSection, setActiveSection] = useState('pedidos');

    const handleSectionChange = (section) => {
        if (section === null) return;
        setActiveSection(section);
    }

    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true)
    const [atualizar, setAtualizar] = useState(false)
    const cardRef = useRef<CardFormFiltrosRef>(null)

    useEffect(() => {
        if (primeiraRenderizacao) { setPrimeiraRenderizacao(false); return }
        const subscription = watch((_value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, primeiraRenderizacao]);

    const atualizarDashboard = cardRef.current?.atualizarDispatch

    const formFields = [
        { name: 'Empresa', value: 'empresaID', type: 'autocompleteEmpresa' },
        { name: 'Data inicial', value: 'dtInicial', type: 'dateTime', rules: { required: 'Data inicial é obrigatória' } },
        { name: 'Data final', value: 'dtFinal', type: 'dateTime', rules: { required: 'Data final é obrigatória' } },
        { name: 'Código', value: 'filtroCodigo' },
        { name: 'Entregador', value: 'filtroNome' },
        {
            name: 'Quilometragem', value: 'faixaKm', type: 'customSelect', options: [
                { label: 'Todas', value: 'todos' },
                { label: 'Faixa de 1Km', value: '1' },
                { label: 'Faixa de 2Km', value: '2' },
                { label: 'Faixa de 3Km', value: '3' },
                { label: 'Faixa de 4Km', value: '4' },
                { label: 'Faixa de 5Km', value: '5' },
                { label: 'Faixa de 6Km', value: '6' },
                { label: 'Faixa de 7Km', value: '7' },
                { label: 'Faixa de 8Km', value: '8' },
                { label: 'Faixa de 9Km', value: '9' },
                { label: 'Faixa de 10Km ou mais', value: '10' }
            ],
            valueKey: 'faixaKm',
            labelKey: 'label',
        }
    ]

    return (
        <Box sx={{ p: 2, backgroundColor: theme.palette.background.paperDark }}>
            <CardFormFiltros
                ref={cardRef}
                fields={formFields}
                formMethods={hookForm}
                dispatchMethods={{ actionName: 'listarPerformancePedidosStart' }}
                loading={loadingPedidos}
            />
            <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap' }} useFlexGap direction="row">
                <CardTabelaTotal
                    srcIcon={timerBlack}
                    altIcon="Ícone Tempo Médio do Pedido Pronto"
                    bgcolor={theme.palette.grey[600]}
                    texts={{
                        title1: 'Tempo Médio do Pedido Pronto',
                        subtitle1: pedidosDados?.mediaTempoPedidoPronto ? formatTime(pedidosDados?.mediaTempoPedidoPronto) : '0'
                    }}
                />
                <CardTabelaTotal
                    srcIcon={timer}
                    altIcon="Ícone Tempo Médio do Despacho"
                    bgcolor={theme.palette.blue[600]}
                    texts={{
                        title1: 'Tempo Médio do Despacho',
                        subtitle1: pedidosDados?.tempoMedioDespacho ? formatTime(pedidosDados?.tempoMedioDespacho) : '0',
                    }}
                />
                <CardTabelaTotal
                    srcIcon={timer}
                    altIcon="Tempo Médio Rota do Entregador"
                    bgcolor={theme.palette.yellow[600]}
                    texts={{
                        title1: 'Tempo Médio Rota do Entregador',
                        subtitle1: pedidosDados?.mediaTempoRotaEntregador ? formatTime(pedidosDados?.mediaTempoRotaEntregador) : '0',
                    }}
                />
                <CardTabelaTotal
                    srcIcon={timer}
                    altIcon="Ícone Porcentagem Entregas Dentro da Estimativa (ETA)"
                    bgcolor={theme.palette.green[600]}
                    texts={{
                        title1: 'Porcentagem Entregas Dentro da Estimativa (ETA)',
                        subtitle1: pedidosDados?.porcentagemNegativos ? `${pedidosDados?.porcentagemNegativos.toFixed(2)} %` : '0%',
                    }}
                />
            </Stack>
            <Stack sx={{ mt: 4, gap: 2, flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }} direction="row">
                <Tabs
                    value={activeSection}
                    onChange={(_, value) => handleSectionChange(value)}
                    textColor="inherit"
                    variant="fullWidth"
                >
                    <Tab label="Pedidos" value="pedidos" />
                    <Tab label="Dashboard" value="dashboard" />
                </Tabs>
            </Stack>

            <Card elevation={1} sx={{ mt: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">PEDIDOS</Typography>
                    <ExportarTabela dispatchMethods={{ actionName: 'exportarPerformancePedidosStart' }} loading={loadingExportar} />
                </Box>
                {pedidosDados?.ultimaPagina > 0 && activeSection === 'pedidos' && (
                    <TableNavigation
                        indiceInicial={pedidosDados?.indiceInicial}
                        indiceFinal={pedidosDados?.indiceFinal}
                        indiceTotal={pedidosDados?.total}
                        ultimaPagina={pedidosDados?.ultimaPagina}
                        hookForm={hookForm}
                        podeTodos={false}
                    />
                )}
                {activeSection === 'pedidos'
                    ? <TabelaPerformance pedidos={pedidosDados?.lista} loading={loadingPedidos} fezRequisicao={cardRef.current?.fezRequisicao} />
                    : <DashboardPerformance formMethods={hookForm} loading={loadingPedidos} atualizar={atualizarDashboard} fezRequisicao={cardRef.current?.fezRequisicao} />}
            </Card>
        </Box>
    );
};

const stylesForTypography = {
    flex: '2 0 250px'
}

export default PerformancePedido;
