import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const BoxScroll = styled(Box)(({ }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
    height: '100%',
    width: '100%',
}));