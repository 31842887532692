import { AddCircleOutline, Delete, Edit } from "@mui/icons-material";
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Autocomplete, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMultiResponseSnackbar, useSnackbar } from "src/ds/CustomHooksDs";
import { ModalBase, ModalOpcoes, SnackbarAlert, TableNavigation, TableRowLoading, TableRowNotFound } from "src/ds/DesignSystem";
import { listaEmpresasStart } from "src/store/reducers/empresa";
import { adicionarEmpresaTabelaStart, deletarEmpresaTabelaStart, limparEmpresaTabela, listarEmpresaTabelaPagamentoStart } from "src/store/reducers/tabelaPagamento";

const EditarEmpresa = ({ dados }: any) => {
    const dispatch = useDispatch();
    const [showSelector, setShowSelector] = useState(false);
    const [selectedEmpresa, setSelectedEmpresa] = useState({ nome: "Selecione ou digite" });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [openDeletar, setOpenDeletar] = useState(false);

    const hookForm = useForm({
        defaultValues: {
            paginacao: 20,
            pagina: 1,
            filtros: 'tabelaValorID.toString() = ' + `"` + dados?.tabelaValorID + `"`,
        },
    });

    const empresas = useSelector((state: any) => state.tabelaPagamento.empresaTabelaPagamento.data?.dados);
    const loading = useSelector((state: any) => state.tabelaPagamento.empresaTabelaPagamento.loading);
    const empresasDados = empresas?.lista;
    const response = useSelector((state: any) => state.tabelaPagamento.adicionarEmpresaTabela);
    const response2 = useSelector((state: any) => state.tabelaPagamento.deletarEmpresaTabela);
    const snack = useSnackbar({ duration: 2000 });

    useMultiResponseSnackbar([
        { response, clearAction: () => { dispatch(limparEmpresaTabela()); } },
        { response: response2, clearAction: () => { dispatch(limparEmpresaTabela()); } },
    ], snack)

    const empresasSelector = useSelector((state: any) => state.empresas.listaEmpresas?.data?.lista);
    const loadedRef = useRef({ empresasTabela: false, empresas: false });

    const listarEmpresasTabela = () => {
        const valores = hookForm.getValues();
        dispatch(listarEmpresaTabelaPagamentoStart({
            pagina: valores.pagina,
            paginacao: valores.paginacao,
            filtros: valores.filtros,
        }));
    };

    useEffect(() => {
        listarEmpresasTabela();
        loadedRef.current.empresasTabela = true;
    }, [response?.data, response2?.data, dados?.tabelaValorID,]);


    useEffect(() => {
        if (showSelector) {
            dispatch(listaEmpresasStart({ pagina: 1, paginacao: -1 }));
        }
    }, [showSelector]);

    const handleSaveEmpresa = () => {
        // @ts-ignore
        dispatch(adicionarEmpresaTabelaStart({ empresaID: selectedEmpresa.empresaID, tabelaValorID: dados?.tabelaValorID }));
        setShowSelector(false);
    };

    const handleDeletar = (item: any) => {
        setSelectedItem(item);
        setOpenDeletar(true);
    }

    useEffect(() => {
        const valores = hookForm.getValues();
        dispatch(listarEmpresaTabelaPagamentoStart({
            pagina: valores.pagina,
            paginacao: valores.paginacao,
            filtros: valores.filtros,
        }));
    }, [hookForm.watch('pagina'), hookForm.watch('paginacao'), hookForm.watch('filtros')]);
    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="containedFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={() => setShowSelector(true)}
                startIcon={<AddCircleOutline />}
            >
                Adicionar nova empresa
            </Button>

            {showSelector && (
                <ModalBase open={showSelector} onClose={() => setShowSelector(false)} headerModal={{ title: 'Adicionar nova empresa' }} size="sm">
                    <Stack spacing={2} p={2} >
                        <Autocomplete
                            options={empresasSelector || []}
                            getOptionLabel={(option: any) => option.nome}
                            value={selectedEmpresa}
                            //@ts-ignore
                            onChange={(event, newValue) => setSelectedEmpresa(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Selecione uma empresa"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                        />

                        <Button
                            variant="containedFilled"
                            sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                            onClick={handleSaveEmpresa}
                        >
                            Salvar
                        </Button>
                    </Stack>
                </ModalBase>
            )}

            <Stack sx={{ flex: 1, gap: 2 }}>
                {empresas?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={empresas.indiceInicial}
                        indiceFinal={empresas.indiceFinal}
                        indiceTotal={empresas.total}
                        ultimaPagina={empresas?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowLoading />
                        ) : empresasDados?.length > 0 ? (
                            empresasDados.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell align="left">{item.empresa?.nome}</TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label="edit" size="small">
                                            <Edit color="warning" />
                                        </IconButton>
                                        <IconButton aria-label="delete" size="small">
                                            <Delete color="error" onClick={() => { handleDeletar(item) }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                        )}
                    </TableBody>
                </Table>
            </Stack>

            <ModalOpcoes
                descricao={(
                    <Typography>
                        Tem certeza que deseja excluir <span style={{ fontWeight: 'bold' }}>{selectedItem?.empresa?.nome}</span> da tabela?
                    </Typography>
                )}
                acao="Excluir"
                open={openDeletar}
                onClose={() => { setOpenDeletar(false) }}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(deletarEmpresaTabelaStart(selectedItem.tabelaValorEmpresaID))
                            setOpenDeletar(false)
                        }
                    }
                ]}
            />
            <SnackbarAlert {...snack} />
        </Stack>
    );
};

export default EditarEmpresa;
