import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalBase, { ModalBaseProps } from 'src/ds/components/ModalBase';
import { BoxLoading, ContainerBodyModal } from 'src/ds/DesignSystem';
import { Bots } from 'src/dtos/BotsDTO';
import { RootState } from 'src/store/reducers';
import { botQrCodeStart } from 'src/store/reducers/bots';
import { limparComprarCreditos } from 'src/store/reducers/wallet';

interface QrCodeProps {
    modalProps: ModalBaseProps;
    qrCode: string | undefined
    loading: boolean
    atualizar: () => void
    copiaECola: string
}

const QrCode = ({ modalProps, qrCode, loading, atualizar, copiaECola }: QrCodeProps) => {
    const dispatch = useDispatch()



    return (
        <ModalBase {...modalProps} headerModal={{ title: 'QRCode PIX' }}>
            <ContainerBodyModal >
                <BoxLoading loading={loading} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
                    {loading
                        ? null
                        : qrCode === null
                            ? <Typography variant='notFound'>Nenhum QrCode encontrado</Typography>
                            : <Box
                                component={'img'}
                                sx={{
                                    maxHeight: '90%',
                                    height: 'auto',
                                    width: 'auto',
                                    aspectRatio: '1 / 1',
                                    objectFit: 'contain'
                                }}
                                src={qrCode ? `${qrCode}` : ''}
                                style={{ height: '100%', width: '100%' }}
                                alt='Qr Code'
                            />
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', gap: '1rem', mt: 2 }}>
                        <Button
                            onClick={() => navigator.clipboard.writeText(copiaECola)}
                            variant="containedFilled"
                            sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                            color='secondary'
                        >
                            Copiar código
                        </Button>
                        <Button
                            onClick={() => atualizar()}
                            variant="containedFilled"
                            sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                        >
                            Atualizar pagamento
                        </Button>
                    </Box>
                </BoxLoading>
            </ContainerBodyModal>
        </ModalBase>
    )
}

export default QrCode