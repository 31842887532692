import { useState, useEffect } from "react";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import { CardPedidoContent } from "../../../ds";
import { formatarCEP } from "../../../ds/common";
import { MapWithMarker } from "src/ds/DesignSystem";
import { AutocompleteMap } from "src/ds/components/AutocompleteMap";

const EnderecoDeColeta = ({
    position, setPosition,
    register, watch, setValue
}) => {
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [autocompleteService, setAutocompleteService] = useState(null);

    useEffect(() => {
        if (position?.latitude && position?.longitude) {
            setValue('origemEndereco.latitude', position.latitude);
            setValue('origemEndereco.longitude', position.longitude);
        }
    }, [position]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    console.error("Erro ao obter a localização:", error);
                }
            );
        } else {
            console.error("Geolocalização não é suportada neste navegador.");
        }
    }, []);

    useEffect(() => {
        if (autocompleteService) {
            setValue('origemEndereco.CEP', autocompleteService?.cep || '');
            setValue('origemEndereco.complemento', autocompleteService?.complemento || '');
            setValue('origemEndereco.logradouro', autocompleteService?.logradouro || '');
            setValue('origemEndereco.bairro', autocompleteService?.bairro || '');
        }
    }, [autocompleteService])

    return (
        <CardPedidoContent>
            <Typography variant="body1">Endereço de Coleta</Typography>
            <div style={{ marginTop: '1rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AutocompleteMap
                            size='small'
                            setPosition={setPosition}
                            setFormState={setAutocompleteService}
                            isModelLoaded={isModelLoaded}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Stack direction={'row'} spacing={2} flexWrap={'wrap'} useFlexGap>
                            <TextField
                                label="CEP"
                                size='small'
                                variant="outlined"
                                {...register('origemEndereco.CEP')}
                                value={formatarCEP(watch('origemEndereco.CEP'))}
                                sx={{ flex: '1 1 150px' }}
                                InputLabelProps={{ shrink: (watch('origemEndereco.CEP') || '').trim() !== '' }}
                                inputProps={{ maxLength: 8 }}
                            />
                            <TextField
                                label="Complemento"
                                size='small'
                                variant="outlined"
                                {...register('origemEndereco.complemento')}
                                sx={{ flex: '1 1 200px' }}
                                InputLabelProps={{ shrink: (watch('origemEndereco.complemento') || '').trim() !== '' }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Ponto de referência"
                            size='small'
                            variant="outlined"
                            {...register('origemEndereco.pontoReferencia')}
                            fullWidth
                            InputLabelProps={{ shrink: (watch('origemEndereco.pontoReferencia') || '').trim() !== '' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Bairro"
                            size='small'
                            variant="outlined"
                            {...register('origemEndereco.bairro')}
                            fullWidth
                            InputLabelProps={{ shrink: (watch('origemEndereco.bairro') || '').trim() !== '' }}
                        />
                    </Grid>
                </Grid>
            </div>
            <Typography variant="body1" mt={2}>Coordenadas</Typography>
            <Grid container spacing={0} style={{ marginTop: '1rem' }}>
                <MapWithMarker position={position} setPosition={setPosition} />
            </Grid>
        </CardPedidoContent>
    );
};

export default EnderecoDeColeta;
