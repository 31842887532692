import { useEffect, useRef, useState } from 'react'
import { CardFormFiltros, CardFormFiltrosRef } from '../../ds/Forms'
import { Box, Button, Card, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducers'
import TabelaTaxaExtra from '../../components/TaxaExtra/TabelaTaxaExtra'
import { TableNavigation } from 'src/ds/DesignSystem'
import { useNavigate } from 'react-router-dom'

const TaxaExtra = () => {
    const taxaExtra = useSelector((state: RootState) => state.taxaExtra.lista)

    const hookForm = useForm({
        defaultValues: {
            paginacao: 20,
            pagina: 1,
        }
    })

    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true)
    const [openCadastro, setOpenCadastro] = useState(false)
    const cardRef = useRef<CardFormFiltrosRef>(null)
    const { watch } = hookForm
    useEffect(() => {
        if (primeiraRenderizacao) {
            cardRef.current?.onSubmit();
            setPrimeiraRenderizacao(false);
            return
        }

        const subscription = watch((_value, { name }) => {
            if (name === 'pagina' || name === 'paginacao') {
                cardRef.current?.onSubmit();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, primeiraRenderizacao]);
    
    const navigate = useNavigate();

    return (
        <Box >
            <Box sx={{ m: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    fields={[
                        { name: 'nome', value: 'nome', type: 'input', label: 'Busca Por Nome', isFiltro: true },
                    ]}

                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'taxaExtraStart',
                    }}
                    loading={taxaExtra.loading}
                />
            </Box>
            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">Taxa extra</Typography>
                    <Button variant="containedFilled" sx={{ width: "5%", height: "30px" }} onClick={() => navigate("/TaxaExtra/Index/Cadastro")}>
                        INSERIR
                    </Button>
                </Box>
                {taxaExtra.data?.dados &&
                    <TableNavigation
                        indiceInicial={taxaExtra.data.dados.indiceInicial}
                        indiceFinal={taxaExtra.data.dados.indiceFinal}
                        indiceTotal={taxaExtra.data.dados.total}
                        ultimaPagina={taxaExtra.data.dados.ultimaPagina}
                        hookForm={hookForm}
                    />
                }
                <TabelaTaxaExtra data={taxaExtra.data?.dados?.lista} loading={taxaExtra.loading} fezRequisicao={cardRef.current?.fezRequisicao} atualizar={cardRef.current?.onSubmit} />
            </Card>
        </Box>
    )
}

export default TaxaExtra