import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { Theme } from '@mui/material';

/**
 * Propriedades aceitas pelo componente `BotaoSituacao`.
 */
interface BotaoSituacaoProps {
  /**
   * Situação que define a cor do botão.
   */
  situacao: keyof Theme['palette']['status'];
}

/**
 * Componente que exibe um botão que altera a cor com base em uma situação.
 * @component
 * @param {BotaoSituacaoProps} props Propriedades do componente, incluindo a situação que define a cor do botão.
 */
export const BotaoSituacao = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'situacao',
})<BotaoSituacaoProps>(({ theme, situacao }) => ({
  backgroundColor: theme.palette.status[situacao],
  color: theme.palette.getContrastText(theme.palette.status[situacao]),
  maxHeight: '1rem',
  padding: '0.2rem 0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '20%',
  minWidth: '20%',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
  textAlign: 'center',
  position: 'relative',
  //@ts-expect-error
  fontSize: theme.sizes.fontSize.xs,
  '&:hover': {
    backgroundColor: theme.palette.status[situacao],
  },
  '& .MuiButton-label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));
