import React, { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

const PedidoContext = createContext();

export function usePedido() {
    return useContext(PedidoContext);
}

export function PedidoProvider({ children }) {
    const loading1 = useSelector(state => state.criarPedido.loading)
    const error1 = useSelector(state => state.criarPedido.error)
    const resposta1 = useSelector(state => state.criarPedido.resposta)
    const [pedidoAtual, setPedidoAtual] = useState(null);
    const [rotaAtual, setRotaAtual] = useState(null);
    const [loading, setLoading] = useState(loading1);
    const [resposta, setResposta] = useState(resposta1);
    const [error, setError] = useState(error1);
    const [snackBar, setSnackBar] = useState(false);
    const [markerSelecionado, setMarkerSelecionado] = useState(null);
    const [markersSelecionados, setMarkersSelecionados] = useState([]);
    const [cardPedidoSelecionado, setCardPedidoSelecionado] = useState(null);
    const [roteando, setIsRouting] = useState(false);


    useEffect(() => {
        setLoading(loading1);
        setResposta(resposta1);
        setError(error1);
        setSnackBar(true);
    }, [loading1, resposta1, error1]);

    const selecionarPedido = (pedido) => {
        setPedidoAtual(pedido);
        setRotaAtual(null);
    };

    const selecionarRota = (rota) => {
        setRotaAtual(rota);
        setPedidoAtual(null);
    };

    const selecionarMarker = (pedidoID) => {
        setMarkerSelecionado(pedidoID);
    };
    
    const selecionarMarkers = (pedidoID) => {
        setMarkersSelecionados(prevMarkers => {
            // Verifica se o pedidoID já está na lista para evitar duplicatas
            if (!prevMarkers.includes(pedidoID)) {
                return [...prevMarkers, pedidoID]; // Adiciona se não estiver na lista
            }
            return prevMarkers; // Retorna a lista atual se o ID já estiver incluído
        });
    };

    const iniciarRota = () => {
        setIsRouting(true);
    };

    const pararRota = () => {
        setIsRouting(false);
    };

    const selecionarCardPedido = (pedido) => {
        setCardPedidoSelecionado(pedido);
    };

    return (
        <PedidoContext.Provider value={{
            pedidoAtual,
            selecionarPedido,
            rotaAtual,
            selecionarRota,
            loading,
            resposta,
            error,
            snackBar,
            setSnackBar,
            selecionarMarker,
            selecionarMarkers,
            markerSelecionado,
            selecionarCardPedido,
            cardPedidoSelecionado,
            iniciarRota,
            pararRota,
            roteando
        }}>
            {children}
        </PedidoContext.Provider>
    );
}
