import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "../../services/api";
import {
  taxaExtraStart,
  taxaExtraSuccess,
  taxaExtraFailure,
  alterarSituacaoStart,
  alterarSituacaoSuccess,
  alterarSituacaoFailure,
  salvarTaxaExtraStart,
  salvarTaxaExtraSuccess,
  salvarTaxaExtraFailure,
  excluirTaxaExtraSuccess,
  excluirTaxaExtraFailure,
  excluirTaxaExtraStart,
  listarTiposDeVeiculoTaxaExtraStart,
  listarTiposDeVeiculoTaxaExtraSuccess,
  listarTiposDeVeiculoTaxaExtraFailure,
  salvarTipoDeVeiculoTaxaExtraSuccess,
  salvarTipoDeVeiculoTaxaExtraFailure,
  salvarTipoDeVeiculoTaxaExtraStart,
  excluirTipoDeVeiculoSuccess,
  excluirTipoDeVeiculoFailure,
  excluirTipoDeVeiculoStart,
  listarPorDiaDaSemanaStart,
  listarPorDiaDaSemanaSuccess,
  listarPorDiaDaSemanaFailure,
  salvarPorDiaDaSemanaStart,
  salvarPorDiaDaSemanaSuccess,
  salvarPorDiaDaSemanaFailure,
  excluirPorDiaDaSemanaStart,
  excluirPorDiaDaSemanaSuccess,
  excluirPorDiaDaSemanaFailure,
  salvarAgendamentoPorDataSuccess,
  salvarAgendamentoPorDataFailure,
  salvarAgendamentoPorDataStart,
} from "../reducers/taxaExtra";
import { AlterarSituacaoDTO, TaxaExtraDTO } from "../../dtos/TaxaExtraDTO";
import { ApiResponse, DefaultDTO } from "../../dtos/DefaultDTO";

interface payloadTaxaExtra {
  paginacao: number;
  pagina: number;
  ordenacaoCampo: string;
  ordenacaoOrdem: string;
  filtros: string;
}

interface payloadAlterarSituacao {
  taxaExtraID: string;
  ativo: boolean;
}
// titulos:
// variaveis:
// formato:

function* taxaExtra({ payload }: { payload: payloadTaxaExtra }) {
  const form = new FormData();

  const typedPayload: Record<string, any> = payload;

  Object.keys(typedPayload).forEach((key) => {
    form.append(key, String(typedPayload[key])); 
  });

  try {
    const response: ApiResponse<TaxaExtraDTO> = yield call(
      api.post,
      "/TaxaExtra/Listar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(taxaExtraSuccess(response.data));
    } else {
      yield put(taxaExtraFailure((response.data.mensagem)));
    }
  } catch (error) {
    yield put(taxaExtraFailure(error));
  }
}

function* alterarSituacao({ payload }: { payload: payloadAlterarSituacao }) {
  try {
    const response: ApiResponse<AlterarSituacaoDTO> = yield call(
      api.post,
      "/TaxaExtra/AlterarSituacao",
      payload
    );
    if (response.data.sucesso === true) {
      yield put(alterarSituacaoSuccess(response.data));
    } else {
      yield put(alterarSituacaoFailure((response.data.mensagem)));
    }
  } catch (error) {
    yield put(alterarSituacaoFailure(error));
  }
}

function* salvarTaxaExtra({ payload }: { payload: any }) {
  const form = new FormData();

  Object.keys(payload).forEach((key) => {
    form.append(key, payload[key]);
  });

  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtra/Salvar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(salvarTaxaExtraSuccess(response.data));
    } else {
      yield put(salvarTaxaExtraFailure((response.data.mensagem)));
    }
  } catch (error) {
    yield put(salvarTaxaExtraFailure(error));

  }
}

function* excluirTaxaExtra({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtra/Excluir?id=" + payload
    );
    if (response.data.sucesso === true) {
      yield put(excluirTaxaExtraSuccess(response.data));
    } else {
      yield put(excluirTaxaExtraFailure((response.data.mensagem)));
    }
  } catch (error) {
    yield put(excluirTaxaExtraFailure(error));
  }
}

function* listarTiposDeVeiculoTaxaExtra({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtraTipoVeiculo/Listar",
      payload
    );
    if (response.data.sucesso === true) {
      yield put(listarTiposDeVeiculoTaxaExtraSuccess(response.data));
    } else {
      yield put(listarTiposDeVeiculoTaxaExtraFailure((response.data.mensagem)));
    }
  } catch (error) {
    yield put(listarTiposDeVeiculoTaxaExtraFailure(error));
  }
}

function* salvarTipoDeVeiculoTaxaExtra({ payload }: { payload: any }) {

  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtraTipoVeiculo/Salvar",
      payload
    );
    if (response.data.sucesso === true) {
      yield put(salvarTipoDeVeiculoTaxaExtraSuccess(response.data));
      yield put(listarTiposDeVeiculoTaxaExtraStart({
        pagina: 1,
        paginacao: 20,
        filtros: `taxaExtraID.toString() = "${payload?.taxaExtraID}"`
    }))
    } else {
      yield put(salvarTipoDeVeiculoTaxaExtraFailure((response.data.mensagem)));
    }
  } catch (error) {
    yield put(salvarTipoDeVeiculoTaxaExtraFailure(error));
  }
}

function* excluirTipoDeVeiculo({ payload }: { payload: any }) {

  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtraTipoVeiculo/Excluir?id=" + payload.id
    );
    if (response.data.sucesso === true) {
      yield put(excluirTipoDeVeiculoSuccess(response.data));
      yield put(
        listarTiposDeVeiculoTaxaExtraStart({
          pagina: 1,
          paginacao: 20,
          filtros: `taxaExtraID.toString() = "${payload?.taxaExtraID}"`
      })
      )
    } else {
      yield put(excluirTipoDeVeiculoFailure((response.data.mensagem)));
    }
  } catch (error) {
    yield put(excluirTipoDeVeiculoFailure(error));
  }
}

function* listarPorDiaDaSemana({ payload }: { payload: any }) {

  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtraDiaSemana/Listar",
      payload
    );
    if (response.data.sucesso === true) {
      yield put(listarPorDiaDaSemanaSuccess(response.data));
    } else {
      yield put(listarPorDiaDaSemanaFailure((response.data.mensagem)));
    }
  } catch (error) {
    console.error(error);
  }
}

function* salvarPorDiaDaSemana({ payload }: { payload: any }) {

  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtraDiaSemana/Salvar",
      payload
    );
    if (response.data.sucesso === true) {
      yield put(salvarPorDiaDaSemanaSuccess(response.data));
    } else {
      yield put(salvarPorDiaDaSemanaFailure((response.data.mensagem)));
    }
  } catch (error) {
    console.error(error);
  }
}

function* excluirPorDiaDaSemana({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtraDiaSemana/Excluir?id=" + payload
    );
    if (response.data.sucesso === true) {
      yield put(excluirPorDiaDaSemanaSuccess(response.data));
    } else {
      yield put(excluirPorDiaDaSemanaFailure((response.data.mensagem)));
    }
  } catch (error) {
    console.error(error);
  }
}

function* salvarAgendamentoPorData({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TaxaExtra/Salvar?taxaExtraID=" + payload.taxaExtraID,
      payload
    );
    if (response.data.sucesso === true) {
      yield put(salvarAgendamentoPorDataSuccess(response.data));
    } else {
      yield put(salvarAgendamentoPorDataFailure((response.data.mensagem)));
    }
  } catch (error) {
    console.error(error);
  }
}

function* TaxaExtraSaga() {
  yield takeLatest(taxaExtraStart, taxaExtra);
  yield takeLatest(alterarSituacaoStart, alterarSituacao);
  yield takeLatest(salvarTaxaExtraStart, salvarTaxaExtra);
  yield takeLatest(excluirTaxaExtraStart, excluirTaxaExtra);
  yield takeLatest(listarTiposDeVeiculoTaxaExtraStart, listarTiposDeVeiculoTaxaExtra);
  yield takeLatest(salvarTipoDeVeiculoTaxaExtraStart, salvarTipoDeVeiculoTaxaExtra);
  yield takeLatest(excluirTipoDeVeiculoStart, excluirTipoDeVeiculo);
  yield takeLatest(listarPorDiaDaSemanaStart, listarPorDiaDaSemana);
  yield takeLatest(salvarPorDiaDaSemanaStart, salvarPorDiaDaSemana);
  yield takeLatest(excluirPorDiaDaSemanaStart, excluirPorDiaDaSemana);
  yield takeLatest(salvarAgendamentoPorDataStart, salvarAgendamentoPorData);
}

export default TaxaExtraSaga;
