import { Stack, TextField, Typography, Button, CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalBase, { ModalBaseProps } from 'src/ds/components/ModalBase'
import { ButtonLoading, ContainerBodyModal, SelectBotFuncao, SelectBotFuncaoMsg, SelectToggleAtivo } from 'src/ds/DesignSystem';
import { MensagemPadrao } from 'src/dtos/BotsDTO';
import { RootState } from 'src/store/reducers';
import { listarPalavrasChaveClear, listarPalavrasChaveStart, salvarMensagemPadraoStart } from 'src/store/reducers/bots';

interface FormData {
  ativo: string;
  funcaoBot: string;
  funcaoMsg: string;
  mensagem: string;
}

interface ModalAddMsgPadraoProps {
  modalProps: ModalBaseProps;
  pickID: string;
  msgTemplate?: MensagemPadrao | null
}

const ModalAddMsgPadrao = ({
  modalProps,
  pickID,
  msgTemplate
}: ModalAddMsgPadraoProps) => {
  const MENSAGEM_PADRAO_ID = "00000000-0000-0000-0000-000000000000"
  const theme = useTheme();
  const dispatch = useDispatch()
  const [mensagem, setMensagem] = useState('');
  const hookForm = useForm<FormData>({
    defaultValues: {
      ativo: 'false',
      funcaoBot: '',
      funcaoMsg: '',
      mensagem: '',
    },
  })
  const { register, handleSubmit, watch, formState: { errors }, setValue, reset } = hookForm
  const palavrasChave = useSelector((state: RootState) => state.bots.palavrasChave)
  const loading = useSelector((state: RootState) => state.bots.palavrasChave.loading)
  useEffect(() => {
    if (msgTemplate) {
      reset({
        ativo: msgTemplate.ativo.valueOf().toString(),
        funcaoBot: msgTemplate.funcaoBot.toString(),
        funcaoMsg: msgTemplate.funcaoMsg.toString(),
        mensagem: msgTemplate.mensagem,
      })
      setMensagem(msgTemplate.mensagem)
    }
  }, [msgTemplate, reset])

  const funcaoBot = watch('funcaoBot')
  useEffect(() => {
    if (funcaoBot) {
      dispatch(listarPalavrasChaveStart({ funcaoBot }))
    } else {
      dispatch(listarPalavrasChaveClear())
    }
  }, [funcaoBot])

  const onSubmit = (data: FormData) => {
    dispatch(salvarMensagemPadraoStart({
      ...data,
      pickID,
      pickWppBotMensagemPadraoID: msgTemplate ? msgTemplate?.pickWppBotMensagemPadraoID : MENSAGEM_PADRAO_ID
    }))
  }

  const handleAddPrefixo = (prefixo: string) => {
    const newMessage = `${mensagem}{{${prefixo}}}`;
    setMensagem(newMessage);
    setValue('mensagem', newMessage);
  };

  const handleMensagemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setMensagem(newValue);
    setValue('mensagem', newValue);
  };

  return (
    <ModalBase {...modalProps}>
      <ContainerBodyModal>
        <Stack sx={{ gap: 2, direction: 'collumn', height: '100%' }}>
          <SelectToggleAtivo
            label='Situação'
            hookForm={{
              methods: hookForm,
              name: 'ativo',
              rules: {
                required: 'Selecione uma situação'
              }
            }}
          />
          <SelectBotFuncao
            label='Função'
            hookForm={{
              methods: hookForm,
              name: 'funcaoBot',
              rules: {
                required: 'Selecione uma função'
              }
            }}
          />
          <SelectBotFuncaoMsg
            funcaoBot={watch('funcaoBot')}
            label='Função da Mensagem'
            hookForm={{
              methods: hookForm,
              name: 'funcaoMsg',
              rules: {
                required: 'Selecione uma função'
              }
            }}
            defaultValue={msgTemplate?.funcaoMsg?.toString() ?? ''}
          />
          <TextField
            {...register('mensagem', { required: 'Informe uma mensagem' })}
            value={mensagem}
            onChange={handleMensagemChange}
            size='small'
            error={!!errors.mensagem}
            helperText={String(errors.mensagem?.message ?? '')}
            label='Mensagem'
            multiline
            rows={5}
          />
          <Typography variant="body1" >Prefixos: </Typography>
          <Stack
            useFlexGap
            sx={{
              gap: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {palavrasChave.loading
              ? (
                <Stack alignItems='center' justifyContent='center' direction='row' sx={{ gap: '.5rem', width: '100%' }}>
                  <CircularProgress size='1rem' />
                  <Typography variant="body2" sx={{ width: 'fit-content' }}>Carregando...</Typography>
                </Stack>
              )
              : palavrasChave.data
                ? palavrasChave.data?.dados.lista.map((prefixo) => (
                  <Button
                    sx={{
                      width: 'fit-content',
                      bgcolor: theme.palette.grey[600],
                      borderColor: theme.palette.grey[600],
                      color: theme.palette.getContrastText(theme.palette.grey[600]),
                      '&:hover': {
                        borderColor: theme.palette.grey[600],
                      }
                    }}
                    variant="containedFilled"
                    key={prefixo.key}
                    onClick={() => handleAddPrefixo(prefixo.key)}
                  >
                    {prefixo.label}
                  </Button>
                ))
                : <Typography variant="body2" sx={{ width: 'fit-content' }}>Selecione uma função</Typography>}
          </Stack>
          <Stack
            useFlexGap
            sx={{
              gap: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              mt: 'auto',
              pt: 2,
            }}
          >
            <Button
              sx={{ width: 'fit-content' }}
              variant="containedFilled"
              color="grey"
              onClick={modalProps.onClose}
            >
              Cancelar
            </Button>
            <ButtonLoading
              loading={loading}
              variant="containedFilled"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: 'fit-content'
              }}
            >
              Confirmar
            </ButtonLoading>
          </Stack>
        </Stack>
      </ContainerBodyModal>
    </ModalBase>
  )
}

export default ModalAddMsgPadrao