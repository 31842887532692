import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, IconButton, Tooltip, Typography } from '@mui/material'
import { Pedido } from '../../dtos/PedidosDTO'
import { TableRowLoading, TableRowNotFound } from 'src/ds/DesignSystem'
import { useCallback, useState } from 'react';
import PedidoRow from './RowPedido';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

interface TabelaPedidosProps {
  pedidos: Pedido[] | undefined;
  loading: boolean;
  fezRequisicao?: boolean;
}

const TabelaPedidos = ({ pedidos, loading, fezRequisicao }: TabelaPedidosProps) => {
  const [isAllOpen, setIsAllOpen] = useState(false);
  const [stepperVisible, setStepperVisible] = useState<{ [key: string]: boolean }>(
    () => pedidos?.reduce((acc, pedido) => ({ ...acc, [pedido.pedidoID]: false }), {}) || {}
  );

  const toggleStepperVisibility = useCallback((pedidoID: string) => {
    setStepperVisible(prev => ({
      ...prev,
      [pedidoID]: !prev[pedidoID],
    }));
  }, []);

  const toggleAllSteppers = useCallback(() => {
    setStepperVisible(prev => {
      const updated = { ...prev };
      pedidos?.forEach(pedido => {
        updated[pedido.pedidoID] = !isAllOpen;
      });
      return updated;
    });
    setIsAllOpen(prev => !prev);
  }, [pedidos, isAllOpen]);

  const closeAllSteppers = useCallback(() => {
    setStepperVisible(prev => {
      const updated = { ...prev };
      pedidos?.forEach(pedido => {
        updated[pedido.pedidoID] = false;
      });
      return updated;
    });
  }, [pedidos]);

  return (
    <>
      <TableContainer
        sx={{
          width: '100%',
          overflowX: 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Origem</TableCell>
              <TableCell align="center">Código</TableCell>
              <TableCell align="center">Situação</TableCell>
              <TableCell align="center">Empresa</TableCell>
              <TableCell align="center">Endereços</TableCell>
              <TableCell align="center">Entregador</TableCell>
              <TableCell align="center">Cliente</TableCell>
              <TableCell align="center">Forma de pagamento</TableCell>
              <TableCell align="center">Valor do pedido</TableCell>
              <TableCell align="center">Taxa cobrada</TableCell>
              <TableCell align="center">Taxa entregador</TableCell>
              <TableCell align="center">
                <Stack spacing={1} direction='row' alignItems='center' justifyContent='center'>
                  <Typography>Ações</Typography>
                  <Tooltip title={isAllOpen ? "Minimizar todos os pedidos" : "Expandir todos os pedidos"} arrow placement='top'>
                    <IconButton
                      onClick={toggleAllSteppers}
                      color={isAllOpen ? "secondary" : "primary"}
                    >
                      {isAllOpen ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? <TableRowLoading />
              : (!pedidos || pedidos?.length === 0)
                ? <TableRowNotFound text="Nenhum resultado encontrado" fezRequisicao={fezRequisicao} />
                : pedidos.map((pedido) => (
                  <PedidoRow
                    key={pedido.pedidoID}
                    pedido={pedido}
                    onToggleStepper={toggleStepperVisibility}
                    stepperVisible={stepperVisible[pedido.pedidoID] || false}
                  />
                ))
            }
          </TableBody>
        </Table>
      </TableContainer >
    </>
  )
}

export default TabelaPedidos;
