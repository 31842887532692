import { Box, Card, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Tabela from 'src/components/FaturasRepresentante/Tabela';
import TabelaFaturas from 'src/components/MinhasFaturas/TabelaFaturas';
import { TableNavigation } from 'src/ds/DesignSystem'
import { CardFormFiltrosRef } from 'src/ds/Forms';
import { RootState } from 'src/store/reducers';
import { listarMinhasStart } from 'src/store/reducers/faturas';

const FaturasRepresentante = () => {
    const dispatch = useDispatch();
    const dadosFaturas = useSelector((state: RootState) => state.faturas?.listarMinhasRepresentante?.data?.dados);
    const listaFaturas = useSelector((state: RootState) => state.faturas?.listarMinhasRepresentante?.data?.dados?.lista);
    const loading = useSelector((state: RootState) => state.faturas?.listarMinhasRepresentante?.loading);
    const cardRef = useRef<CardFormFiltrosRef>(null);
    const hookForm = useForm({ defaultValues: { pagina: 1, paginacao: 20 } });

    useEffect(() => {
        dispatch(listarMinhasStart(hookForm.getValues()));
    }, [])
    return (
        <Box p={2} >
            <Card elevation={1} sx={{ mt: 2, p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Faturas</Typography>
                </Box>
                {(dadosFaturas?.ultimaPagina) ? (
                    <TableNavigation
                        indiceInicial={dadosFaturas?.indiceInicial}
                        indiceFinal={dadosFaturas?.indiceFinal}
                        indiceTotal={dadosFaturas?.indiceFinal}
                        ultimaPagina={dadosFaturas?.ultimaPagina}
                        hookForm={hookForm}
                    />

                ) : null}
                <Tabela data={listaFaturas} loading={loading} fezRequisicao={cardRef.current?.fezRequisicao} />
            </Card>
        </Box>
    )
}

export default FaturasRepresentante