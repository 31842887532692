import { ArrowBackIos } from '@mui/icons-material';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { pedidoModeloClear, pedidoModeloStart } from 'src/store/reducers/pedidos';
import DadosBasicos from './DadosBasicos';
import { RootState } from 'src/store/reducers';
import { BoxLoading } from 'src/ds/DesignSystem';
import LogPedido from './LogPedido';
import Despachos from './Despachos';
import MapaPedido from './MapaPedido';

interface TabsPedidosProps {
    id: string
}

const TabsPedido: React.FC<TabsPedidosProps> = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const pedidoModelo = useSelector((state: RootState) => state.pedidos.pedidoModelo);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    function reqPedidoModelo() {
        if (!id) return;
        dispatch(pedidoModeloStart({ id }));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (!id) return;
        reqPedidoModelo();
        return () => {
            dispatch(pedidoModeloClear());
        }
    }, [id])

    const tabsConfig = [
        { label: 'Dados Básicos', content: <DadosBasicos callback={reqPedidoModelo} /> },
        { label: 'Despachos', content: <Despachos /> },
        { label: 'Mapa', content: <MapaPedido /> },
        { label: 'Log', content: <LogPedido /> },
        { label: 'Formulários', content: <div /> },
    ];

    return (
        <Box p={2} sx={{ backgroundColor: 'background.paper' }}>

            <Stack sx={{ justifyContent: 'flex-start', alignItems: 'center', mb: 1, gap: 1, flexDirection: 'row' }}>
                <ArrowBackIos
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(-1)}
                />
                <Typography variant="h6" >
                    {`Editar #${pedidoModelo.data?.modelo?.codigoQuatro ?? ''}`}
                </Typography>
                <Typography variant="body2" color="grey">({pedidoModelo.data?.modelo?.pedidoID ?? ''})</Typography>
            </Stack>

            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Tabs de Edição"
                variant="scrollable"
                scrollButtons="auto"
                sx={{ minHeight: '48px' }}
            >
                {tabsConfig.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                        sx={{
                            minWidth: '100px',
                            maxWidth: '200px',
                            color: 'text.primary',
                            fontSize: '12px',
                            textTransform: 'none'
                        }}
                    />
                ))}
            </Tabs>
            <BoxLoading loading={pedidoModelo.loading} sx={{ mt: 3 }}>
                {tabsConfig[activeTab].content}
            </BoxLoading>
        </Box >
    )
}

export default TabsPedido