import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, IconButton, Button, useTheme, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useState } from "react";
//@ts-ignore
import editar from "../../assets/icons/editar.png";
//@ts-ignore
import deleteIcon from "../../assets/icons/delete.png";
import { telefone } from "src/ds/common";
import { ChipSituacao, ModalOpcoes, SnackbarAlert, TableRowLoading, TableRowNotFound, ButtonTableOrder } from "src/ds/DesignSystem";
import { DA } from "src/dtos/DADTOS";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { excluirDespachoAutomaticoStart, limparSalvarDa, listarDaStart } from "src/store/reducers/despachoAutomatico";
import { useMultiResponseSnackbar, useSnackbar } from "src/ds/CustomHooksDs";

interface TabelaDAProps {
    dados: DA[];
    loading: boolean;
    fezRequisicao?: boolean;
}

const TabelaDA = ({ dados, loading, fezRequisicao }: TabelaDAProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [openDeletar, setOpenDeletar] = useState(false);
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        ativo: 'asc',
        nome: 'asc',
        dataCadastro: 'asc',
    });
    const response = useSelector((state: any) => state.despachoAutomatico?.salvarDa);
    const snack = useSnackbar({ duration: 2000 });

    useMultiResponseSnackbar([{ response, clearAction: () => { dispatch(limparSalvarDa()); } }], snack);

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        const updatedSortDirection = Object.keys(sortDirection).reduce((acc, key) => {
            acc[key] = key === column ? newSortDirection : 'asc';
            return acc;
        }, {} as { [key: string]: 'asc' | 'desc' });

        setSortDirection(updatedSortDirection);


        const payload = {
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        };

        dispatch(listarDaStart(payload));
    };

    const theme = useTheme();

    return (
        <TableContainer component={Paper} sx={{ width: '100%', overflowY: 'auto', margin: '0 auto' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ borderTop: '1px solid #ccc' }}>
                        <TableCell align="center" sx={{ width: '5%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('ativo')} sortDirection={sortDirection} nome="ativo">
                                Situação
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '25%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('nome')} sortDirection={sortDirection} nome="nome">
                                Tabela
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('dataCadastro')} sortDirection={sortDirection} nome="dataCadastro">
                                Data de cadastro
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="right" sx={{ width: '10%' }}>
                            <ButtonTableOrder>
                                Ações
                            </ButtonTableOrder>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : (
                        dados?.length > 0
                            ? dados?.map((row, index) => (
                                <TableRow key={index} hover>
                                    <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        <ChipSituacao situacao={row.ativo ? 'Ativa' : 'Inativa'} label={row.ativo ? 'Ativa' : 'Inativa'} />
                                    </TableCell>
                                    <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        {row.nome}
                                    </TableCell>
                                    <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        {row.dataCadastro}
                                    </TableCell>
                                    <TableCell align="right" sx={{ py: 2 }}>
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <Tooltip title="Editar" arrow placement="top">
                                                <IconButton onClick={() => navigate(`/TabelaDespachoAutomatico/Editar/${row.tabelaDespachoAutomaticoID}`)}>
                                                    <Edit color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Excluir" arrow placement="top">
                                                <IconButton onClick={() => {
                                                    setSelectedItem(row);
                                                    setOpenDeletar(true);
                                                }}>
                                                    <Delete color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))
                            : <TableRowNotFound fezRequisicao={fezRequisicao} />
                    )}
                </TableBody>
            </Table>
            <ModalOpcoes
                descricao={(
                    <Typography variant="body1" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        Excluir <Typography component="span" fontWeight="bold" sx={{ display: 'inline' }}>{selectedItem?.nome}</Typography>
                    </Typography>
                )}
                acao="Excluir"
                open={openDeletar}
                onClose={() => { setOpenDeletar(false) }}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(excluirDespachoAutomaticoStart(selectedItem.tabelaDespachoAutomaticoID));
                            setOpenDeletar(false);
                        }
                    }
                ]}
            />
            <SnackbarAlert {...snack} />
        </TableContainer>
    );
};

export default TabelaDA;
