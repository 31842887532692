import { Box, Step, StepIcon, StepLabel, Stepper, SxProps, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

export interface HistoricoAndamento {
    recebido?: string | null;
    agendamento?: string | null;
    pronto?: string | null;
    despacho?: string | null;
    aceito?: string | null;
    noLocal?: string | null;
    emRota?: string | null;
    retorno?: string | null;
    finalizado?: string | null;
    cancelado?: string | null;
}

interface StepperAndamentoProps {
    historicoAndamento?: HistoricoAndamento | null;
    sx?: SxProps;
}

const steps = [
    { label: 'Recebido', key: 'recebido' },
    { label: 'Agendamento', key: 'agendamento' },
    { label: 'Pronto', key: 'pronto' },
    { label: 'Despacho', key: 'despacho' },
    { label: 'Aceito', key: 'aceito' },
    { label: 'No Local', key: 'noLocal' },
    { label: 'Em Rota', key: 'emRota' },
    { label: 'Retorno', key: 'retorno' },
    { label: 'Finalizado', key: 'finalizado' },
    { label: 'Cancelado', key: 'cancelado' },
];

const StepperAndamento: React.FC<StepperAndamentoProps> = ({ historicoAndamento, sx }) => {
    const [visibleSteps, setVisibleSteps] = useState<{ label: string; key: string; date: string }[]>([]);
    const theme = useTheme();

    useEffect(() => {
        if (!historicoAndamento) return;

        const completedSteps = steps
            .map(step => {
                const stepDate = historicoAndamento[step.key as keyof HistoricoAndamento];
                return stepDate !== null ? { label: step.label, key: step.key, date: stepDate } : null;
            })
            .filter(step => step !== null) as { label: string; key: string; date: string }[];
        setVisibleSteps(completedSteps);
    }, [historicoAndamento]);

    // Verifica se o agendamento é diferente de false
    const agendamentoDate = historicoAndamento?.agendamento;

    return (
        <Box sx={{
            width: '100%',
            overflowX: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: theme.palette.primary.main,
            pb: 2,
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            alignItems: 'center',
            ...sx,
        }}>
            {agendamentoDate && (
                <Stepper activeStep={0} alternativeLabel>
                    <Step>
                        <StepLabel
                            StepIconComponent={(props) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '30px',
                                        borderRadius: '50%',
                                        aspectRatio: '1/1',
                                        backgroundColor: theme.palette.secondary.main,
                                    }}
                                >
                                    <StepIcon
                                        {...props}
                                        icon={<CalendarMonthRoundedIcon sx={{ color: theme.palette.secondary.contrastText }} />}
                                    />
                                </Box>
                            )}
                        >
                            <Typography variant="body1">Agendamento</Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>{agendamentoDate}</Typography>
                        </StepLabel>
                    </Step>
                </Stepper>
            )}
            <Stepper activeStep={visibleSteps.length - 1} alternativeLabel>
                {visibleSteps.map((step, index) => {
                    if (step.key === 'agendamento') return null
                    return (
                        <Step key={step.label}>
                            <StepLabel
                                StepIconComponent={(props) => (
                                    <Box
                                        sx={{
                                            bgcolor: step.key === 'cancelado' ? 'error.main' : 'primary.main',
                                            borderRadius: '50%',
                                            aspectRatio: '1/1',
                                            height: '30px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <StepIcon
                                            {...props}
                                            icon={
                                                step.key === 'cancelado'
                                                    ? <WarningAmberRoundedIcon style={{ color: theme.palette.text.secondary }} />
                                                    : <CheckRoundedIcon style={{ color: theme.palette.text.secondary }} />
                                            }
                                        />
                                    </Box>
                                )}
                            >
                                <Typography variant="body1">{step.label}</Typography>
                                <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>{step.date}</Typography>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Box >
    );
}

export default StepperAndamento;