import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Typography, Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, Tooltip, IconButton, useTheme } from '@mui/material';
import { GridPainelLateral } from "../../../ds";
import { InfoOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getInfo, limparDaRobo, setDaRobo } from '../../../store/reducers/darobo';
import { HeaderCard, SnackbarAlert } from '../../../ds/DesignSystem';
import { RootState } from 'src/store/reducers';
import { useMultiResponseSnackbar, useSnackbar } from 'src/ds/CustomHooksDs';

export const Robo = ({ onClose }: any) => {
    const pickId = useSelector((state: any) => state.config.masterPage?.pickSelecionada?.pickID);
    const modelo = useSelector((state: any) => state.daRobo?.modelo);
    const loading = useSelector((state: any) => state.daRobo?.loading);
    const resposta = useSelector((state: any) => state.daRobo?.resposta);
    const error = useSelector((state: any) => state.daRobo?.error);
    const theme = useTheme();
    const dispatch = useDispatch();
    const snack = useSnackbar({ duration: 2000 });

    useMultiResponseSnackbar([{ response: error, clearAction: () => { dispatch(limparDaRobo({})); } }], snack);

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            tabelaDespachoAutomaticoID: modelo?.tabelaDespachoAutomaticoID || 0,
            ativo: modelo?.ativo || true,
            nome: modelo?.nome || '',
            tempoMaximoEntrePedidos: modelo?.tempoMaximoEntrePedidos || 100,
            numeroMaximoPedidosAgrupados: modelo?.numeroMaximoPedidosAgrupados || 2,
            distanciaMaximaEntreEmpresas: modelo?.distanciaMaximaEntreEmpresas || 2000,
            tempoParaRecusaAutomatica: modelo?.tempoParaRecusaAutomatica || 0,
            angulo: modelo?.angulo || 90,
            distanciaMaximaEntrePedidos: modelo?.distanciaMaximaEntrePedidos || 2500,
            raioEmMetros: modelo?.raioEmMetros || 400,
            despacharPorBase: modelo?.despacharPorBase || false
        }
    });

    useEffect(() => {
        if (modelo) {
            reset({
                tabelaDespachoAutomaticoID: modelo.tabelaDespachoAutomaticoID,
                ativo: modelo.ativo,
                nome: modelo.nome,
                tempoMaximoEntrePedidos: modelo.tempoMaximoEntrePedidos,
                numeroMaximoPedidosAgrupados: modelo.numeroMaximoPedidosAgrupados,
                distanciaMaximaEntreEmpresas: modelo.distanciaMaximaEntreEmpresas,
                tempoParaRecusaAutomatica: modelo.tempoParaRecusaAutomatica,
                angulo: modelo.angulo,
                distanciaMaximaEntrePedidos: modelo.distanciaMaximaEntrePedidos,
                raioEmMetros: modelo.raioEmMetros,
                despacharPorBase: modelo.despacharPorBase
            });
        }
    }, [modelo, reset]);

    const onSubmit = (data: any) => {
        const updatedData = {
            ...data,
            ativo: !data.ativo
        };
        dispatch(setDaRobo({ pickID: pickId, ...updatedData }));
    };

    const handleReset = () => {
        if (modelo) {
            reset({
                tabelaDespachoAutomaticoID: modelo.tabelaDespachoAutomaticoID,
                ativo: modelo.ativo,
                nome: modelo.nome,
                tempoMaximoEntrePedidos: modelo.tempoMaximoEntrePedidos,
                numeroMaximoPedidosAgrupados: modelo.numeroMaximoPedidosAgrupados,
                distanciaMaximaEntreEmpresas: modelo.distanciaMaximaEntreEmpresas,
                tempoParaRecusaAutomatica: modelo.tempoParaRecusaAutomatica,
                angulo: modelo.angulo,
                distanciaMaximaEntrePedidos: modelo.distanciaMaximaEntrePedidos,
                raioEmMetros: modelo.raioEmMetros,
                despacharPorBase: modelo.despacharPorBase
            });
        }
    };

    useEffect(() => {
        dispatch(getInfo({ pickID: pickId }));
    }, [pickId]);

    const stylesTextField = {
        flex: '2 1 250px',
        width: '100%',
    }

    return (
        <GridPainelLateral item xs={12} sx={{ minHeight: '100%' }}>
            <Box sx={{ p: 1, pb: 0, width: '100%', boxSizing: 'border-box' }}>
                <HeaderCard title="Roteirização" onClick={onClose} />
            </Box>
            <Box sx={{ p: '0 .5rem .5rem .5rem', display: 'flex', flexDirection: 'column', height: '100%', gap: 2 }}>
                <Box sx={{ p: '0.25rem', m: '0 0 .5rem 0', backgroundColor: !modelo?.ativo ? theme.palette.error.light + '!important' : theme.palette.success.light + '!important', borderRadius: 1, color: !modelo?.ativo ? theme.palette.getContrastText(theme.palette.error.main) : theme.palette.getContrastText(theme.palette.success.main) }}>
                    <Typography textAlign={'center'}>Robô está {!modelo?.ativo ? 'desativado' : 'ativado'}</Typography>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Box display="flex" flexWrap="wrap" gap={2} sx={{ mb: 2 }}>
                        <Controller
                            name="tempoMaximoEntrePedidos"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    label="Tempo máximo entre pedidos"
                                    variant="outlined"
                                    sx={stylesTextField}
                                    disabled={modelo?.ativo}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title="Tempo limite para agrupar um pedido ao outro.">
                                                <IconButton>
                                                    <InfoOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="numeroMaximoPedidosAgrupados"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    label="Máximo de pedidos agrupados"
                                    variant="outlined"
                                    sx={stylesTextField}
                                    disabled={modelo?.ativo}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title="Quantidade máxima de pedidos que podem ser enviados para um único motoboy de acordo com as configurações definidas.">
                                                <IconButton>
                                                    <InfoOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="distanciaMaximaEntreEmpresas"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    label="Distância máx. entre empresas"
                                    variant="outlined"
                                    sx={stylesTextField}
                                    disabled={modelo?.ativo}

                                />
                            )}
                        />
                        <Controller
                            name="tempoParaRecusaAutomatica"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    label="Tempo para recusa automática"
                                    variant="outlined"
                                    sx={stylesTextField}
                                    disabled={modelo?.ativo}

                                />
                            )}
                        />
                        <Controller
                            name="angulo"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    label="Ângulo"
                                    variant="outlined"
                                    sx={stylesTextField}
                                    disabled={modelo?.ativo}

                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title="Utilizado para determinar a angulação máxima entre dois ou mais pedidos utilizando a empresa como ponto zero.">
                                                <IconButton>
                                                    <InfoOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="distanciaMaximaEntrePedidos"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    label="Distância máx. entre pedidos"
                                    variant="outlined"
                                    sx={stylesTextField}
                                    disabled={modelo?.ativo}

                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip
                                                title="Distância definida para poder agrupar pedidos. Caso o ângulo seja superior a 90º recomenda-se diminuir os valores da distância máxima entre pedidos. Caso o ângulo seja inferior a 90º recomenda-se aumentar os valores da distância máxima entre pedidos."
                                            >
                                                <IconButton>
                                                    <InfoOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="raioEmMetros"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    type="number"
                                    label="Distância para entrar na fila"
                                    variant="outlined"
                                    sx={stylesTextField}
                                    disabled={modelo?.ativo}

                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title="Distância em que o entregador será inserido na fila ao se aproximar do estabelecimento.">
                                                <IconButton>
                                                    <InfoOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="despacharPorBase"
                            control={control}
                            render={({ field }) => (
                                <FormControl fullWidth size="small" sx={stylesTextField}>
                                    <InputLabel>Despachar por fila?</InputLabel>
                                    <Select
                                        {...field}
                                        disabled={modelo?.ativo}

                                        label="Despachar por fila?"
                                        inputProps={{
                                            endAdornment: (
                                                <Tooltip title="A ordem de despacho respeitará a ordem na qual os entregadores entraram na fila.">
                                                    <IconButton>
                                                        <InfoOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }}
                                    >
                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Box>
                    <Box sx={{ mt: 'auto', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Button type="button" variant="contained" color="secondary" onClick={handleReset}>Restaurar definições</Button>
                        <Button type="submit" variant="containedFilled">{modelo?.ativo ? 'Desativar' : 'Ativar'} robô</Button>
                    </Box>
                </form>
            </Box>
            <SnackbarAlert {...snack} />
        </GridPainelLateral>
    );
}

export default Robo;
