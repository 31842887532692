import { CircularProgress, Stack, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'

const TableRowLoading = () => {
    return (
        <TableRow>
            <TableCell colSpan={12}>
                <Stack alignItems='center' justifyContent='center' direction='row' sx={{ gap: '.5rem' }}>
                    <CircularProgress size='1rem' />
                    <Typography variant="body2" sx={{ width: 'fit-content' }}>Carregando...</Typography>
                </Stack>
            </TableCell>
        </TableRow>
    )
}

export default TableRowLoading