// SelectPaginacao.tsx
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';

interface SelectPaginacaoProps {
  hookForms: UseFormReturn<any>;
  podeTodos?: boolean;
  name?: string;
  props?: TextFieldProps;
}

/**
* Componente SelectPaginacao
* 
* Este componente exibe um campo de seleção automática de paginação. 
* Extende o `TextField` do `@mui/material`.
* Necessita do objeto retornado pelo `useForm` do `react-hook-form` para funcionar.
*
* @component
* @param {UseFormReturn<any>} props.hookForms - Objeto retornado pelo `useForm` do `react-hook-form`.
* @param {boolean} props.podeTodos - Boleano que indica se o `Todos` deve ser exibido, padrao `true`.
* @param {string} [props.name='paginacao'] - O nome do campo no formulário, usado pelo `setValue`.
* 
* @returns {JSX.Element} Retorna o campo de seleção automática de paginação.
* 
* @example
* ```tsx
*  const hookForms = useForm()
*  <SelectPaginacao 
*      hookForms={hookForms}
*      podeTodos={false}
*      name="paginacao" 
*  />
* ```
*/
const SelectPaginacao = ({
  name = 'paginacao', // Paginacao como valor padrão
  podeTodos = true,
  hookForms,
  ...props
}: SelectPaginacaoProps) => {
  const { control } = hookForms
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          select
          label="Paginação"
          size="small"
          sx={{ width: '100px' }}
          {...field}
          {...props}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          {podeTodos && <MenuItem value={-1}>TODOS</MenuItem>}
        </TextField>
      )}
    />
  );
};


export default SelectPaginacao;
