import { QrCode } from "@mui/icons-material";
import { Avatar, Box, Card, Divider, FormGroup, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { dinheiro } from "src/ds/common";

interface DetalhesProps {
    selectedItem: any;
    template: any;
}

const Detalhes: React.FC<DetalhesProps> = ({ selectedItem, template }) => {
    if (!selectedItem) return null;
    return (
        <Card sx={{ p:2, flexGrow: 1, display: "flex", flexDirection: "column", maxWidth: "90%" }} elevation={0}>
            <Box display="flex" justifyContent="space-between" gap={2} alignItems="center" mb={2}>
                <Box display="flex" gap={2} alignItems="center">
                    <Avatar src={template?.logoUrl} sx={{ width: 60, height: 60 }} />
                    <Typography variant="h6">{selectedItem?.usuarioResponsavel}</Typography>
                </Box>
                <span>
                    <Typography variant="body2" align="right">
                        <strong>Recibo #{selectedItem?.codigo}</strong>
                </Typography>
                    <Typography variant="body2" align="right">
                        Data de vencimento: {dayjs(selectedItem?.dataFechamento, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')}
                    </Typography>
                </span>
            </Box>
            <Box display="flex" gap={6} flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                <Box>
                    <Typography>
                        <strong>Emitente:</strong> Pick N Go!
                    </Typography>
                    Brasília <br />
                    Brasília, DF <br />
                    70360-510
                </Box>
                <Box textAlign="left">
                    <Typography>
                        <strong>Tomador:</strong> {selectedItem?.usuarioResponsavel}
                    </Typography>
                    <Typography>{selectedItem?.endereco?.logradouro}</Typography>
                    <Typography>
                        {selectedItem?.endereco?.cidadeNome}, {selectedItem?.endereco?.uf}
                    </Typography>
                    <Typography>{selectedItem?.endereco?.cep}</Typography>
                    <Typography>{selectedItem?.telefoneResponsavel}</Typography>
                    <Typography>{selectedItem?.emailResponsavel}</Typography>
                </Box>
                <Box>
                    <QrCode sx={{ width: 100, height: 100 }} />
                </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <FormGroup>
                {selectedItem?.itens?.map((item: any) => (
                    <TextField
                        key={item.assinaturaFaturaItemID}
                        id="outlined-basic"
                        label="Detalhes do serviço"
                        variant="outlined"
                        color="secondary"
                        value={item.descricao}
                        sx={{
                            '& label': {
                                color: '#000000',
                            },
                            '& label.Mui-focused': {
                                color: '#000000',
                            },
                            '& .MuiOutlinedInput-root': {
                                color: '#000000',
                                '& fieldset': {
                                    borderColor: '#000000',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#000000',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#000000',
                                },
                            },
                        }}
                    />
                ))}
            </FormGroup>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between">
                <Typography>
                    <strong>Total:</strong>
                </Typography>
                <Typography>{dinheiro(selectedItem?.valor)}</Typography>
            </Box>
        </Card>
    );
}

export default Detalhes;
