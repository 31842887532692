// MapWithMarker.tsx
import { Box, Typography, useTheme } from '@mui/material';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import React, { useEffect, useState, useCallback } from 'react';
import { GOOGLE_API_KEY } from 'src/utils';

interface Coordinates {
  latitude: number | null;
  longitude: number | null;
}

interface MapWithMarkerProps {
  position: Coordinates;
  setPosition: (position: Coordinates) => void;
}

const MapWithMarker: React.FC<MapWithMarkerProps> = ({ position, setPosition }) => {
  const theme = useTheme();
  
  const initialCenter = {
    latitude: position.latitude ?? 0,  // Fallback para 0
    longitude: position.longitude ?? 0,
  };

  const [center, setCenter] = useState<Coordinates>(initialCenter);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  useEffect(() => {
    if (position.latitude !== null && position.longitude !== null) {
      setCenter({
        latitude: position.latitude,
        longitude: position.longitude,
      });
    }
  }, [position]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Control') setIsCtrlPressed(true);
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Control') setIsCtrlPressed(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const handleMarkerDragEnd = useCallback((e: google.maps.MapMouseEvent) => {
    const latLng = e.latLng;
    if (latLng) {
      setPosition({
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      });
    }
  }, [setPosition]);

  const containerStyle = {
    height: '400px',
    width: '100%',
    border: 'none',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  const mapOptions = {
    scrollwheel: isCtrlPressed,
    disableDefaultUI: false,
    zoomControl: true,
  };

  if (!position.latitude || !position.longitude || !center.latitude || !center.longitude) {
    return (
      <Box
        sx={{
          ...containerStyle,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: theme.palette.grey[200],
        }}
      >
        <Typography variant="notFound" sx={{ color: theme.palette.grey[300] }}>
          Informe um Endereço...
        </Typography>
      </Box>
    );
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: center.latitude, lng: center.longitude }}
      zoom={14}
      options={mapOptions}
    >
      <Marker
        position={{ lat: center.latitude, lng: center.longitude }}
        draggable
        onDragEnd={handleMarkerDragEnd}
      />
    </GoogleMap>
  ) : null;
};

export default MapWithMarker;
