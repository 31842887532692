import React, { useState } from 'react'
import { Box, Button } from '@mui/material';
import capacete from '../../../assets/capacete.png'
import ListaEntregadores from './ListaEntregadores';
import { useResponsive } from 'src/ds/CustomHooksDs';
import { SportsMotorsports, SportsMotorsportsOutlined, SportsMotorsportsTwoTone } from '@mui/icons-material';

const ModalEntregadores = ({ abrirEntregadores, setAbrirEntregadores }: any) => {
    const { isMobile } = useResponsive()
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: isMobile ? '-16px' : '68px' }}>
            <Button
                onClick={() => setAbrirEntregadores(true)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '56px',
                    height: '56px',
                    backgroundColor: 'primary.main',
                    '&:hover': { backgroundColor: 'lightgray' },
                    padding: 0,
                }}
            >
                <SportsMotorsportsTwoTone sx={{ color: 'white', fontSize: '38px' }} />
            </Button>
            {abrirEntregadores && <ListaEntregadores abrirEntregadores={abrirEntregadores} setAbrirEntregadores={setAbrirEntregadores} />}
        </div>
    )
}

export default ModalEntregadores
