import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { TableRowLoading, TableRowNotFound } from 'src/ds/DesignSystem'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { FinanceiroPorEntregador } from 'src/dtos/FinanceiroPorEntregadorDTO';
import { dinheiro, maskCpf, telefone } from 'src/ds/common';

interface TabelaFinanceiroPorEntregadorProps {
    loading: boolean;
    fezRequisicao?: boolean;
    entregadores?: FinanceiroPorEntregador[] | null;
}

const TabelaFinanceiroPorEntregador = (
    { loading, entregadores, fezRequisicao }: TabelaFinanceiroPorEntregadorProps
) => {
    return (
        <TableContainer
            sx={{
                width: '100%',
                overflowX: 'auto',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Entregador</TableCell>
                        <TableCell align="center">CPF</TableCell>
                        <TableCell align="center">Telefone</TableCell>
                        <TableCell align="center">E-Mail</TableCell>
                        <TableCell align="center">Chave PIX</TableCell>
                        <TableCell align="center">Qtd. Pedidos</TableCell>
                        <TableCell align="center">Valor Realizado</TableCell>
                        <TableCell align="center">Valor total já antecipado</TableCell>
                        <TableCell align="center">Valor total</TableCell>
                        <TableCell align="center">
                            <Stack
                                sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', gap: '.5rem', flexDirection: 'row' }}
                            >
                                <Typography>Valor total programado</Typography>
                                <Tooltip
                                    title="Valor Referente ao total programado + antecipações solicitadas"
                                    arrow
                                    placement='top'
                                    sx={{ textAlign: 'center' }}
                                >
                                    <HelpOutlineOutlinedIcon color='info' />
                                </Tooltip>
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : entregadores && entregadores.length > 0 ? (
                        entregadores.map((entregador) => (
                            <TableRow key={entregador.usuarioID} hover>
                                <TableCell align="center">{entregador.nome}</TableCell>
                                <TableCell align="center">{maskCpf(entregador.cpf)}</TableCell>
                                <TableCell align="center">{telefone(entregador.telefone)}</TableCell>
                                <TableCell align="center">{entregador.email}</TableCell>
                                <TableCell align="center">{entregador.chavePix}</TableCell>
                                <TableCell align="center">{entregador.qtdPedidos}</TableCell>
                                <TableCell align="center">{dinheiro(entregador.taxaTotalEntregador)}</TableCell>
                                <TableCell align="center">{dinheiro(entregador.valorAntecipado)}</TableCell>
                                <TableCell align="center">{dinheiro(entregador.valorTotal)}</TableCell>
                                <TableCell align="center">{dinheiro(entregador.taxaTotalEntregadorProgramada)}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRowNotFound text="Nenhum resultado encontrado" fezRequisicao={fezRequisicao} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TabelaFinanceiroPorEntregador