import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'rastreioReducer',
    initialState: {
        pedido: {
            loading: false,
            error: null,
            data: null
        },
        entregadores: {
            loading: false,
            error: null,
            data: null
        },
        empresa: {
            loading: false,
            error: null,
            data: null
        },
        avaliacao: {
            data: {
                comentario: null,
                nota: null,
                pedidoID: null,
            },
        }
    },
    reducers: {
        pedidoStart(state, action) {
            state.pedido = {
                ...state.pedido,
                loading: true,
            }
        },
        pedidoSuccess(state, action) {
            state.pedido = {
                loading: false,
                data: action.payload,
                error: null,
            }
        },
        pedidoFailure(state, action) {
            state.pedido = {
                loading: false,
                data: null,
                error: action.payload,
            }
        },
        entregadoresStart(state, action) {
            state.entregadores = {
                ...state.entregadores,
                loading: true,
            }
        },
        entregadoresSuccess(state, action) {
            state.entregadores = {
                loading: false,
                data: action.payload,
                error: null,
            }
        },
        entregadoresFailure(state, action) {
            state.entregadores = {
                loading: false,
                data: null,
                error: action.payload,
            }
        },
        empresaStart(state, action) {
            state.empresa = {
                ...state.empresa,
                loading: true,
            }
        },
        empresaSuccess(state, action) {
            state.empresa = {
                loading: false,
                data: action.payload,
                error: null,
            }
        },
        empresaFailure(state, action) {
            state.empresa = {
                loading: false,
                data: null,
                error: action.payload,
            }
        },
        comentarioStart(state, action) {
            state.comentarios = {
                ...state.comentarios,
                loading: true,
                response: null,
            }
        },
        
    }
})
export const {
    pedidoStart, pedidoSuccess, pedidoFailure,
    entregadoresStart, entregadoresSuccess, entregadoresFailure,
    empresaStart, empresaSuccess, empresaFailure,
    comentarioStart
} = slice.actions;
export default slice