import { useEffect, useState } from 'react'
import { ContainerBodyModal, LoadingContainer } from '../../../ds'
import { useDispatch, useSelector } from 'react-redux'
import { atualizarQrCodeStart, gerarQrCodeStart, inativarQrCodeStart, limparQrCode, listarQrCodeStart } from '../../../store/reducers/utils'
import { Button, Container, Grid2, Typography } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { RootState } from 'src/store/reducers'
import { ModalBase, SnackbarAlert } from 'src/ds/DesignSystem'
import { useSnackbar } from 'src/ds/CustomHooksDs'

interface ModalQrCodeProps {
    open: boolean;
    onClose: () => void;
    empresaID: string;
}

//Todo: Está fazendo requisições em excesso, refatorar. 
//Todo: Botão de gerar pica na primeira renderização. 
const ModalQrCode = ({ open, onClose, empresaID }: ModalQrCodeProps) => {
    const dispatch = useDispatch()
    const listarQrCode = useSelector((state: RootState) => state.utils?.listarQrCode)
    const gerarQrCode = useSelector((state: RootState) => state.utils?.gerarQrCode)
    const inativarQrCode = useSelector((state: RootState) => state.utils?.inativarQrCode)
    const atualizarQrCode = useSelector((state: RootState) => state.utils?.atualizarQrCode)
    const dadosListarQrCode = listarQrCode?.data?.dados.lista[0]
    const [qrCode, setQrCode] = useState('')
    const printSnackbar = useSnackbar({ duration: 3000 })
    const gerarSnackbar = useSnackbar({ severity: 'success', message: 'QR Code gerado com sucesso', duration: 5000 })
    const inativarSnackbar = useSnackbar({ severity: 'success', message: 'QR Code inativado com sucesso', duration: 5000 })
    const atualizarSnackbar = useSnackbar({ severity: 'success', message: 'QR Code atualizado com sucesso', duration: 5000 })
    const permisoesUsuario = useSelector((state: RootState) => state.config?.masterPage?.usuario?.permissoes)
    const devMode = useSelector((state: RootState) => state.utils?.devMode);

    useEffect(() => {
        if (open) dispatch(listarQrCodeStart(empresaID))
        return () => {
            dispatch(limparQrCode())
            setQrCode('')
        }
    }, [open])

    useEffect(() => {
        if (listarQrCode?.data?.sucesso && listarQrCode?.data?.dados.lista.length > 0) {
            dispatch(gerarQrCodeStart(empresaID))
        }
    }, [listarQrCode])

    useEffect(() => {
        if (gerarQrCode?.data?.sucesso === true) {
            setQrCode(gerarQrCode.data.qrcode)
        } else if (gerarQrCode?.error) {
            gerarSnackbar.showSnackbar({
                message: gerarQrCode?.error,
                severity: 'error'
            })
            setQrCode('')
        }
    }, [gerarQrCode])

    useEffect(() => {
        if (!permisoesUsuario?.['empresa.editar']) return
        if (atualizarQrCode?.data?.sucesso === true) {
            atualizarSnackbar.showSnackbar({
                message: 'QR Code gerado com sucesso',
                severity: 'success'
            })
            dispatch(listarQrCodeStart(empresaID))
        } else if (atualizarQrCode?.error) {
            atualizarSnackbar.showSnackbar({
                message: atualizarQrCode?.error,
                severity: 'error'
            })
        }
    }, [atualizarQrCode])

    useEffect(() => {
        if (!permisoesUsuario?.['empresa.editar']) return
        if (inativarQrCode?.data?.sucesso) {
            inativarSnackbar.showSnackbar({
                message: inativarQrCode?.data?.mensagem,
                severity: 'success'
            })
            setQrCode('')
            dispatch(listarQrCodeStart(empresaID))
        } else if (inativarQrCode?.error) {
            inativarSnackbar.showSnackbar({
                message: inativarQrCode?.error,
                severity: 'error'
            })
        }
    }, [inativarQrCode])

    const handlePrint = () => {
        printSnackbar.showSnackbar({ severity: 'warning', message: 'Feche a nova guia antes de continuar' })
        setTimeout(() => {
            const printWindow = window.open('', '_blank');
            if (printWindow) {
                printWindow.document.write(`
                    <html>
                      <head>
                        <title>Imprimir QR Code</title>
                        <style>
                          body {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100vh;
                            margin: 0;
                          }
                          img {
                            max-width: 350px;
                            max-height: 350px;
                            width: 100%;
                          }
                        </style>
                      </head>
                      <body>
                        <img src="data:image/png;base64,${qrCode}" alt="QR Code" />
                        <script>
                        ${devMode
                        ? `const img = document.createElement('img');
                        img.src = 'https://i.gifer.com/4KY.gif';
                        img.style.width = '350px';
                        img.style.height = '350px';
                        document.body.appendChild(img);`
                        : ` setTimeout(() => {
                            // Garante que a imagem seja exibida antes de imprimir
                            window.print();
                            window.close();
                        }, 200)`}
                        </script>
                      </body>
                    </html>
                `);
            }
        }, 100)
    };

    const handleAtualizar = () => { dispatch(atualizarQrCodeStart(empresaID)) }

    const handleGerar = () => { dispatch(atualizarQrCodeStart(empresaID)) }

    const handleInativar = () => {
        dispatch(inativarQrCodeStart(listarQrCode?.data?.dados.lista[0].empresaQrcodeID))
    }

    return (
        <ModalBase
            open={open}
            onClose={onClose}
            size={'sm'}
            headerModal={{
                title: 'QR Code',
            }}
        >
            <ContainerBodyModal>
                {listarQrCode?.data?.dados.lista.length > 0 || qrCode
                    ? (gerarQrCode?.loading
                        ? <LoadingContainer />
                        : (
                            <Grid2 container justifyContent='center' spacing={2} alignContent='flex-start' height={'100%'}>
                                {qrCode && (
                                    <>
                                        <Grid2 size={{ xs: 12 }}>
                                            <Typography variant='body1' align='center'>
                                                Escaneie o QR Code com o aplicativo do entregador para ter acesso aos pedidos em lista.
                                            </Typography>
                                        </Grid2>
                                        <Grid2 size={{ xs: 12, sm: 8, md: 6 }}>
                                            <img
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    width: '100%',
                                                    display: 'block',
                                                    margin: '0 auto',

                                                }}
                                                src={`data:image/png;base64,${qrCode}`}
                                                alt="QR Code"
                                            />
                                        </Grid2>
                                        <Grid2 size={{ xs: 12 }}>
                                            <Typography variant='body1' align='center'>
                                                Gerado em {dadosListarQrCode?.dataCadastro}
                                            </Typography>
                                        </Grid2>
                                    </>
                                )}
                                <Grid2 size={{ xs: 12, sm: 4, md: 3 }}>
                                    <Button
                                        variant='iconeComTextoFilled'
                                        color='grey'
                                        sx={{ width: '100%' }}
                                        onClick={handlePrint}
                                        startIcon={<PrintIcon />}
                                    >
                                        Imprimir
                                    </Button>
                                </Grid2>
                                {permisoesUsuario?.['empresa.editar'] && (
                                    <>
                                        <Grid2 size={{ xs: 12, sm: 4, md: 3 }} >
                                            <Button
                                                variant='iconeComTextoFilled'
                                                color='green'
                                                sx={{ width: '100%' }}
                                                onClick={handleAtualizar}
                                                startIcon={<UpdateIcon />}
                                            >
                                                Atualizar
                                            </Button>
                                        </Grid2>
                                        <Grid2 size={{ xs: 12, sm: 4, md: 3 }} >
                                            <Button
                                                variant='iconeComTextoFilled'
                                                color='red'
                                                sx={{ width: '100%' }}
                                                onClick={handleInativar}
                                                startIcon={<DeleteOutlinedIcon />}
                                            >
                                                Inativar
                                            </Button>
                                        </Grid2>
                                    </>
                                )}
                            </Grid2>
                        )
                    ) : (
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100%', height: '100%', width: '100%' }}>
                            <Typography variant='notFound' component={'p'} textAlign={'center'} mb={2}>
                                Nenhum QR Code Encontrado.
                            </Typography>
                            {permisoesUsuario?.['empresa.editar'] &&
                                <Grid2 justifyContent='center'>
                                    <Grid2 size={{ xs: 3 }}>
                                        <Button
                                            variant='containedFilled'
                                            color='green'
                                            onClick={handleGerar}
                                        >
                                            Gerar
                                        </Button>
                                    </Grid2>
                                </Grid2>}
                        </Container>
                    )
                }
            </ContainerBodyModal>
            <SnackbarAlert {...printSnackbar} />
            <SnackbarAlert {...gerarSnackbar} />
            <SnackbarAlert {...inativarSnackbar} />
            <SnackbarAlert {...atualizarSnackbar} />
        </ModalBase >
    )
}

export default ModalQrCode