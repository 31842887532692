import { Box, Button } from '@mui/material'
import React from 'react'
import { BaseModal } from 'src/ds'
import { useResponsive } from 'src/ds/customHooks'

const ModalVideos = ({ open, onClose }: any) => {
    const { isMobile } = useResponsive()
    return (
        //@ts-ignore
        <BaseModal open={open} onClose={onClose}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: isMobile ? '8px' : '16px',
                    width: '140vh',
                    height: 'auto',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <iframe
                    style={{
                        aspectRatio: '16/8',
                        width: '100%',
                    }}
                    src={isMobile ? "https://www.youtube.com/embed/k_r5Y60pZfA" : "https://www.youtube.com/embed/6Xgfqp-HaX4"}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
                <Button onClick={onClose} variant='containedFilled' sx={{ width: '20%', marginTop: '16px' }}>Comece a utilizar</Button>
            </Box>
        </BaseModal>
    )
}

export default ModalVideos