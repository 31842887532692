import { Avatar, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Entregador } from '../../dtos/EntregadoresDTO';
import { dinheiro } from '../../ds/common';
import { ButtonTableOrder, SnackbarAlert, TableRowLoading, TableRowNotFound } from 'src/ds/DesignSystem';
import { ArrowDownward, ArrowUpward, Edit, NotificationAdd, Visibility } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { AvatarWithModal } from 'src/ds/DesignSystem';
import EditarEntregador from 'src/components/Painel/ModalEntregadores/EditarEntregador';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { limparPush, listaEntregadoresStart } from 'src/store/reducers/entregador';
import EnviarPush from './EnviarPush';
import { useMultiResponseSnackbar, useSnackbar } from 'src/ds/CustomHooksDs';

interface TabelaEntregadoresProps {
    entregadores: Entregador[] | undefined;
    loading: boolean;
    fezRequisicao?: boolean;
}

const TabelaEntregadores = ({ entregadores, loading, fezRequisicao }: TabelaEntregadoresProps) => {
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        email: 'asc',
        telefone: 'asc',
        nomeTipoVeiculo: 'asc',
        dataEntregadorSolicitacao: 'asc',
        pontuacaoTotal: 'asc',
        creditoPreAprovado: 'asc',
        situacaoStr: 'asc',
        notaMedia: 'asc',
    });
    const [entregador, setEntregador] = useState<Entregador | null>(null);
    const [showEditar, setShowEditar] = useState(false);
    const [showPush, setShowPush] = useState(false);
    const [atualizar, setAtualizar] = useState(false);
    const navigate = useNavigate();
    const snack = useSnackbar({ duration: 2000 });
    const response = useSelector((state: any) => state.entregadores?.push);
    useMultiResponseSnackbar([{
        response,
        clearAction: () => { dispatch(limparPush()) }
    }], snack);

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        const updatedSortDirection = Object.keys(sortDirection).reduce((acc, key) => {
            acc[key] = key === column ? newSortDirection : 'asc';
            return acc;
        }, {} as { [key: string]: 'asc' | 'desc' });

        setSortDirection(updatedSortDirection);

        const payload = {
            paginacao: 20,
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
            filtros: '',
            titulos: '',
            variaveis: '',
            formato: '',
        };

        dispatch(listaEntregadoresStart(payload));
    };

    const dispatch = useDispatch();

    return (
        <TableContainer
            sx={{
                width: '100%',
                overflowY: 'auto',
                margin: '0 auto',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow sx={{ borderTop: '1px solid #ccc' }}>
                        <TableCell sx={{ width: '5%' }} >
                            <ButtonTableOrder>
                                Imagem
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '15%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('nome')} sortDirection={sortDirection} nome="nome" >
                                Nome
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '15%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('email')} sortDirection={sortDirection} nome="email">
                                E-mail
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('telefone')} sortDirection={sortDirection} nome="telefone">
                                Telefone
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('nomeTipoVeiculo')} sortDirection={sortDirection} nome="nomeTipoVeiculo">
                                Veículo
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '15%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('dataEntregadorSolicitacao')} sortDirection={sortDirection} nome="dataEntregadorSolicitacao">
                                Cadastro
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('pontuacaoTotal')} sortDirection={sortDirection} nome="pontuacaoTotal">
                                Pontuação
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('creditoPreAprovado')} sortDirection={sortDirection} nome="creditoPreAprovado">
                                Saldo
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('situacaoStr')} sortDirection={sortDirection} nome="situacaoStr">
                                Situação
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('notaMedia')} sortDirection={sortDirection} nome="notaMedia">
                                Avaliação
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell sx={{ width: '10%' }} align="center">
                            <ButtonTableOrder>
                                Ações
                            </ButtonTableOrder>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : entregadores && entregadores.length > 0 ? (
                        entregadores.map((entregador) => (
                            <TableRow key={entregador.id} hover>
                                <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.perfilUrl ? <AvatarWithModal src={entregador.perfilUrl} alt={entregador.nome} /> : <Avatar>{entregador.nome.slice(0, 1).toUpperCase()}</Avatar>}
                                </TableCell>
                                <TableCell align="center" sx={{ py: 2, wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.nome}
                                </TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.email}
                                </TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.telefone}
                                </TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.nomeTipoVeiculo}
                                </TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.dataEntregadorSolicitacao}
                                </TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.pontuacaoTotal}
                                </TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {dinheiro(entregador.creditoPreAprovado)}
                                </TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.situacaoStr}
                                </TableCell>
                                <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                    {entregador.notaMedia}
                                </TableCell>
                                <TableCell align="center">
                                    <Stack direction="row" spacing={1} justifyContent="center">

                                        {!false &&
                                            <Tooltip title="Disparar push" arrow placement="top">
                                                <IconButton color="warning" sx={{ width: 24, height: 24, cursor: 'pointer' }} onClick={() => {
                                                    setEntregador(entregador);
                                                    setShowPush(true)
                                                }}>
                                                    <NotificationAdd />
                                                </IconButton>
                                            </Tooltip>}
                                        <Tooltip title="Editar" arrow placement="top">
                                            <IconButton color="secondary" sx={{ width: 24, height: 24, cursor: 'pointer' }} onClick={() => navigate(`/Usuario/Entregadores/${entregador.id}`)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                        {false && <Tooltip title="Visualizar" arrow placement="top">
                                            <IconButton color="primary" sx={{ width: 24, height: 24, cursor: 'pointer' }} onClick={() => { console.log(true) }}>
                                                <Visibility />
                                            </IconButton>
                                        </Tooltip>}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRowNotFound text="Nenhum resultado encontrado" fezRequisicao={fezRequisicao} />
                    )}
                </TableBody>
            </Table >
            <EditarEntregador
                entregador={entregador}
                showEditar={showEditar}
                setShowEditar={setShowEditar}
                atualizar={() => { setAtualizar(!atualizar) }}
                redefinir={true}
            />
            <EnviarPush open={showPush} setOpen={setShowPush} entregador={entregador} />
            <SnackbarAlert {...snack} />
        </TableContainer >
    );
};

export default TabelaEntregadores;
