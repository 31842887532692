import { Avatar, Box, Container, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { avaliarEntregadorReq, listarAvaliacao } from "../../../store/reducers/config";
import { useEffect, useState } from "react";
import { CardPrincipal, GridPainelLateral, listOfSkeleton, StarRating } from "../../../ds";
import { useSnackbar } from "../../../ds/customHooks";
import { HeaderCard } from "../../../ds/DesignSystem";

const AvaliarPedidos = ({ onClose }) => {
  const dispatch = useDispatch();
  const [avaliacoes, setAvaliacoes] = useState({});
  const avaliarEntregadorSnackbar = useSnackbar({ duration: 5000 });
  const theme = useTheme();
  const avaliacao = useSelector(state => state.config.avaliacao);
  const avaliacaoEntregador = useSelector(state => state.config.avaliacaoEntregador);
  const loading = useSelector(state => state.config.loading);

  // Lista os pedidos a serem avaliados
  useEffect(() => {
    dispatch(listarAvaliacao());
  }, []);

  // Avaliação Entregador
  useEffect(() => {
    if (avaliacaoEntregador?.data?.sucesso) {
      avaliarEntregadorSnackbar
        .showSnackbar(avaliacaoEntregador?.data.mensagem, 'success');
      dispatch(listarAvaliacao());
    } else if (avaliacaoEntregador?.erro) {
      avaliarEntregadorSnackbar
        .showSnackbar(avaliacaoEntregador?.erro.mensagem, 'error');
    }
  }, [avaliacaoEntregador]);

  const handleCloseClick = () => {
    onClose();
  };

  const handleStarRatingChange = (pedidoID, estrelas) => {
    setAvaliacoes(prevState => ({
      ...prevState,
      [pedidoID]: estrelas
    }));
    dispatch(avaliarEntregadorReq({ pedidoID, nota: estrelas }));
  };

  return (
    <GridPainelLateral container sx={{ padding: ".5rem" }}>
      <HeaderCard title={"Avaliar pedidos"} onClick={handleCloseClick} />
      {loading
        ? listOfSkeleton(3, "rounded", "120px")
        : !Array.isArray(avaliacao) || avaliacao.length === 0
          ? <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }} >
            <Typography variant="notFound" component={"p"} sx={{ textAlign: "center" }}>Não há pedidos para avaliar</Typography>
          </Box>
          : <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '100%',
              overflowY: 'auto',
              height: '100%',
              width: '100%',
              '@media (min-width: 1280px)': {
                maxWidth: '100%',
              },
              '@media (min-width: 600px)': {
                padding: '0rem',
              },
              '&::-webkit-scrollbar': {
                width: '0px',
              },
            }}
          >
            {avaliacao.map((item, index) => (
              <CardPrincipal
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "1rem",
                  padding: "1.5rem 0.75rem",
                  width: "100%",
                  justifyContent: "space-between",
                  flexShrink: 0,
                }}
                key={index}
              >
                <Avatar src={item.entregadorUsuario.perfilUrl} sx={{ width: "60px", height: "60px" }} />
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%" }}>
                  <Typography sx={{ fontSize: "0.9rem", fontWeight: "700" }}>#{item.codigoQuatro}</Typography>
                  <Typography sx={{ fontSize: "0.8rem", color: "#888" }}>
                    Entregador: {item.entregadorUsuario.nome.length > 8
                      ? `${item.entregadorUsuario.nome.substring(0, 8)}...`
                      : item.entregadorUsuario.nome}
                  </Typography>
                </div>
                <StarRating
                  estrelas={avaliacoes[item.pedidoID] || 0}
                  setEstrelas={(estrelas) => handleStarRatingChange(item.pedidoID, estrelas)}
                  cor={theme.palette.primary.main}
                />
              </CardPrincipal>
            ))}
          </Container>

      }

      {/* 
      
           */}
      <div style={{ marginTop: "auto" }}></div>
    </GridPainelLateral>
  );
};

export default AvaliarPedidos;
