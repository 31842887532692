import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import { adicionarEmpresaTabelaFailure, adicionarEmpresaTabelaStart, adicionarEmpresaTabelaSuccess, deletarEmpresaTabelaFailure, deletarEmpresaTabelaStart, deletarEmpresaTabelaSuccess, duplicarTabelaPagamentoFailure, duplicarTabelaPagamentoStart, duplicarTabelaPagamentoSuccess, excluirTabelaFailure, excluirTabelaPorBairroFailure, excluirTabelaPorBairroStart, excluirTabelaPorBairroSuccess, excluirTabelaPorKmFailure, excluirTabelaPorKmStart, excluirTabelaPorKmSuccess, excluirTabelaPorRaioFailure, excluirTabelaPorRaioStart, excluirTabelaPorRaioSuccess, excluirTabelaStart, excluirTabelaSuccess, excluirTipoDeVeiculoTabelaFailure, excluirTipoDeVeiculoTabelaStart, excluirTipoDeVeiculoTabelaSuccess, listarEmpresaTabelaPagamento, listarEmpresaTabelaPagamentoFailure, listarEmpresaTabelaPagamentoStart, listarEmpresaTabelaPagamentoSuccess, listarPorBairroFailure, listarPorBairroStart, listarPorBairroSuccess, listarPorKmCalculadoFailure, listarPorKmCalculadoStart, listarPorKmCalculadoSuccess, listarPorRaioFailure, listarPorRaioStart, listarPorRaioSuccess, listarTipoVeiculoTabelaPagamentoFailure, listarTipoVeiculoTabelaPagamentoStart, listarTipoVeiculoTabelaPagamentoSuccess, listaTabelaPagamentoFailure, listaTabelaPagamentoStart, listaTabelaPagamentoSuccess, salvarTabelaFailure, salvarTabelaPorBairroFailure, salvarTabelaPorBairroStart, salvarTabelaPorBairroSuccess, salvarTabelaPorKmFailure, salvarTabelaPorKmStart, salvarTabelaPorKmSuccess, salvarTabelaPorRaioFailure, salvarTabelaPorRaioStart, salvarTabelaPorRaioSuccess, salvarTabelaStart, salvarTabelaSuccess, salvarTipoVeiculoTabelaFailure, salvarTipoVeiculoTabelaStart, salvarTipoVeiculoTabelaSuccess } from '../reducers/tabelaPagamento';


export function* listaTabelaPagamento(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/TabelaValor/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listaTabelaPagamentoFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listaTabelaPagamentoSuccess(response.data));
    } catch (error) {
        yield put(listaTabelaPagamentoFailure(error.toString()));
    }
}

export function* listaEmpresaTabelaPagamento(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorEmpresa/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarEmpresaTabelaPagamentoFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarEmpresaTabelaPagamentoSuccess(response.data));
    } catch (error) {
        yield put(listarEmpresaTabelaPagamentoFailure(error.toString()));

    }
}

export function* listarTiposDeVeiculoTabelaPagamento(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TipoVeiculo/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarTipoVeiculoTabelaPagamentoFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarTipoVeiculoTabelaPagamentoSuccess(response.data));
    } catch (error) {
        yield put(listarTipoVeiculoTabelaPagamentoFailure(error.toString()));
    }
}

function* listarPorRaio(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorKm/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarPorRaioFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarPorRaioSuccess(response.data));
    } catch (error) {
        yield put(listarPorRaioFailure(error.toString()));
    }
}

function* listarPorBairro(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorBairro/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarPorBairroFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarPorBairroSuccess(response.data));
    } catch (error) {
        yield put(listarPorBairroFailure(error.toString()));
    }
}

function* listarPorKmCalulado(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorKmCalculado/Listar', form);
        if (response.data.sucesso !== true) {
            yield put(listarPorKmCalculadoFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(listarPorKmCalculadoSuccess(response.data));
    } catch (error) {
        yield put(listarPorKmCalculadoFailure(error.toString()));
    }
}

function* salvarTabela(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })

    try {
        const response = yield call(api.post, '/TabelaValor/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }

        yield put(salvarTabelaSuccess(response.data));
    } catch (error) {
        yield put(salvarTabelaFailure(error.toString()));
    }
}

function* excluirTabela(action) {
    try {
        const response = yield call(api.post, '/TabelaValor/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTabelaSuccess(response.data));
    } catch (error) {
        yield put(excluirTabelaFailure(error.toString()));
    }
}

function* adicionarEmpresaTabela(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorEmpresa/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(adicionarEmpresaTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(adicionarEmpresaTabelaSuccess(response.data));
    } catch (error) {
        yield put(adicionarEmpresaTabelaFailure(error.toString()));
    }
}

function* deletarEmpresaTabela(action) {
    try {
        const response = yield call(api.post, '/TabelaValorEmpresa/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(adicionarEmpresaTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(deletarEmpresaTabelaSuccess(response.data));
    } catch (error) {
        yield put(deletarEmpresaTabelaFailure(error.toString()));
    }
}

function* salvarTIpoVeiculoTabelaPagamento(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorTipoVeiculo/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTipoVeiculoTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(salvarTipoVeiculoTabelaSuccess(response.data));
    } catch (error) {
        yield put(salvarTipoVeiculoTabelaFailure(error.toString()));
    }
}

function* excluirTipoDeVeiculoTabela(action) {
    try {
        const response = yield call(api.post, '/TabelaValorTipoVeiculo/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTipoDeVeiculoTabelaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTipoDeVeiculoTabelaSuccess(response.data));
    } catch (error) {
        yield put(excluirTipoDeVeiculoTabelaFailure(error.toString()));
    }
}

function* salvarTabelaPorRaio(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorKm/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTabelaPorRaioFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(salvarTabelaPorRaioSuccess(response.data));
    } catch (error) {
        yield put(salvarTabelaPorRaioFailure(error.toString()));
    }
}

function* excluirTabelaPorRaio(action) {
    try {
        const response = yield call(api.post, '/TabelaValorKm/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTabelaPorRaioFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTabelaPorRaioSuccess(response.data));
    } catch (error) {
        yield put(excluirTabelaPorRaioFailure(error.toString()));
    }
}

function* excluirTabelaPorBairro(action) {
    try {
        const response = yield call(api.post, '/TabelaValorBairro/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTabelaPorBairroFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTabelaPorBairroSuccess(response.data));
    } catch (error) {
        yield put(excluirTabelaPorBairroFailure(error.toString()));
    }
}

function* salvarTabelaPorKm(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => {
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorKmCalculado/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTabelaPorKmFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(salvarTabelaPorKmSuccess(response.data));
    } catch (error) {
        yield put(salvarTabelaPorKmFailure(error.toString()));
    }
}

function* excluirTabelaPorKm(action) {
    try {
        const response = yield call(api.post, '/TabelaValorKmCalculado/Excluir?id=' + action.payload);
        if (response.data.sucesso !== true) {
            yield put(excluirTabelaPorKmFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(excluirTabelaPorKmSuccess(response.data));
    } catch (error) {
        yield put(excluirTabelaPorKmFailure(error.toString()));
    }
}

function* salvarTabelaPorBairro(action) {
    const form = new FormData();
    Object.keys(action.payload).forEach((key) => { 
        form.append(key, action.payload[key]);
    })
    try {
        const response = yield call(api.post, '/TabelaValorBairro/Salvar', form);
        if (response.data.sucesso !== true) {
            yield put(salvarTabelaPorBairroFailure(response.data));
        }
        yield put(salvarTabelaPorBairroSuccess(response.data));
        
    } catch (error) {
        yield put(salvarTabelaPorBairroFailure(error.toString()));
    }
}

function* duplicarTabelaPagamento(action) {
    try {
        const response = yield call(api.post, '/TabelaValor/Duplicar?tabelaValorID=' + action.payload.tabelaValorID);
        if (response.data.sucesso !== true) {
            yield put(duplicarTabelaPagamentoFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(duplicarTabelaPagamentoSuccess(response.data));
    } catch (error) {
        yield put(duplicarTabelaPagamentoFailure(error.toString()));
    }
}
function* ListarTabelaPagamento() {
    yield takeLatest(listaTabelaPagamentoStart.type, listaTabelaPagamento);
    yield takeLatest(listarEmpresaTabelaPagamentoStart.type, listaEmpresaTabelaPagamento);
    yield takeLatest(listarTipoVeiculoTabelaPagamentoStart.type, listarTiposDeVeiculoTabelaPagamento);
    yield takeLatest(listarPorRaioStart.type, listarPorRaio);
    yield takeLatest(listarPorBairroStart.type, listarPorBairro);
    yield takeLatest(listarPorKmCalculadoStart.type, listarPorKmCalulado);
    yield takeLatest(salvarTabelaStart.type, salvarTabela);
    yield takeLatest(excluirTabelaStart.type, excluirTabela);
    yield takeLatest(adicionarEmpresaTabelaStart.type, adicionarEmpresaTabela);
    yield takeLatest(deletarEmpresaTabelaStart.type, deletarEmpresaTabela);
    yield takeLatest(salvarTipoVeiculoTabelaStart.type, salvarTIpoVeiculoTabelaPagamento);
    yield takeLatest(excluirTipoDeVeiculoTabelaStart.type, excluirTipoDeVeiculoTabela);
    yield takeLatest(salvarTabelaPorRaioStart.type, salvarTabelaPorRaio);
    yield takeLatest(excluirTabelaPorRaioStart.type, excluirTabelaPorRaio);
    yield takeLatest(salvarTabelaPorKmStart.type, salvarTabelaPorKm);
    yield takeLatest(excluirTabelaPorKmStart.type, excluirTabelaPorKm);
    yield takeLatest(salvarTabelaPorBairroStart.type, salvarTabelaPorBairro);
    yield takeLatest(excluirTabelaPorBairroStart.type, excluirTabelaPorBairro);
    yield takeLatest(duplicarTabelaPagamentoStart.type, duplicarTabelaPagamento);
}

export default ListarTabelaPagamento;
