import { Box, Card, Typography } from "@mui/material";
import { CardFormFiltros, CardFormFiltrosRef } from "../../ds/Forms";
import { useForm } from "react-hook-form";
import TabelaEntregadores from "../../components/Entregadores/Tabela";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ExportarTabela from "src/ds/components/ExportarTabela";
import { listarTipoDeVeiculoStart } from "src/store/reducers/config";
import { TableNavigation } from "src/ds/DesignSystem";
import { RootState } from "src/store/reducers";

const Entregadores = () => {
    const entregadoresDados = useSelector((state: any) => state.entregadores.listaEntregadores?.data?.dados);
    const entregadores = entregadoresDados?.lista;
    const loadingEntregadores = useSelector((state: any) => state.entregadores.listaEntregadores.loading);
    const tiposDeVeiculo = useSelector((state: any) => state.config?.tiposDeVeiculos?.dados?.lista);
    const dispatch = useDispatch();
    const cardRef = useRef<CardFormFiltrosRef>(null);
    const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true);

    const hookForm = useForm({
        defaultValues: {
            nome: '',
            cpf: '',
            dtInicial: '',
            dtFinal: '',
            tagsFiltro: '',
            tipoVeiculoID: '',
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '(entregador || dataEntregadorSolicitacao != null)',
            titulos: '',
            variaveis: '',
            formato: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const { watch, getValues, setError, clearErrors } = hookForm;

    useEffect(() => {
        if (primeiraRenderizacao) {
            cardRef.current?.onSubmit();
            setPrimeiraRenderizacao(false);
            return;
        }

        const subscription = watch((value, { name }) => {
            if ((name === 'pagina' || name === 'paginacao')) {
                cardRef.current?.onSubmit();
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [primeiraRenderizacao]);

    useEffect(() => {
        dispatch(listarTipoDeVeiculoStart({
            paginacao: -1,
            pagina: 1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }));
    }, []);

    return (
        <Box>
            <Box sx={{ p: 2 }}>
                <CardFormFiltros
                    ref={cardRef}
                    formMethods={hookForm}
                    dispatchMethods={{
                        actionName: 'listaEntregadoresStart'
                    }}
                    fields={[
                        { name: "Nome do entregador", value: "nome" },
                        { name: "Data inicial", type: "date", value: "dtInicial", label: "Data inicial" },
                        { name: "Data final", type: "date", value: "dtFinal", label: "Data final" },
                        {
                            name: "Tipo de veículo", value: "tipoVeiculoID",
                            type: "customSelect",
                            options: tiposDeVeiculo,
                            valueKey: 'tipoVeiculoID',
                            labelKey: 'nome',
                        },
                        { name: "CPF", value: "cpf" },
                        { name: "Tags", value: "tagsFiltro" },
                    ]}
                    loading={loadingEntregadores}
                />
            </Box>
            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">Entregadores</Typography>
                    <ExportarTabela dispatchMethods={{ actionName: 'exportarTabelaEntregadores' }} loading={loadingEntregadores} />
                </Box>
                {entregadoresDados &&
                    <TableNavigation
                        indiceInicial={entregadoresDados.indiceInicial}
                        indiceFinal={entregadoresDados.indiceFinal}
                        indiceTotal={entregadoresDados.total}
                        ultimaPagina={entregadoresDados?.ultimaPagina}
                        hookForm={hookForm}
                    />
                }
                <TabelaEntregadores entregadores={entregadores} loading={loadingEntregadores} fezRequisicao={cardRef.current?.fezRequisicao} />
            </Card>
        </Box >
    );
};

export default Entregadores;
