import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../ds';
import { Box } from '@mui/material';
import {
  buscarTemplateRequest,
  emAndamentoStart,
  fetchMasterPageStart,
  listarEntregadoresRequest,
  listarRequest,
  walletStart,
  listarEmpresasPick,
  definirTipoDeUsuario,
} from '../../store/reducers/config';
import { setCookies } from '../../utils';
import { toggleCriarPedido } from 'src/store/reducers/utils';

// Interface para empresa
interface Empresa {
  empresaID: string;
}

// Interface para a MasterPage (corrigida)
interface MasterPage {
  empresas: Empresa[];
  pickSelecionada?: {
    pickID: string;
  };
}

// Estado de login
interface LoginState {
  tokenUsuario: string;
  usuario: {
    id: string;
    nome: string;
  };
}

// Estado de configuração
interface ConfigState {
  masterPage?: MasterPage;
  empresasPick?: any;
}

// Estado raiz
interface RootState {
  login: LoginState;
  config: ConfigState;
}

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const token = useSelector((state: RootState) => state.login.tokenUsuario);
  const masterPage = useSelector((state: RootState) => state.config?.masterPage);
  const usuario = useSelector((state: RootState) => state.login.usuario);
  const empresasPick = useSelector((state: RootState) => state.config.empresasPick?.dados?.lista);


  const dispatch = useDispatch();


  useEffect(() => {
    if (masterPage?.pickSelecionada?.pickID) {
      localStorage.setItem('@xpick', masterPage.pickSelecionada.pickID);
    }

    if (masterPage?.empresas && masterPage.empresas.length > 0) {
      dispatch(definirTipoDeUsuario({ tipo: 'Representante', empresas: masterPage.empresas }));
    } else {
      dispatch(definirTipoDeUsuario({ tipo: 'SAAS', empresas: empresasPick }));
    }
  }, [masterPage]);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!hasFetchedData.current) {
          localStorage.setItem('@token', token);
          setCookies('x-token', token);
          dispatch(buscarTemplateRequest());
          dispatch(fetchMasterPageStart());
          dispatch(
            emAndamentoStart({
              empresaID: usuario.id,
            })
          );
          dispatch(walletStart());
          dispatch(listarRequest());
          hasFetchedData.current = true;
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    if (token && !hasFetchedData.current) {
      fetchInitialData();
    }
  }, [token]);

  const abrirCriarPedido = () => {
    dispatch(toggleCriarPedido());
  }

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <Box sx={{ maxWidth: '100vw', minHeight: '100vh' }}>
      {/*@ts-ignore */}
      <Header abrirCriarPedido={abrirCriarPedido} />
      <Box sx={(theme) => ({ minHeight: 'calc(100vh - 52px)'})}>
        {children}
      </Box>
    </Box>
  );
};

export default ProtectedRoute;
