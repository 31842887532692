import React, { useEffect, useState } from 'react';
import { BaseModal, GridBodyModal, HeaderModal, LoadingContainer } from '../../../ds';
import { Typography, Avatar, Table, TableBody, TableRow, TableCell, TableHead, Box, Grid2 } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { listarCidades, listarParceiros } from '../../../store/reducers/utils';
import { GOOGLE_API_KEY } from '../../../utils';
import { telefone } from '../../../ds/common';

const ModalParceiros = ({ open, onClose }) => {
    const [locationError, setLocationError] = useState(null);
    const [userCity, setUserCity] = useState(null);
    const [userCityId, setUserCityId] = useState(null);
    const listaDeCidades = useSelector(state => state.utils.cidades);
    const parceiros = useSelector(state => state.utils.parceiros);
    const empresa = useSelector(state => state.config?.masterPage?.pickSelecionada);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(listarCidades());
    }, [dispatch]);

    useEffect(() => {
        if (empresa) {
            setUserCity(empresa?.cidadeNome);
        }
    }, [empresa]);

    useEffect(() => {

        if (userCity && listaDeCidades.length > 0) {
            const city = listaDeCidades.find(cidade => cidade.cidadeNome?.toLowerCase() === userCity?.toLowerCase());
            if (city) {
                setUserCityId(city.cidadeID);
                setLocationError(null);
                dispatch(listarParceiros({ cidadeID: city.cidadeID }));
            }
        }
    }, [userCity, listaDeCidades, dispatch]);

    return (
        <BaseModal open={open} onClose={onClose}>
            <HeaderModal title="Terceirização da operação logística" onClose={onClose} />
            <GridBodyModal alignItems="center" size='lg' sx={{ width: '100%', overflow: 'auto' }}>
                {locationError
                    ? (!userCityId || !parceiros.length) ? <LoadingContainer /> : <Typography sx={{ p: 2, textAlign: 'center' }}> {locationError}</Typography>
                    : (
                        <Grid2 item sx={{ p: 2, width: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Table sx={{ maxWidth: '900px', width: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Marca</TableCell>
                                            <TableCell align="center">Nome do parceiro</TableCell>
                                            <TableCell align="center">Telefone</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {parceiros.map((parceiro) => (
                                            <TableRow key={parceiro.id}>
                                                <TableCell align="right">
                                                    <Avatar src={parceiro?.logoUrl} alt={parceiro.nome} />
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Typography>{parceiro.nome}</Typography>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Typography>{telefone(parceiro.telefoneWhatsapp) || telefone(parceiro.telefoneComercial) || 'Não informado'}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid2>
                    )}
            </GridBodyModal>
        </BaseModal>
    );
}

export default ModalParceiros;
