import { KeyboardArrowDown, KeyboardArrowUp, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Chip, Collapse, IconButton, TableCell, TableRow, Theme, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { dinheiro } from 'src/ds/common';
import { ChipSituacao } from 'src/ds/DesignSystem';
import Detalhes from './Detalhes';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import dayjs from 'dayjs';
import ModalCartao from 'src/ds/components/ModalCartao';

interface Row {
    rowID?: string;
    codigo?: number;
    assinaturaFaturaID?: string;
    planoAssinaturaID?: string;
    dataCadastro?: string;
    dataFechamento?: string;
    dataPeriodoInicio?: string;
    dataPeriodoFim?: string;
    descricao?: string;
    tipoFatura?: number;
    faturaAtrasada?: boolean;
    faturaNaoPaga?: boolean;
    telefoneResponsavel?: string;
    emailFinanceiroResponsavel?: string;
    usuarioResponsavel?: string;
    enderecoStr?: string;
    valor?: number;
    formaPagamentoNome?: string;
    formaPagamentoID?: string;
    pickIDs?: string[];
    itens?: {
        assinaturaFaturaItemID?: string;
        descricao?: string;
        dataCadastro?: string;
        valor?: number;
    }[];
    mensagemCliente?: string | null;
    notaFiscal?: {
        notaFiscalID: "a879741b-4ddf-11ef-ba6f-0aa63e100dd7",
        "chave": "aadc2e15-9169-4b63-9607-dc46484f74e6",
        "tipo": "NFSe",
        "dataCadastro": "29/07/2024 16:20:52",
        "dataSituacao": "29/07/2024 16:20:52",
        "log": "{\"metodo\":\"GerarNfse\",\"success\":true,\"status\":\"aprovado\",\"nfse\":[{\"numero\":\"1570\",\"codigo_verificacao\":\"F6A71E01A\",\"data_emissao\":\"2024-07-29T16:20:49\"}]}",
        "motivoCancelamento": null,
        "numeroNfe": 1566,
        "numeroSerie": 3,
        "recibo": "",
        "situacao": "aprovado",
        "urlDanfe": "https://api.webmaniabr.com/nfse/aadc2e15-9169-4b63-9607-dc46484f74e6",
        "urlXml": "https://api.webmaniabr.com/xmlnfse/aadc2e15-9169-4b63-9607-dc46484f74e6",
        "xmlID": "a879741d-4ddf-11ef-ba6f-0aa63e100dd7",
        "xml": {
            "arquivoID": "a879741d-4ddf-11ef-ba6f-0aa63e100dd7",
            "nomeArquivo": "danfe-a879741b-4ddf-11ef-ba6f-0aa63e100dd7.xml",
            "tamanho": 6245,
            "criadorUsuario": null,
            "dataCadastro": "29/07/2024 16:20:52"
        },
        "danfeID": "a879741c-4ddf-11ef-ba6f-0aa63e100dd7",
        "danfe": {
            "arquivoID": "a879741c-4ddf-11ef-ba6f-0aa63e100dd7",
            "nomeArquivo": "danfe-a879741b-4ddf-11ef-ba6f-0aa63e100dd7.pdf",
            "tamanho": 108,
            "criadorUsuarioID": null,
            "dataCadastro": "29/07/2024 16:20:52"
        }
    };
}

interface RowProps {
    row: Row;
    index: string;
    handleToggle: (assinaturaFaturaID: string) => void;
    openRow: boolean;
}

const RowFatura = ({ row, index, handleToggle, openRow }: RowProps) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme();
    const template = useSelector((state: RootState) => state.config?.template?.template);
    return (
        <React.Fragment key={index}>
            <TableRow>
                <TableCell align='center'><Chip label={row.codigo} color='primary' /></TableCell>
                <TableCell align='center'>{dayjs(row.dataFechamento).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='center'>{dinheiro(row.valor)}</TableCell>
                <TableCell align="center">
                    <ChipSituacao
                        situacao={row?.faturaAtrasada ? "Atrasada" : "Pago"}
                        label={row?.faturaAtrasada ? "Atrasada" : "Pago"}
                        sx={{
                            backgroundColor: row?.faturaAtrasada
                                ? theme.palette.error.main
                                : theme.palette.success.main,
                        }}
                    />
                </TableCell>
                <TableCell align="center">
                    {row?.faturaAtrasada &&
                        <Button
                            variant="containedFilled"
                            sx={{ width: "min-content" }}
                            onClick={() => setOpen(true)}
                            color="primary">
                            Pagar
                        </Button>
                    }
                    {!row?.faturaAtrasada &&
                        <Button
                            variant='contained'
                            sx={{ width: 'min-content' }}
                            color='secondary'
                            onClick={() => window.open(row.notaFiscal?.urlDanfe, '_blank')}
                        >
                            Ver nota fiscal
                        </Button>
                    }
                </TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => handleToggle(index)}>
                        {openRow ? <VisibilityOff color='secondary' /> : <Visibility color='primary' />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={7} sx={{ p: 0 }}>
                    <Collapse in={openRow} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                
                            }}
                        >
                            <Detalhes selectedItem={row} template={template} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <ModalCartao open={open} onClose={() => { setOpen(false) }} dispatchMethods={{ actionName: "pagarTransacaoCartaoStart" }} />
        </React.Fragment>
    )
}

export default RowFatura