import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoxLoading } from 'src/ds/DesignSystem';

const Redirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const loadPixelScript = () => {
            const f = window;
            const b = document;
            const e = 'script';
            const v = 'https://connect.facebook.net/en_US/fbevents.js';
            let n;
            const t = b.createElement(e);
            const s = b.getElementsByTagName(e)[0];

            if (f.fbq) return;

            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = '2.0';
            n.queue = [];
            t.async = true;
            t.src = v;
            s.parentNode.insertBefore(t, s);
        };

        const addMetaPixel = () => {
            if (!window.fbq) {
                loadPixelScript();
                window.fbq('init', '525115865853668');
                window.fbq('track', 'PageView');
            } else {
                window.fbq('track', 'PageView');
            }
        };

        addMetaPixel();

        const timeoutId = setTimeout(() => {
            navigate('/Painel');
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [navigate]);

    return (
        <Box justifyContent={'center'} alignItems={'center'} display={'flex'} sx={{ height: '100vh' }}>
            <BoxLoading loading />
        </Box>
    );
};

export default Redirect;
