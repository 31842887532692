import { createSlice } from "@reduxjs/toolkit";
import { DefaultAsyncState } from ".";
import { AlterarSituacaoDTO, TaxaExtraDTO } from "../../dtos/TaxaExtraDTO";

interface InitialState {
  lista: DefaultAsyncState<TaxaExtraDTO>;
  alterarSituacao: DefaultAsyncState<AlterarSituacaoDTO>;
  editar: DefaultAsyncState<any>;
  salvarTaxaExtra: DefaultAsyncState<any>;
  excluir: DefaultAsyncState<any>;
  listarTiposVeiculo: DefaultAsyncState<any>;
  salvarTiposVeiculo: DefaultAsyncState<any>;
  excluirTipoDeVeiculo: DefaultAsyncState<any>;
  listarPorDiaDaSemana: DefaultAsyncState<any>;
  salvarPorDiaDaSemana: DefaultAsyncState<any>;
  excluirPorDiaDaSemana: DefaultAsyncState<any>;
  salvarAgendamentoPorData: DefaultAsyncState<any>;
}

const initialState: InitialState = {
  lista: {
    loading: false,
    data: null,
    error: null,
  },
  alterarSituacao: {
    loading: false,
    data: null,
    error: null,
  },
  editar: {
    loading: false,
    data: null,
    error: null,
  },
  salvarTaxaExtra: {
    loading: false,
    data: null,
    error: null,
  },
  excluir: {
    loading: false,
    data: null,
    error: null,
  },
  listarTiposVeiculo: {
    loading: false,
    data: null,
    error: null,
  },
  salvarTiposVeiculo: {
    loading: false,
    data: null,
    error: null,
  },
  excluirTipoDeVeiculo: {
    loading: false,
    data: null,
    error: null
  },
  listarPorDiaDaSemana: {
    loading: false,
    data: null,
    error: null
  },
  salvarPorDiaDaSemana: {
    loading: false,
    data: null,
    error: null
  },
  excluirPorDiaDaSemana: {
    loading: false,
    data: null,
    error: null
  },
  salvarAgendamentoPorData: {
    loading: false,
    data: null,
    error: null
  }
};

const taxaExtraSlice = createSlice({
  name: "taxaExtraReducer",
  initialState: initialState,
  reducers: {
    taxaExtraStart(state, action) {
      state.lista.loading = true;
      state.lista.data = null;
      state.lista.error = null;
    },
    taxaExtraSuccess(state, action) {
      state.lista.loading = false;
      state.lista.data = action.payload;
      state.lista.error = null;
    },
    taxaExtraFailure(state, action) {
      state.lista.loading = false;
      state.lista.data = null;
      state.lista.error = action.payload;
    },
    alterarSituacaoStart(state, action) {
      state.alterarSituacao.loading = true;
      state.alterarSituacao.data = null;
      state.alterarSituacao.error = null;
    },
    alterarSituacaoSuccess(state, action) {
      state.alterarSituacao.loading = false;
      state.alterarSituacao.data = action.payload;
      state.alterarSituacao.error = null;
    },
    alterarSituacaoFailure(state, action) {
      state.alterarSituacao.loading = false;
      state.alterarSituacao.data = null;
      state.alterarSituacao.error = action.payload;
    },
    // utils
    alterarSituacaoReset(state) {
      state.alterarSituacao = initialState.alterarSituacao;
    },
    salvarTaxaExtraStart(state, action) {
      state.salvarTaxaExtra.loading = true;
      state.salvarTaxaExtra.data = null;
      state.salvarTaxaExtra.error = null;
    },
    salvarTaxaExtraSuccess(state, action) {
      state.salvarTaxaExtra.loading = false;
      state.salvarTaxaExtra.data = action.payload;
      state.salvarTaxaExtra.error = null;
    },
    salvarTaxaExtraFailure(state, action) {
      state.salvarTaxaExtra.loading = false;
      state.salvarTaxaExtra.data = null;
      state.salvarTaxaExtra.error = action.payload;
    },
    limparTaxaExtra(state) {
      state.salvarTaxaExtra = initialState.salvarTaxaExtra;
    },
    excluirTaxaExtraStart(state, action) {
      state.excluir.loading = true;
      state.excluir.data = null;
      state.excluir.error = null;
    },
    excluirTaxaExtraSuccess(state, action) {
      state.excluir.loading = false;
      state.excluir.data = action.payload;
      state.excluir.error = null;
    },
    excluirTaxaExtraFailure(state, action) {
      state.excluir.loading = false;
      state.excluir.data = null;
      state.excluir.error = action.payload;
    },
    limparExcluir(state) {
      state.excluir = initialState.excluir
    },
    listarTiposDeVeiculoTaxaExtraStart(state, action) {
      state.listarTiposVeiculo.error = null;
      state.listarTiposVeiculo.loading = true;
    },
    listarTiposDeVeiculoTaxaExtraFailure(state, action) {
      state.listarTiposVeiculo.data = null;
      state.listarTiposVeiculo.error = action.payload;
      state.listarTiposVeiculo.loading = false;
    },
    listarTiposDeVeiculoTaxaExtraSuccess(state, action) {
      state.listarTiposVeiculo.data = action.payload;
      state.listarTiposVeiculo.error = null;
      state.listarTiposVeiculo.loading = false;
    },
    limparTiposDeVeiculoTaxaExtra(state) {
      state.listarTiposVeiculo = initialState.listarTiposVeiculo
    },
    salvarTipoDeVeiculoTaxaExtraStart(state, action) {
      state.salvarTiposVeiculo.error = null;
      state.salvarTiposVeiculo.loading = true;
    },
    salvarTipoDeVeiculoTaxaExtraFailure(state, action) {
      state.salvarTiposVeiculo.data = null;
      state.salvarTiposVeiculo.error = action.payload;
      state.salvarTiposVeiculo.loading = false;
    },
    salvarTipoDeVeiculoTaxaExtraSuccess(state, action) {
      state.salvarTiposVeiculo.data = action.payload;
      state.salvarTiposVeiculo.error = null;
      state.salvarTiposVeiculo.loading = false;
    },
    limparSalvarTipoDeVeiculoTaxaExtra(state) {
      state.salvarTiposVeiculo = initialState.salvarTiposVeiculo
    },
    excluirTipoDeVeiculoStart(state, action) {
      state.excluirTipoDeVeiculo.error = null;
      state.excluirTipoDeVeiculo.loading = true;
    },
    excluirTipoDeVeiculoFailure(state, action) {
      state.excluirTipoDeVeiculo.data = null;
      state.excluirTipoDeVeiculo.error = action.payload;
      state.excluirTipoDeVeiculo.loading = false;
    },
    excluirTipoDeVeiculoSuccess(state, action) {
      state.excluirTipoDeVeiculo.data = action.payload;
      state.excluirTipoDeVeiculo.error = null;
      state.excluirTipoDeVeiculo.loading = false;
    },
    limparExcluirTipoDeVeiculo(state) {
      state.excluirTipoDeVeiculo = initialState.excluirTipoDeVeiculo
    },
    listarPorDiaDaSemanaStart(state, action) {
      state.listarPorDiaDaSemana.error = null;
      state.listarPorDiaDaSemana.loading = true;
    },
    listarPorDiaDaSemanaSuccess(state, action) {
      state.listarPorDiaDaSemana.data = action.payload;
      state.listarPorDiaDaSemana.error = null;
      state.listarPorDiaDaSemana.loading = false;
    },
    listarPorDiaDaSemanaFailure(state, action) {
      state.listarPorDiaDaSemana.data = null;
      state.listarPorDiaDaSemana.error = action.payload;
      state.listarPorDiaDaSemana.loading = false;
    },
    salvarPorDiaDaSemanaStart(state, action) {
      state.salvarPorDiaDaSemana.error = null;
      state.salvarPorDiaDaSemana.loading = true;
    },
    salvarPorDiaDaSemanaSuccess(state, action) {
      state.salvarPorDiaDaSemana.data = action.payload;
      state.salvarPorDiaDaSemana.error = null;
      state.salvarPorDiaDaSemana.loading = false;
    },
    salvarPorDiaDaSemanaFailure(state, action) {
      state.salvarPorDiaDaSemana.data = null;
      state.salvarPorDiaDaSemana.error = action.payload;
      state.salvarPorDiaDaSemana.loading = false;
    },
    limparSalvarPorDiaDaSemana(state) {
      state.salvarPorDiaDaSemana = initialState.salvarPorDiaDaSemana
    },
    excluirPorDiaDaSemanaStart(state, action) {
      state.salvarPorDiaDaSemana.error = null;
      state.salvarPorDiaDaSemana.loading = true;
    },
    excluirPorDiaDaSemanaSuccess(state, action) {
      state.salvarPorDiaDaSemana.data = action.payload;
      state.salvarPorDiaDaSemana.error = null;
      state.salvarPorDiaDaSemana.loading = false;
    },
    excluirPorDiaDaSemanaFailure(state, action) {
      state.salvarPorDiaDaSemana.data = null;
      state.salvarPorDiaDaSemana.error = action.payload;
      state.salvarPorDiaDaSemana.loading = false;
    },
    limparExcluirPorDiaDaSemana(state) {
      state.salvarPorDiaDaSemana = initialState.excluirPorDiaDaSemana
    },
    salvarAgendamentoPorDataStart(state, action) {
      state.salvarAgendamentoPorData.error = null;
      state.salvarAgendamentoPorData.loading = true;
    },
    salvarAgendamentoPorDataSuccess(state, action) {
      state.salvarAgendamentoPorData.data = action.payload;
      state.salvarAgendamentoPorData.error = null;
      state.salvarAgendamentoPorData.loading = false;
    },
    salvarAgendamentoPorDataFailure(state, action) {
      state.salvarAgendamentoPorData.data = null;
      state.salvarAgendamentoPorData.error = action.payload;
      state.salvarAgendamentoPorData.loading = false;
    },
    limparSalvarAgendamentoPorData(state) {
      state.salvarAgendamentoPorData = initialState.salvarAgendamentoPorData
    }
  },
});

export const {
  taxaExtraStart,
  taxaExtraSuccess,
  taxaExtraFailure,
  alterarSituacaoStart,
  alterarSituacaoSuccess,
  alterarSituacaoFailure,
  alterarSituacaoReset,
  salvarTaxaExtraStart,
  limparTaxaExtra,
  salvarTaxaExtraFailure,
  salvarTaxaExtraSuccess,
  excluirTaxaExtraFailure,
  excluirTaxaExtraStart,
  excluirTaxaExtraSuccess,
  limparExcluir,
  limparTiposDeVeiculoTaxaExtra,
  listarTiposDeVeiculoTaxaExtraFailure,
  listarTiposDeVeiculoTaxaExtraSuccess,
  listarTiposDeVeiculoTaxaExtraStart,
  limparSalvarTipoDeVeiculoTaxaExtra,
  salvarTipoDeVeiculoTaxaExtraFailure,
  salvarTipoDeVeiculoTaxaExtraStart,
  salvarTipoDeVeiculoTaxaExtraSuccess,
  excluirTipoDeVeiculoFailure,
  excluirTipoDeVeiculoStart,
  excluirTipoDeVeiculoSuccess,
  limparExcluirTipoDeVeiculo,
  listarPorDiaDaSemanaFailure,
  listarPorDiaDaSemanaStart,
  listarPorDiaDaSemanaSuccess,
  limparSalvarPorDiaDaSemana,
  salvarPorDiaDaSemanaFailure,
  salvarPorDiaDaSemanaStart,
  salvarPorDiaDaSemanaSuccess,
  excluirPorDiaDaSemanaFailure,
  excluirPorDiaDaSemanaStart,
  excluirPorDiaDaSemanaSuccess,
  limparExcluirPorDiaDaSemana,
  limparSalvarAgendamentoPorData,
  salvarAgendamentoPorDataFailure,
  salvarAgendamentoPorDataStart,
  salvarAgendamentoPorDataSuccess
} = taxaExtraSlice.actions;

export default taxaExtraSlice;
