import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClienteDTOS } from "../../dtos/ClientesDTO";

interface ListaClientesParams {
    pagina: number;
    paginacao: number;
    filtros?: string;
    empresaID?: string;
    dtInicial?: string;
    dtFinal?: string;
    empresaClienteID?: string;
    empresaNome?: string;
    dataCadastro?: string;
    nome?: string;
    ordenacaoCampo?: string;
    ordenacaoOrdem?: string;
}



interface ClienteState {
    loading: boolean;
    data: ClienteDTOS | null;
    error: string | null;
}

interface ClientesState {
    listaClientes: ClienteState;
}

const initialState: ClientesState = {
    listaClientes: {
        loading: false,
        data: null,
        error: null,
    },
};

const clientesSlice = createSlice({
    name: 'Clientes',
    initialState: initialState,
    reducers: {
        listaClientesStart(state, action: PayloadAction<ListaClientesParams>) {
            state.listaClientes.loading = true;
            state.listaClientes.error = null;
        },
        listaClientesFailure(state, action: PayloadAction<string>) {
            state.listaClientes.loading = false;
            state.listaClientes.error = action.payload;
        },
        listaClientesSuccess(state, action: PayloadAction<any>) {
            state.listaClientes.loading = false;
            state.listaClientes.data = action.payload;
        },
    },
});

export const {
    listaClientesStart,
    listaClientesFailure,
    listaClientesSuccess
} = clientesSlice.actions;

export default clientesSlice;
