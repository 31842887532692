import { QrCode } from '@mui/icons-material';
import { Box, Card, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { dinheiro } from 'src/ds/common';
import { useMultiResponseSnackbar, useSnackbar } from 'src/ds/CustomHooksDs';
import { ModalOpcoes, SnackbarAlert, TableNavigation, TableRowLoading, TableRowNotFound, ButtonTableOrder, ButtonLoading } from 'src/ds/DesignSystem'; // Importado ButtonTableOrder
import { RootState } from 'src/store/reducers';
import { gerarQrCodeStart } from 'src/store/reducers/utils';
import { comprarCreditosStart, consultarPagamentoStart, limparConsultarPagamento, minhasTransacoesStart } from 'src/store/reducers/wallet';
import QrCodeWallet from './QRCode';

const AbastecerWallet = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state: any) => state.wallet.minhasTransacoes.loading);
    const loadingComprar = useSelector((state: any) => state.wallet.comprarCreditos.loading);
    const data = useSelector((state: any) => state.wallet.minhasTransacoes.data);
    const qrCode = useSelector((state: RootState) => state.utils.gerarQrCode?.data?.qrcode);
    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: 20,
        }
    });

    useEffect(() => {
        dispatch(minhasTransacoesStart({ pagina: hookForm.getValues().pagina || 1, paginacao: hookForm.getValues().paginacao || 5 }));
    }, [dispatch, hookForm.watch('pagina'), hookForm.watch('paginacao')]);

    const snack = useSnackbar({ duration: 2000 });
    const response = useSelector((state: RootState) => state.wallet.consultarPagamento);
    const response2 = useSelector((state: RootState) => state.wallet.comprarCreditos);
    useMultiResponseSnackbar([
        { response, clearAction: () => dispatch(limparConsultarPagamento()) },
    ], snack);

    const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
    const [copiaECola, setCopiaECola] = useState<any>();
    const [rowID, setRowID] = useState<any>();
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        dataCadastro: 'asc',
        valor: 'asc',
        quantidadeCreditos: 'asc',
        situacaoNome: 'asc',
    });

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        setSortDirection({
            dataCadastro: 'asc',
            valor: 'asc',
            quantidadeCreditos: 'asc',
            situacaoNome: 'asc',
            [column]: newSortDirection,
        });

        const payload = {
            paginacao: hookForm.getValues().paginacao || 5,
            pagina: hookForm.getValues().pagina || 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
        };

        dispatch(minhasTransacoesStart(payload));
    };

    const cardsData = [
        { creditos: 100, valor: 100, nome: '100 créditos' },
        { creditos: 300, valor: 300, nome: '300 créditos' },
        { creditos: 500, valor: 500, nome: '500 créditos' },
        { creditos: 1000, valor: 1000, nome: '1000 créditos' },
    ];
    const handleBuyCredits = (card: any) => {
        const generatedUUID = String(crypto.randomUUID());

        dispatch(
            comprarCreditosStart({
                planoCreditoID: generatedUUID,
                nome: String(card.nome),
                valor: String(card.valor),
                quantidadeCreditos: String(card.creditos),
            })
        );
        setRowID(generatedUUID);
    };

    const handleConfirmarPagamento = (row: any) => {
        setRowID(row.pickWalletID);
        const payload = {
            conteudo: row.pixQrcode,
        };
        dispatch(gerarQrCodeStart(payload));
        setOpenQrCodeModal(true);
    }


    useEffect(() => {
        if (response2?.data?.pixQrcode) {
            setCopiaECola(response2?.data?.pixQrcode);
            setOpenQrCodeModal(true);
        }
    }, [response2]);

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ p: 2 }}>
                {cardsData.map((card) => (
                    <Card key={card.creditos} sx={{ p: 2, borderRadius: 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2">{`${card.creditos} Créditos`}</Typography>
                        <Typography variant="h4" sx={{ my: 2 }}>{dinheiro(card.valor)}</Typography>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            {`Compre ${card.creditos} créditos para utilizar nas entregas parceiras`}
                        </Typography>
                        <ButtonLoading variant="containedFilled" color="primary" onClick={() => handleBuyCredits(card)} loading={loadingComprar}>
                            Pix
                        </ButtonLoading>
                    </Card>
                ))}
            </Stack>
            <QrCodeWallet
                modalProps={{
                    open: openQrCodeModal,
                    onClose: () => setOpenQrCodeModal(false),
                }}
                qrCode={qrCode}
                loading={false}
                atualizar={() => { dispatch(consultarPagamentoStart({ id: rowID })); }}
                copiaECola={copiaECola}
            />
            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">Abastecer wallet</Typography>
                </Box>
                {data?.dados && (
                    <TableNavigation
                        indiceInicial={data.dados.indiceInicial}
                        indiceFinal={data.dados.indiceFinal}
                        indiceTotal={data.dados.total}
                        ultimaPagina={data.dados.ultimaPagina}
                        hookForm={hookForm}
                    />
                )}
                <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('dataCadastro')} sortDirection={sortDirection} nome="dataCadastro">
                                        Data
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('valor')} sortDirection={sortDirection} nome="valor">
                                        Valor
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('quantidadeCreditos')} sortDirection={sortDirection} nome="quantidadeCreditos">
                                        Créditos
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('situacaoNome')} sortDirection={sortDirection} nome="situacaoNome">
                                        Situação
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell align="center">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRowLoading />
                            ) : data && data.dados?.lista.length > 0 ? (
                                data.dados?.lista.map((row: any) => (
                                    <TableRow key={row.pickWalletID} hover>
                                        <TableCell align="center">{row.dataCadastro}</TableCell>
                                        <TableCell align="center">{dinheiro(row.valor)}</TableCell>
                                        <TableCell align="center">{row.quantidadeCreditos}</TableCell>
                                        <TableCell align="center">{row.situacaoNome}</TableCell>
                                        <TableCell align="center">
                                            {row.situacao !== 5 && (
                                                <Tooltip title="Abrir QR Code" arrow>
                                                    <IconButton onClick={() => handleConfirmarPagamento(row)} size="small">
                                                        <QrCode />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRowNotFound text="Nenhum resultado encontrado" />
                            )}
                        </TableBody>
                    </Table>
                    <ModalOpcoes
                        open={false}
                        onClose={() => console.log(false)}
                        acao="Excluir"
                        descricao={<Typography>
                            Tem certeza que deseja excluir <span style={{ fontWeight: 'bold' }}>Pix</span>?
                        </Typography>}
                        opcoes={[
                            {
                                label: 'Cancelar',
                                action() { console.log(false) },
                            },
                            {
                                label: 'Excluir',
                                action() { console.log() },
                            },
                        ]}
                    />
                    <SnackbarAlert {...snack} />
                </TableContainer>
            </Card>
        </Box>
    );
};

export default AbastecerWallet;
