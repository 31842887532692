import { Box, Typography } from '@mui/material';
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalBase, { ModalBaseProps } from 'src/ds/components/ModalBase';
import { BoxLoading, ContainerBodyModal } from 'src/ds/DesignSystem';
import { Bots } from 'src/dtos/BotsDTO';
import { RootState } from 'src/store/reducers';
import { botQrCodeStart } from 'src/store/reducers/bots';

interface ModalBotQrCodeProps {
    modalProps: ModalBaseProps;
    bot: Bots;
}

const ModalBotQrCode = ({ modalProps, bot }: ModalBotQrCodeProps) => {
    const dispatch = useDispatch()
    const qrCode = useSelector((state: RootState) => state.bots.listaQrCode.data?.qrCode)
    const loading = useSelector((state: RootState) => state.bots.listaQrCode.loading)

    useEffect(() => {
        dispatch(botQrCodeStart({ id: bot.pickWppBotID }))
    }, [bot])

    return (
        <ModalBase {...modalProps}>
            <ContainerBodyModal>
                <BoxLoading loading={loading} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    {loading
                        ? null
                        : qrCode === null
                            ? <Typography variant='notFound'>Nenhum QrCode encontrado</Typography>
                            : <Box
                                component={'img'}
                                sx={{
                                    maxHeight: '100%',
                                    height: 'auto',
                                    width: 'auto',
                                    aspectRatio: '1 / 1', 
                                    objectFit: 'contain'
                                }}
                                src={qrCode ? `data:image/png;base64,${qrCode}` : ''}
                                style={{ height: '100%', width: '100%' }}
                                alt='Qr Code'
                            />
                    }
                </BoxLoading>
            </ContainerBodyModal>
        </ModalBase>
    )
}

export default ModalBotQrCode