import { Box, Button, Grid, Typography, useTheme, Container, Badge, IconButton, Paper, Collapse, Grid2, Slide, Stack, InputAdornment, TextField } from "@mui/material";
import promo from '../../assets/promo.png';
import { Add, ExpandLess, ExpandMore, FilterAlt, QuestionMark, Search, SmartDisplay, } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { emAndamentoStart, limparEntregaParceira, listarEmpresasPick, listarEntregadoresRequest } from "../../store/reducers/config";
import './styles.css';
import { PLANOFREEMIUM } from "../../utils";
import { BotaoFecharDelineado, GridPainelLateral, Line } from "../../ds";
import CriarOcorrencia from "../../components/Painel/CriarOcorrencia";
import CriarPedido from "../../components/Painel/CriarPedido";
import CardPedido from "../../components/Painel/CardPedido";
import MapHome from "../../components/Painel/MapHome";
import EntregaParceira from "../../components/Painel/EntregaParceira";
import ModalFiltros from "../../components/Painel/ModalFiltros";
import { usePedido } from "../../context/PedidoContext";
import MenuFooter from "../../components/Painel/MenuFooter";
import AtribuirPedido from "../../components/Painel/AtribuirPedido";
import Chat from "../../components/Painel/Chat";
import { usePagina } from "../../context/PaginaContext";
import MapIcon from '@mui/icons-material/Map';
import AvaliarPedidos from "../../components/Painel/AvaliarPedidos";
import TempoMedio from "../../components/Painel/TempoMedio";
import ModalContratarPlanos from "../../components/Painel/ModalContratarPlanos";
import ModalEntregadores from "../../components/Painel/ModalEntregadores";
import ModalBoasVindas from "../../components/Painel/ModalBoasVindas";
import ModalVideos from "../../components/Painel/ModalBoasVindas/ModalVideos";
import { useResponsive } from "../../ds/customHooks";
import Financeiro from "../../components/Painel/Financeiro";
import Robo from "../../components/Painel/Robo";
import TaxaExtra from "../../components/Painel/TaxaExtra";
import { unSanitizeSituacao } from "../../ds/common";
import { mudarSize, toggleCriarPedido, toggleDevMode } from "../../store/reducers/utils";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { useHubspotConversations } from '../../context/HubspotConversationsContext'
import { SnackbarAlert } from "../../ds/DesignSystem";
import { useMultiResponseSnackbar, useSnackbar } from "../../ds/CustomHooksDs";

const Painel = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { isMobile } = useResponsive()
    const { selecionarPedido, markerSelecionado, markersSelecionados, selecionarMarker, roteando } = usePedido();
    const usuario = useSelector(state => state.login.usuario);
    const emAndamento = useSelector(state => state.config?.emAndamento?.dados?.lista);
    const filtrosReducer = useSelector(state => state.utils.filtros);
    const [abrirFinanceiro, setAbrirFinanceiro] = useState(false);
    const [abrirRobo, setAbrirRobo] = useState(false);
    const [abrirTaxaExtra, setAbrirTaxaExtra] = useState(false);
    const [videos, setVideos] = useState(false);
    const [entregadores, setAbrirEntregadores] = useState(false);
    const [showEmAndamento, setShowEmAndamento] = useState(true);
    const [pedidoStatus, setPedidoStatus] = useState(null);
    const [criarPedido, setCriarPedido] = useState(false);
    const [abrirOcorrencia, setAbrirOcorrencia] = useState(false);
    const [atribuirPedido, setAtribuirPedido] = useState(false);
    const [abrirParceira, setAbrirParceira] = useState(false);
    const [abrirChatLateral, setAbrirChatLateral] = useState(false);
    const [abrirAvaliar, setAbrirAvaliar] = useState(false);
    const [abrirTempoMedio, setAbrirTempoMedio] = useState(false);
    const [editarPedido, setEditarPedido] = useState(null);
    const [addFiltros, setAddFiltros] = useState(false);
    const [filtros, setFiltros] = useState({});
    const [position, setPosition] = useState({ latitude: -12.7797200, longitude: -41.9297200 })
    const [searchTerm, setSearchTerm] = useState('');
    const [showInput, setShowInput] = useState(true);
    const criarPedidoRef = useRef();
    const abrirOcorrenciaRef = useRef();
    const atribuirRef = useRef();
    const [abrirMapa, setAbrirMapa] = useState(false);
    const refTimeout = useRef(null)
    const [mapaMobile, setMapaMobile] = useState(false)
    const [mapaDesktop, setMapaDesktop] = useState(false)
    const [showMapaMobile, setShowMapaMobile] = useState();
    const [mostrarModalContratar, setMostrarModalContratar] = useState(false);
    const timeAnimation = 300;
    const usuarioPlano = useSelector(state => state.config.usuarioPlano);
    const visualizou = useSelector(state => state.utils.visualizouTreinamento);
    const sizeAtual = useSelector(state => state.utils?.sizeAtual);
    const refPainelLateral = useRef(null);
    const [expandedGroups, setExpandedGroups] = useState({});
    const tipoDeUsuario = useSelector(state => state.config?.tipoDeUsuario);
    const actionPedido = useSelector(state => state.utils?.criarPedido);
    const [keySequence, setKeySequence] = useState('');
    const response = useSelector((state) => state.config.solicitarEntregaParceira);
    const snack = useSnackbar({ duration: 2000 });

    useMultiResponseSnackbar([{ response, clearAction: () => { dispatch(limparEntregaParceira()); } }], snack);

    const ehFreemium = useMemo(() => {
        return usuarioPlano === PLANOFREEMIUM;
    }, [usuarioPlano]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            setKeySequence(prev => prev + event.key);
            if (keySequence.endsWith('toggledevmode')) {
                dispatch(toggleDevMode())
                setKeySequence('');
            }
            if (keySequence.length > 20) {
                setKeySequence(prev => prev.slice(-20));
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [keySequence]);

    const toggleGroup = (groupName) => {
        setExpandedGroups(prevState => ({
            ...prevState,
            [groupName]: !prevState[groupName]
        }));
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCallbackFiltros = (filtrosAtualizados) => {
        setFiltros(filtrosAtualizados);
    };

    const aplicarFiltros = (pedido) => {
        let filtroStatus = true;
        let filtroEntregador = true;
        let filtroPedido = true;
        let filtroEmpresa = true;
        if (filtros.status) {
            filtroStatus = pedido.situacao === unSanitizeSituacao(filtros.status);
        }
        if (filtros.entregador) {
            filtroEntregador = pedido.entregadorUsuarioID === filtros?.entregador;
        }
        if (filtros.pedidos) {
            filtroPedido = pedido.codigoQuatro === filtros.pedidos.replace('#', '');
        }
        if (filtros.empresas) {
            filtroEmpresa = pedido?.empresaID === filtros.empresas; // TODO: Filtrar por id
        }
        return filtroStatus && filtroEntregador && filtroPedido && filtroEmpresa;
    };

    const filteredPedidos = emAndamento?.filter((pedido) =>
        pedido.codigoQuatro.includes(searchTerm) && aplicarFiltros(pedido)
    );

    function getUserLocation() {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject(new Error("Geolocation is not supported by this browser."));
            } else {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ latitude, longitude });
                    },
                    (error) => {
                        reject(new Error("Unable to retrieve your location. Error: " + error.message));
                    }
                );
            }
        });
    }

    useEffect(() => {
        getUserLocation()
            .then(({ latitude, longitude }) => {
                setPosition({ latitude, longitude });
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const empresasIds = [];

    empresasIds.push(usuario.id);
    const { paginaAtual, selecionarPagina } = usePagina();
    useEffect(() => {
        selecionarPagina('Painel');
    }, []);

    useEffect(() => {
        const listagemInterval = () => {
            dispatch(emAndamentoStart({ empresaID: usuario.id }));
            dispatch(listarEntregadoresRequest(empresasIds));
            dispatch(listarEmpresasPick({ empresaIDs: empresasIds, filtrarPorTags: false, pagina: 1, paginacao: -1 }));
        }
        const interval = setInterval(listagemInterval, 10000);

        return () => clearInterval(interval);
    }, [usuario.id]);

    const abrir = (abrirQual, pedido) => {
        if (pedido) {
            setPedidoStatus(pedido);
            if (pedido.toString() === 'true') {
                selecionarPedido(null);
            } else {
                selecionarPedido(pedido);
            }
        }
        if (usuarioPlano === PLANOFREEMIUM && abrirQual !== 'entregadores' && abrirQual !== 'abrirMapa' && abrirQual !== 'videos') {
            setMostrarModalContratar(true);
            return;
        }

        const states = {
            addFiltros: false,
            abrirOcorrencia: false,
            atribuirPedido: false,
            editarPedido: false,
            criarPedido: false,
            showInput: false,
            showEmAndamento: true,
            abrirParceira: false,
            abrirChatLateral: false,
            abrirAvaliar: false,
            abrirTempoMedio: false,
            abrirMapa: false,
            entregadores: false,
            abrirFinanceiro: false,
            abrirRobo: false,
            abrirTaxaExtra: false,
            videos: false
        };

        const currentValue = eval(abrirQual);
        states[abrirQual] = !currentValue;


        if (!currentValue && abrirQual !== 'addFiltros' && abrirQual !== 'entregadores' && abrirQual !== 'abrirMapa' && abrirQual !== 'videos') {
            states.showEmAndamento = false;
        } else if (currentValue) {
            states.showEmAndamento = true;
        }

        setAddFiltros(states.addFiltros);
        setAbrirOcorrencia(states.abrirOcorrencia);
        setAtribuirPedido(states.atribuirPedido);
        setCriarPedido(states.criarPedido || states.editarPedido);
        setEditarPedido(states.editarPedido);
        setShowInput(states.showInput);
        setAbrirParceira(states.abrirParceira);
        setAbrirChatLateral(states.abrirChatLateral);
        setAbrirAvaliar(states.abrirAvaliar);
        setAbrirTempoMedio(states.abrirTempoMedio);
        setAbrirMapa(states.abrirMapa);
        setShowEmAndamento(states.showEmAndamento);
        setAbrirEntregadores(states.entregadores);
        setAbrirFinanceiro(states.abrirFinanceiro);
        setAbrirRobo(states.abrirRobo);
        setAbrirTaxaExtra(states.abrirTaxaExtra);
        setVideos(states.videos);
    };

    useEffect(() => {
        setShowMapaMobile(isMobile)
        if (isMobile) setMapaMobile(true)
        if (!isMobile) {
            setMapaDesktop(true)
            setAbrirMapa(false)
        }
    }, [isMobile])

    useEffect(() => {
        if (roteando) {
            markersSelecionados?.forEach(pedidoID => {
                const card = emAndamento?.find(e => e.pedidoID === pedidoID);
                if (card && expandedGroups[card.empresa.nome] === false) {
                    toggleGroup(card.empresa.nome);
                }
                const elemento = document.getElementById(pedidoID);
                if (elemento) {
                    elemento.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        } else if (markerSelecionado) {
            const card = emAndamento?.find(e => e.pedidoID === markerSelecionado);
            if (card && expandedGroups[card.empresa.nome] === false) {
                toggleGroup(card.empresa.nome);
            }
            document.getElementById(markerSelecionado)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            if (refTimeout.current) clearTimeout(refTimeout.current);
            refTimeout.current = setTimeout(() => { selecionarMarker(null) }, 5000);
        }
    }, [roteando, markerSelecionado, markersSelecionados]);

    const handleSizeChange = (newSize) => {
        if (isMobile) return

        dispatch(mudarSize(newSize[0]));
    };

    const ordemSituacao = ['RECEBIDO', 'PRONTO', 'DESPACHADO', 'ACEITO', 'NO_ESTABELECIMENTO', 'EM_ROTA', 'RETORNANDO', 'FINALIZADO'];

    const parseTempoDecorrido = (tempo) => {
        let totalSeconds = 0;

        const timeParts = tempo.split(' ');

        timeParts.forEach(part => {
            if (part.includes('h')) {
                const hours = parseInt(part.replace('h', ''));
                totalSeconds += hours * 3600;
            } else if (part.includes('m')) {
                const minutes = parseInt(part.replace('m', ''));
                totalSeconds += minutes * 60;
            } else if (part.includes('s')) {
                const seconds = parseInt(part.replace('s', ''));
                totalSeconds += seconds;
            }
        });

        return totalSeconds;
    };

    const compare = (a, b) => {
        const situacaoA = ordemSituacao.indexOf(a.situacao);
        const situacaoB = ordemSituacao.indexOf(b.situacao);

        if (situacaoA !== situacaoB) {
            return situacaoA - situacaoB;
        } else {
            const tempoA = parseTempoDecorrido(a.tempoDecorridoCadastro);
            const tempoB = parseTempoDecorrido(b.tempoDecorridoCadastro);

            return tempoB - tempoA;
        }
    };

    const pedidosOrdenados = filteredPedidos?.sort(compare);
    const groupedPedidos = pedidosOrdenados?.reduce((groups, item) => {
        const groupName = item.empresa.nome;
        if (!groups[groupName]) {
            groups[groupName] = [];
        }
        groups[groupName].push(item);
        return groups;
    }, {});

    const empresas = Object.keys(groupedPedidos ?? {});
    const hasMultipleEmpresas = empresas.length > 1;

    useEffect(() => {
        if (actionPedido === true) {
            abrir('criarPedido');
            dispatch(toggleCriarPedido());
        }
    }, [actionPedido]);

    const { toggleWidget } = useHubspotConversations();
    return (
        <Box sx={{ height: '100%' }}>
            <Box height="calc(100vh - 52px)">
                <Allotment
                    vertical={false}
                    style={{ height: '100%' }}
                    minSize={300}
                    onChange={(newSize) => handleSizeChange(newSize)}
                    defaultSizes={[sizeAtual, window.innerWidth - sizeAtual]}
                >
                    <Allotment.Pane
                        style={{ height: '100%' }}
                        priority={'HIGH'}
                        size={isMobile ? 1 : (sizeAtual || 530)}
                    >
                        <Grid2
                            container
                            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                            id="painelLateral"
                            ref={refPainelLateral}
                        >
                            {/* LISTA DE PEDIDOS */}
                            <Slide in={showEmAndamento} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral container item xs={12} sx={{ minHeight: showEmAndamento ? '100%' : '0' }}>
                                    <Line
                                        sx={{
                                            justifyContent: 'space-between',
                                            gap: '1rem',
                                            m: '0 0 1rem 0',
                                            flexGrow: '0',
                                            padding: '.5rem .5rem 0 .5rem',
                                        }}
                                    >
                                        <TextField
                                            size='small'
                                            fullWidth
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search />
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }}
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            placeholder='Encontre o pedido pelo código...'
                                        />
                                        <Badge
                                            badgeContent={filtrosReducer && Object?.keys(filtrosReducer).length}
                                            color={'primary'}
                                            sx={{
                                                '& .MuiBadge-badge': {
                                                    top: '0.4rem',
                                                    right: '0.4rem',
                                                }
                                            }}
                                        >
                                            <IconButton
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => abrir('addFiltros')}
                                            >
                                                <FilterAlt />
                                            </IconButton>
                                        </Badge>
                                    </Line>
                                    <Line
                                        sx={{
                                            justifyContent: 'space-between',
                                            gap: '1rem',
                                            m: '0 0 0rem 0',
                                            flexGrow: '0',
                                            padding: '0 .5rem 0 .5rem',
                                        }}
                                    >
                                        {isMobile &&
                                            <IconButton
                                                variant="containedFilled"
                                                color="primary"
                                                size="small"
                                                sx={{ width: '100%', borderRadius: '6px', gap: 2 }}
                                                onClick={() => abrir('abrirMapa', true)}>
                                                Ver mapa
                                                <MapIcon />
                                            </IconButton>
                                        }
                                    </Line>
                                    <Container
                                        sx={{
                                            flexGrow: '1',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            overflowY: 'auto',
                                            overflowX: 'hidden',
                                            '&.MuiContainer-root': {
                                                padding: '.1rem 0 0 0',
                                            },
                                        }}
                                    >
                                        {filteredPedidos?.length > 0 ? (
                                            <Grid container height={'100%'} padding={'0 0.5rem'} sx={{ alignContent: 'flex-start' }}>
                                                {hasMultipleEmpresas ? (
                                                    empresas?.map((groupName, index) => (
                                                        <Grid item xs={12} key={index}>
                                                            <Paper
                                                                elevation={0}
                                                                sx={{
                                                                    borderRadius: '4px',
                                                                    minWidth: '100%',
                                                                }}
                                                            >
                                                                <Box
                                                                    onClick={() => toggleGroup(groupName)}
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                        padding: '0.5rem',
                                                                        borderRadius: `${theme.sizes.borderRadius.xs} ${theme.sizes.borderRadius.xs} 0 0`,
                                                                        backgroundColor: expandedGroups[groupName] ? theme.palette.primary.light : 'transparent',
                                                                        transition: 'background-color 0.3s ease',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="body1"
                                                                        component="div"
                                                                        color={
                                                                            expandedGroups[groupName] ? theme.palette.text.secondary : theme.palette.text.primary
                                                                        }
                                                                    >
                                                                        {groupName}
                                                                    </Typography>
                                                                    <IconButton>
                                                                        {expandedGroups[groupName] ? (
                                                                            <ExpandLess sx={{ color: theme.palette.text.secondary }} />
                                                                        ) : (
                                                                            <ExpandMore sx={{ color: theme.palette.text.primary }} />
                                                                        )}
                                                                    </IconButton>
                                                                </Box>
                                                                <Collapse in={expandedGroups[groupName]} timeout="auto" unmountOnExit mountOnEnter>
                                                                    <Box
                                                                        sx={{
                                                                            padding: '0.3rem',
                                                                            borderTop: '0px solid #ccc',
                                                                            borderRadius: `0 0 ${theme.sizes.borderRadius.xs} ${theme.sizes.borderRadius.xs}`,
                                                                        }}
                                                                    >
                                                                        {groupedPedidos[groupName]?.map((item, itemIndex) => (
                                                                            <CardPedido
                                                                                key={itemIndex}
                                                                                pedido={item}
                                                                                abrirCriarOcorrencia={() => abrir('abrirOcorrencia', item)}
                                                                                abrirEditarPedido={() => abrir('editarPedido', item)}
                                                                                abrirEntregaParceira={() => abrir('abrirParceira', item)}
                                                                                abrirAtribuirPedido={() => abrir('atribuirPedido', item)}
                                                                                id={item.pedidoID}
                                                                            />
                                                                        ))}
                                                                    </Box>
                                                                </Collapse>
                                                            </Paper>
                                                        </Grid>
                                                    ))
                                                ) : (
                                                    groupedPedidos[empresas[0]]?.map((item, itemIndex) => (
                                                        <Grid item xs={12} key={itemIndex}>
                                                            <CardPedido
                                                                pedido={item}
                                                                abrirCriarOcorrencia={() => abrir('abrirOcorrencia', item)}
                                                                abrirEditarPedido={() => abrir('editarPedido', item)}
                                                                abrirEntregaParceira={() => abrir('abrirParceira', item)}
                                                                abrirAtribuirPedido={() => abrir('atribuirPedido', item, 'do card')}
                                                                id={item.pedidoID}
                                                            />
                                                        </Grid>
                                                    ))
                                                )}
                                            </Grid>
                                        ) : (
                                            <Container style={{
                                                position: 'relative',
                                                top: 0,
                                                textAlign: 'center',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Criar um novo pedido
                                                </Typography>
                                                <Typography variant="body2" color={theme.palette.grey[500]} gutterBottom>
                                                    A lista dos seus pedidos estará aqui!
                                                </Typography>
                                                <IconButton variant="containedFilled" color="primary" onClick={() => abrir('criarPedido', true)} size="large"> <Add /></IconButton>
                                            </Container>
                                        )}
                                    </Container>
                                    <MenuFooter
                                        sx={{ flexGrow: 'auto' }}
                                        abrirChat={() => abrir('abrirChatLateral')}
                                        abrirAvaliar={() => abrir('abrirAvaliar')}
                                        abrirTempoMedio={() => abrir('abrirTempoMedio')}
                                        abrirFinanceiro={() => abrir('abrirFinanceiro')}
                                        abrirRobo={() => abrir('abrirRobo')}
                                        abrirTaxaExtra={() => abrir('abrirTaxaExtra')}
                                        abrirFreemium={() => setMostrarModalContratar(true)}

                                    />
                                </GridPainelLateral>
                            </Slide>
                            {/* CRIAR PEDIDO */}
                            <Slide in={criarPedido} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral ref={criarPedidoRef} item container xs={12} sx={{ minHeight: criarPedido ? '100%' : '0' }}>
                                    <CriarPedido
                                        close={() => abrir('criarPedido', true)}
                                        editar={editarPedido}
                                    />
                                </GridPainelLateral>
                            </Slide>
                            {/* OCORRÊNCIA */}
                            <Slide in={abrirOcorrencia} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral ref={abrirOcorrenciaRef} item xs={12} sx={{ minHeight: abrirOcorrencia ? '100%' : '0', }}>
                                    <CriarOcorrencia close={() => abrir('abrirOcorrencia')} />
                                </GridPainelLateral>
                            </Slide>
                            {/* ATRIBUIR PEDIDO */}
                            <Slide in={atribuirPedido} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral ref={atribuirRef} item xs={12} sx={{ minHeight: atribuirPedido ? '100%' : '0', }}>
                                    <AtribuirPedido close={() => abrir('atribuirPedido')} />
                                </GridPainelLateral>
                            </Slide>
                            {/* ENTREGA PARCEIRA */}
                            <Slide in={abrirParceira} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral item xs={12} sx={{ minHeight: abrirParceira ? '100%' : '0', }}>
                                    <EntregaParceira close={() => abrir('abrirParceira')} />
                                </GridPainelLateral>
                            </Slide>
                            {/* CHAT */}
                            <Slide in={abrirChatLateral} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral item xs={12} sx={{ minHeight: abrirChatLateral ? '100%' : '0' }}>
                                    <Chat close={() => abrir('abrirChatLateral')} />
                                </GridPainelLateral>
                            </Slide>
                            {/* AVALIAR PEDIDOS */}
                            <Slide in={abrirAvaliar} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral item xs={12} sx={{ minHeight: abrirAvaliar ? '100%' : '0' }}>
                                    <AvaliarPedidos onClose={() => abrir('abrirAvaliar')} />
                                </GridPainelLateral>
                            </Slide>
                            {/* TEMPO MÉDIO */}
                            <Slide in={abrirTempoMedio} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral item xs={12} sx={{ minHeight: abrirTempoMedio ? '100%' : '0' }}>
                                    <TempoMedio onClose={() => abrir('abrirTempoMedio')} />
                                </GridPainelLateral>
                            </Slide>
                            {/* Financeiro */}
                            <Slide in={abrirFinanceiro} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral item xs={12} sx={{ minHeight: abrirFinanceiro ? '100%' : '0' }}>
                                    <Financeiro onClose={() => abrir('abrirFinanceiro')} />
                                </GridPainelLateral>
                            </Slide>
                            {/* ROBO */}
                            <Slide in={abrirRobo} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral item xs={12} sx={{ minHeight: abrirRobo ? '100%' : '0' }}>
                                    <Robo onClose={() => abrir('abrirRobo')} />
                                </GridPainelLateral>
                            </Slide>
                            {/* TAXA EXTRA */}
                            <Slide in={abrirTaxaExtra} timeout={timeAnimation} direction="right" unmountOnExit mountOnEnter>
                                <GridPainelLateral item xs={12} sx={{ minHeight: abrirTaxaExtra ? '100%' : '0' }}>
                                    <TaxaExtra onClose={() => abrir('abrirTaxaExtra')} />
                                </GridPainelLateral>
                            </Slide>
                        </Grid2>
                    </Allotment.Pane>
                    <Allotment.Pane
                        style={{ height: '100%' }}
                        visible={!isMobile}
                        priority={'LOW'}
                    >
                        {mapaDesktop && <Box
                            sx={{ display: !showMapaMobile ? 'block' : 'none', justifyContent: 'center', height: '100%' }}
                        >
                            <MapHome
                                position={position}
                                abrirAtribuirPedido={() => abrir('atribuirPedido', null, 'do mapa desktop')}
                                abrirChatLateral={() => abrir('abrirChatLateral')}

                            />
                        </Box>}
                    </Allotment.Pane>
                </Allotment>
            </Box>
            {mapaMobile && <div
                style={{
                    display: (showMapaMobile && abrirMapa) ? 'block' : 'none',
                    height: '100vh',
                    width: '100%',
                    borderRadius: '0px',
                    overflow: 'hidden',
                    position: 'absolute',
                    boxSizing: 'border-box',
                    zIndex: 1500,
                    top: '0px',
                    padding: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
                <BotaoFecharDelineado
                    onClick={() => abrir('abrirMapa')}
                    sx={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        zIndex: 10000,
                        backgroundColor: '#fff',
                        '&:hover': {
                            color: '#fff',
                            borderColor: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.main,
                        }
                    }}
                />
                <MapHome
                    position={position}
                    abrirAtribuirPedido={() => abrir('atribuirPedido', null, 'do mapa')}
                    styleProps={{ borderRadius: '10px' }}
                    abrirChatLateral={() => abrir('abrirChatLateral')}

                />
            </div>}
            {(isMobile ? showEmAndamento : true)
                ? (
                    <Stack
                        useFlexGap
                        sx={{
                            gap: '.5rem',
                            flexDirection: 'column-reverse',
                            zIndex: 1001,
                            position: 'absolute',
                            bottom: isMobile ? 80 : 20,
                            right: isMobile ? 10 : 20,
                            padding: 0,
                            bgColor: 'red',
                        }}
                    >

                        {tipoDeUsuario !== 'Representante' && <ModalEntregadores abrirEntregadores={entregadores} setAbrirEntregadores={setAbrirEntregadores} />}
                        {ehFreemium &&
                            <Button
                                sx={{
                                    width: '56px',
                                    height: '56px',
                                    backgroundColor: '#000',
                                    '&:hover': { backgroundColor: 'lightgray' },
                                    padding: 0,

                                }}
                                onClick={() => abrir('videos')}
                            >
                                <SmartDisplay sx={{ color: 'white' }} />
                            </Button>}
                        {ehFreemium &&
                            <Button
                                sx={{
                                    width: '56px',
                                    height: '56px',
                                    backgroundColor: 'secondary.main',
                                    '&:hover': { backgroundColor: 'lightgray' },
                                    padding: 0,
                                }}
                                onClick={() => abrir('info')}
                            >
                                <Box
                                    component="img"
                                    src={promo}
                                    alt="Promo"
                                    sx={{ width: '28px', height: '26px' }}
                                />
                            </Button>}
                    </Stack>
                )
                : null
            }
            <ModalContratarPlanos open={mostrarModalContratar} onClose={() => setMostrarModalContratar(false)} />
            <ModalBoasVindas open={!visualizou && usuarioPlano === PLANOFREEMIUM} setAbrirEntregadores={setAbrirEntregadores} />
            <ModalVideos open={videos} onClose={() => setVideos(false)} />
            <ModalFiltros
                visible={addFiltros}
                onClose={() => abrir('addFiltros')}
                callback={handleCallbackFiltros}
            />
            <SnackbarAlert {...snack} />
        </Box >
    );


}

export default Painel;
