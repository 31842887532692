import { Box, Typography, TextField, Button, FormControlLabel, Checkbox, Stack } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { GridBodyModal } from "src/ds";
import { ContainerBodyModal, HeaderModal, ModalBase, SelectToggleAtivo } from "src/ds/DesignSystem";
import { listaTabelaCobrancaStart, salvarTabelaStart } from "src/store/reducers/tabelaCobranca";
import EditarTabelaCobranca from "./Tabs";
import { liveMaskMoney } from "src/ds/Utils";
import { cleanInput } from "src/ds/common";

interface FormValues {
    nome: string;
    ativo: boolean;
    valorPontoAdicional: string;
}

const CadastrarTabela = ({ open, onClose, editar }: any) => {
    const hookForm = useForm<FormValues>({
        defaultValues: {
            nome: "",
            ativo: true,
            valorPontoAdicional: "0",
        },
    });
    const { register, handleSubmit, control, watch, formState: { errors } } = hookForm

    const dispatch = useDispatch();
    const onSubmit = (data: FormValues) => {
        dispatch(salvarTabelaStart({
            ...data,
            valorPontoAdicional: cleanInput(data.valorPontoAdicional)
        }));
        onClose();
    };

    return (
        <ModalBase open={open} onClose={onClose} size="sm">
            <HeaderModal title="Cadastrar tabela" onClose={onClose} />
            <Box p={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <TextField
                            label="Nome"
                            fullWidth
                            size="small"
                            {...register("nome", { required: "Nome é obrigatório" })}
                            error={!!errors.nome}
                            helperText={errors.nome ? errors.nome.message : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Controller
                            name="valorPontoAdicional"
                            control={control}
                            rules={{ required: "O valor por ponto adicional é obrigatório" }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    label="Valor por ponto adicional"
                                    fullWidth
                                    size="small"
                                    value={liveMaskMoney(value || "")}
                                    onChange={(e) => onChange(liveMaskMoney(e.target.value))}
                                    error={!!errors.valorPontoAdicional}
                                    helperText={errors.valorPontoAdicional ? errors.valorPontoAdicional.message : ""}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />

                        <SelectToggleAtivo
                            hookForm={{ methods: hookForm, name: "ativo" }}
                            label="Situação"
                            toggleValues={{ ativo: "true", inativo: "false" }}

                        />

                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" color="secondary" sx={{ width: "fit-content" }} onClick={() => {
                                hookForm.reset();
                                onClose();
                            }}>
                                Cancelar
                            </Button>
                            <Button variant="containedFilled" color="primary" sx={{ width: "fit-content" }} type="submit">
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </ModalBase>
    );
};

export default CadastrarTabela;
