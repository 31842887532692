const liveMaskMoney = (value: string | number, isChanged: boolean = false) => {
  if (!value) return value;

  const numericValue = String(value).replace(/\D/g, "");

  const formattedValue = isChanged || numericValue.length > 2
    ? (Number(numericValue) / 100)
    : Number(numericValue);

  const maskedValue = formattedValue.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return maskedValue;
};

export default liveMaskMoney;
