import React from 'react'
import { useDispatch } from 'react-redux'
import { DispatchMethods } from '../Forms'
import { actions } from 'src/store/Actions'
import ButtonLoading from './ButtonLoading'

interface ExportarTabelaProps {
    dispatchMethods: DispatchMethods;
    loading?: boolean
}

const ExportarTabela: React.FC<ExportarTabelaProps> = ({ dispatchMethods, loading }: ExportarTabelaProps) => {
    const dispatch = useDispatch()

    const onSubmit = (data: any) => {
        if (dispatchMethods?.actionName) {
            const actionCreator = actions[dispatchMethods.actionName];
            if (actionCreator) {
                dispatch(actionCreator(data));
            }
        }
    }

    const dispatchData = dispatchMethods?.payload ?? {
        paginacao: -1,
        pagina: 1,
        ordenacaoCampo: '',
        ordenacaoOrdem: '',
        filtros: '',
        titulos: '',
        variaveis: '',
        formato: 'csv',
    }

    return (
        <ButtonLoading
            variant="contained"
            sx={{ width: "min-content", height: "fit-content" }}
            onClick={() => onSubmit(dispatchData)}
            loading={loading}
        >
            Exportar
        </ButtonLoading>
    )
}

export default ExportarTabela