import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Slide, Typography, Autocomplete, TextField, Stack } from '@mui/material';
import { Line } from '../../../ds';
import { useTheme } from '@mui/material/styles';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { emAndamentoStart, limparResposta } from '../../../store/reducers/config';
import { sanitizeSituacao } from '../../../utils';
import { limparFiltros, setFiltros } from '../../../store/reducers/utils';
import { useResponsive } from '../../../ds/CustomHooksDs';
import { RootState } from 'src/store/reducers';

interface Props {
    visible: boolean;
    onClose: () => void;
    callback: ({ }) => void;
}

const ModalFiltros = ({ visible, onClose, callback }: Props) => {
    const dispatch = useDispatch();
    const refCard = useRef<null | HTMLDivElement>(null);
    const theme = useTheme();
    const { isMobile } = useResponsive();
    const filtros = useSelector((state: RootState) => state.utils?.filtros);
    const usuario = useSelector((state: RootState) => state.login?.usuario);
    const respostaSituacao = useSelector((state: RootState) => state.config?.respostaSituacao);
    const entregadores = useSelector((state: RootState) => state.config.entregadores?.lista);
    const emAndamento = useSelector((state: RootState) => state.config.emAndamento?.dados?.lista);
    const [opcaoStatus, setOpcaoStatus] = useState<string | null>(null);
    const [opcaoEntregador, setOpcaoEntregador] = useState<string | null>(null);
    const [opcaoPedido, setOpcaoPedido] = useState<string | null>(null);
    const [opcaoEmpresa, setOpcaoEmpresa] = useState<string | null>(null);

    // Garantir Valores Únicos
    const uniqueById = <T extends { id: string, nome: string }>(arr: T[]): T[] => {
        const seen = new Set();
        return arr.filter((item) => {
            const isUnique = !seen.has(item.id);
            seen.add(item.id);
            return isUnique;
        });
    };

    const opcoesEntregadores = uniqueById(
        // @ts-expect-error
        (entregadores?.map(entregador => ({ id: entregador.id, nome: entregador.nome })) || [])
    );

    const opcoesStatus = uniqueById(
        // @ts-expect-error
        (emAndamento?.map(pedido => ({ id: pedido.situacao, nome: sanitizeSituacao(pedido) })) || [])
    );


    const opcoesPedidos = uniqueById(
        // @ts-expect-error
        (emAndamento?.map(pedido => ({ id: pedido.codigoQuatro, nome: `#${pedido.codigoQuatro}` })) || [])
    );

    const opcoesEmpresas = uniqueById(
        // @ts-expect-error
        (emAndamento?.map(pedido => ({
            id: pedido.empresaID,
            nome: pedido?.empresa?.nome || 'Empresa não especificada'
        })) || [])
    );

    useEffect(() => {
        if (respostaSituacao) {
            // @ts-expect-error
            dispatch(emAndamentoStart(usuario.id));
            setTimeout(() => dispatch(limparResposta()), 5000);
        }
    }, [respostaSituacao]);

    useEffect(() => {
        if (filtros?.status) {
            setOpcaoStatus(filtros?.status);
        }
        if (filtros?.entregador) {
            setOpcaoEntregador(filtros?.entregador);
        }
        if (filtros?.pedidos) {
            setOpcaoPedido(filtros?.pedidos);
        }
        if (filtros?.empresas) {
            setOpcaoEmpresa(filtros?.empresas);
        }
    }, [filtros]);

    const handleLimparFiltros = () => {
        dispatch(limparFiltros());
        setOpcaoStatus(null);
        setOpcaoEntregador(null);
        setOpcaoPedido(null);
        setOpcaoEmpresa(null);
        callback({});
    };

    const handleFiltros = (filtro: string, value: unknown) => {
        // @ts-expect-error
        const novosFiltros = { ...filtros, [filtro]: value?.id || value };
        dispatch(setFiltros(novosFiltros));
        callback(novosFiltros);
    };

    const fechar = () => onClose();

    const handleResize = useCallback(() => {
        if (!refCard.current) return;
        const cardWidth = refCard.current?.getBoundingClientRect().width;
        const painelLateralRect = document.querySelector('#painelLateral')?.getBoundingClientRect();
        const painelHeaderRect = document.querySelector('#painelHeader')?.getBoundingClientRect();
        if (!painelLateralRect || !painelHeaderRect) return;

        if (window.innerWidth - 15 > painelLateralRect.width + cardWidth) {
            refCard.current.style.top = painelHeaderRect.bottom + 'px';
            refCard.current.style.left = painelLateralRect.right + 'px';
        } else {
            refCard.current.style.top = painelHeaderRect.bottom + 'px';
            refCard.current.style.left = '0px';
        }
    }, []);

    const handleKeydown = (event: KeyboardEvent) => event.key === 'Escape' && onClose();

    useEffect(() => {
        if (!refCard.current || !visible) return;
        const handleResizeThrottled = () => window.requestAnimationFrame(handleResize);
        handleResize();
        window.addEventListener('resize', handleResizeThrottled);
        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('resize', handleResizeThrottled);
            window.removeEventListener('keydown', handleKeydown);
        };
    }, [visible]);

    return (
        <Slide in={visible} direction="right" timeout={300} unmountOnExit mountOnEnter>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: '0',
                    backgroundColor: theme.palette.background.default,
                    borderLeft: `1px solid ${theme.palette.background.paper}`,
                    overflowY: 'auto',
                    zIndex: isMobile ? 1001 : 1000,
                    padding: theme.spacing(2),
                    width: isMobile ? '100vw' : '300px',
                    boxSizing: 'border-box',
                }}
                ref={refCard}
            >
                <Stack
                    sx={{
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}
                    alignItems="center"
                    height="3rem"
                >
                    <CloseOutlined style={{ cursor: 'pointer' }} fontSize="small" onClick={fechar} />
                    <Typography variant="body1" sx={{ fontWeight: 600, mx: 2, flex: '1', textAlign: 'left' }}>Filtros:</Typography>
                    {filtros && Object.keys(filtros).length > 0 && (
                        <Button variant="containedFilled" sx={{ width: 'fit-content' }} onClick={handleLimparFiltros}>Limpar filtros</Button>
                    )}
                </Stack>
                <Stack
                    sx={{
                        width: '100%',
                        marginTop: '10px',
                        gap: '.5rem',
                        flexDirection: 'column',
                        boxSizing: 'border-box'
                    }}
                >
                    <Autocomplete
                        size="small"
                        options={opcoesStatus}
                        getOptionLabel={(option) => option.nome}
                        value={opcoesStatus.find(option => option.id === opcaoStatus) || null}
                        onChange={(_, newValue) => {
                            setOpcaoStatus(newValue?.id || null);
                            handleFiltros('status', newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Status" />}
                    />
                    <Autocomplete
                        size="small"
                        options={opcoesEntregadores}
                        getOptionLabel={(option) => option.nome}
                        value={opcoesEntregadores.find(option => option.id === opcaoEntregador) || null}
                        onChange={(_, newValue) => {
                            setOpcaoEntregador(newValue?.id || null);
                            handleFiltros('entregador', newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Entregadores" />}
                    />
                    <Autocomplete
                        size="small"
                        options={opcoesPedidos}
                        getOptionLabel={(option) => option.nome}
                        value={opcoesPedidos.find(option => option.id === opcaoPedido) || null}
                        onChange={(_, newValue) => {
                            setOpcaoPedido(newValue?.id || null);
                            handleFiltros('pedidos', newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Pedidos" />}
                    />
                    <Autocomplete
                        size="small"
                        options={opcoesEmpresas}
                        getOptionLabel={(option) => option.nome}
                        value={opcoesEmpresas.find(option => option.id === opcaoEmpresa) || null}
                        onChange={(_, newValue) => {
                            setOpcaoEmpresa(newValue?.id || null);
                            handleFiltros('empresas', newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Empresas" />}
                    />
                </Stack>
            </Box>
        </Slide>
    );
};

export default ModalFiltros;