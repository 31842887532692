import { useEffect, useRef, useState } from 'react'
import { useTheme, Avatar, Box, Card, CardContent, CardMedia, Container, Grid, LinearProgress, Typography, linearProgressClasses, styled, CircularProgress, Button, TextField } from '@mui/material'
import logo from '../../assets/images/logo.png'
import { StarRating } from '../../ds/index';
import { useNavigate, useParams } from 'react-router-dom';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import MapRastreio from '../../components/MapRastreio';
import { useDispatch, useSelector } from 'react-redux';
import { comentarioStart, empresaStart, entregadoresStart, pedidoStart } from '../../store/reducers/rastreio';

const etapas = {
    // estados possíveis = ['RECEBIDO', 'PRONTO', 'DESPACHADO', 'ACEITO', 'NO_ESTABELECIMENTO', 'EM ROTA', 'RETORNANDO', 'FINALIZADO', 'CANCELADO',],
    recebido: {
        situacao: 'Recebido',
        nEtapa: 0,
        texto: 'Seu pedido está sendo preparado...',
    },
    pronto: {
        situacao: 'Pronto',
        nEtapa: 0,
        texto: 'Seu pedido está sendo preparado...',
    },
    despachado: {
        situacao: 'Despachado',
        nEtapa: 1,
        texto: 'Seu pedido está aguardando coleta...',
    },
    aceito: {
        situacao: 'Aceito',
        nEtapa: 1,
        texto: 'Entregador a caminho do estabelecimento...',
    },
    no_estabelecimento: {
        situacao: 'No local',
        nEtapa: 1,
        texto: 'Entregador no estabelecimento...',
    },
    em_rota: {
        situacao: 'Em rota',
        nEtapa: 2,
        texto: 'A caminho do seu local...',
    },
    finalizado: { //Avalie
        situacao: 'Finalizado',
        nEtapa: 4,
        texto: 'Chegou no seu local...',
    },
    cancelado: {
        situacao: '',
        nEtapa: 4,
        texto: null,
    },
    retornando: {
        situacao: 'Retornando',
        nEtapa: null,
        texto: null,
    },
}

const Rastreio = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme();
    const { idParam } = useParams()
    const pedido = useSelector(state => state.rastreio.pedido)
    const [nomeCliente, setNomeCliente] = useState('')
    const [horarios, setHorarios] = useState({
        prevMinEntrega: '',
        prevMaxEntrega: '',
        ultimaAtualizacao: '',
    })
    const entregadoresOnline = useSelector(state => state.rastreio.entregadores)
    const empresa = useSelector(state => state.rastreio.empresa)
    const [etapaAtual, setEtapaAtual] = useState('')
    const [estrelas, setEstrelas] = useState(0)
    const [comentario, setComentario] = useState('')
    const [positionCliente, setPositionCliente] = useState(null)
    const [positionEntregador, setPositionEntregador] = useState(null)
    const [positionEmpresa, setPositionEmpresa] = useState(null)
    const timer = useRef(null)

    const verificarEtapaAtual = (etapaAtiva) => {
        const etapa = etapas[etapaAtiva.toLowerCase()]
        if (!pedido.dados?.lista[0] && etapa.nEtapa === 4) clearInterval(timer.current)
        if (etapaAtual.nEtapa !== etapa.nEtapa) setEtapaAtual(etapa)
    }

    useEffect(() => {
        const fetchDataEmAndamento = async () => {
            dispatch(pedidoStart(idParam))
            dispatch(entregadoresStart(idParam))
            dispatch(empresaStart(idParam))
        }
        if (idParam === 'rastreio/finalizar') navigate('/rastreio/finalizar')
        fetchDataEmAndamento()
        timer.current = setInterval(fetchDataEmAndamento, 20000)
        return () => clearInterval(timer.current)
    }, [])

    useEffect(() => {
        if (!pedido?.data?.sucesso) return
        if (pedido.data.dados?.lista[0] === undefined) {
            verificarEtapaAtual('FINALIZADO')
        } else {
            const { clienteNome, situacao, dataEtaEntrega, dataEtaEntregaStr, latitude, longitude } = pedido.data.dados.lista[0]
            setNomeCliente(clienteNome)
            verificarEtapaAtual(situacao)
            setHorarios({
                prevMinEntrega: dataEtaEntrega,
                prevMaxEntrega: dataEtaEntregaStr,
                ultimaAtualizacao: new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo', hour12: false, hour: '2-digit', minute: '2-digit' }),
            })
            setPositionCliente({
                lat: latitude,
                lng: longitude
            })
        }
    }, [pedido])

    useEffect(() => {
        if (!entregadoresOnline?.data?.sucesso || entregadoresOnline?.data?.dados.lista[0] === undefined) return
        const { latitude, longitude } = entregadoresOnline.data.dados.lista[0]
        setPositionEntregador({ lat: latitude, lng: longitude })
    }, [entregadoresOnline])

    useEffect(() => {
        if (!empresa?.data?.sucesso) return
        const { latitude, longitude } = empresa.data.dados.lista[0]
        setPositionEmpresa({ lat: latitude, lng: longitude })
    }, [empresa])

    const handleEnviarAvaliacao = () => {
        dispatch(comentarioStart({
            pedidoID: idParam,
            avaliacao: estrelas,
            comentario: comentario,
        }));
        navigate('/rastreio/finalizar')
    }

    /* Estilização */
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
        },
    }));

    if (!pedido.data) return (
        <Container
            sx={{
                height: '100vh',
                position: 'relative',
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
            }}
            component={'section'}
        >
            <Box maxWidth="xs" sx={{
                height: '100vh', zIndex: 100, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
            }} component={'section'}>
                <CircularProgress size={70} />
            </Box>
        </Container>
    )

    return (
        <Box sx={{ height: '100vh', position: 'relative' }} component={'section'}>
            <MapRastreio
                positionCliente={positionCliente}
                positionEntregador={positionEntregador}
                positionEmpresa={positionEmpresa}
                etapaAtual={etapaAtual}
            />
            <Container maxWidth="xs" sx={{ height: '100vh', zIndex: 100 }} component={'section'}>
                <Grid container alignContent="space-between" sx={{ p: '1rem', height: '100vh' }} >
                    <Grid item xs={12} sx={{ zIndex: 100 }} >
                        <Card
                            sx={{
                                borderRadius: '7.5px',
                                p: '1rem .5rem',
                            }}
                            elevation={3}
                        >
                            <CardMedia
                                component="img"
                                image={logo}
                                title="Logo Empresa"
                                sx={{ width: '50%', margin: '0 auto' }}
                            />
                            <CardContent
                                sx={{
                                    p: '1rem 0',
                                    '&:last-child': {
                                        pb: '0',
                                    }
                                }}
                            >
                                <Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>
                                    Olá {nomeCliente}, {horarios.prevMinEntrega
                                        ? <>a previsão de entrega será <strong style={{ color: 'primary.main' }}>{`${horarios.prevMinEntrega} e ${horarios.prevMaxEntrega}`}</strong></>
                                        : 'seu pedido está sendo preparado e logo estará em sua direção'
                                    }.
                                </Typography>
                                <Typography sx={{ fontSize: '0.75rem', color: 'rgba(159, 159, 159, 1)' }}>
                                    Última atualização do mapa: {horarios.ultimaAtualizacao}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sx={{ zIndex: 100 }}>
                        <Card
                            sx={{ borderRadius: '7.5px' }}
                            elevation={3}
                        >
                            {(etapaAtual.nEtapa >= 0 && etapaAtual.nEtapa <= 3) &&
                                <CardContent>
                                    <Grid container spacing={1} >
                                        <Grid item xs={3}>
                                            {etapaAtual.nEtapa === 0 ? <BorderLinearProgress variant="indeterminate" /> :
                                                <BorderLinearProgress variant="determinate" value={etapaAtual.nEtapa >= 0 ? 100 : 0} />}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {etapaAtual.nEtapa === 1 ? <BorderLinearProgress variant="indeterminate" /> :
                                                <BorderLinearProgress variant="determinate" value={etapaAtual.nEtapa >= 1 ? 100 : 0} />}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {(etapaAtual.nEtapa === 2) ? <BorderLinearProgress variant="indeterminate" /> :
                                                <BorderLinearProgress variant="determinate" value={etapaAtual.nEtapa >= 2 ? 100 : 0} />}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {etapaAtual.nEtapa === 3 ? <BorderLinearProgress variant="indeterminate" /> :
                                                <BorderLinearProgress variant="determinate" value={etapaAtual.nEtapa >= 3 ? 100 : 0} />}
                                        </Grid>
                                    </Grid>
                                    <Typography
                                        sx={{
                                            fontSize: '1rem',
                                            m: '.75rem 0',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {etapaAtual.texto}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        <Avatar
                                            sx={{
                                                bgcolor: theme.palette.primary.main,
                                                width: 46,
                                                height: 46,
                                            }}
                                            alt="Remy Sharp"
                                            src={pedido.data?.dados.lista[0]?.entregadorUsuario?.perfilUrl || ''}
                                        >
                                            {pedido.data?.dados.lista[0]?.entregadorUsuario?.perfilUrl ? null : <SportsMotorsportsIcon />}
                                        </Avatar>
                                        <Typography sx={{ fontSize: '0.75rem', fontWeight: '500' }}>
                                            {entregadoresOnline.data?.dados.lista[0]
                                                ? `${entregadoresOnline.data?.dados.lista[0].nome}`
                                                : 'Entregador não alocado'
                                            }

                                        </Typography>
                                    </Box>
                                </CardContent>
                            }
                            {(etapaAtual.nEtapa === 4) &&
                                <CardContent sx={{ alignContent: "center", textAlign: "center" }}>
                                    <Grid container rowGap={'.5rem'} >
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <BorderLinearProgress variant="determinate" value={100} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <BorderLinearProgress variant="determinate" value={100} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <BorderLinearProgress variant="determinate" value={100} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <BorderLinearProgress variant="determinate" value={100} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontSize: '1rem',
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Seu pedido foi finalizado, avalie a entrega
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <StarRating
                                                estrelas={estrelas}
                                                setEstrelas={setEstrelas}
                                                size={'1.875rem'}
                                                cor='primary.main'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Comentário"
                                                name='comentario'
                                                value={comentario}
                                                multiline
                                                onChange={(e) => setComentario(e.target.value)}
                                                maxRows={5}
                                                inputProps={{ maxLength: 300 }}
                                            />
                                        </Grid>
                                        <Grid container justifyContent={"center"} textAlign={"center"} >
                                            <Grid item xs={8}>
                                                <Button
                                                    variant="contained"
                                                    disabled={estrelas === 0}
                                                    onClick={handleEnviarAvaliacao}
                                                >
                                                    Enviar avaliação
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Rastreio