import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Paper, useTheme, IconButton, Typography } from "@mui/material";
import { AddToPhotos, ArrowDownward, ArrowUpward, Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ButtonTableOrder, ChipSituacao, ModalOpcoes, SnackbarAlert, TableRowLoading, TableRowNotFound } from "src/ds/DesignSystem";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "src/ds/CustomHooksDs";
import { duplicarTabelaPagamentoStart, excluirTabelaStart, listaTabelaPagamentoStart } from "src/store/reducers/tabelaPagamento"; // Importa listaTabelaPagamentoStart
import { useNavigate } from "react-router-dom";

interface Empresa {
    empresaID: string;
    nome: string;
}

interface TipoVeiculo {
    tipoVeiculoID?: string;
    nome: string;
}

interface Valor {
    tabelaValorKmID: string;
    kmInicial: number;
    kmFinal: number;
    valor: number;
    taxaRetorno: number;
}

interface TabelaPagamentoProps {
    tabelasPagamento: Array<{
        tabelaValorID: string;
        pickID: string;
        pick: {
            pickID: string;
            nome: string;
        };
        dataCadastro: string;
        ativo: boolean;
        nome: string;
        valorDiariaPorHora: number;
        valorPontoAdicional: number;
        tipoVeiculoID: string;
        tipoVeiculo: TipoVeiculo | null;
        empresas: Empresa[];
        valores: Valor[];
        tiposVeiculo: TipoVeiculo[];
        empresaID: string;
    }>;
    loading: boolean;
    fezRequisicao?: boolean;
    atualizar: any;
    ultimaTabelaCadastrada: any;
    triggerEditar: boolean;
    setTriggerEditar: (trigger: boolean) => void;
}

const TabelaPagamento = ({ tabelasPagamento, loading, atualizar, ultimaTabelaCadastrada, triggerEditar, setTriggerEditar, fezRequisicao }: TabelaPagamentoProps) => {
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        nome: 'asc',
        dataCadastro: 'asc',
        valorDiariaPorHora: 'asc',
        valorPontoAdicional: 'asc',
    });
    const [visible, setVisible] = useState(false);
    const [excluir, setExcluir] = useState(false);
    const [row, setRow] = useState<any>(null);
    const [abrirOpcoes, setAbrirOpcoes] = useState(false);
    const snack = useSnackbar({ duration: 2000 });
    const response = useSelector((state: any) => state.tabelaPagamento?.duplicarTabelaPagamento);

    useEffect(() => {
        if (response.data?.sucesso === true) {
            snack.showSnackbar({ message: response?.data.mensagem, severity: 'success' });
            setAbrirOpcoes(false);
            atualizar();
        } else if (response.data?.sucesso === false) {
            snack.showSnackbar({ message: response?.data.erro, severity: 'error' });
        }
    }, [response.data]);

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        const updatedSortDirection = Object.keys(sortDirection).reduce((acc, key) => {
            acc[key] = key === column ? newSortDirection : 'asc';
            return acc;
        }, {} as { [key: string]: 'asc' | 'desc' });

        setSortDirection(updatedSortDirection);

        const payload = {
            paginacao: 20, 
            pagina: 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
        };

        dispatch(listaTabelaPagamentoStart(payload)); 
    };

    useEffect(() => {
        if (triggerEditar && ultimaTabelaCadastrada) {
            handleView(ultimaTabelaCadastrada);
            setTriggerEditar(false);
        }
    }, [triggerEditar, ultimaTabelaCadastrada]);

    const theme = useTheme();

    const handleView = (row: any) => {
        setVisible(true);
        setRow(row);
    }

    const handleExcluir = (row: any) => {
        setExcluir(true);
        setRow(row);
    }

    const handleDouble = (row: any) => {
        setRow(row);
        setAbrirOpcoes(true);
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleConfirm = () => {
        dispatch(excluirTabelaStart(row.tabelaValorID));
        setExcluir(false);
        setTimeout(() => {
            atualizar();
        }, 500);
    }

    return (
        <TableContainer component={Paper} sx={{ width: '100%', overflowY: 'auto', margin: '0 auto' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ borderTop: '1px solid #ccc' }}>
                        <TableCell align="center" sx={{ width: '5%' }}>
                            <ButtonTableOrder>
                                Situação
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('nome')} sortDirection={sortDirection} nome="nome">
                                Tabela
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('dataCadastro')} sortDirection={sortDirection} nome="dataCadastro">
                                Data de Cadastro
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder onClick={() => toggleSortDirection('empresas')} sortDirection={sortDirection} nome="empresas">
                                Empresas
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder>
                                Tipos de veículo
                            </ButtonTableOrder>
                        </TableCell>
                        <TableCell align="center" sx={{ width: '20%' }}>
                            <ButtonTableOrder>
                                Ações
                            </ButtonTableOrder>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : (
                        tabelasPagamento ?
                            tabelasPagamento?.map((row, index) => (
                                <TableRow key={index} hover>
                                    <TableCell align="center" sx={{ py: 2 }}>
                                        <ChipSituacao situacao={row.ativo ? 'Ativa' : 'Inativa'} label={row.ativo ? 'Ativa' : 'Inativa'} />
                                    </TableCell>
                                    <TableCell align="center" sx={{ py: 2 }}>{row.nome}</TableCell>
                                    <TableCell align="center" sx={{ py: 2 }}>{row.dataCadastro}</TableCell>
                                    <TableCell align="center" sx={{ py: 2 }}>
                                        {row.empresas && row.empresas.length > 0 ? (
                                            row.empresas.map((empresa: any, index: number) => (
                                                <div key={index}>{empresa?.nome || 'Sem nome'}</div>
                                            ))
                                        ) : (
                                            <div>Nenhum item disponível</div>
                                        )}
                                    </TableCell>
                                    <TableCell align="center" sx={{ py: 2 }}>
                                        <div>{row.tipoVeiculo?.nome || 'Sem nome'}</div>
                                    </TableCell>

                                    <TableCell align="center" sx={{ py: 2 }}>
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <Tooltip title="Duplicar" arrow placement="top">
                                                <IconButton onClick={() => handleDouble(row)}>
                                                    <AddToPhotos color="warning" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Editar" arrow placement="top">
                                                <IconButton onClick={() => { navigate(`/TabelaValor/Index/${row.tabelaValorID}`) }}>
                                                    <Edit color="secondary" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Excluir" arrow placement="top">
                                                <IconButton onClick={() => handleExcluir(row)}>
                                                    <Delete color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))
                            : <TableRowNotFound fezRequisicao={fezRequisicao} />
                    )}
                </TableBody>
            </Table>
            <ModalOpcoes
                open={excluir}
                onClose={() => setExcluir(false)}
                acao="Excluir"
                descricao={(
                    <Typography>
                        Tem certeza que deseja excluir <span style={{ fontWeight: 'bold' }}>{row?.nome}</span> da tabela?
                    </Typography>
                )}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action() { setExcluir(false) },
                    },
                    {
                        label: 'Excluir',
                        action() { handleConfirm() },
                    },
                ]}
            />
            <ModalOpcoes
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => { setAbrirOpcoes(false) }
                    },
                    {
                        label: 'Confirmar',
                        action: () => { dispatch(duplicarTabelaPagamentoStart({ tabelaValorID: row.tabelaValorID })); }
                    }
                ]}
                open={abrirOpcoes}
                onClose={() => setAbrirOpcoes(false)}
                acao='Duplicar'
                descricao='Tem certeza que deseja duplicar essa tabela?'
            />
            <SnackbarAlert {...snack} />
        </TableContainer>
    );
}

export default TabelaPagamento;
