import { Avatar, Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardPrincipal, ColumnGrid, GridPainelLateral, RowGrid } from "../../../ds";
import { ArrowForward } from "@mui/icons-material";
import { usePedido } from "../../../context/PedidoContext";
import { despacharRequest, limparDespachar } from "../../../store/reducers/criarPedido";
import { telefone } from "../../../ds/common";
import { emAndamentoStart, listarEntregadoresRequest } from "../../../store/reducers/config";
import { RootState } from "src/store/reducers";
import { useSnackbar } from "src/ds/CustomHooksDs";
import { ButtonLoading, HeaderCard, SnackbarAlert } from "src/ds/DesignSystem";
import { Entregador } from "src/dtos/EntregadoresDTO";
import { taxaExtraStart } from "src/store/reducers/taxaExtra";
import ModalTaxaExtra from "./ModalTaxaExtra";
import ModalConfigCobranca from "./ModalConfigCobranca";

interface AtribuirProps {
    close: () => void;
}

interface ErroDespachar {
    bairro: string;
    mensagem: string;
    sucesso: boolean;
    tabelaCobrancaID: string | null;
    tabelaValorID: string | null;
}

const AtribuirPedido = ({ close }: AtribuirProps) => {
    const dispatch = useDispatch();
    const entregadores = useSelector((state: RootState) => state.config.entregadores.lista);
    const [shouldClose, setShouldClose] = useState(false);
    const { pedidoAtual, rotaAtual } = usePedido();
    const atribuirStore = useSelector((state: RootState) => state.criarPedido?.atribuir);
    const atribuirSnackbar = useSnackbar({ duration: 5000 });
    const taxasExtra = useSelector((state: RootState) => state.taxaExtra?.lista.data?.dados?.lista);
    const manuais = taxasExtra?.filter(t => t.aplicarAutomaticamente === false) || [];
    const [entregadorSelecionado, setEntregadorSelecionado] = useState<Entregador | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [erroDespachar, setErroDespachar] = useState<ErroDespachar | null>(null);
    const [modalConfigCobranca, setModalConfigCobranca] = useState(false);

    const handleAtribuir = (entregador: Entregador | null, direto: boolean) => {
        console.log(entregador, direto);
        setEntregadorSelecionado(entregador);
        if (!entregador) return
        const payload = {
            // @ts-expect-error
            pedidoID: rotaAtual ? rotaAtual.map(p => p.pedidoID) : pedidoAtual?.pedidoID,
            usuarioID: entregador.usuarioID,
            rota: !!rotaAtual
        };

        if (manuais.length > 0 && !direto) {
            setOpenModal(true);
        } else {
            dispatch(despacharRequest(payload));
        }

        setShouldClose(false);
    };

    useEffect(() => {
        if (atribuirStore.error) {
            // @ts-expect-error
            atribuirSnackbar.showError(atribuirStore.error?.mensagem || errorPedido);
            setErroDespachar(atribuirStore.error)
        } else if (atribuirStore.data) {
            // @ts-expect-error
            atribuirSnackbar.showSuccess(atribuirStore.data?.mensagem || atribuirStore.data.mensagem);
            setShouldClose(true);
        }
        dispatch(limparDespachar());
    }, [atribuirStore.data, atribuirStore.error]);

    useEffect(() => {
        if (erroDespachar) {
            setModalConfigCobranca(true);
        }
    }, [erroDespachar])

    const handleCloseConfigCobranca = () => {
        setErroDespachar(null);
        setModalConfigCobranca(false);
    }

    useEffect(() => {
        if (shouldClose) {
            setTimeout(() => {
                close();
            }, 500)
        }
    }, [shouldClose]);

    useEffect(() => {
        dispatch(taxaExtraStart({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: ''
        }));
    }, [])

    useEffect(() => {
        const empresaID = pedidoAtual?.empresaID || (rotaAtual && rotaAtual[0]?.empresaID);

        if (empresaID) {
            dispatch(listarEntregadoresRequest({ empresaID }));
            dispatch(emAndamentoStart({ empresaID }));
        }
        setShouldClose(false);


    }, [pedidoAtual, rotaAtual]);

    return (
        <>
            <GridPainelLateral container sx={{ padding: '.5rem', justifyContent: 'stretch' }}>
                <HeaderCard
                    // @ts-expect-error
                    title={`Atribuir ${!rotaAtual ? `pedido #${pedidoAtual?.codigoQuatro}` : rotaAtual?.map(p => ` #${p.codigoQuatro}`)}`}
                    onClick={close}
                />
                <Box sx={{ flexGrow: 1, width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
                    {(entregadores && entregadores.length > 0)
                        ? entregadores.map((entregador: Entregador, index: number) => (
                            <CardPrincipal sx={{ py: '1rem', '&:last-child': { pb: '1rem' } }} key={index}>
                                <RowGrid>
                                    <Avatar sx={{ width: 48, height: 48, mr: '1rem' }}>{entregador.nome.charAt(0)}</Avatar>
                                    <ColumnGrid style={{ alignItems: 'flex-start' }}>
                                        <Typography variant="h6">{entregador?.nome}</Typography>
                                        <Typography variant="body2">{telefone(entregador?.telefone)}</Typography>
                                    </ColumnGrid>
                                    <ButtonLoading
                                        variant="iconeComTextoFilled"
                                        onClick={() => { handleAtribuir(entregador, false) }}
                                        style={{ marginLeft: 'auto' }}
                                        loading={atribuirStore.loading}
                                    >
                                        Atribuir <ArrowForward />
                                    </ButtonLoading>
                                </RowGrid>
                            </CardPrincipal>
                        ))
                        :
                        <Container sx={{ flexGrow: 1, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ textAlign: 'center', width: '100%' }} variant="notFound">
                                Nenhum Entregador Disponível
                            </Typography>
                        </Container>
                    }
                </Box>
            </GridPainelLateral>
            <SnackbarAlert {...atribuirSnackbar} />
            <ModalTaxaExtra open={openModal} setOpen={setOpenModal} data={manuais} entregador={entregadorSelecionado} />
            {modalConfigCobranca && <ModalConfigCobranca
                modalProps={{
                    open: modalConfigCobranca,
                    onClose: handleCloseConfigCobranca,
                }}
                erroDespachar={erroDespachar}
                callback={() => {
                    console.log('ativou')
                    handleAtribuir(entregadorSelecionado, true)
                }}
            />}
        </>
    );
}

export default AtribuirPedido;
