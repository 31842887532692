//* Máscaras e formatadores
export const formatarMoeda = (value) => {
    const numero = String(value).replace(/\D/g, '');
    return Number(numero / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
};

export const formatCardNumber = (value) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let matches = v.match(/\d{1,4}/g);
    let match = matches && matches.join(' ');
    return match ? match : '';
};

export const gif = 'https://i.gifer.com/4KY.gif'

export const replaceDecimalSeparator = (input) => {
    let inputStr = typeof input === 'number' ? input.toString() : input;

    return typeof inputStr === 'string' && inputStr.includes('.')
        ? inputStr.replace('.', ',')
        : inputStr;
};

export const cleanInput = (input) => {
    if (typeof input !== 'string') {
        input = String(input || '');
    }
    return input.replace(/[^0-9,]/g, '');
};

export const cleanMoneyInput = (value) => {
    if (!value) return '';

    let stringValue = String(value);

    let cleanedValue = stringValue.replace(/[^\d,]/g, '');

    return cleanedValue;
};



export const onlyNumbers = (value) => {
    if (!value) return '';
    const formatedValue = value.replace(/\D/g, '');
    return formatedValue
};

export const telefone = (input) => {
    if (input?.startsWith("0800")) return input;

    if (!input) return '';
    const cleaned = ('' + input).replace(/\D/g, '');

    const match9 = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

    const match8 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

    if (match9) {
        return `(${match9[1]}) ${match9[2]}-${match9[3]}`;
    } else if (match8) {
        return `(${match8[1]}) ${match8[2]}-${match8[3]}`;
    }

    return cleaned;
};

export const dinheiro = (valor) => {
    const valorString = String(valor);

    const numero = parseFloat(valorString.replace(/[^0-9.-]+/g, ""));

    if (isNaN(numero)) return valor;

    return numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

/**
 * @deprecated - Utilize o maskCpf do arquivo `ds/utils/maskCpf.ts`
 */
export const maskCpf = (input) => {
    // Remove todos os caracteres que não são números
    const valorString = String(input).replace(/\D/g, '');

    // Adiciona os pontos e o hífen conforme a máscara de CPF
    const cpfMascarado = valorString
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return cpfMascarado;
}

export const formatarCEP = (input) => {
    if (!input) return '';
    const cleaned = input.replace(/\D/g, '');
    // Aplica a formatação de CEP: XXXXX-XXX
    const formatted = cleaned.replace(/^(\d{5})(\d{3})$/, '$1-$2');
    return formatted;
};

export const unSanitizeSituacao = (situacao) => {
    switch (situacao) {
        case 'Criado':
            return 'CRIADO';
        case 'Pronto':
            return 'PRONTO';
        case 'Despachado':
            return 'DESPACHADO';
        case 'Recebido':
            return 'RECEBIDO';
        case 'Em rota':
            return 'EM_ROTA';
        case 'Aceito':
            return 'ACEITO';
        case 'No local':
            return 'NO_ESTABELECIMENTO';
        case 'Retornando':
            return 'RETORNANDO';
        case 'Finalizado':
            return 'FINALIZADO';
        case 'Cancelado':
            return 'CANCELADO';
        default:
            return situacao;
    }
}

export const formatCpfOrCnpj = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length <= 11) {
        return value.replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
        return value.replace(/^(\d{2})(\d)/, '$1.$2')
            .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            .replace(/\.(\d{3})(\d)/, '.$1/$2')
            .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    }
};

export const formatCnpj = (value) => {
    value = value.replace(/\D/g, '');

    return value.replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
}

export const getSituacaoStyles = (situacao, theme) => {
    if (!situacao) return {};
    const newSituacao = situacao.toUpperCase();
    const situacaoColors = {
        'CRIADO': theme.palette.status['Criado'],
        'PRONTO': theme.palette.status['Pronto'],
        'DESPACHADO': theme.palette.status['Despachado'],
        'RECEBIDO': theme.palette.status['Recebido'],
        'EM ROTA': theme.palette.status['Em rota'],
        'EM_ROTA': theme.palette.status['Em rota'],
        'ACEITO': theme.palette.status['Aceito'],
        'NO LOCAL': theme.palette.status['No local'],
        'RETORNANDO': theme.palette.status['Retornando'],
        'CANCELADO': theme.palette.status['Recebido'],
        'FINALIZADO': theme.palette.status['Retornando'],
        'ATIVA': theme.palette.green[500],
        'INATIVA': theme.palette.red[500]
    };
    const backgroundColor = situacaoColors[newSituacao] || '#9e9e9e';
    const color = theme.palette.getContrastText(backgroundColor);

    return {
        backgroundColor,
        color
    };
};

