import { Box, Card, Stack, Typography, useTheme } from "@mui/material"
import { useForm } from "react-hook-form"
import { CardTabelaTotal, ExportarTabela, TableNavigation } from "src/ds/DesignSystem"
import { CardFormFiltros, CardFormFiltrosRef } from "src/ds/Forms"
import circleDollar from "../../assets/icons/circle-dollar.svg";
import TabelaFinanceiroPorEntregador from "src/components/FinanceiroPorEntregador/TabelaFinanceiroPorEntregador";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import { dinheiro } from "src/ds/common";
import { useEffect, useRef, useState } from "react";

const FinanceiroPorEntregador = () => {
  const theme = useTheme()
  const hookForm = useForm({
    defaultValues: {
      pagina: 1,
      paginacao: 20,
      dataInicio: null,
      dataFim: null,
    },
    mode: "onChange",
  })

  const financeiroPorEntregador = useSelector((state: RootState) => state.entregadores.financeiroPorEntregador)
  const financeiroPorEntregadorDados = useSelector((state: RootState) => state.entregadores.financeiroPorEntregador.data?.dados)

  const [primeiraRenderizacao, setPrimeiraRenderizacao] = useState(true)
  const cardRef = useRef<CardFormFiltrosRef>(null)
  const { watch } = hookForm
  useEffect(() => {
    if (primeiraRenderizacao) { setPrimeiraRenderizacao(false); return }
    const subscription = watch((_value, { name }) => {
      if (name === 'pagina' || name === 'paginacao') {
        cardRef.current?.onSubmit();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, primeiraRenderizacao]);

  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <CardFormFiltros
          ref={cardRef}
          formMethods={hookForm}
          dispatchMethods={{
            actionName: 'financeiroPorEntregadorStart',
          }}
          fields={[
            {
              name: "buscar",
              value: "buscar",
              label: "Buscar",
              type: "input",
            },
            {
              name: "Data Inicial",
              value: "dataInicio",
              label: "Data Início",
              type: "dateTime",
              rules: {
                required: 'Data Início obrigatório',
              }
            },
            {
              name: "Data Final",
              value: "dataFim",
              label: "Data final",
              type: "dateTime",
              rules: {
                required: 'Data final obrigatório',
              }
            },
            {
              name: "empresaID",
              value: "empresaID",
              label: "Empresa",
              type: "autocompleteEmpresa",
            },
          ]}
          loading={financeiroPorEntregador.loading}
        />
      </Box>
      <Box sx={{ p: 2 }}>
        <Stack sx={{ gap: 2, flexWrap: 'wrap' }} useFlexGap direction="row">
          <CardTabelaTotal
            srcIcon={circleDollar}
            altIcon="Valor total a pagar"
            bgcolor={theme.palette.blue[600]}
            texts={{
              title1: 'Valor total a pagar',
              subtitle1: dinheiro(financeiroPorEntregador.data?.totalTaxasEntregador) ?? 'R$0,00',
            }}
          />
          <CardTabelaTotal
            srcIcon={circleDollar}
            altIcon="Valor total pago"
            bgcolor={theme.palette.green[600]}
            texts={{
              title1: 'Valor total pago',
              subtitle1: dinheiro(financeiroPorEntregador.data?.totalTaxasEntregadorProgramadas) ?? 'R$0,00',
            }}
          />
          <CardTabelaTotal
            srcIcon={circleDollar}
            altIcon="Valor antecipado"
            bgcolor={theme.palette.red[600]}
            texts={{
              title1: 'Valor antecipado',
              subtitle1: dinheiro(financeiroPorEntregador.data?.totalAntecipado) ?? 'R$0,00',
            }}
          />
          <CardTabelaTotal
            srcIcon={circleDollar}
            altIcon="Valor antecipado a pagar"
            bgcolor={'orange'}
            texts={{
              title1: 'Valor antecipado a pagar',
              subtitle1: dinheiro((financeiroPorEntregador.data?.totalAntecipado ?? 0) - (financeiroPorEntregador.data?.totalAntecipadoRecebido ?? 0)) ?? 'R$0,00',
              title2: 'Valor antecipado pago',
              subtitle2: dinheiro(financeiroPorEntregador.data?.totalAntecipadoRecebido) ?? 'R$0,00',
            }}
          />
        </Stack>
      </Box>
      <Card elevation={1} sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">FINANCEIRO POR ENTREGADOR</Typography>
          <ExportarTabela dispatchMethods={{
            actionName: 'exportarFinanceiroEntregadorStart', payload: {
              dtInicial: hookForm.getValues('dataInicio'),
              dtFinal: hookForm.getValues('dataFim'),
            }
          }} loading={financeiroPorEntregador.loading} />
        </Box>
        {financeiroPorEntregadorDados?.ultimaPagina ? (
          <TableNavigation
            indiceInicial={financeiroPorEntregadorDados.indiceInicial}
            indiceFinal={financeiroPorEntregadorDados.indiceFinal}
            indiceTotal={financeiroPorEntregadorDados.total}
            ultimaPagina={financeiroPorEntregadorDados?.ultimaPagina}
            hookForm={hookForm}
          // namePagina="pagina"
          // namePaginacao="paginacao"
          />
        ) : null}
        <TabelaFinanceiroPorEntregador
          fezRequisicao={cardRef.current?.fezRequisicao}
          loading={financeiroPorEntregador.loading}
          entregadores={financeiroPorEntregadorDados?.lista}
        />
      </Card>
    </Box >
  )
}

export default FinanceiroPorEntregador